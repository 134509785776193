<template>
  <div style="margin: 0 20px">
    <!-- <label style="color: gray; font-weight: 400; font-size: 23px">Edit User</label>
    <b-button>xxx</b-button> -->
    <div class="columns is-multiline" style="margin-top: 2.8vh">
      <!-- <div class="column">
        <label style="color: gray; font-weight: 400; font-size: 23px">Edit User</label>
      </div> -->
      <!-- <div class="column" style="text-align: right">
        <b-button type="is-danger" @click="deleteModelVisible = true" class="float-right"
          >Delete</b-button
        >
      </div> -->
    </div>
    <div class="mb-5">
      <label style="color: #0083ca; font-size: 20px">User Info</label>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :type="invalidEmpId == '' ? '' : 'is-danger'" :message="invalidEmpId">
          <!-- <template slot="label"
            >Employee ID <span class="has-text-danger">*</span></template
          > -->
          <template slot="label"
            >Employee ID <span class="has-text-danger">*</span></template
          >
          <b-input
            v-model="formData.username"
            @blur="onBlur"
            @focus="isFocused = true"
            @input="onInput"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidName == '' ? '' : 'is-danger'" :message="invalidName">
          <template slot="label">Name <span class="has-text-danger">*</span></template>
          <b-input @input="validateName('invalidName')" v-model="formData.name"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidMobile == '' ? '' : 'is-danger'" :message="invalidMobile">
          <template slot="label">Mobile <span class="has-text-danger">*</span></template>
          <b-input
            v-model="formData.contact"
            @input="resetInvalidStatus('invalidMobile')"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :type="invalidEmail == '' ? '' : 'is-danger'" :message="invalidEmail">
          <template slot="label"
            >Email <span class="has-text-danger">*</span></template
          >
          <b-input
            v-model="formData.email"
            @input="resetInvalidStatus('invalidEmail')"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidRole == '' ? '' : 'is-danger'" :message="invalidRole">
          <template slot="label">Role<span class="has-text-danger">*</span></template>
          <b-select
            expanded
            placeholder="Select role"
            v-model="formData.role"
            @input="resetInvalidStatus('invalidRole')"
          >
            <option v-for="role in roleList" :key="role.id" :value="role.id">
              {{ role.role_name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidBranch == '' ? '' : 'is-danger'" :message="invalidBranch">
          <template slot="label">Branch<span class="has-text-danger">*</span></template>
          <b-select
            expanded
            v-model="formData.branch"
            @input="resetInvalidStatus('invalidBranch')"
          >
            <option v-for="branch in branchList" :key="branch.id" :value="branch.branch_code">
              {{ branch.branch_name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns is-multiline" style="margin-top: 1.5vh">
      <div class="column is-full">
        <label style="color: #0083ca; font-size: 20px"
          >Status<span class="has-text-danger">*</span></label
        >
      </div>
      <div class="check-box-container">
        <!-- <div class="block"> -->
        <b-radio
          v-model="formData.disabled"
          type="is-primary"
          name="status"
          native-value="0"
        >
          Active
        </b-radio>
        <b-radio
          v-model="formData.disabled"
          type="is-primary"
          name="status"
          native-value="1"
        >
          Not Active
        </b-radio>

        <!-- </div> -->
      </div>
    </div>
    <div class="align-right mt-5">
      <b-button type="is-primary is-light" class="mr-5" size="is-normal" @click="goBack"
        >Cancel</b-button
      >
      <b-button type="is-primary" size="is-normal" @click="validateAndSubmit"
        >Edit User</b-button
      >
    </div>

    <b-modal v-model="deleteModelVisible" :width="400" scroll="keep" animation="zoom-out">
      <div class="model-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete User</p>
          <button
            @click="deleteModelVisible = false"
            type="button"
            class="delete"
          ></button>
        </header>

        <div class="modal-card-body">
          <div class="content pt-2">
            <p>Are you sure that you want to delete this user from the system ?</p>
          </div>
        </div>

        <footer class="modal-card-foot">
          <div class="is-full column py-0 px-0">
            <div class="column">
              <div class="columns">
                <div class="column"></div>
                <div class="buttons">
                  <b-button
                    rounded
                    id="detele_now"
                    @click="deleteRecord"
                    type="is-primary is-small"
                    >Delete Now</b-button
                  >
                  <b-button
                    rounded
                    @click="deleteModelVisible = false"
                    type="is-danger is-small"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
    <div class="tile custom-tile-narrow">
      <div class="column">
        <div class="columns">
          <div class="column">
            <p class="has-text-danger is-italic">
              Fields marked with <big>*</big> are mandatory
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <section class="mb-5" v-if="formIsInvalid">
      <b-message type="is-danger">
        Form contains invalid fields. Please make sure you have filled the form properly.
      </b-message>
    </section> -->
  </div>
</template>

<style>
.align-right {
  text-align: right;
}

.check-box-container {
  display: flex;
  width: 20%;
  justify-content: space-between;
  margin-left: 15px;
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";

export default {
  name: "EditUser",
  components: {},
  data() {
    return {
      user: {
        user_name: "",
        name: "",
        contact: "",
        email: "",
        branch: "",
        password: "",
        disabled: 0,
      },
      branchList: [],
      roleList: [],
      formData: {
        name: "",
        username: "",
        email: "",
        contact: "",
        branch: "",
        role: "",
        permissions: "",
        disabled: 0,

        //new fields for SDB bank requirement
        emp_title : "",
        emp_name : "",
        emp_finit : "",
        emp_designation : "",
        emp_Department : "",
        emp_Department_code : "",
        branch_coordinator : "",
        supervisor : "",
        second_supervisor : "",
        emp_mob1 : "",
        emp_mob2 : "",
        emp_cat : "",
        branch_previous_one : "",
        branch_previous_two : "",
        classfication_name : "",
        emp_status : "",
        emp_lifecycle_status : "",
      },
      oldName: "",
      oldUsername: "",
      oldEmail: "",
      oldContact: "",
      oldBranch: "",
      oldRole: "",
      oldPermissions: "",
      oldDisabled: "",
      deleteModelVisible: false,
      invalidName: "",
      invalidMobile: "",
      invalidEmail: "",
      invalidEmpId: "",
      invalidRole: "",
      invalidBranch: "",
      formIsInvalid: false,
      employeeBranch: "",
      employeeId: "",
      regex: {
        name: /^[a-zA-Z][a-zA-Z ]{1,128}$/,
        userName: /^[a-zA-Z0-9._]{1,100}$/,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        contact: /^[0-9]{10}$/,
      },
    };
  },
  methods: {
    validateForm: function () {
      const comp = this;
      var formIsValid = true;
      comp.btnDisabled = true;

      if (
        comp.oldName === comp.formData.name &&
        comp.oldUsername === comp.formData.username &&
        comp.oldEmail === comp.formData.email &&
        comp.oldContact === comp.formData.contact &&
        comp.oldBranch === comp.formData.branch &&
        comp.oldRole === comp.formData.role &&
        comp.oldPermissions === comp.formData.permissions &&
        comp.oldDisabled === comp.formData.disabled
      ) {
        BuefyHelper.showToastMessage("No changes made", "is-danger");
        formIsValid = false;
      }

      // name
      if (comp.formData.name == "") {
        comp.invalidName = "This field cannot left blank";
        formIsValid = false;
      } else if (!comp.regex.name.test(comp.formData.name)) {
        comp.invalidName =
          "Name cannot contain numbers or special characters and should be below 128 characters";
        formIsValid = false;
      }

      // userName
      if (comp.formData.username == "") {
        comp.invalidEmpId = "This field cannot left blank";
        formIsValid = false;
      }
      // else if (!this.regex.userName.test(this.formData.username)) {
      //   this.invalidUsername =
      //     "Username cannot contain special characters and should be below 100 characters";
      //   formIsValid = false;
      // }

      //email
      if (comp.formData.email == "") {
        comp.invalidEmail = "This field cannot left blank";
        formIsValid = false;
      } else if (!comp.regex.email.test(comp.formData.email)) {
        comp.invalidEmail = "Please enter a valid Email Address";
        formIsValid = false;
      }
      // else if (this.formData.email.length > 128) {
      //   this.invalidEmail = "Email should be below 128 characters";
      //   formIsValid = false;
      // }

      // contact
      if (comp.formData.contact == "") {
        comp.invalidMobile = "This field cannot left blank";
        formIsValid = false;
      }
      else if (!this.regex.contact.test(this.formData.contact)) {
        comp.invalidMobile = "Please enter a valid Mobile number.";
        formIsValid = false;
      }

      // role
      if (comp.formData.role == "") {
        comp.invalidRole = "This field cannot left blank";
        formIsValid = false;
      }

      // branch
      if (comp.formData.branch == "") {
        comp.invalidBranch = "This field cannot left blank";
        formIsValid = false;
      }

      // password
      if (comp.formData.password == "") {
        comp.invalidPassword = "This field cannot left blank";
        formIsValid = false;
      }
      // else if (this.formData.password.length < 8) {
      //   this.invalidPassword = "Password must contain at least 8 characters";
      //   formIsValid = false;
      // }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    onInput() {
      if (!this.isFocused) {
        this.validateEmpId('invalidEmpId');
      }
    },

    onBlur() {
      this.isFocused = false;
      this.validateEmpId('invalidEmpId');
    },
    
    submitData: function () {
      NetworkManager.apiRequest("backend/user/edit", this.formData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval successfully",
            "is-success"
          );
          this.$router.push("/user");
        } else if (e.success === false) {
          BuefyHelper.showToastMessage(e.data.message, "is-danger");
        }
      });
    },
    deleteRecord: function () {
      NetworkManager.apiRequest("backend/user/delete", this.formData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval successfully",
            "is-success"
          );
          // location.reload();
          this.$router.push("/user");
        } else {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval failed",
            "is-danger"
          );
        }
      });
    },
    validateAndSubmit: function () {
      if (this.validateForm()) {
        this.submitData();
      } else {
        this.formIsInvalid = true;
      }
    },
    resetInvalidStatus: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;
    },
    validateEmpId: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;

      if (this.formData.username == "") {
        this.invalidEmpId = "This field cannot left blank";
      }
      // else if (this.user.user_name.length != 4) {
      //   this.invalidEmpId = "Employee ID should be 4 characters";
      // }
      // else if (!this.regex.userName.test(this.user.user_name)) {
      //   this.invalidEmpId =
      //     "Employee ID cannot contain special characters and strings";
      // }
      else{
        this.invalidEmpId = "";
        this.fillValues();
      }
    },

    fillValues(){
      this.employeeId = this.formData.username;
      // if(this.employeeId.startsWith('0')){
      //   this.employeeId =  this.employeeId.slice(1);
      // }
      let digits = this.employeeId.match(/\d+/);
      let convertedEmpId =  digits ? digits[0] : '';
      // console.log("convertedEmpId",convertedEmpId);

      NetworkManager.apiRequest("backend/sdb/getEmployerDetail", {employeeId:convertedEmpId}, (e) => {
          if (e.success == false) {
            // BuefyHelper.showToastMessage(e.message, "is-danger");
            // BuefyHelper.showToastMessage(
            //   "Employee ID is invalid",
            //   "is-danger"
            // );
          };
          if (e.data.emp_branch_code.startsWith('0')) {
            this.employeeBranch =  e.data.emp_branch_code.slice(1);
            if(this.employeeBranch == "1"){
              if(e.data.emp_Department_code != ""){
                this.employeeBranch = "99";
              }else{
                this.employeeBranch = "1";
              }
            }
          }
          // console.log("employee details",e.data);
          this.formData.name = e.data.emp_fullname;
          this.formData.contact = e.data.emp_mob1;
          this.formData.email = e.data.emp_email;
          this.formData.branch = this.employeeBranch;

          //new fields for SDB bank requirement
          this.formData.emp_title = e.data.emp_title
          this.formData.emp_name = e.data.emp_name
          this.formData.emp_finit = e.data.emp_finit
          this.formData.emp_designation = e.data.emp_designation
          this.formData.emp_Department = e.data.emp_Department
          this.formData.emp_Department_code = e.data.emp_Department_code
          this.formData.branch_coordinator = e.data.branch_coordinator
          this.formData.supervisor = e.data.supervisor
          this.formData.second_supervisor = e.data.second_supervisor
          this.formData.emp_mob1 = e.data.emp_mob1
          this.formData.emp_mob2 = e.data.emp_mob2
          this.formData.emp_cat = e.data.emp_cat
          this.formData.branch_previous_one = e.data.branch_previous_one
          this.formData.branch_previous_two = e.data.branch_previous_two
          this.formData.classfication_name = e.data.classfication_name
          this.formData.emp_status = e.data.emp_status
          this.formData.emp_lifecycle_status = e.data.emp_lifecycle_status
        });
    },
    validateName: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;

      const pattern = /^[a-zA-Z ]*$/;

      if (!pattern.test(this.formData.name)) {
        this.invalidName = "Please enter only letters in the name field";
      } else {
        this.invalidName = ""; // Clear the error message if the input is valid
      }
    },

    getItemForEdit(){
      var comp = this;
      // console.log("self.$route", comp.$route.params.username);
      const data = {
        username: comp.$route.params.username,
      };
      NetworkManager.apiRequest("backend/user/getItemForEdit", data, (e) => {
        // console.log("user/getItemForEdit", e.data);
        comp.branchList = e.data.branchList;
        comp.roleList = e.data.roleList;
        comp.formData = e.data.itemData;

        // console.log("FormData", comp.formData);


        comp.oldName = comp.formData.name;
        comp.oldUsername = comp.formData.username;
        comp.oldEmail = comp.formData.email;
        comp.oldContact = comp.formData.contact;
        comp.oldBranch = comp.formData.branch;
        comp.oldRole = comp.formData.role;
        comp.oldPermissions = comp.formData.permissions;
        comp.oldDisabled = comp.formData.disabled;
      });
    }

   
  },
  mounted() {
    this.getItemForEdit();
  },
};
</script>
