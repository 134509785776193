<template>
    <div
      style="max-height: 100%; min-height: 100%; overflow-x: scroll; overflow-y: hidden"
      class="pending-table-container"
    >
      <div
        style="
          width: 100%;
          color: gray;
          font-weight: 400;
          font-size: 15px;
          margin: 20px auto;
        "
      >
        <!-- Data table with full feature -->
      </div>
  
      <TableComponent
        :columns="columns"
         height="60vh"
        :tableData="pendingList"
        :isLoading="isLoading"
        :objectList="filterFields"
        @tableFilter="
          () => {
            tableFilterRequestHandler();
          }
        "
        @resetFilter="
          () => {
            resetFilter();
          }
        "
        @applicationViewer="pendingApplicationViewer"
      />
    </div>
</template>
  
  <script>
  import TableComponent from "@/components/TableComponent.vue";
  import NetworkManager from "@/network";
  import { FilterInputFiled } from "@/helpers/FilterInputFiled";
  import { useAuthStore } from "@/store/authStore";
  import DateConverter from "@/helpers/DateConverter";
  
  export default {
    name: "FailedSmsApplications",
    components: {
      TableComponent,
    },
    setup() {
      const authStore = useAuthStore();
      return { authStore };
    },
  
    data() {
      return {
        pendingList: [],
  
        columns: [
          {
              field: "id",
              label: "Application Id",
          },
          {
              field: "branch",
              label: "Branch Code",
          },
          
          {
            field: "account_ownership",
            label: "Account Ownership",
          },
          {
            field: "product_code",
            label: "Product Code",
          },
          {
            field: "account_number",
            label: "Account Number",
          },
    
          {
            field: "title",
            label: "Title",
          },
          {
            field: "full_name",
            label: "Full Name",
          },
          {
            field: "nic",
            label: "NIC",
          },
          // {
          //   field: "applicant_individual_account_type",
          //   label: "Product Type",
          // },
          {
            field: "primary_mobile_number",
            label: "Primary Mobile Number",
          },
          {
            field: "updated_at",
            label: "Updated Time",
          },
          {
            field: "created_at",
            label: "Applied Time",
          },
          {
            field: "sms_status",
            label: "Status",
          },
          {
            field: "applicationView",
            label: "Actions",
          },
        ],
        filterFields: [
          new FilterInputFiled("NIC", "", "", "INPUT"),
          new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
          new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
          new FilterInputFiled("Search", "", "", "BUTTON"),
          new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
        ],
        isLoading: true,
        isFullPage: false,
        // isTableComponentLoading: false
      };
    },
  
    watch : {
      filterFields: {
        handler(){
          this.filterButtonStateHandler()
        },
        deep: true
      }
    },
  
    methods: {
     
      tableFilterRequestHandler: function () {
        this.isLoading = true;
        NetworkManager.apiRequest(
          "backend/applicant/filterSmsNotificationData",
          {
            nic: this.filterFields[0].value,
            from_date: this.filterFields[1].value
            ? DateConverter.dateFormater(this.filterFields[1].value)
            : "",
          to_date: this.filterFields[2].value
            ? DateConverter.dateFormater(this.filterFields[2].value)
            : "",
            sms_status:"Failed"

          },
          (e) => {
          if (e.success === true) {
            this.isLoading = false;
            // console.log(e.data)
            e.data.map((data) => {
              data.updated_at = DateConverter.dateFormater(data.updated_at);
              data.created_at = DateConverter.dateFormater(data.created_at);
            });
            this.pendingList = e.data;
            // console.log("this.filterdata ", e.data);
          } else {
            this.isLoading = false;
          }
        },
        false
      );
      },

      filterButtonStateHandler : function(){
        if(this.filterFields[0].value !== "" || this.filterFields[1].value !== "" || this.filterFields[2].value !== "" ){
          this.filterFields[3].isButtonDisabled = false
          this.filterFields[4].isButtonDisabled = false
        }
        else{
          this.filterFields[3].isButtonDisabled = true
          this.filterFields[4].isButtonDisabled = true
        }
      },
  
      resetFilter: function () {
        this.isLoading = true;
        NetworkManager.apiRequest(
        
        "backend/applicant/getSmsNotificationData",
        {sms_status: "Reject" },
        (e) => {
        if (e.success === true) {
            this.isLoading = false;
            const activatedList = e.data.list.filter(data => data.sms_status === "Reject");
            
            activatedList.forEach(data => {
                data.updated_at = DateConverter.dateFormater(data.updated_at);
                data.created_at = DateConverter.dateFormater(data.created_at);
            });
            this.pendingList = activatedList;
            // console.log("Activated SMS Notification Data: ", activatedList);
        } else {
            this.isLoading = false;
        }
       },
       false
      ); 
      //  Reset filter fields
        this.filterFields.forEach((field) => {
              field.value = "";
        });
    },
      //   this.isLoading = true;
      //   NetworkManager.apiRequest(
      //     "backend/applicant/getSmsNotificationData",
      //   {sms_status: "Failed" },
      //   (e) => {
      //   if (e.success === true) {
      //       this.isLoading = true;
      //       const activatedList = e.data.list.filter(data => data.sms_status === "Failed");
            
      //       activatedList.forEach(data => {
      //           data.updated_at = DateConverter.dateFormater(data.updated_at);
      //           data.created_at = DateConverter.dateFormater(data.created_at);
      //       });
      //       this.pendingList = activatedList;
      //       console.log("Activated SMS Notification Data: ", activatedList);
      //   } else {
      //       this.isLoading = false;
      //   }
      //  },
      //  false,
      
      // );
      //   // Reset filter fields
      //   this.filterFields.forEach((field) => {
      //         field.value = "";
      //   });
      // },
  
      pendingApplicationViewer: function (tableValue) {
      //   if (tableValue.sms_status === "Activated") {
      //     const route = this.$router.resolve({
      //       name: "sms-activation-view",
      //       params: { id: tableValue.id},
      //     });
      //     window.open(route.href, "_blank");
       
      //  }
       if (tableValue.sms_status === "Reject") {
          const route = this.$router.resolve({
            name: "sms-failed-view",
            params: { id: tableValue.id},
          });
          window.open(route.href, "_blank");

      }

    },
  },
  
    mounted() {

      this.filterButtonStateHandler();


      NetworkManager.apiRequest(
        "backend/applicant/getSmsNotificationData",
        {sms_status: "Reject" },
        (response) => {
        if (response.success === true) {
            this.isLoading = false;
            const activatedList = response.data.list.filter(data => data.sms_status === "Reject");
            
            activatedList.forEach(data => {
                data.updated_at = DateConverter.dateFormater(data.updated_at);
                data.created_at = DateConverter.dateFormater(data.created_at);
            });
            this.pendingList = activatedList;
            // console.log("Activated SMS Notification Data: ", activatedList);
        } else {
            this.isLoading = false;
        }
       },
       false
      );  
    
    },
  };
  </script>
  