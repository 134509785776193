
<template>
    <div>
        <MyCustomComp :myData="array" @valueChanged="(e) => { array = e }"></MyCustomComp>

        <p v-for="option, i in array" :key="option.value + '' + i">{{ option.value }}</p>
    </div>
</template>

<script>
import { MyFilters } from '@/components/MyCustomComp.vue';
import MyCustomComp from '@/components/MyCustomComp.vue';

export default {

    name: "MyComp",
    components: {
        MyCustomComp
    },
    data() {
        return {
            array: [
                new MyFilters('', 'INPUT', []),
                new MyFilters('', 'SELECT', ['Male', 'Female']),
            ]
        }
    },
    mounted() {

    }
}
</script>