var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pending-table-container",staticStyle:{"max-height":"90%","min-height":"90%","overflow-x":"scroll","overflow-y":"scroll"}},[_c('div',{staticStyle:{"width":"100%","color":"gray","font-weight":"400","font-size":"15px","margin":"20px auto"}}),_c('TableComponent',{attrs:{"height":"40vh","columns":_vm.columns,"excelColumns":_vm.excelColumns,"tableData":_vm.pendingList,"objectList":_vm.filterFields,"isLoading":_vm.isLoading,"shouldShowExcelComponent":true,"name":_vm.name},on:{"tableFilter":() => {
        _vm.tableFilterRequestHandler();
      },"resetFilter":() => {
        _vm.resetFilter();
      },"changeValues":(item) => {
        _vm.changeValues(item);
      },"applicationViewer":_vm.smsActivationViewer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }