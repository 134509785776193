<template>
  <div>
    <div style="height: 100vh; overflow-y: auto; padding-bottom: 130px">
      <div class="bar-columns">
        <!-- First Row -->
        <div class="row" @click="toggleTableAndArrow(1)">
          <div class="bar">
            User Requests<span
              class="arrow"
              :class="{ 'arrow-up': showArrowFlag[1] }"
              >&#62;</span
            >
          </div>
        </div>
        <!-- Table for First Row -->
        <TableComponent
          v-if="userList.length != 0 && showTableFlag[1]"
          :columns="columns"
           height="55vh"
          :tableData="userList"
          :isHidden="true"
          header="User"
          :loggedInUserId="loggedInUserId"
          @inputChange="
            () => {
              valueOnChangee();
            }
          "
          @showModal="
            (id) => {
              showModal(id);
            }
          "
          type="text"
        />

        <!-- Second Row -->
        <div class="row" @click="toggleTableAndArrow(2)">
          <div class="bar">
            Role Requests<span
              class="arrow"
              :class="{ 'arrow-up': showArrowFlag[2] }"
              >&#62;</span
            >
          </div>
        </div>
        <!-- Table for Second Row -->
        <TableComponent
          height="45vh"
          v-if="roleList.length != 0 && showTableFlag[2]"
          :columns="roleColumns"
          :tableData="roleList"
          :isHidden="true"
          header="Role"
          :loggedInUserId="loggedInUserId"
          @inputChange="
            () => {
              rolealueOnChangee();
            }
          "
          @showRoleModal="
            (id) => {
              showRoleModal(id);
            }
          "
          type="text"
        />

        <!-- Third Row -->
        <!-- <div class="row" @click="toggleTableAndArrow(3)">
          <div class="bar">
            Product Requests<span
              class="arrow"
              :class="{ 'arrow-up': showArrowFlag[3] }"
              >&#62;</span
            >
          </div>
        </div> -->
        <!-- Table for Third Row -->

        <!-- <div v-if="hasElementsBehind" class="load-more">
          <button @click="loadMore">Load More</button>
        </div> -->
        <b-modal
          class="modal-wrapper"
          v-model="modalIsVisible"
          :width="550"
          scroll="clip"
          animation="zoom-out"
        >
          <div class="model-card">
            <header
              class="modal-card-heading"
              style="
                border-top-left-radius: 32px;
                border-top-right-radius: 32px;
              "
            >
              <!-- change the colour of text to white -->
              <div style="display: flex">
                <p
                  class="modal-card-title"
                  id="change_color"
                  style="
                    color: #0083ca;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-weight: 700;
                    padding-left: 50px;
                    padding-top: 30px;
                    padding-bottom: 25px;
                    align-items: center;
                    font-family: Poppins;
                    justify-content: center;
                  "
                >
                  {{ modelTitles[user.pending_action] }} User
                  <!-- <span style="margin-left:270px;">
            </span> -->
                </p>
                <button class="close-button" @click="modalIsVisible = false">
                  ✕
                </button>
              </div>

              <!-- <div>
                <div class="field-details" style=" color:black; font-size:14px; padding-right:100px; padding-top:16px;" >{{ user.username }}</div>
                <div
                  v-if="user.original_record.username != user.username"
                  class="dual-auth-model-old-value has-text-danger-dark"
                >
                  {{ user.original_record.username }}
                </div>
                </div>
               -->
            </header>

            <div class="modal-card-body" style="padding: 0px 50px 0px 50px">
              <div class="content pt-2">
                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.username != user.username
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Employee ID
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ user.username }}
                    </div>
                    <div
                      v-if="user.original_record.username != user.username"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{ user.original_record.username }}
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.name != user.name
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Name
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ user.name }}
                    </div>
                    <div
                      v-if="user.original_record.name != user.name"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{ user.original_record.name }}
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.email != user.email
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Email
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ user.email }}
                    </div>
                    <div
                      v-if="user.original_record.email != user.email"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{ user.original_record.email }}
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.contact != user.contact
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Mobile
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ user.contact }}
                    </div>
                    <div
                      v-if="user.original_record.contact != user.contact"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{ user.original_record.contact }}
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.branch != user.branch
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Branch
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ user.branch }}
                    </div>
                    <div
                      v-if="user.original_record.branch != user.branch"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{ user.original_record.branch }}
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.role != user.role
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Role
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ user.role }}
                      <div
                        v-if="user.original_record.role != user.role"
                        class="dual-auth-model-old-value has-text-danger-dark original-record"
                      >
                        {{ user.original_record.role }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        user.original_record.disabled != user.disabled
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Status
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span>
                      <span
                        :class="{
                          'text-success': user.disabled == 0,
                          'text-danger': user.disabled != 0,
                        }"
                      >
                        {{ user.disabled == 0 ? "Active" : "Not Active" }}
                      </span>
                    </div>
                    <div
                      v-if="user.original_record.disabled != user.disabled"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{
                        user.original_record.disabled == 0
                          ? "Active"
                          : "Not Active"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer
              class="modal-card-foot"
              style="
                padding: 25px;
                border-bottom-left-radius: 32px;
                border-bottom-right-radius: 32px;
              "
            >
              <div class="is-full column py-0 px-0">
                <div class="column">
                  <div class="columns">
                    <div class="column"></div>
                    <div class="buttons">
                      <b-button
                        v-if="hasPermission('change-request-approve-reject')"
                        @click="approveOrRejectRequest('reject')"
                        class="is-danger is-small"
                        style="margin-left: 0px;
    border-radius: 5px;
    height: 40px;
    width: 180px;
    font-size: 15px;
    font-weight: 500;
    margin-right:70px;
    background-color:#FF505A;
}"
                        >Reject</b-button
                      >
                      <b-button
                        v-if="hasPermission('change-request-approve-reject')"
                        @click="approveOrRejectRequest('approve')"
                        class="is-primary is-small"
                        :loading="isButtonLoading"
                        style="margin-left: 0px;
    border-radius: 5px;
    height: 40px;
    width: 180px;
    font-size: 15px;
    font-weight: 500;
    margin-right:35px
}"
                        >Approve</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>

        <b-modal
          v-model="roleModalIsVisible"
          :width="550"
          scroll="keep"
          animation="zoom-out"
        >
          <div class="model-card">
            <header
              class="modal-card-heading"
              style="
                border-top-left-radius: 32px;
                border-top-right-radius: 32px;
              "
            >
              <div style="display: flex">
                <p
                  class="modal-card-title"
                  id="change_color"
                  style="
                    color: #0083ca;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-weight: 700;
                    padding-left: 50px;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    align-items: center;
                    font-family: Poppins;
                    justify-content: center;
                  "
                >
                  {{ modelTitles[role.pending_action] }} Role
                </p>
                <button
                  class="close-button"
                  @click="roleModalIsVisible = false"
                >
                  ✕
                </button>
              </div>
            </header>

            <div class="modal-card-body" style="max-height:250px; overflow-y:auto">
              <div class="content pt-2">
                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        role.original_record.role_name != role.role_name
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Role
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span
                      >{{ role.role_name }}
                    </div>
                    <div
                      v-if="role.original_record.role_name != role.role_name"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{ role.original_record.role_name }}
                    </div>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        role.original_record.permissions != role.permissions
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Permissions<span style="margin-left: 35px; color: #757575"
                        >:</span
                      >
                    </div>
                  </div>
                  <div class="column" style="margin-left: 30px">
                    <b-taglist>
                      <b-tag
                        id="change_color"
                        type="is-primary is-light"
                        v-for="permission in role.permissions.split('|')"
                        :key="permission"
                        >{{ permission }}</b-tag
                      >
                    </b-taglist>
                  </div>
                </div>

                <div class="columns mb-0 dual-auth-model-row">
                  <div class="column is-narrow">
                    <div
                      id="change_color"
                      :class="
                        role.original_record.disabled != role.disabled
                          ? 'has-text-danger'
                          : ''
                      "
                      class="dual-auth-model-row-label"
                      style="
                        width: 100px;
                        font-weight: 400;
                        font-size: 15px;
                        color: #757575;
                      "
                    >
                      Status
                    </div>
                  </div>
                  <div class="column">
                    <div class="field-details">
                      <span style="margin-right: 30px; color: #757575">:</span>
                      <span
                        :class="{
                          'text-success': user.disabled == 0,
                          'text-danger': user.disabled != 0,
                        }"
                      >
                        {{ role.disabled == 0 ? "Active" : "Not Active" }}
                      </span>
                    </div>
                    <div
                      v-if="role.original_record.disabled != role.disabled"
                      class="dual-auth-model-old-value has-text-danger-dark original-record"
                    >
                      {{
                        role.original_record.disabled == 0
                          ? "Active"
                          : "Not Active"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer
              class="modal-card-foot"
              style="
                padding: 25px;
                border-bottom-left-radius: 32px;
                border-bottom-right-radius: 32px;
              "
            >
              <div class="is-full column py-0 px-0">
                <div class="column">
                  <div class="columns">
                    <div class="column"></div>
                    <div class="buttons">
                      <b-button
                        v-if="hasPermission('change-request-approve-reject')"
                        @click="roleApproveOrRejectRequest('reject')"
                        class="is-danger is-small"
                        style="margin-left: 0px;
    border-radius: 5px;
    height: 40px;
    width: 180px;
    font-size: 15px;
    font-weight: 500;
    margin-right:70px;
    background-color:#FF505A;
}"
                        >Reject</b-button
                      >
                      <b-button
                        v-if="hasPermission('change-request-approve-reject')"
                        @click="roleApproveOrRejectRequest('approve')"
                        class="is-primary is-small"
                        style="margin-left: 0px;
    border-radius: 5px;
    height: 40px;
    width: 180px;
    font-size: 15px;
    font-weight: 500;
    margin-right:35px
}"
                        >Approve</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import TableComponent from "@/components/TableComponent.vue";
import BuefyHelper from "@/helpers/bufeyHelper";

export default {
  name: "ChangeRequests",
  components: {
    TableComponent,
  },
  data() {
    return {
      showTableFlag: {
        1: false,
        2: false,
        3: false,
      },
      showArrowFlag: {
        1: false,
        2: false,
        3: false,
      },
      columns: [
        {
          field: "username",
          label: "Employee ID",
          width: "80",
        },
        {
          field: "name",
          label: "Name",
          width: "80",
        },
        {
          field: "branch",
          label: "Branch",
          width: "80",
        },

        {
          field: "pending_action",
          label: "Pending Action",
          width: "80",
        },
        {
          field: "approveReject",
          label: "Action",
          width: "100",
        },
      ],
      roleColumns: [
        {
          field: "role_name",
          label: "Role",
          width: "100",
        },
        {
          field: "pending_action",
          label: "Pending Action",
          width: "130",
        },
        {
          field: "roleApproveReject",
          label: "Action",
          width: "80",
        },
      ],
      userList: [],
      roleList: [],
      isButtonLoading: false,
      modelTitles: {
        create: "Create New",
        edit: "Edit",
        delete: "Delete",
      },
      role: "",
      roleModalIsVisible: false,
      valueOne: "",
      RoleValue: "",
      loggedInUserId: "",
      original_record: {
        id: "",
        name: "",
        username: "",
        email: "",
        contact: "",
        role: "",
        branch: "",
        disabled: "",
      },
      role: {
        id: "",
        role_name: "",
        permissions: "",
        disabled: "",
        pending_action: "",
        original_record: {
          role_name: "",
          permissions: "",
          address: "",
          disabled: "",
        },
      },
      user: {
        id: "",
        name: "",
        username: "",
        email: "",
        contact: "",
        role: "",
        branch: "",
        disabled: "",
        pending_action: "",
        original_record: {
          id: "",
          name: "",
          username: "",
          email: "",
          contact: "",
          role: "",
          branch: "",
          disabled: "",
        },
      },
      modalIsVisible: false,
    };
  },
  // computed: {

  //   hasElementsBehind() {
  //     for (let key in this.showTableFlag) {
  //     if (this.showTableFlag[key]) {

  //       return false;
  //     }
  //   }

  //   return true;
  //   },
  // },
  methods: {
    getPendingActionColor(action) {
      if (action === "Edit") {
        return "color: yellow";
      } else if (action === "Create") {
        return "color: green";
      } else if (action === "Delete") {
        return "color: red";
      } else {
        return "black";
      }
    },
    toggleTableAndArrow(row) {
      this.showTableFlag[row] = !this.showTableFlag[row];
      this.showArrowFlag[row] = !this.showArrowFlag[row];
      // console.log("Toggling table and arrow for row", row);
    },

    // loadMore() {

    // },

    valueOnChangee(value) {
      const comp = this;
      // console.log("Parent value chnage ", value);
      comp.valueOne = value;
    },
    rolealueOnChangee(value) {
      const comp = this;
      // console.log("Parent value chnage ", value);
      comp.RoleValue = value;
    },

    roleApproveOrRejectRequest: function (actionTaken) {
      var component = this;

      if (actionTaken == "approve") {
        component.isApproving = true;
        component.openLoading();
      } else if (actionTaken == "reject") {
        component.isRejecting = true;
        component.openLoading();
      }

      component.role["action_taken"] = actionTaken;

      NetworkManager.apiRequest("backend/dualAuth/role", this.role, (e) => {
        if (e.success === true) {
          this.isLoading = false;
          if (actionTaken == "approve") {
            BuefyHelper.showToastMessage(e.data.message, "is-success");
          } else if (actionTaken == "reject") {
            BuefyHelper.showToastMessage(e.data.message, "is-danger");
          }
          location.reload();
        } else {
          this.isLoading = false;
          BuefyHelper.showToastMessage("Something went wrong", "is-danger");
        }
      });
    },

    approveOrRejectRequest: function (actionTaken) {
      var component = this;

      if (actionTaken == "approve") {
        component.isApproving = true;
        this.isButtonLoading = true;
      } else if (actionTaken == "reject") {
        component.isRejecting = true;
      }

      component.user["action_taken"] = actionTaken;

      NetworkManager.apiRequest("backend/dualAuth/user", this.user, (e) => {
        if (e.success === true) {
          if (actionTaken == "approve") {
            this.isButtonLoading = false;
            BuefyHelper.showToastMessage(e.data.message, "is-success");
          } else if (actionTaken == "reject") {
            BuefyHelper.showToastMessage(e.data.message, "is-danger");
          }
          location.reload();
        } else if (
          (e.data.message == "Cannot Approve the Request. The username is already taken. Please choose a different username")
        ) {
          BuefyHelper.showToastMessage(
            "Cannot Approve the Request. The username is already taken. Please choose a different username",
            "is-danger"
          );
          this.isButtonLoading = false
        } else {
          (this.isLoading = false),
            BuefyHelper.showToastMessage("Something went wrong", "is-danger");
        }
      });
    },

    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 1000);
    },

    showModal(id) {
      // Find the item in this.dataList where id matches props.row.id
      const selectedItem = this.dataList.find((index) => index.id === id);
      if (selectedItem) {
        //Assign the found item to this.user
        this.user = selectedItem;

        // console.log("Selected User:", this.user);

        // Set the modal to be visible
        this.modalIsVisible = true;
      } else {
        // console.error("User not found in dataList");
      }
    },

    showRoleModal(id) {
      // Find the item in this.roleList where id matches props.row.id
      const selectedItem = this.roleList.find((index) => index.id === id);

      if (selectedItem) {
        //Assign the found item to this.user
        this.role = selectedItem;

        // console.log("Selected Role:", this.role);

        // Set the modal to be visible
        this.roleModalIsVisible = true;
      } else {
        // console.error("Role not found in roleList");
      }
    },
  },
  mounted() {
    NetworkManager.apiRequest("backend/dualAuth/list", {}, (e) => {
      this.dataList = e.data.pendingUserList;
      this.userList = e.data.pendingUserList;
      this.roleList = e.data.pendingRoleList;
      this.loggedInUserId = e.data.loggedInUserId;
      // console.log(e.data.pendingUserList);
      // console.log("UserList", this.userList.length);
    });
  },
};
</script>

<style scoped>
.modal-card-foot {
  background-color: white;
  border-top: none;
}
.close-button {
  border: none;
  background: none;
  color: #757575;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 28px;
  font-weight: 700;
}
.close-button:hover {
  color: red;
}
.column-field {
  margin-left: 100px;
}
.bar-columns {
  margin-top: 40px;
  position: relative;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
span {
  font-size: 20px;
  margin-right: 25px;
}
.original-record {
  margin-left: 35px;
}
.bar {
  width: 82vw;
  height: 50px;
  border-radius: 10px;
  background-color: #0083ca12;
  text-align: left;
  line-height: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  padding-left: 20px;
  font-weight: 600;
  color: #0083ca;
  margin-left: 10px;
  font-size: 15px;
  border-bottom-width: 2px;
  border-bottom-color: #0083ca;
  border-bottom-style: solid;
}

.table {
  border-collapse: collapse;
  margin-top: 10px;
}

.table th,
.table td {
  border: 1px solid black;
  padding: 8px 4px;
  text-align: center;
}

/* Arrow Icon */
.arrow {
  position: absolute;
  right: 10px;
  transition: transform 0.3s ease;
}

.arrow-up {
  transform: rotate(90deg);
}
.modal-card-body::-webkit-scrollbar {
  display: none;
}

.load-more {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
}

.load-more button {
  padding: 10px 20px;
  background-color: #0083ca;
  color: white;
  border: none;
  border-radius: 90px;
  cursor: pointer;
  width: 150px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  margin-left: 100px;
}

.animation-content {
  border-radius: 64px !important;
}
.field-details {
  color: #212121;
  font-size: 15px;
  font-weight: 500;
}

.text-success {
  color: #30bf62;
  font-size: 15px;
}

.text-danger {
  color: red;
  font-size: 15px;
}
.modal-card-heading {
  background-color: white;
}
</style>
