import { render, staticRenderFns } from "./DebitCommentField.vue?vue&type=template&id=5aff5b02&scoped=true"
import script from "./DebitCommentField.vue?vue&type=script&lang=js"
export * from "./DebitCommentField.vue?vue&type=script&lang=js"
import style0 from "./DebitCommentField.vue?vue&type=style&index=0&id=5aff5b02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aff5b02",
  null
  
)

export default component.exports