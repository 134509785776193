<template>
  <!-- <div class="columns is-centered">
    <div class="column is-one-second">
      <img src="logo.png" alt="User Picture" class="user-picture" />
    </div>

    <div class="column is-one-second">
      <div class="has-text-centered" style="margin-top: 13.3vh">
        <label class="is-size-2 has-text-primary">Set New Password</label>
      </div>
      <div
        class="field"
        style="margin-top: 15.3vh; margin-left: 27.3vh; margin-right: 6.3vh"
      >
        <div class="control">
          <b-field
            :type="invalidFields.password == '' ? '' : 'is-danger'"
            :message="invalidFields.password"
          >
            <b-input
              @input="resetInvalidStatus('password')"
              id="password"
              type="password"
              placeholder="Enter your new password"
              icon-color="is-primary"
              password-reveal
              v-model="user.password"
              style="width: 75%"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="field" style="margin-left: 27.3vh; margin-right: 6.3vh">
        <div class="control">
          <b-field
            :type="invalidFields.ConfirmPassword == '' ? '' : 'is-danger'"
            :message="invalidFields.ConfirmPassword"
          >
            <b-input
              @input="resetInvalidStatus('confirmPassword')"
              id="password"
              type="password"
              placeholder="Re-enter password"
              v-model="user.ConfirmPassword"
              icon-color="is-primary"
              password-reveal
              style="width: 75%"
            ></b-input>
          </b-field>
        </div>
      </div>

      <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button
            class="button is-primary is-medium is-rounded"
            style="width: 107%; margin-right: 34.3vh"
            @click="validateAndResetPassword"
          >
            Reset Password
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="login-page-bg">
    <div class="wrapper">
      <div class="cover-img"></div>

      <div class="input-area">
        <!-- First Column: Display Picture -->
        <div>
          <img
            src="../../assets/images/Forgot Password.png"
            alt="SDB Bank Logo"
            style="width: 6vw; margin: 0 40%"
          />
        </div>

        <div class="has-text-centered is-size-3 has-text-primary has-text-weight-bold">
          Set New Password
        </div>
        <!-- <div class="has-text-centered column is-size-6 has-text-black has-text-weight-semibold"
          style="color: #757575; width: 75%; margin: 0 auto">
          Please enter your username below, and we will send an OTP code to your
          registered email address.
        </div> -->
        <div class="inputs">
          <div class="input-felds-styles">
            <!-- <div class="control"> -->
            <b-field
              :type="invalidFields.password == '' ? '' : 'is-danger'"
              :message="invalidFields.password"
            >
              <b-input
                @input="resetInvalidStatus('password')"
                id="password"
                type="password"
                placeholder="Enter your new password"
                icon-color="is-primary"
                password-reveal
                v-model="user.password"
              ></b-input>
            </b-field>
            <!-- </div> -->
          </div>
          <div class="input-felds-styles">
            <b-field
              :type="invalidFields.ConfirmPassword == '' ? '' : 'is-danger'"
              :message="invalidFields.ConfirmPassword"
            >
              <b-input
                @input="resetInvalidStatus('confirmPassword')"
                id="password"
                type="password"
                placeholder="Re-enter password"
                v-model="user.ConfirmPassword"
                icon-color="is-primary"
                password-reveal
              ></b-input>
            </b-field>
          </div>

          <div
            class="content is-normal is-size-7 has-text-weight-semibold"
            style="color: #757575; margin: 0 auto"
          >
            <h6>Your password must</h6>
            <ul>
              <li>Have 8+ characters, no spaces</li>
              <li>Use a mix of numbers, uppercase, & lowercase letters</li>
              <li>Don’t use the same character three times in a row</li>
              <li>Not be something common</li>
            </ul>
          </div>

          <div class="action-buttons">
            <ActionButton
              type="is-primary"
              buttonLabel="Reset Password"
              expanded=" expanded"
              size="is-medium"
              :buttonAction="validateAndResetPassword"
              divStyles="input-felds-styles-buttons"
            />

            <ActionButton
              inverted
              type="is-primary"
              buttonLabel="Back to Sign In"
              expanded=" expanded"
              :buttonAction="Back"
              size="is-medium"
              divStyles="input-felds-styles-buttons"
              icon-left="arrow-left"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.align-right {
  text-align: right;
}

.input-felds-styles {
  margin: 25px auto;
}

.login-page-bg {
  background-image: url("../../assets/images/Login_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
}

.wrapper {
  display: flex;
  width: fit-content;
  border-radius: 8px;
  flex-direction: row;
  background: white;
  height: 650px;
  margin: auto;
}
.cover-img {
  background-image: url("../../assets/images/Login_Page_Image.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 560px;
  height: inherit;
}

.input-area {
  padding: 20px;
  height: 735px;
  width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: inherit;
}

.inputs {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.input-felds-styles-buttons {
  margin: 5px auto;
}

.action-buttons {
  margin: 40px auto 0px auto;
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";
import ActionButton from "@/components/support/buttons/ActionButton.vue";

export default {
  props: ["userData"],
  name: "PasswordReset",
  components: {
    ActionButton,
  },
  data() {
    return {
      userData: "",
      user: {
        password: "",
        ConfirmPassword: "",
      },
      invalidFields: {
        password: "",
        ConfirmPassword: "",
      },
    };
  },
  methods: {
    ResetPassword: function () {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const requestData = {
        userInfo: userInfo,
        user: this.user,
      };

      NetworkManager.apiRequest("resetPassword", requestData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage("Password reset successful", "is-success");
          this.$router.push("/login");
        } else if (e.success === false) {
          BuefyHelper.showToastMessage("Password Reset Failed", "is-danger");
        }
      });
    },

    validateAndResetPassword: function () {
      if (this.validateResetPassword()) {
        this.ResetPassword();
      }
    },

    validateResetPassword: function () {
      var formIsValid = true;

      if (this.user.password == "") {
        this.invalidFields.password = "Enter a new password";
        formIsValid = false;
      } else if (this.user.password.length < 8) {
        this.invalidFields.password = "Password must contain at least 8 characters";
        formIsValid = false;
      } else if (/\s/.test(this.user.password)) {
        this.invalidFields.password = "Password cannot contain spaces";
        formIsValid = false;
      } else if (!/[a-z]/.test(this.user.password) || !/[A-Z]/.test(this.user.password) || !/\d/.test(this.user.password)) {
        this.invalidFields.password = "Password must contain a mix of numbers, uppercase and lowercase letters";
        formIsValid = false;
      } else if (/(\w)\1\1/.test(this.user.password)) {
        this.invalidFields.password = "Password cannot contain the same character 3 times in a row";
        formIsValid = false;
      }  else {
        if (this.user.ConfirmPassword == "") {
          this.invalidFields.ConfirmPassword = "Re-enter your new password";
          formIsValid = false;
        } else {
          if (this.user.ConfirmPassword != this.user.password) {
            this.invalidFields.ConfirmPassword = "Passwords does not match";
            formIsValid = false;
          }
        }
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    resetInvalidStatus: function (key) {
      this.invalidFields[key] = "";
    },

     Back: function () {
      this.$router.push("/login");
    },
  },
  mounted() {},
};
</script>
