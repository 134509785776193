<template>
  <div>
    <div>
      <div class="search-field-container">
        <div
          v-for="item in objectList"
          :key="item.name"
          style="margin: 2px 20px"
          :class="
            item.type === 'BUTTON' || item.type === 'RESETBUTTON'
              ? 'filter-btn'
              : 'filter-field'
          "
        >
          <!-- <div> -->
          <!-- <SelectField
              
              :selectOptions="item.options"
              :fieldName="item.label"
              expanded="expanded"
              :selectFieldValue="item.value"
            /> -->

          <b-field :label="item.label" v-if="item.type === 'SELECT'">
            <b-select
              expanded
              is-small
              v-model="item.value"
              @input="
                $emit('changeValues', { value: item.value, label: item.label })
              "
              :disabled="item.isButtonDisabled"
            >
              <option
                v-for="option in item.options"
                :value="option"
                :key="option"
              >
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <!-- </div> -->

          <!-- <div> -->
          <!-- <SelectField
              
              :selectOptions="item.options"
              :fieldName="item.label"
              expanded="expanded"
              :selectFieldValue="item.value"
            /> -->

          <b-field :label="item.label" v-if="item.type === 'USERSELECT'">
            <b-select
              expanded
              v-model="item.value"
              :disabled="item.isButtonDisabled"
            >
              <option
                v-for="option in item.options"
                :value="option.id"
                :key="option.id"
              >
                {{ option.username }}
              </option>
            </b-select>
          </b-field>
          <!-- </div> -->

          <!-- <div> -->
          <b-field :label="item.label" v-if="item.type === 'INPUT'">
            <b-input
              icon="magnify"
              icon-right="right"
              v-model="item.value"
            ></b-input>
          </b-field>
          <!-- </div> -->

          <!-- <div> -->
          <b-field :label="item.label" v-else-if="item.type === 'DATEPICKER'">
            <b-datepicker
              v-model="item.value"
              placeholder="Click to select..."
              icon="calendar-today"
              trap-focus
              :max-date="item.maxDate"
            >
            </b-datepicker>
          </b-field>
          <!-- </div> -->

          <!-- <div> -->
          <b-button
            type="is-primary"
            @click="$emit('tableFilter', objectList)"
            expanded
            style="width: 100px; margin-right: 40px"
            :disabled="item.isButtonDisabled"
            v-else-if="item.type === 'BUTTON'"
            >{{ item.label }}</b-button
          >
          <!-- </div> -->
          <!-- <div> -->
          <b-button
            type="is-success"
            @click="$emit('resetFilter', objectList)"
            expanded
            style="width: 100px; margin-left: 40px"
            :disabled="item.isButtonDisabled"
            v-else-if="item.type === 'RESETBUTTON'"
            >{{ item.label }}</b-button
          >
          <!-- </div> -->
        </div>

        <!-- <div class="column mt-5"> -->
        <!-- <b-button type="is-primary" icon-right="magnify">Filter</b-button> -->
        <!-- <ActionButton
            :buttonAction="navigateTo"
            buttonLabel="Create User"
            type="is-info"
          />
        </div> -->

        <!-- <div class="column mt-5"> -->
        <!-- <b-button type="is-primary" icon-right="magnify">Filter</b-button> -->
        <!-- <SelectField fieldType="item.type" :selectOptions="itemsPerPage" /> -->
        <!-- </div> -->

        <!-- <div class="column is-one-fifth mt-5"> -->
        <!-- <b-button type="is-primary" icon-right="magnify">Filter</b-button> -->
        <!-- <ActionButton buttonLabel="Filter" type="is-primary" /> -->
        <!-- </div> -->
      </div>

      <div
        style="
          font-size: 14px;
          display: flex;
          align-items: flex-end;
          margin-top: 10px;
          margin-bottom: 0px;
          margin-left: 20px;
        "
      >
        <b-select v-model="perPage">
          <option value="100">100 Per Page</option>
          <option value="200">200 Per Page</option>
          <option value="300">300 Per Page</option>
        </b-select>

        <div v-if="shouldShowExcelComponent" @click="download(name)">
          <vue-excel-xlsx
            :data="tableData"
            :columns="excelColumns"
            :file-name="name"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
            :disabled="tableData.length === 0"
            class="button is-primary ml-3"
          >
            <span style="flex: 1">Download Excel</span>
            <b-icon icon="download" size="is-small"></b-icon>
          </vue-excel-xlsx>
        </div>
      </div>
    </div>
    <!-- <div style="display: flex; align-items: flex-end; margin: 20px;">
      <h1 style="font-weight: bold;">{{ header }}</h1>
    </div> -->

    <b-table
      :height="height"
      sticky-header
      v-if="tableData !== null"
      class="mt-5"
      style="
        height: 80%vw;
        max-height: 100%vw;
        overflow-x: auto;
        max-width: 100%;
        overflow-y: hidden;
        padding-bottom: 10px;
      "
      :data="tableData"
      paginated
      :per-page="perPage"
    >
      <b-table-column
        v-for="column in columns"
        :key="column.id"
        :field="column.field"
        :label="column.label"
        v-slot="props"
      >
        <!-- <span>
                    {{ props.row[column.field] }}
                </span> -->

        <span v-if="column.field === 'permissions'">
          <b-taglist>
            <b-tag
              id="permission"
              v-for="permission in props.row[column.field].split('|')"
              :key="permission"
              >{{ permission }}</b-tag
            >
          </b-taglist>
        </span>
        <span v-if="column.field === 'disabled'">
          <b-tag
            v-if="
              props.row[column.field] == 0 ||
              props.row[column.field] == 'Active'
            "
            type="is-success"
            size="is-normal"
          >
            Active
          </b-tag>
          <b-tag v-else size="is-normal" type="is-danger"> Inactive </b-tag>
        </span>
        <span
          class="has-text-weight-medium"
          v-if="
            column.field !== 'action' &&
            column.field !== `status` &&
            column.field !== `application_status` &&
            column.field !== `debit_card_status` &&
            !column.hiden &&
            column.field !== `pending_action` &&
            column.field !== `sms_status` &&
            column.field !== 'permissions' &&
            column.field !== 'disabled'
          "
        >
          {{ props.row[column.field] }}
        </span>
        <div v-if="column.field === `status` && !column.hide">
          <b-field>
            <b-tag
              v-if="props.row[column.field] === 'Pending'"
              type="is-danger is-light"
              >Pending</b-tag
            >
            <b-tag
              v-if="props.row[column.field] === 'Granted'"
              type="is-success is-light"
              >Granted</b-tag
            >
            <b-tag
              v-if="props.row[column.field] === 'Denied'"
              type="is-danger is-light"
              >Denied</b-tag
            >
          </b-field>
        </div>

        <div v-if="column.field === `application_status` && !column.hide">
          <b-field>
            <!-- <b-tag v-if="props.row[column.field] === 'pending'" type="is-danger is-light"
              >{{props.row[column.field]}}</b-tag
            >
            <b-tag v-if="props.row[column.field] === 'Granted'" type="is-success is-light"
              >Granted</b-tag
            >
            <b-tag v-if="props.row[column.field] === 'Denied'" type="is-danger is-light"
              >Denied</b-tag
            > -->
            <span
              class="has-text-primary has-text-weight-semibold"
              v-if="props.row[column.field] === 'pending'"
              >Pending</span
            >
            <span
              class="has-text-success has-text-weight-semibold"
              v-if="props.row[column.field] === 'Approved'"
              >Approved</span
            >
            <span
              class="has-text-success has-text-weight-semibold"
              v-if="props.row[column.field] == null"
              >Approved</span
            >
            <span
              class="has-text-danger has-text-weight-semibold"
              v-if="props.row[column.field] === 'Reject'"
              >Rejected</span
            >
            <span
              class="has-text-success has-text-weight-semibold"
              v-if="props.row[column.field] === 'Reviewed'"
              >Reviewed</span
            >
            <span
              class="has-text-info has-text-weight-semibold"
              v-if="props.row[column.field] === 'Request To Improve'"
              >Req. To Improve</span
            >
          </b-field>
        </div>

        <div v-if="column.field === 'sms_status' && !column.hide">
          <b-field>
            <span
              class="has-text-success has-text-weight-semibold"
              v-if="props.row[column.field] === 'Activated'"
              >Activated</span
            >
            <span
              class="has-text-danger has-text-weight-semibold"
              v-if="props.row[column.field] === 'Reject'"
              >Reject</span
            >
            <span
              class="has-text-primary has-text-weight-semibold"
              v-if="props.row[column.field] === 'Pending'"
              >Pending</span
            >
          </b-field>
        </div>
        <div v-if="column.field === `debit_card_status` && !column.hide">
          <b-field>
            <!-- <b-tag v-if="props.row[column.field] === 'pending'" type="is-danger is-light"
              >{{props.row[column.field]}}</b-tag
            >
            <b-tag v-if="props.row[column.field] === 'Granted'" type="is-success is-light"
              >Granted</b-tag
            >
            <b-tag v-if="props.row[column.field] === 'Denied'" type="is-danger is-light"
              >Denied</b-tag
            > -->
            <span
              class="has-text-primary has-text-weight-semibold"
              v-if="props.row[column.field] === 'pending'"
              >Pending</span
            >
            <span
              class="has-text-success has-text-weight-semibold"
              v-if="props.row[column.field] == 'issued'"
              >Issued</span
            >
            <span
              class="has-text-danger has-text-weight-semibold"
              v-if="props.row[column.field] == 'rejected'"
              >Rejected</span
            >
          </b-field>
        </div>
        <div v-if="column.field === `pending_action` && !column.hide">
          <b-field>
            <span
              class="has-text-success has-text-weight-semibold"
              v-if="props.row[column.field] === 'create'"
              >Create</span
            >
            <span
              class="has-text-info has-text-weight-semibold"
              v-if="props.row[column.field] == 'edit'"
              >Edit</span
            >
            <span
              class="has-text-danger has-text-weight-semibold"
              v-if="props.row[column.field] == 'delete'"
              >Delete</span
            >
          </b-field>
        </div>

        <!-- //group by dots -->
        <div v-if="column.field === 'flag' && !column.hide">
          <b-field>
            <span v-if="props.row.kyc_is_customer_pep != 'Customer is not a politically exposed person' && props.row.kyc_is_customer_pep != 'I am not a politically exposed person'">
              <span class="dot-red"></span>
            </span>

            <span v-if="props.row.old_req_to_improve_application_id !== null">
              <span class="dot-yellow"></span>
            </span>
          </b-field>
        </div>

        <!-- :disabled="
              props.row.username === 'sdb_superadmin' || props.row.locked === 1
                ? true
                : false
            " -->
        <div v-if="column.field === 'actions' && !column.hiden">
          <b-button
            v-if="hasPermission('user-edit')"
            
            class="mr-2"
            icon-left="pencil"
            title="Edit Record"
            type="is-info"
            rounded
            size="is-small"
            @click="navigateTo('edit-user', { username: props.row.username })"
          ></b-button>
          <b-button
            v-if="hasPermission('password-reset')"
            :disabled="props.row.username === 'sdb_superadmin' ? true : false"
            icon-left="form-textbox-password"
            title="Password Reset"
            type="is-primary"
            class="mr-2"
            rounded
            size="is-small"
            @click="$emit('showResetPasswordsModal', props.row.id)"
          ></b-button>
          <b-button
            v-if="
              hasPermission('attempts-reset') && props.row.login_attempts > 5
            "
            icon-left="lock-reset"
            title="Attempt Reset"
            type="is-primary"
            rounded
            size="is-small"
            @click="$emit('showResetAttemptModal', props.row)"
          ></b-button>
        </div>

        <div v-if="column.field === 'Roleaction' && !column.hiden">
          <b-button
            v-if="hasPermission('role-edit')"
            :disabled="props.row.user_level === 100 || props.row.locked === 1"
            type="is-info"
            title="Edit Role"
            rounded
            size="is-small"
            icon-left="pencil"
            @click="navigateTo('edit-role', { id: props.row.id })"
          ></b-button>
        </div>

        <div v-if="column.field === 'approveReject' && !column.hiden">
          <button
            class="table-content-btn"
            type="is-info"
            rounded
            size="is-small"
            v-if="props.row.requested_by != loggedInUserId || superAdmin"
            @click="$emit('showModal', props.row.id)"
          >
            Approve/Reject
          </button>
          <b-icon
            v-else
            icon="lock-outline"
            size="is-normal"
            title="Not allowed to Approve/Reject"
          ></b-icon>
        </div>

        <div v-if="column.field === 'applicationView' && !column.hiden">
          <b-button
            type="is-primary"
            @click="$emit('applicationViewer', props.row)"
          >
            View Latest</b-button
          >
        </div>

        <div v-if="column.field === 'roleApproveReject' && !column.hiden">
          <button
            class="table-content-btn"
            type="is-info"
            rounded
            size="is-small"
            v-if="props.row.requested_by != loggedInUserId || superAdmin"
            @click="$emit('showRoleModal', props.row.id)"
          >
            Approve/Reject
          </button>
          <b-icon
            v-else
            icon="lock-outline"
            size="is-normal"
            title="Not allowed to Approve/Reject"
          ></b-icon>
        </div>

        <div v-if="column.field === 'action-view' && !column.hiden">
          <b-button type="is-primary" rounded size="is-small"></b-button>
        </div>
      </b-table-column>

      <!-- <b-loading  :v-model="isTableLoading" :can-cancel="isCancelble" :is-full-page="isFullPage"></b-loading> -->
      <b-loading
        :is-full-page="isFullPage"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </b-table>
  </div>
</template>

<script>
// import SelectField from "./support/fields/SelectField.vue";
// import ActionButton from "./support/buttons/ActionButton.vue";
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";

export default {
  name: "TableComponent",

  components: {
    // SelectField,
    // ActionButton,
  },
  computed: {},
  props: [
    "tableData",
    "columns",
    "filterList",
    "objectList",
    "header",
    "loggedInUserId",
    "isLoading",
    "shouldShowExcelComponent",
    "name",
    "excelColumns",
    "height",
  ],
  emits: ["tableFilter", "applicationViewer"],

  data() {
    return {
      searchValue: "",
      itemsPerPage: [1, 2, 3, 4, 5, 6, 7],
      //chageVal : {value: '', label:''},
      user: {
        id: "",
        name: "",
        username: "",
        email: "",
        contact: "",
        role: "",
        branch: "",
        disabled: "",
        pending_action: "",
      },
      isFullPage: false,
      edit: {},
      superAdmin: false,

      // data: [
      //     { 'id': 1, 'first_name': 'Jesse', 'last_name': 'Simmons', 'date': '2016-10-15 13:43:27', 'gender': 'Male' },
      //     { 'id': 2, 'first_name': 'John', 'last_name': 'Jacobs', 'date': '2016-12-15 06:00:53', 'gender': 'Male' },
      //     { 'id': 3, 'first_name': 'Tina', 'last_name': 'Gilbert', 'date': '2016-04-26 06:26:28', 'gender': 'Female' },
      //     { 'id': 4, 'first_name': 'Clarence', 'last_name': 'Flores', 'date': '2016-04-10 10:28:46', 'gender': 'Male' },
      //     { 'id': 5, 'first_name': 'Anne', 'last_name': 'Lee', 'date': '2016-12-06 14:38:38', 'gender': 'Female' }
      // ],
      // columns: [
      //     {
      //         field: 'id',
      //         label: 'ID',
      //         width: '100',
      //         numeric: true,
      //         searchable: true,
      //     },
      //     {
      //         field: 'first_name',
      //         label: 'First Name',
      //         searchable: true,
      //     },
      //     {
      //         field: 'last_name',
      //         label: 'Last Name',
      //         searchable: true,
      //     },
      //     {
      //         field: 'date',
      //         label: 'Date',
      //         centered: true
      //     },
      //     {
      //         field: 'gender',
      //         label: 'Gender',
      //     }
      // ]
      perPage: 100,
    };
  },
  methods: {
    // updateValue(value) {
    //     console.log("Parent value chage in child", value)
    //     this.$emit('inputChange', value)
    //     //  this.searchValue = value;

    // }
    updateValue(value) {
      // console.log("Parent value chage in child", value);
      this.$emit("inputChange", value);
      //  this.searchValue = value;
    },

    download(name) {
      NetworkManager.apiRequest(
        "backend/applicant/downloadExcel",
        { name: name },
        (e) => {
          // console.log(e);
        }
      );
    },

    navigateTo: function (routeName, params) {
      this.$router.push({ name: routeName, params: params });
    },

    resetInvalidStatus: function (key) {
      this.invalidFields[key] = "";
    },
  },
  mounted() {
    NetworkManager.apiRequest("backend/dualAuth/list", {}, (e) => {
      // console.log(e.data.list)
      this.dataList = e.data.pendingUserList;
      this.roleList = e.data.pendingRoleList;
      this.superAdmin = e.data.isSuperAdmin;
      // console.log(e.data.pendingUserList);
    });
  },
};
</script>
<style>
.table-content-btn {
  background-color: #0083ca;
  border-color: transparent;
  color: #fff;
  font-size: 11px;
  font-family: Poppins;
  border-radius: 5px;
  padding: 8px;
}
.table-content-btn:hover {
  background-color: #63abd4;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(88, 88, 88, 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.search-field-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.search-field {
  flex-grow: 1;
}

.filter-field {
  width: 20%;
}

.filter-btn {
  width: 10%;
}

.b-table thead th {
  font-size: 13px;
  text-align: center;
  font-family: Poppins;
}

.b-table tbody td {
  font-size: 8px;
  text-align: left;
  font-family: Poppins;
}
.table td:not([align]),
.table th:not([align]) {
  text-align: left;
}
.has-text-weight-medium {
  font-size: 11px;
  text-align: left;
}

.filter-field label {
  font-size: 10px;
}

.scrollable-table {
  max-width: 75vw;
  overflow-x: auto;
}

.table-comp-wrapper {
  overflow: auto !important;
}
#permission {
  background-color: #0083ca;
  color: white;
}
.dual-auth-model-old-value {
  text-decoration: line-through;
  font-size: 0.85rem;
  opacity: 0.75;
}

.dot-red,
.dot-yellow {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.dot-red {
  background-color: red;
}

.dot-yellow {
  background-color: yellow;
}
</style>
