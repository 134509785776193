<template>
    <div  :class="cardStyles">

        <section>

            <b-collapse class="notification" :open="false" animation="slide" aria-id="contentIdForA11y3" style="padding: 0;">
                <template #trigger="props">
                    <div class="is-flex is-justify-content-space-between" role="button" aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title">
                            {{ cardTitle }}
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'minus-box' : 'plus-box'" type="is-primary"
                                size="is-medium">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <div  v-for="(data, index) in cardData" :key="index">
                            <div class="containerStyles">
                                <div class="has-text-weight-semibold titleFontStyles">
                                    {{ data.title }}
                                </div>
                                <div class="seperatorContainer">
                                    :
                                </div>

                                <div  class="has-text-weight-semibold valueFontStyles">
                                    {{ getDataValues(data)}}
                                </div>

                            </div>
                        </div>
                            
                    </div>
                </div>

            </b-collapse>

        </section>

    </div>
</template>

<script>
import SpanFields from '../fields/SpanFields.vue';

export default {
    components: {
        SpanFields,
    },
    data() {
        return {
        k: "",
        }
    },

    name: "ApplicationViewCard",
    props: ["cardStyles", "cardTitle", "cardData"],

    methods:
    {
        getDataValues(data) {
            let result = "";
            for (let s = 0; s < data.length; s++) {
                // result = result + data[s] + ', ';
                if (s === data.length - 1) {
                    result = result + data[s];
                } else {
                    result = result + data[s] + ', ';
                }
            }
            return result;
        }
    }

}

</script>

<style>
.titleFontStyles {
    font-size: 1rem;
    color: #757575;
    width: 45%;
}

.containerStyles{
    display: flex;
    margin: 10px 0;
    /* justify-content: space-between */
    
}

.seperatorContainer{
    width: 7%;
}

.valueFontStyles {
    font-size: 1rem;
    color: #000000;
    /* word-wrap: break-word; */
    width: 48%;
}
</style>