export default {
    // apiKey: "AIzaSyCiCikMClhKVdVqWtjVmnfuztL_cmRlzzQ",
    // authDomain: "sdb-push-notifications.firebaseapp.com",
    // projectId: "sdb-push-notifications",
    // storageBucket: "sdb-push-notifications.appspot.com",
    // messagingSenderId: "280383579278",
    // appId: "1:280383579278:web:da3666900c9d113a2f15df",
    // measurementId: "G-4E6T1YNYGR",
    // vapidKey: 'BAhtGTswJgtkfTBljsnH8-11V6piM3tGBWy8srxOI9SY22qXqXtbgP5HCfp3K-6lOwI-xxK5rNflhuw_qbf1jEs',

    // apiKey: "AIzaSyBQ1HisErs1PDB2KVpkbFWAUC9RhNBxhBA",
    // authDomain: "sdb-fcm-52b97.firebaseapp.com",
    // projectId: "sdb-fcm-52b97",
    // storageBucket: "sdb-fcm-52b97.appspot.com",
    // messagingSenderId: "617005406108",
    // appId: "1:617005406108:web:203b76fc8c107c7a545a59",
    // measurementId: "G-7H0Z2MYQNF",
    // vapidKey: 'BD_E06O-bygVWQWzLpIWNj6G630DXTobHT-MKfI73QvA-GaBfhH6Jcf3FZvKqkblKYtZLI-XLKtW4-l7PmsoKrY',

    apiKey: "AIzaSyBCb5MBVKPLKnSYvygWhrbb7GrZNFrQjEg",
    authDomain: "conboard-54115.firebaseapp.com",
    projectId: "conboard-54115",
    storageBucket: "conboard-54115.appspot.com",
    messagingSenderId: "291474909726",
    appId: "1:291474909726:web:4d35fb9024b1bf2bdf5c0f",
    measurementId: "G-KLB0CWLZFC",
    vapidKey: 'BLpGF8hJFuiz9vXFTejJP0vEZaBfAvWGaQ1o0MD4MZZIAmiModRstg_uxc-thz3JbQFZSKOHft8R8QS8Ouiq-Wc'
};