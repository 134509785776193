<template>
  <b-modal v-model="cardModalActive">
    <div class="main-container">
      <div class="header-container">
        <div>
          <span
            class="has-text-weight-bold has-text-primary"
            style="margin-right: 5px; font-size: 20px"
            >Debit Card Approve</span
          >       
          <span class="nic-no">{{ clientData.nic }}</span>
        </div>
        <div>
          <!-- <b-icon icon="close" size="is-medium" style="color: gray; cursor: pointer;" class="b-icon" onMouseOver="this.style.color='red'" onMouseOut="this.style.color='gray'" onClick="closeModal()">
</b-icon> -->
          <b-button @click="closeModal" style="border: none">
            <b-icon
              icon="close"
              style="
                color: #b7b8b8;
                cursor: pointer;
                font-size: 14px;
                margin-left: 25px;
                margin-bottom: 10px;
              "
              class="b-icon"
              onMouseOver="this.style.color='red'"
              onMouseOut="this.style.color='#B7B8B8'"
            ></b-icon>
          </b-button>
        </div>
      </div>

      <div class="body-container">
        <div style="width: 50%; height: 50%; margin: 0 0 0 15px">
          <div class="profile-container">
           <div>
             <div class="img-wrapper-profile">
              <img
                :src="baseUrl + clientData.image_customer_img"
                class="round-image-box-profile"
                style="width: 100%; height: 100%;"
              />
            </div>
           </div>
            <div>
              <div class="title-container-one">
                <span
                  class="has-text-weight-semibold has-text-primary"
                  style="font-size: 20px"
                  >{{ clientData.title + " " + getnameWithInitials(clientData.full_name) }}
                </span>
              </div>
              <div class="title-container-two">
                <span
                  class="has-text-weight-medium mr-4 gray-txt"
                  style="font-size: 13px"
                  >{{ clientData.nic }}</span
                >

                <span
                  class="has-text-weight-medium gray-txt"
                  style="font-size: 13px"
                  >{{ clientData.sex }}</span
                >
              </div>
              <span
                class="has-text-weight-medium gray-txt"
                style="font-size: 13px"
              >
                {{ clientData.applicant_status }}
              </span>
            </div>
          </div>
          <div class="info-container">
            <div class="application-sub-headers">Account Info</div>

            <SpanFields
              fieldTitle="Account Ownership"
              fieldValue="Individual"
              titleStyles="is-size-7"
              valueStyles="is-size-7"
            />
            <SpanFields
              fieldTitle="Individual acc: type"
              fieldValue="Savings Account"
              titleStyles="is-size-7"
              valueStyles="is-size-7"
            />
            <SpanFields
              fieldTitle="Saving acc: type"
              :fieldValue="clientData.savings_account_type"
              titleStyles="is-size-7"
              valueStyles="is-size-7"
            />
            <SpanFields
              fieldTitle="Account Number"
              :fieldValue="clientData.account_number"
              titleStyles="is-size-7"
              valueStyles="is-size-7"
            />
            <SpanFields
              fieldTitle="Customer CIF"
              :fieldValue="clientData.customer_cif"
              titleStyles="is-size-7"
              valueStyles="is-size-7"
            />

            <div>
              <div v-if="clientData.application_status == 'Approved'">
                <SpanFields
                  fieldTitle="Status"
                  :fieldValue="clientData.application_status"
                  titleStyles="is-size-7"
                  valueStyles="is-size-7 has-text-link"
                />
              </div>
              <div v-if="clientData.application_status == 'Reviewed'">
                <SpanFields
                  fieldTitle="Status"
                  :fieldValue="clientData.application_status"
                  titleStyles="is-size-7"
                  valueStyles="is-size-7 has-text-danger"
                />
              </div>
            </div>
            <div class="application-sub-headers" style="margin-top: 25px">
              Debit card Info
            </div>

            <SpanFields
              fieldTitle="Mother's Maiden Name"
              :fieldValue="clientData.debit_Security_question"
              titleStyles="is-size-7"
              valueStyles="is-size-7"
            />
          </div>
        </div>
        <div style="width: 50%; margin: 0 15px 0 0">
          <div class="dynamic-container">
            <!-- <div>FD Account Information</div>

                                    <SpanFields
                                     fieldTitle="FD Period"
                                     fieldValue="-"
                                    />
                                    <SpanFields
                                    fieldTitle="Deposit Value"
                                    fieldValue="-"
                                    />
                                    <SpanFields
                                    fieldTitle="Interest payment at"
                                    fieldValue="-"
                                    />
                                    <SpanFields fieldTitle="Interest payable method" fieldValue="CIF" />

                                    <SpanFields fieldTitle="Interset Rate" fieldValue="-" /> -->

            <img src="../../../assets/images/CreditCard.webp" />

            <div style="width: 85%; margin: 0 auto">
              <span class="debit-card-input-title">Debit Card Number </span>
              <span class="star">*</span>
              <!-- <span class="has-text-danger">*</span> -->
              <b-input
                placeholder="Enter Debit Card Number"
                v-model="debitCardNum"
                class="b-input-debit-card-num"
                style="font-size: 14px"
              ></b-input>

              <b-checkbox
                size="is-small"
                style="margin: 15px 0"
                v-if="hasPermission('card-issue')"
                v-model="verifiedInterview"
                >This customer was verifyed by face to face
                interview</b-checkbox
              >

              <b-button
                icon-left="credit-card-check-outline"
                v-if="hasPermission('card-issue')"
                type="is-primary"
                style="border-radius: 8px; font-size: 16px"
                expanded
                size="is-medium"
                :disabled="!debitCardNum || verifiedInterview == false"
                @click="
                  $emit(
                    'debitCardIssueClick',
                    debitCardNum,
                    clientData.id,
                    verifiedInterview
                  )
                "
                >Approve Debit Card</b-button
              >

              <b-button
                type="is-danger"
                v-if="hasPermission('card-reject')"
                expanded
                size="is-medium"
                style="
                  margin-top: 10px;
                  background-color: #ff505a;
                  border-radius: 8px;
                  font-size: 16px;
                "
                @click="$emit('debitCardRejectClick')"
                >Reject</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import SpanFields from "../fields/SpanFields.vue";
import { appSettingsStorage } from "@/appSettingsStorage";

export default {
  name: "CardIssueModal",
  props: ["isCardModalActive", "nicNo", "clientData"],
  emits: ["debitCardIssueClick", "debitCardRejectClick"],

  components: {
    SpanFields,
  },

  data() {
    return {
      baseUrl: appSettingsStorage.baseUrl,
      debitCardNum: "",
      verifiedInterview: false,
    };
  },

  computed: {
    cardModalActive() {
      return this.isCardModalActive;
    },
  },

  methods: {
    closeModal() {
      this.$emit("update:isCardModalActive", false);
    },
    getnameWithInitials(name) {
      // Split the name by spaces and dots
      const parts = name.split(/[\s.]+/);

      // Get the last part of the name
      const lastString = parts.pop();

      // Get the first letters of the remaining parts
      const firstLetters = parts.map((word) => word[0].toUpperCase());

      // Join the first letters and the last part of the name
      const result = firstLetters.join(" ") + " " + lastString;
      return result;
    },
  },
};
</script>

<style scoped>
.main-container {
  background: #ffff;
  width: 930px;
  height: 520px;
  border-radius: 32px;
  margin-top: 55px;

  /* display: flex;
    justify-content: space-between; */
}
.header-container {
  height: 10%;
  /* background-color: #474eaf; */
  display: flex;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 5px 25px;
  padding-right: 10px;
  justify-content: space-between;
  /* background: #edf0f0; */
}

.body-container {
  display: flex;
  height: 90%;
}
.profile-container {
  display: flex;
  margin: 20px;
}

.img-wrapper-profile {
  overflow: hidden;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid #0083CA;
  margin-right: 10px;

}

.img-wrapper-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-container-one {
  padding-bottom: 3px;
  border-bottom: 1px solid gray;
  
}

.title-container-two {
  padding-top: 3px;
  display: flex;
  justify-content: space-between;
}

.title-container-two span {
  width: 50%;
}

.info-container {
  margin-left: 15px;
}

.dynamic-container {
  border: 0.3px solid #edeff2;
  width: 90%;
  height: 80%;
  margin: 15px auto;
  border-radius: 8px;
}

.dynamic-container img {
  width: 50%;
  margin: 10px 90px;
}

.gray-txt {
  color: gray;
}

.application-sub-headers {
  font-size: 14px;
}

.star {
  font-size: 14px;
  color: red;
}

.debit-card-input-title {
  font-size: 12px;
}

.b-input-debit-card-num {
  margin-top: 4px;
}

.nic-no {
  font-size: 13px;
  color: #757575;
}
</style>
