var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApplicationViewVue',{attrs:{"viewData":_vm.applicationData,"comments":_vm.comments,"isLoading":_vm.isLoading,"isFullPage":_vm.isFullPage,"listLength":_vm.listLength},on:{"userCommentSubmitHandler":(userComment) => {
      _vm.userCommentSubmitHandler(userComment);
    },"checkboxChangeHandler":(value) => {
      _vm.pepCheckHandler(value);
    },"agreedRadioButton":(value) => {
      _vm.agreedRadioButton(value);
    },"centralizedOpsChangeHandler":(value) => {
      _vm.centralizedOpsChangeHandler(value);
    },"sanctionListChangeHandler":(value) => {
      _vm.sanctionListChangeHandler(value);
    },"userTwo":()=>{
    _vm.jointOwner(1);
  },"userThree":()=>{
    _vm.jointOwner(2);
  },"userOne":()=>{
    _vm.jointOwner(0);
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }