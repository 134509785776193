<template>
  <div>
  <b-navbar
    :style="{
      background: '#0083CA',
      'box-shadow': '0px 4px 25px rgba(0, 0, 0, 0.28)',
      'backdrop-filter': 'blur(50px)',
    }"
  >
    <template #brand>
      <b-navbar-item>
        <img src="../../assets/logo.png" @click="goToRoot()" />
        <!-- <img src="../../assets/logo.webp" style="mix-blend-mode: darken" /> -->
      </b-navbar-item>
    </template>
    <!-- <template #start>
      <b-navbar-item>
        <p class="navbar-title">DirectID Common UPM Admin Portal</p>
      </b-navbar-item>
    </template> -->
    <template #start>
     <div style="margin: auto 0 auto 15px; color: #ffff;"> 
        <span class=" has-text-weight-semibold" style="margin-right:8px; font-size:22px;">{{ $route.meta.mainTitle }}</span> <span  class="is-size-4 has-text-weight-semibold" v-if="$route.meta.navTitle && $route.meta.mainTitle">  </span> 
        <span class=" has-text-weight-normal" style="padding:2px; font-size:16px;">{{ $route.meta.navTitle }}</span> <span  class="is-size-6 has-text-weight-normal" v-if="$route.meta.subtitle && $route.meta.navTitle"> / </span>  
        <span class="has-text-weight-normal" style="font-size:16px;">{{ $route.meta.subtitle }}</span>
    </div>
    </template>

    <template #end>
      <b-navbar-item tag="div" style="display: flex;justify-content: flex-end;">
        
        <div class="bell-icon-card" rounded style="cursor: pointer; border-radius: 25px; text-align: center; margin-right: 20px;" @click="openNotificationModal" >
          <!-- <span class="mdi mdi-bell-outline"></span> -->
          <img v-if="notificationReceived == true" src="../../assets/images/notify-bell1.png" alt="notify-bell1"/>
          <img v-if="notificationReceived == false" src="../../assets/images/notify-bell2.png" alt="notify-bell1"/>
        </div>  
        <!-- <b-icon icon="account-circle" size="is-medium"></b-icon>
        <b-navbar-dropdown :label="getUsername">
          <b-navbar-item @click="logout()"> Logout </b-navbar-item>
        </b-navbar-dropdown> -->
        <!-- <b-dropdown position="is-bottom-left" append-to-body aria-role="list"> -->
          <template>
            <!-- <b-icon
              icon="account-circle"
              size="is-medium"
              style="cursor: pointer"
            ></b-icon> -->
            
            <div class="columns" style="margin-right: 30px; width: max-content">
              <div class="column">
                <!-- <div class="row" style="width: max-content; text-align: right">
                  <label style="color: rgb(255, 255, 255)">
                    <b>{{ getUsername }}</b>
                  </label>
                </div>
                <div class="row" style="width: max-content; text-align: right">
                  <label style="font-size: small; color: rgb(255, 255, 255)">
                    {{ getUserRole }}
                  </label>
                </div> -->
                <!-- add profile image below-->
              <img v-if="formData && formData.image" :src="baseUrl + formData.image" alt="Profile Image" class="profile-image" @click="navigateToProfilePage" @mouseover="changeCursor('pointer')" @mouseleave="changeCursor('')"/>
              <img v-else src="../../assets/images/default-image.png" alt="Profile Image" class="profile-image" @click="navigateToProfilePage" @mouseover="changeCursor('pointer')" @mouseleave="changeCursor('')"/>
              </div>
            </div>

            <!-- <b-icon icon="account-circle" size="is-large"></b-icon>  -->
          </template>
          <!-- <b-navbar-item >
              <b-icon icon="account-circle" size="is-large"></b-icon> 
              <label>Logged in as: {{ getUsername }} </label>            
            </b-navbar-item> -->
          <!-- <b-navbar-item @click="logout()" style="margin-left: 40px">
            <b-icon icon="exit-to-app" size="is-medium"></b-icon>
            <label style="margin-left: 10px">Logout</label>
          </b-navbar-item> -->
        <!-- </b-dropdown> -->
      </b-navbar-item>
    </template>
    
  </b-navbar>
  <notification-card @isCardModalActive="
        () => {
          isCardModalActive=false;
        }
      " :isCardModalActive="isCardModalActive" />

      <div class="overlap-div" v-if="isNotificationModalActive == true">
        <div class="card">
            <div class="card-content">
                <div class="content">
                    <div v-for="notification in notifications" :key="notification.id">
                        <NotificationBoxVue :notification="notification" @close="(id) => {handleClose(id)}"></NotificationBoxVue>
                    </div>
                    <b-button class="notification-button">
                        <span style="font-size:medium; color:#0083CA;" @click="viewAllNotifications">View All</span>
                    </b-button>
                </div>
            </div>
        </div>
      </div>

  </div>
</template>

<script>
import JwtUtils from "@/helpers/jwtUtils";
import { appSettingsStorage } from "@/appSettingsStorage";
import NetworkManager from "@/network";
import NotificationCard from "@/components/notification/NotificationCard.vue";
import NotificationBoxVue from '../../components/notification/NotificationBox.vue';
import { getMessaging, onMessage } from "firebase/messaging";



export default {
  name: "NavBar",
  components: {
    NotificationCard,
    NotificationBoxVue
  },
  data() {
    return {
      user: null,
      role: null,
      formData: null,
      baseUrl: appSettingsStorage.baseUrl,
      isCardModalActive: false,
      notifications: [],
      isNotificationModalActive: false,
      fcmMessageData: null,
      notificationReceived: false
    };
  },

  computed: {
    getUsername() {
      return this.user !== null ? this.user.name : "User";
    },
    getUserRole() {
      return this.user !== null ? this.user.role : "Role";
    },
  },

  methods: {
    logout: function () {
      JwtUtils.logoutTheUser(true);
    },

    openNotificationModal() {
      // this.isCardModalActive = true;
      this.notificationReceived == false
      // this.isNotificationModalActive = true;
      this.isNotificationModalActive = !this.isNotificationModalActive;

      if (this.isNotificationModalActive) {
        this.getAllNotifications();
      }
    },

    navigateToProfilePage() {
      this.$router.push('profileManagement');
    },

    changeCursor(cursorType) {
      this.$el.style.cursor = cursorType;
    },

    handleClose(id) {
      // console.log("Closed notification", id);
      NetworkManager.apiRequest("backend/notification/readNotifications", {id:id}, (e) => {
        // console.log("Notification ID",id)
        });
        // Remove the closed notification from the notifications array
        this.notifications = this.notifications.filter(notification => notification.id !== id);   
    },
    closeNotificationWindow() {
        this.$emit('isCardModalActive')
        this.notificationReceived = false;
    },

    viewAllNotifications() {
      this.isCardModalActive = true;
      this.isNotificationModalActive = false;
    },

    getUserData: function(){
      NetworkManager.apiRequest("backend/user/getUserData", {}, (e) => {
        this.formData = e.data;
    });
    },

    getAllNotifications(){
      NetworkManager.apiRequest("backend/notification/getNotifications", {}, (e) => {
        e.data.notifications.map((data) => {
          data.created_at=this.getTimeAgo(data.created_at);
        });
        // console.log("Notification Dataaa in App main",e.data.notifications)
        this.notifications = e.data.notifications.slice(0, 3);
        // this.notifications = e.data.notifications;
      });
    },
    getFcmMessages(){
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        // if(payload.data.branch == userBranch){
          this.fcmMessageData = payload;
          // console.log('Message received in navbar. ', payload);
          // const notificationData = {
          //   title: payload.notification.title,
          //   body: payload.notification.body,
          // };
      });
    },

    getTimeAgo(createdTime) {
      const currentTime = new Date();
      const timeDifference = currentTime - new Date(createdTime);
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      if (minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
      } else {
        const hoursDifference = Math.floor(minutesDifference / 60);
        if (hoursDifference < 24) {
          return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else {
          const daysDifference = Math.floor(hoursDifference / 24);
          return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        }
      }
    },

    notificationChanged(){
      this.notificationReceived = true;
      // console.log("New Notification received in navbar.")
      // console.log("Notification Dataaa in App main",this.fcmMessageData)
    },
    // goToRoot() {
    //   if(this.user.role === 'Detail Reviewer')
    //   {
    //     this.$router.push('/assigned');
    //   }
    //   if(this.user.role === 'Super Admin' || 'Admin')
    //   {
    //     window.open('/applicationList', '_self');
    //   }
    //   if(this.user.role === 'Call Agent')
    //   {
    //     window.open('/schedulevideocall', '_self');
    //   }
    //   if(this.user.role === 'Authorizer')
    //   {
    //     window.open('/applicationsForApproval', '_self');
    //   }
    //   if(this.user.role === 'Exceptional Approver')
    //   {
    //     window.open('/Approvalrequired', '_self');
    //   }
    //   if(this.user.role === 'Branch User')
    //   {
    //     window.open('/RefferToBranchQueue', '_self');
    //   }
    // }
  },

  watch : {
    fcmMessageData: {
      handler(){
        this.notificationChanged();
      },
      deep: true

    }
  },

  mounted() {
    // const messaging = getMessaging();
    //   onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     const notificationData = {
    //       title: payload.notification.title,
    //       body: payload.notification.body,
    //       // Add other relevant data fields if present
    //       timestamp: firebase.firestore.FieldValue.serverTimestamp() // Optional: Store server timestamp
    //     };

    //     db.collection('notifications')
    //     .add(notificationData)
    //     .then(() => {
    //       console.log('Notification data stored in Firestore successfully!');
    //     })
    //     .catch((error) => {
    //       console.error('Error storing notification data:', error);
    //     });
    //     // ...
    //   });
    this.getFcmMessages();
    // this.getAllNotifications();
    
    this.user = JwtUtils.loadToken().jwtObj;
    // console.log("USER DETAILS ", this.user);
    this.getUserData();

    
  },
};
</script>

<style scoped>
.navbar-title {
  font-size: x-large;
  font-weight: bold;
}
.navbar-item img {
  max-height: 3rem;
}

.profile-image {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  border-radius: 50%; /* Make it a circle */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.overlap-div {
  position: absolute; 
  top: 0; 
  left: 0; 
  z-index: 1000; 
  margin-left: 620px;
  margin-top: 70px;
  background-color: bisque;
}

.notification-button{
  width:757px;
  border-top: 1px solid #dbdbdb;
  border-bottom: #ffffff;
  border-left: #ffffff;
  border-right: #ffffff;
}

.bell-icon-card{
  width: 43px;
  height: 44px;
  cursor: pointer;
  border-radius: 25px;
  text-align: center;
  margin-right: 20px;
  background-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
}
</style>
