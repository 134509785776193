<template>
  <div style="overflow: hidden; max-height: 100%; padding-top:10px; padding-bottom:50px">
    <!-- <div style="position: sticky; margin-left: 40px; "><label style="color: gray; font-weight: 400; font-size: 23px">Edit Role</label> </div> -->
    <div class="column" style="text-align: right; padding-right:20px">
        <b-button type="is-danger" @click="deleteModelVisible = true" class="float-right"
          >Delete</b-button
        >
      </div>
  <div style="overflow-y: hidden; overflow-x: hidden; max-height: 100vh;  padding-bottom:180px; padding-left: 10px; padding-right: 20px; overflow-y: scroll;" >
    <div class="columns is-multiline" style="margin-top: 2.8vh">
      <!-- <div class="column">
        <label style="color: gray; font-weight: 400; font-size: 23px">Edit Role</label>
      </div> -->
      
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical is-12">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <div class="tile is-child custom-tile">
              <section>
                <b-field
                  horizontal
                  :type="invalidRole == '' ? '' : 'is-danger'"
                  :message="invalidRole"
                >
                  <template slot="label"
                    >Role <span class="has-text-danger">*</span></template
                  >
                  <b-input
                    v-model="formData.role_name"
                    expanded
                    @input="resetInvalidStatus('invalidRole')"
                  ></b-input>
                </b-field>

                <!-- <b-field
                  horizontal
                  :type="invalidUserLevel == '' ? '' : 'is-danger'"
                  :message="invalidUserLevel"
                >
                  <template slot="label"
                    >User Level <span class="has-text-danger">*</span></template
                  >
                  <b-select
                    v-model="formData.user_level"
                    placeholder="Select user level"
                    @input="resetInvalidStatus('invalidUserLevel')"
                  >
                    <option
                      v-for="userLevel in userLevelList"
                      v-bind:value="userLevel.user_level"
                      :key="userLevel.id"
                    >
                      {{ userLevel.title }}
                    </option>
                  </b-select>
                </b-field> -->

                <b-field
                  horizontal
                  :type="invalidPermissions == '' ? '' : 'is-danger'"
                  :message="invalidPermissions"
                >
                  <div class="block"></div>
                </b-field>

                <b-field horizontal>
                  <template slot="label"
                    >Permissions <span class="has-text-danger">*</span></template
                  >

                  <div class="permissions-container">
                    <div
                      class="permission-group-wrapper"
                      v-for="permissionData of permissionList"
                      :key="permissionData.rowId"
                    >
                      <div class="permission-group-title">
                        {{ permissionData.group_name }}
                      </div>
                      <div class="permissions-flex-box">
                        <div class="permission-group-permission-list">
                          <div
                            class="field"
                            v-for="permissionItem of permissionData.permission_list"
                            :key="permissionItem"
                          >
                            <b-checkbox
                              type="is-primary"
                              @input="addOrRemovePermission(permissionItem)"
                              v-model="checkBoxBindings[permissionItem]"
                              size="is-small"
                              >{{ permissionItem }}</b-checkbox
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-field>

                <b-field horizontal message=" ">
                  <template slot="label"
                    >Status <span class="has-text-danger">*</span></template
                  >
                  <div class="block">
                    <b-radio
                      v-model="formData.disabled"
                      type="is-primary"
                      name="status"
                      native-value="0"
                      style="margin-right: 10px"
                    >
                      Active
                    </b-radio>
                    <b-radio
                      v-model="formData.disabled"
                      name="status"
                      native-value="1"
                      type="is-danger"
                    >
                      Not Active
                    </b-radio>
                  </div>
                </b-field>
              </section>
            </div>

            <!-- <section class="mb-5" v-if="formIsInvalid">
              <b-message type="is-danger">
                Form contains invalid fields. Please make sure you have filled the form
                properly.
              </b-message>
            </section> -->

            <div class="tile custom-tile-narrow">
              <div class="column">
                <!-- <section class="mb-5" v-if="formIsInvalid">
                  <b-message type="is-danger">
                    Form contains invalid fields. Please make sure you have filled the
                    form properly.
                  </b-message>
                </section> -->
                <div class="columns">
                  <div class="column">
                    <p class="has-text-danger is-italic">
                      Fields marked with <big>*</big> are mandatory
                    </p>
                  </div>
                  <!-- <div class="buttons">
                    <b-button rounded @click="validateAndSubmit" type="is-primary"
                  <div class="buttons">
                    <b-button @click="validateAndSubmit" type="is-primary"
                      >Save</b-button
                    >
                    <b-button  @click="$router.go(-1)" class="is-danger"
                      >Cancel</b-button
                    >
                  </div> -->

                  <div class="align-right mt-5">
                    <b-button
                      type="is-primary is-light"
                      class="mr-5"
                      size="is-normal"
                      @click="$router.go(-1)"
                      >Cancel</b-button
                    >
                    <b-button type="is-primary" size="is-normal" @click="validateAndSubmit"
                      >Edit Role</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="deleteModelVisible" :width="400" scroll="keep" animation="zoom-out">
      <div class="model-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete User</p>
          <button
            @click="deleteModelVisible = false"
            type="button"
            class="delete"
          ></button>
        </header>

        <div class="modal-card-body">
          <div class="content pt-2">
            <p>Are you sure that you want to delete this user from the system ?</p>
          </div>
        </div>

        <footer class="modal-card-foot">
          <div class="is-full column py-0 px-0">
            <div class="column">
              <div class="columns">
                <div class="column"></div>
                <div class="buttons">
                  <b-button
                    rounded
                    id="detele_now"
                    @click="deleteRecord"
                    type="is-primary is-small"
                    >Delete Now</b-button
                  >
                  <b-button
                    rounded
                    @click="deleteModelVisible = false"
                    type="is-danger is-small"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</div>
</template>

<style>
.align-right {
  text-align: right;
}

.permissions-flex-box {
  display: flex;
}

.permission-level-input {
  max-width: 50px;
}
.permission-group-wrapper {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;
  vertical-align: top;
}
.permission-group-title {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
.permission-group-permission-list {
  padding-left: 0.1rem;
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";

export default {
  name: "CreateRole",
  components: {},
  data() {
    return {
      formData: {
        role_name: "",
        user_level: null,
        selectedPermissions: [],
        disabled: 0,
      },
      userLevelList: [],
      permissionList: [],
      checkBoxBindings: {},
      groupWisePermissions: {},
      permissionGroup: [],
      deleteModelVisible: false,
      formIsInvalid: false,
      invalidRole: "",
      invalidName: "",
      invalidUserLevel: "",
      invalidPermissions: "",

      oldRoleName: "",
      oldUserLevel: "",
      oldPermissions: [],
      oldDisabled: "",

      regex: {
        name: /^[a-zA-Z][a-zA-Z ]+$/,
      },
    };
  },
  methods: {
    validateForm: async function () {
      const component = this;
      var formIsValid = true;
      component.btnDisabled = true;

      const oldPermission = component.formData.permissions.split("|");
      var newPermission = component.selectedPermissions;

      const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
          return false;
        }

        return arr1.every((value, index) => value === arr2[index]);
      };

      if (
        component.formData.role_name == component.oldRoleName &&
        component.formData.user_level == component.oldUserLevel &&
        areArraysEqual(oldPermission, newPermission) &&
        component.formData.disabled == component.oldDisabled
      ) {
        BuefyHelper.showToastMessage("No changes detected", "is-danger");
        formIsValid = false;
      }

      // Role Name
      if (component.formData.role_name == "") {
        component.invalidRole = "This field cannot left blank";
        formIsValid = false;
      }

      // else if (!this.regex.name.test(this.formData.role_name)) {
      //   this.invalidName = "Role name cannot contain numbers or special characters";
      //   formIsValid = false;
      // } else if (this.formData.role_name.length > 64) {
      //   this.invalidName = "Role name should be below 64 characters";
      //   formIsValid = false;
      // } else {
      //   var roleNameStatus = await this.validateRoleName();

      //   if (!roleNameStatus) {
      //     formIsValid = false;
      //   }
      // }

      // if (component.formData.user_level == null) {
      //   component.invalidUserLevel = "This field cannot left blank";
      //   formIsValid = false;
      // }

      if (component.selectedPermissions.length < 1) {
        component.invalidPermissions = "Please select at least one permission";
        formIsValid = false;
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    resetInvalidStatus: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;
    },

    validateAndSubmit: async function () {
      var formValidStatus = await this.validateForm();

      if (formValidStatus) {
        this.submitForm();
      } else {
        this.formIsInvalid = true;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    addOrRemovePermission: function (permissionItem) {
      if (this.selectedPermissions.includes(permissionItem)) {
        var index = this.selectedPermissions.indexOf(permissionItem);
        this.selectedPermissions.splice(index, 1);
      } else {
        this.selectedPermissions.push(permissionItem);
      }

      // this.fieldChanged();
      this.resetInvalidStatus("invalidPermissions");
    },
    submitForm() {
      var permissionText = "";
      this.selectedPermissions.forEach(function (permission) {
        permissionText += "|";
        permissionText += permission;
      });
      permissionText = permissionText.substring(1);
      this.formData.permissions = permissionText;

      NetworkManager.apiRequest("backend/roles/edit", this.formData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval successfully",
            "is-success"
          );
          this.$router.push("/role");
        } else {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval failed",
            "is-danger"
          );
        }
      });
    },
    deleteRecord: function () {
      NetworkManager.apiRequest("backend/roles/delete", this.formData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval successfully",
            "is-success"
          );
          // location.reload(); 
          this.$router.push("/role");
        } else {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval failed",
            "is-danger"
          );
        }
      });
    },
  },
  mounted() {
    var component = this;
    // console.log("self.$route", component.$route.params.id);
    const data = {
      id: component.$route.params.id,
    };

    NetworkManager.apiRequest("backend/roles/getItemForEdit", data, (e) => {
      component.formData = e.data.itemData;

      component.oldRoleName = e.data.itemData.role_name;
      component.oldUserLevel = e.data.itemData.user_level;
      component.oldPermissions = e.data.itemData.permissions;
      component.oldDisabled = e.data.itemData.disabled;

      var tmpUserPermissions = e.data.itemData.permissions.split("|");

      // component.permissionList = this.$route.params.permissions
      // component.userLevelList = this.$route.params.userLevelList
      component.userLevelList = e.data.userLevelList;
      component.permissionList = e.data.permissions;
      component.canDelete = component.$route.params.canDelete;

      var tmpPermissionGroup = [];
      var tmpSelectedPermissions = [];
      var bindings = {};
      var permissionKeys = {};

      component.permissionList.forEach(function (element) {
        tmpPermissionGroup.push(element.group_name);
        permissionKeys[element.group_name] = [];
        element.permission_list.forEach(function (permisssion) {
          if (tmpUserPermissions.includes(permisssion)) {
            bindings[permisssion] = true;
            tmpSelectedPermissions.push(permisssion);
          } else {
            bindings[permisssion] = false;
          }
          permissionKeys[element.group_name].push(permisssion);
        });
      });

      component.checkBoxBindings = bindings;
      component.groupWisePermissions = permissionKeys;
      component.permissionGroup = tmpPermissionGroup;
      component.selectedPermissions = tmpSelectedPermissions;
    });
  },
};
</script>
