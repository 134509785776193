<template>
     <b-modal v-model="isNotificationModalActive" >
          <div class="main-container">
               <img :src="imgSrc"/>
               <div style="text-align: center" class="is-size-4 has-text-weight-semibold">
                    Are You Sure, <br>
                   <span class="has-text-weight-bold"> {{applicationStatText}}</span>
               </div>
               <div class="button-container">
                    <b-button expanded :type ='buttonType' size="is-medium" @click="$emit('applicationStatActionHandler')">
                         Yes, {{buttonActionText}}
                        
                    </b-button>

                    <b-button expanded size="is-medium" @click="$emit('closeAction')">
                         Cancel                       
                    </b-button>


               </div>
          </div>

        
     </b-modal>
</template>

<script>
import { computed } from 'vue'
export default {
     name : "NotificationModal",
     props : ["isNotificationModalActive", "applicationType", ],
     emits : ["applicationStatActionHandler", 'closeAction'],

     data(){
          return{
               applicationStatText : "",
               imgSrc : "",
               buttonType : "",
               buttonActionText : "",
                    
               
          }
     },

     methods:{
          valueChanger :function(){
               if(this.applicationType === 'Reject'){
                    this.applicationStatText = 'You Want To Reject This Application?'
                    this.imgSrc = require('../../../assets/images/RejectNotification.png')
                    this.buttonType = 'is-danger';
                    this.buttonActionText = 'Reject'
                  
               }
               else if(this.applicationType === "Request To Improve"){
                    this.applicationStatText = 'You Want To Request An Improvement?'
                    this.imgSrc = require('../../../assets/images/ReqToImprove.png')
                    this.buttonType = 'is-info'
                    this.buttonActionText = 'Confirm'
               }
              
          }
     },

      watch: {
          applicationType: {
               handler(){
               this.valueChanger()
               },
                deep : true
               
               // Call the function whenever applicationType changes
               
          }
  },

     mounted(){
           this.valueChanger();
          // alert("in");
          // function applicationStatText(){
          //           alert('hgdjwh');
          //           return this.applicationType === 'Reject' ? 'You Want To Reject This Application?' :  'You Want To Request An Improvement?'
          // }
          

          // const applicationStatText = () => {
          //      alert(this.applicationType);
          //      return this.applicationType === 'Reject' ? 'You Want To Reject This Application?' : 'You Want To Request An Improvement?';
          // };

          // Call the test function
          // const result = applicationStatText();
          // console.log(result); 
     }


}
</script>

<style scoped>
.main-container{
     background: #ffff;
    width: 480px;
    height: 500px;
    border-radius:12px;
    margin: auto;
}

.main-container img{
     width: 120px;
     margin: 20px 160px 10px 181px;
  
}

.button-container{
     margin: 100px auto 0 auto; 
     width: 80%;
     
}
.button-container button{
     margin: 10px 0;
}
</style>