import router from "@/router"
import { ToastProgrammatic as Toast } from 'buefy'
import { useAuthStore } from "../store/authStore";
import NetworkManager from "@/network"
import { PiniaStoreHelper } from "./piniaHelper";

const jwtUtils = {
    status: {
        valid: 'valid',
        invalid: 'invalid',
        expired: 'expired',
        margin: 'margin'
    },

    loadToken: function (customToken="") {
        try {
            let data = null

            const token = customToken === "" ? localStorage.getItem('token') : customToken
            const jwtObj = this.parseJwt(token)
            const status = this.validateJwt(jwtObj)
            // console.log("status of the token..", status)

            switch (status) {
                case this.status.valid:
                    data = { token, jwtObj }
                    break
                case this.status.margin:
                    data = { token, jwtObj }
                    setTimeout(() => {
                        this.refreshJwt().then()
                    }, 5000)
                    break
                case this.status.expired:
                    console.error('Token expired!')
                    this.logoutTheUser()
                    break
                case this.status.invalid:
                    console.error('Token invalid!')
                    this.logoutTheUser()
                    break
            }

            return data
        }
        catch (e) {
            // console.error(e.message)
            this.logoutTheUser()
            return null
        }
    },

    saveToken: function (token){
        console.warn('Saving token...')
        try {
            this.parseJwt(token)
            const parseJwt = this.parseJwt(token)

            const authStore= useAuthStore()
            authStore.token = token
            authStore.isTokenRefreshing = false
            authStore.loggedInUser = parseJwt.user_id
            authStore.loggedInUserBranch = parseJwt.branch
            localStorage.setItem('token', token)
            localStorage.setItem('isTokenRefreshing', "false")
        }
        catch {
            console.error('Token save failed!')
        }
    },

    validateToken: function (){
        try {
            let isValid = true

            const token = localStorage.getItem('token')
            const jwtObj = this.parseJwt(token)

            const status = this.validateJwt(jwtObj)

            switch (status){
                case this.status.valid:
                    isValid = true
                    break
                case this.status.margin:
                    isValid = true
                    break
                case this.status.expired:
                    console.error('Token expired!')
                    this.logoutTheUser()
                    isValid = false
                    break
                case this.status.invalid:
                    console.error('Token invalid!')
                    this.logoutTheUser()
                    isValid = false
                    break
            }

            return isValid
        }
        catch (e){
            // console.error(e.message)
            this.logoutTheUser()

            return false
        }
    },

    parseJwt: function (token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            const decoded = atob(base64);
            return JSON.parse(decoded);
        } catch (e) {
            throw "Token parsing failed!";
        }
    },

    validateJwt: function (jwtObj) {
        try {
            let status = this.status.valid

            const createdTime = jwtObj.iat
            const expireTime = jwtObj.exp
            const refreshMargin = jwtObj.rfh
            const currTime = Date.now()/1000
            const timespan = currTime - createdTime

            //Debug Logs --------------------------------------
            // console.warn("validating JWT token ...")
            // console.log("jwt details")
            // console.log("Expire time",jwtObj.exp)
            // console.log("refresh margin",refreshMargin/1000)
            // console.log("timespan",timespan)
            // console.log("currTime",currTime)
            // console.log("currTime-Expire time : Expire",expireTime-currTime)
            // console.log("timespan-refreshMargin : Refresh",(refreshMargin/10)-timespan)
            // console.log("timespan >= refreshMargin",timespan >= refreshMargin)
            // console.log("Current Timeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",Date.now()/1000)
            // console.log("Expire Timeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",expireTime)
            // console.log("Created Timeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",createdTime)
            // console.log("is video call started :",GlobalFunctions.getVideoCallStartedState())


            if (currTime >= expireTime) {
                status = this.status.expired
                return status
            }

            if(timespan >= refreshMargin/1000) {
                
                status = this.status.margin
                return status
            }
            return status
        }
        catch {
            return this.status.invalid
        }

    },

    refreshJwt: async function (enforcedRefresh=false){
        const self = this
        const authStore = useAuthStore()

        const isRefreshing = localStorage.getItem('isTokenRefreshing')
        if(enforcedRefresh){
            NetworkManager.apiRequest('backend/user/refreshJwtToken', {}, (e) => {
                let token = e.data.authToken;
                self.saveToken(token);
            });
        }
        else{
            if(isRefreshing === "false"){
                // console.log('Refreshing token...')
                localStorage.setItem('isTokenRefreshing', 'true')
                authStore.isTokenRefreshing = true
                    NetworkManager.apiRequest('backend/user/refreshJwtToken', {}, (e) => {
                        let token = e.data.authToken;
                        self.saveToken(token);
                    });
                }
            }  
    },

    logoutTheUser: function (manualLogOut = false) {
        const authStore = useAuthStore()
        if (localStorage.getItem('token') != null) {
            localStorage.removeItem('token')
            PiniaStoreHelper.clearLocalStorage(authStore)
            router.replace({ path: '/login' }).then(() => { router.go() })
        }
        if (router.currentRoute.name !== 'Login') {
            if (manualLogOut) {
                router.replace({ path: '/login' }).then(() => { router.go() })
            }
            else {
                const params = {
                    type: 'is-info',
                    message: 'Please login again!'
                }
                Toast.open(params)
                setTimeout(() => {
                    router.replace({ path: '/login' }).then(() => { router.go() })
                }, 100)
            }
        }
    }
}

export default jwtUtils
