<template>
  <!-- <div class="columns is-centered">
    <div class="column is-one-second">
      <img src="logo.png" alt="User Picture" class="user-picture" />
    </div>

    <div class="column is-one-second">
      <div class="has-text-centered" style="margin-top: 13.3vh">
        <label class="is-size-3 has-text-primary">Forgot Password?</label>
      </div>
      <div class="has-text-centered" style="margin-top: 2.8vh">
        <label class="is-size-5 has-text-black">Please enter your username below, and we will send an OTP</label>
      </div>
     <div class="has-text-centered" style="margin-top: 0.2vh">
        <label class="is-size-5 has-text-black">code to your registered email address</label>
      </div>
      <div class="field" style="margin-top: 5.3vh;margin-left: 27.3vh;margin-right: 6.3vh">
        <div class="control">
        <b-field
          :type="invalidFields.username == '' ? '' : 'is-danger'"
          :message="invalidFields.username">
              <b-input
               @input="resetInvalidStatus('username')"
                id="username"
                type="text"
                placeholder="Enter user name"
                v-model="user.username"
                style="width: 75%;"></b-input>
          </b-field>
        </div>
      </div>
      <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button class="button is-primary is-medium is-rounded" style="width: 107%; margin-top: 24.3vh; margin-right: 34.3vh" @click="validateAndSendOtp">Send OTP</button>
        </div> 
      </div>
    </div>
  </div> -->

  <div class="login-page-bg" style="background-color: #f2f2f2">
    <div class="wrapper">
      <div class="cover-img"></div>

      <div class="input-area">
        <!-- First Column: Display Picture -->
        <div>
          <img
            src="../../assets/images/Forgot Password.png"
            alt="SDB Bank Logo"
            style="width: 6vw; margin: 0 40%"
          />
        </div>

        <div class="has-text-centered is-size-3 has-text-primary has-text-weight-bold">
          Forgot Password?
        </div>
        <div
          class="has-text-centered is-size-7 has-text-weight-semibold"
          style="color: #757575; width: 75%; margin: 0 auto"
        >
          Please enter your Employee ID below, and we will send an OTP code to your
          registered email address.
        </div>
        <div class="inputs">
          <div class="input-felds-styles">
            <!-- <div class="control"> -->
            <b-field
              :type="invalidFields.username == '' ? '' : 'is-danger'"
              :message="invalidFields.username"
            >
              <b-input
                id="username"
                size="is-medium"
                type="text"
                v-model="user.username"
                @input="resetInvalidStatus('username')"
                placeholder="Enter your Employee ID"
              />
            </b-field>
            <!-- </div> -->
          </div>

          <ActionButton
            type="is-primary"
            buttonLabel="Send OTP"
            expanded=" expanded"
            size="is-medium"
            :buttonAction="validateAndSendOtp"
            divStyles="input-felds-styles-buttons"
            :loading="isButtonLoading"
          />

          <b-loading
            :is-full-page="false"
            :active.sync="isLoading"
            :can-cancel="false"
          ></b-loading>

          <ActionButton
            inverted
            type="is-primary"
            buttonLabel="Back to Sign In"
            expanded=" expanded"
            size="is-medium"
            :buttonAction="Back"
            divStyles="input-felds-styles-buttons"
            icon-left="arrow-left"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.align-right {
  text-align: right;
}
</style>

<script>
import NetworkManager from "@/network";
import ActionButton from "@/components/support/buttons/ActionButton.vue";
import BuefyHelper from "@/helpers/bufeyHelper";

export default {
  name: "ForgotPassword",
  components: {
    ActionButton,
  },
  data() {
    return {
      isLoading: false,
      isFullPage: true,
      data: "",
      otpData: null,
      user: {
        username: "",
      },
      invalidFields: {
        username: "",
      },
      isButtonLoading: false,
    };
  },
  methods: {
    sendOTP: function () {
      this.isButtonLoading = true;
      //  var component = this
      NetworkManager.apiRequest("sendInitialOTPEmail", this.user, (e) => {
        this.isLoading = false; // Stop loading
        if (e.success === true) {
          this.isButtonLoading = false;
          this.otpData = {
            user: e.data.user,
            email: e.data.email,
          };

          localStorage.setItem("otpData", JSON.stringify(this.otpData));

          this.$router.push({
            name: "OTPVerification",
            params: { otpData: this.otpData },
          });
        } else if (e.success === false) {
          BuefyHelper.showToastMessage("Please enter a valid Employee ID", "is-danger");
        }
      });
    },

    validateAndSendOtp: function () {
      if (this.validateForgotPassword()) {
        this.sendOTP();
      }
    },

    validateForgotPassword: function () {
      var formIsValid = true;

      if (this.user.username == "") {
        this.invalidFields.username = "Enter your Employee ID";
        formIsValid = false;
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    resetInvalidStatus: function (key) {
      this.invalidFields[key] = "";
    },

    Back: function () {
      this.$router.push("/login");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.login-page-bg {
  background-image: url("../../assets/images/Login_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
}

.cover-img {
  background-image: url("../../assets/images/Login_Page_Image.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 560px;
}

.wrapper {
  display: flex;
  width: fit-content;
  border-radius: 8px;
  flex-direction: row;
  background: white;
  height: 650px;
  margin: auto;
}

.input-area {
  padding: 20px;
  height: 735px;
  width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: inherit;
}

.inputs {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.input-felds-styles-buttons {
  margin: 20px auto;
}

.input-felds-styles {
  margin: 60px auto 130px auto;
}
</style>
