<template>
    <div style="padding: 0px 20px">
      <div class="columns is-multiline" style="margin-top: 2.8vh">
        <div class="column">
          <!-- <label style="color: gray; font-weight: 400; font-size: 15px">User List</label> -->
        </div>
      </div>
      <div style="height: 80vh; overflow-y: hidden !important">
        <TableComponent
          :objectList="filterList"
           height="53vh"
          :columns="displayedColumns"
          :excelColumns="excelColumns"
          :tableData="dataList"
          :isHidden="true"
          :shouldShowExcelComponent="true"
          :name = name
          :isLoading="isLoading"
          @inputChange="
            () => {
              valueOnChangee();
            }
          "
          @tableFilter="
            () => {
              userFilterRequestHandler();
            }
          "
          @resetFilter="
            () => {
              resetFilter();
            }
          "
          @showResetPasswordsModal="
            (id) => {
              showResetPasswordsModal(id);
            }
          "
          type="text"
        />
      </div>
      <b-modal
        v-model="resetPasswordModalVisible"
        :width="400"
        scroll="keep"
        animation="zoom-out"
      >
        <div class="model-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Reset User Password</p>
            <!-- <button
              @click="resetPasswordModalVisible = false"
              type="button"
              class="delete"
            ></button> -->
          </header>
  
          <div class="modal-card-body">
            <div class="content pt-2">
              <p>Generate a new password for user</p>
            </div>
  
            <section>
              <b-field
                grouped
                :type="invalidFields.invalidUserPassword == '' ? '' : 'is-danger'"
                :message="invalidFields.invalidUserPassword"
              >
                <b-input
                  @input="resetInvalidStatus('invalidUserPassword')"
                  v-model="formData.userPassword"
                  expanded
                ></b-input>
                <p class="control">
                  <b-tooltip label="Regenerate Password" position="is-left">
                    <b-button
                      @click="generatePassword"
                      type="is-primary"
                      icon-right="autorenew"
                    />
                  </b-tooltip>
                </p>
                <p class="control">
                  <b-tooltip label="Copy Password" position="is-left">
                    <b-button
                      @click="copyToClipboard"
                      type="is-primary"
                      icon-right="content-copy"
                    />
                  </b-tooltip>
                </p>
              </b-field>
            </section>
          </div>
  
          <footer class="modal-card-foot">
            <div class="is-full column py-0 px-0">
              <div class="column">
                <div class="columns">
                  <div class="column"></div>
                  <div class="buttons">
                    <b-button
                      id="apply_new"
                      rounded
                      :loading="isButtonLoading"
                      @click="validateAndResetPassword"
                      type="is-success is-small"
                      >Apply New Password</b-button
                    >
                    <b-button
                      rounded
                      @click="resetPasswordModalVisible = false"
                      type="is-danger is-small"
                      >Cancel</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </div>
</template>
  
  <script>
  import NetworkManager from "@/network";
  import TableComponent from "@/components/TableComponent.vue";
  import { FilterInputFiled } from "@/helpers/FilterInputFiled";
  import DateConverter from "@/helpers/DateConverter";
  import BuefyHelper from "@/helpers/bufeyHelper";
  // import BranchListSender from "@/network"
  // import NavBar from "@/layout/components/NavBar";
  // import SideBar from "@/layout/components/SideBar";
  
  export default {
    name: "UserReport",
    components: {
      TableComponent,
      // NavBar,
      // SideBar
    },
    data() {
      return {
        columns: [
          {
            field: "username",
            label: "Employee ID",
          },
          {
            field: "name",
            label: "Name",
          },
          {
            field: "role_name",
            label: "Role",
          },
          {
            field: "email",
            label: "Email Address",
          },
  
          {
            field: "contact",
            label: "Mobile",
          },
          {
            field: "disabled",
            label: "Status",
          },
          {
            field: "branch_name",
            label: "Branch",
          },
          {
            field: "created_at",
            label: "Created At",
          },
          {
            field: "updated_at",
            label: "Updated At",
          },
        ],
        excelColumns: [
          {
            field: "username",
            label: "Employee ID",
          },
          {
            field: "name",
            label: "Name",
          },
          {
            field: "role_name",
            label: "Role",
          },
          {
            field: "email",
            label: "Email",
          },
  
          {
            field: "contact",
            label: "Mobile",
          },
          {
            field: "disabled",
            label: "Status",
          },
          {
            field: "created_at",
            label: "Created At",
          },
          {
            field: "updated_at",
            label: "Updated At",
          },
        ],
        invalidFields: {
          invalidUserPassword: "",
        },
        formData: {
          userPassword: "",
        },
        data: {
          userId: "",
          userPassword: "",
        },
        isButtonLoading: false,
        resetPasswordModalVisible: false,
        isFullPage: false,
        dataList: [],
        branchList: [],
        valueOne: "",
        isLoading: false,
        resetPasswordRowId: null,
        
        name: "",
  
        filterList: [
          new FilterInputFiled("Select Branch", "", [], "SELECT"),
          new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
          new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
          new FilterInputFiled("Search", "", "", "BUTTON"),
          new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
        ],
        // isLoading: true,
        // isFullPage: false,
      };
    },
    computed: {
      displayedColumns() {
        const hasUserCreatePermission = this.hasPermission(
          "user-create" || "user-edit" || "user-delete" || "password-reset"
        );
        return hasUserCreatePermission
          ? this.columns
          : this.columns.filter((column) => column.field !== "actions");
      },
    },
    methods: {
      valueOnChangee(value) {
        const comp = this;
        // console.log("Parent value chnage ", value);
        comp.valueOne = value;
      },
  
      userFilterRequestHandler: function () {
        this.isLoading = true;
        NetworkManager.apiRequest(
          "backend/user/filterUsers",
          {
            branch: this.filterList[0].value === "All" ? "" : this.filterList[0].value,
            from_date: this.filterList[1].value
              ? DateConverter.dateFormater(this.filterList[1].value)
              : "",
            to_date: this.filterList[2].value
              ? DateConverter.dateFormater(this.filterList[2].value)
              : "",
          },
  
          (e) => {
            e.data.list.map((data) => {
              data.updated_at = DateConverter.dateFormater(data.updated_at);
              data.created_at = DateConverter.dateFormater(data.created_at);
              data.contact = '+94' + data.contact.slice(1);
              data.disabled == 0 ? (data.disabled = "Active") : (data.disabled = "Inactive");
            });
            this.dataList = e.data.list;
            // console.log("this.dataList ", this.dataList);
            this.isLoading  = false;
          },
          false
        );
      },
  
      resetFilter: function () {
            this.isLoading = true;
            // Reset filter fields
            this.filterList.forEach((field) => {
                  field.value = "";
            });
            this.dataList = [];
            this.isLoading = false;
           
      },

      filterButtonStateHandler : function(){
       
        if(this.filterList[0].value !== "" || this.filterList[1].value !== "" || this.filterList[2].value !== ""){
          this.filterList[3].isButtonDisabled = false
          this.filterList[4].isButtonDisabled = false
        }
        else{
          this.filterList[3].isButtonDisabled = true
          this.filterList[4].isButtonDisabled = true
        }
      
      }
    },

    watch : {
      filterList: {
        handler(){
          this.filterButtonStateHandler()
        },
        deep: true
  
      }
    },
  
    mounted() {
      this.branchListLoader(this.filterList[0].options);
      var date = new Date();
      var month = date.getMonth() + 1;
      this.name = "User Report " + date.getFullYear() + "/" + month + "/" + date.getDate()
    },
  };
  </script>
  