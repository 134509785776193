<template>
  <!-- background image -->
  <div class="login-page-bg">
    <div class="wrapper">
      <div class="cover-img"></div>

      <div class="input-area">
        <!-- First Column: Display Picture -->
        <div>
          <img
            src="../../assets/images/Bank_LOGO.png"
            alt="SDB Bank Logo"
            style="width: 15vw; margin: 0 25%"
          />
        </div>

        <!-- Second Column: Data Fields -->
        <!-- <div class="column "> -->
        <!-- <div class="has-text-centered" style="margin-top: 12.8vh">
        <label class="is-size-3 has-text-primary">SDB bank</label>
      </div> -->
        <div class="is-size-5 has-text-weight-medium" style="margin: 0 auto">
          Welcome Back
        </div>
        <div
          class="is-size-2 has-text-primary has-text-weight-bold"
          style="margin: 0 auto"
        >
          Let's Sign In
        </div>

        <div class="inputs">
          <div class="input-felds-styles">
            <!-- <div class="control"> -->
            <b-input
              id="mobile"
              size="is-medium"
              type="text"
              v-model="user.username"
              placeholder="Enter your Employee ID"
              @keyup.native="(e)=>keyEnterLoginHandler(e)"
              
            />
            <!-- </div> -->
          </div>
          <div class="input-felds-styles">
            <!-- <div class="control"> -->
            <b-input
              size="is-medium"
              type="password"
              v-model="user.password"
              placeholder="Enter your password"
              icon-color="is-primary"
              password-reveal
              @keyup.native="(e)=>keyEnterLoginHandler(e)"
            />
            <!-- </div> -->
          </div>

          <div class="remember-me-wrapper input-felds-styles">
            <div>
              <!-- <b-checkbox v-model="user.rememberMe">Remember Me</b-checkbox> -->
            </div>
            <div class="control has-text-right has-text-weight-medium">
              <!-- <a href="user/forgotPassword">Forgot Password?</a> -->
            </div>
          </div>
          <ActionButton
            type="is-primary"
            buttonLabel="Sign In"
            expanded="expanded"
            size="is-medium"
            :buttonAction="Login"
            :loading ="isButtonLoading"
            divStyles=" input-felds-styles"
            btnReferance="loginReferance"
            :disabled="buttonDisabledChecker()"
          />
        </div>


      <!-- Enrollment Acknowledgement Start -->
 
  <b-modal v-model="termsVisible" :can-cancel="false">
   <div class="terms-container" style="background-color: white; border-radius: 20px; padding: 40px;">
    <p class="is-size-3 has-text-left has-text-weight-semi-bold has-text-primary">Enrollment Acknowledgement</p>
    <div class="terms-content">
      <p>I hereby confirm that I properly logged in to the Digital customer onboarding solution after creating my User ID and I agree to hold the under mentioned responsibilities in connection with my User ID, Password and functions handle via my user credentials.</p>
      <br>
      <p>a)I am not sharing my user ID and Password at any time with anyone under any circumstance and to change my password at regular or irregular intervals to safeguard it’s confidentiality.</p>
      <p>b)I thoroughly know that I should use my User ID, password, Digital customer onboarding solution and any relevant devices only for the purpose of business needs of the SDB Bank and not for any other non-business related work or for my personal work.</p>
      <p>c)I thoroughly know that all the activities done from my User ID and password (whether it done by me or another party) is the responsibility of me.</p> 
      <p>d)I agree to explain all terms and conditions for opening and operating accounts / fixed deposits and other banking services to the customer before onboarding. </p>
      <p>e)I agree to interview the applicant and check the relevant documents properly and obtain digital copies such document and relevant declarations appropriately.</p>
      <p>f)I agree to obtain applicants digital signature via mobile device provided by SDB bank in the process of onboarding, account opening and providing other banking facilities and agree to be a witness for applicant/s digital signature obtained through my user credentials.  </p>
    </div>
      <b-button type="is-primary" @click="agreeToTerms" class="agree-btn" style="display: block; margin: 20px auto;">Agree</b-button>
  </div>
</b-modal>

      <!-- Enrollment Acknowledgement End -->

        <!-- <div
          class="has-text-primary has-text-centered has-text-weight-semibold is-size-5"
        >
          Create an Account
        </div> -->

        <!-- <div class="columns is-centered">
        <div class="column is-one-second">
          <div class="field is-grouped is-grouped-right" style="margin-left: 34.3vh;margin-right: 22.3vh">
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" v-model="user.rememberMe" />
                  Remember Me
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-second">
          <div class="field is-grouped is-grouped-right" style="margin-left: 10.3vh;margin-right: 26.3vh">
            <div class="control">
              <a href="#">Forgot Password?</a>
            </div>
          </div>
        </div>
      </div> -->
        <!-- <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button class="button is-info is-medium is-rounded" style="width: 107%; margin-right: 34.3vh"
            @click="Login">Sign In</button>
        </div>
      </div> -->
      </div>
    </div>
    <!-- <b-modal
      v-model="forcedPasswordChangeVisible"
      :can-cancel="canCancel"
      :width="500"
      scroll="keep"
      animation="zoom-out"
    >
      <div class="model-card">
        <div class="modal-card-body">
          <div class="content pt-2">
            <p>
              You have logged in with your temporary password. You must update your
              password in order to continue.
            </p>
          </div>
          <section>
            <b-field
              horizontal
              :type="invalidFields.newPassword == '' ? '' : 'is-danger'"
              :message="invalidFields.newPassword"
            >
              <template slot="label">
                <div class="password-change-form-label" style="width: 150px">
                  New Password <span class="has-text-danger">*</span>
                </div>
              </template>
              <b-input
                @input="resetInvalidStatus('newPassword')"
                v-model="updatePasswordForm.newPassword"
                type="password"
                autocomplete="new-password"
                password-reveal
                expanded
              ></b-input>
            </b-field>
            <b-field
              horizontal
              :type="invalidFields.confirmPassword == '' ? '' : 'is-danger'"
              :message="invalidFields.confirmPassword"
            >
              <template slot="label">
                <div class="password-change-form-label" style="width: 150px">
                  Confirm Password <span class="has-text-danger">*</span>
                </div>
              </template>
              <b-input
                @input="resetInvalidStatus('confirmPassword')"
                v-model="updatePasswordForm.confirmPassword"
                type="password"
                autocomplete="new-password"
                password-reveal
                expanded
              ></b-input>
            </b-field>
          </section>
        </div>

        <footer class="modal-card-foot">
          <div class="is-full column py-0 px-0">
            <div class="column">
              <div class="columns">
                <div class="column"></div>
                <div class="buttons">
                  <b-button @click="validateAndUpdatePassword" type="is-success is-small"
                    >Update Now</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </b-modal> -->
    <b-modal v-model="forcedPasswordChangeVisible" :can-cancel="false" >
      <div class="main-container">
        <div style="display:flex; flex-direction: row-reverse; margin: 4px 4px 4px 5px;">
          <b-button @click="closePopupWindow" style="border: none !important; outline: none !important;">
            <span style="font-weight: bold; font-size:x-large;">×</span>
          </b-button>
        </div>
       
        <img src="@/assets/images/Forgot Password.png">
      
      <p class="is-size-3 has-text-centered has-text-weight-bold has-text-primary">Set A New Password</p>
      <p class="discription is-size-7 has-text-centered has-text-weight-medium">Your current password is a system-generated one. To ensure optimal security, please take a moment to create a new, personalized password.</p>


      <div class="inpt-fields">
          <b-field :type="invalidFields.newPassword == '' ? '' : 'is-danger'" :message="invalidFields.newPassword" label="Enter Your New Password">
            <b-input  @input="resetInvalidStatus('newPassword')" v-model="updatePasswordForm.newPassword"  type="password"  password-reveal></b-input>
        </b-field>

          <b-field :type="invalidFields.confirmPassword == '' ? '' : 'is-danger'" :message="invalidFields.confirmPassword" label="Re-enter Password">
            <b-input    @input="resetInvalidStatus('confirmPassword')" v-model="updatePasswordForm.confirmPassword" type="password" password-reveal></b-input>
        </b-field>

        <b-button :disabled="updatePasswordForm.newPassword === ''|| updatePasswordForm.confirmPassword===''" @click="validateAndUpdatePassword()" class="save-btn" type="is-primary" expanded>Save</b-button>
      </div>
      </div>
      
    </b-modal>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import ActionButton from "@/components/support/buttons/ActionButton.vue";
import jwtUtils from "@/helpers/jwtUtils";
import BuefyHelper from "@/helpers/bufeyHelper";
import { PiniaStoreHelper } from "@/helpers/piniaHelper";
import { useAuthStore } from "@/store/authStore";

export default {
  name: "CreateUser",
  components: {
    ActionButton,
  },
  setup() {
    const authStore = useAuthStore();

    return { authStore };
  },
  data() {
    return {

      user: {
        username: "",
        password: "",
        rememberMe: false,
        user_id: null,
      },
      passwordChangeVisible: false,
      invalidFields: {
        username: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      updatePasswordForm: {
        newPassword: "",
        confirmPassword: "",
      },
      canCancel: ["x"],
      forcedPasswordChangeVisible: false,
      isButtonLoading: false,
      isButtonDisabled: false,
      termsVisible: false, 
    };   
  },
  methods: {

    handleSignIn() {
      this.Login();
    },
    agreeToTerms() {
    this.termsVisible = false;

    const data = {
      user_id: this.user.user_id,
      acknowledgement_status: 1
    };
    NetworkManager.apiRequest(
      "backend/applicant/updateAcknowledgementStatus",
      data,
      (response) => {
        if (response.success) {
          this.loginNavigation();
        } else {
          BuefyHelper.showToastMessage(response.message, "is-danger");
        }
      }
    );
  },
 Login: function () {
  
      // console.log("login button worked");
      this.isButtonLoading = true;
      NetworkManager.apiRequest("login", this.user, (e) => {
        if (e.data.acknowledgement_status == 0) {
          this.user.user_id = e.data.id;
          // this.forcedPasswordChangeVisible = true;
          this.termsVisible = true;
          jwtUtils.saveToken(e.data.authToken); // add token to local storage here
        } 

        else {
          this.isButtonLoading = false;
          jwtUtils.saveToken(e.data.authToken); // add token to local storage here
          if (e.success === true) {
            this.user.user_id = e.data.id;
            if (e.data.acknowledgement_status === 0) {
              this.termsVisible = true;
            } else {
              this.loginNavigation();
            }
          } else if (e.success === false) {
            this.user.username = "";
            this.user.password = "";
            BuefyHelper.showToastMessage(e.data.message, "is-danger");
          }
        }   
        // jwtUtils.saveToken(e.data.authToken); // add token to local storage here
        // if (e.success === true) {
        //   // this.$router.push("user");
        // } else if (e.success === false) {
        //   BuefyHelper.showToastMessage("Login Failed", "is-danger");
        // }
      });
      
    },

    closePopupWindow()
    {
      this.forcedPasswordChangeVisible = false;
      this.isButtonLoading = false;
    },

    trigger: function () {
      this.$btnReferance.loginReferance.click();
    },
    buttonDisabledChecker: function(){
      if(this.user.username == "" || this.user.password == ""){
        return this.isButtonDisabled = true;
      }
      else{
        return this.isButtonDisabled = false;
      }
    },

    keyEnterLoginHandler: function (event) {
      if (event.keyCode == 13 && !this.isButtonDisabled) {
        this.handleSignIn();
        // console.log("user login")
        // this.Login();
      }
      
    },

    loginNavigation(){
      const comp = this;
      comp.$router.push("dashboard");

    //   // if(comp.hasPermission("application-list")){
    //   //   comp.$router.push("dashboard");
    //   // }else if(comp.hasPermission("user-list")){
    //   //   comp.$router.push("user");
    //   // }else if(comp.hasPermission("role-list")){
    //   //   comp.$router.push("role");
    //   // }else if(comp.hasPermission("report-view")){
    //   //   comp.$router.push("auidt-report");
    //   // }else if(comp.hasPermission("application-view")){
    //   //   comp.$router.push("debit-applications");
    //   // }else{
    //   //   BuefyHelper.showToastMessage("You don't have any permission", "is-danger");
    //   // }
    },

    
 
    resetInvalidStatus: function (key) {
      this.invalidFields[key] = "";
    },
    validateUpdatePasswordForm: function () {
      var formIsValid = true;

      if (this.updatePasswordForm.newPassword == "") {
        this.invalidFields.newPassword = "Enter a new password";
        formIsValid = false;
      } else if (this.updatePasswordForm.newPassword.length < 8) {
        this.invalidFields.newPassword = "Password must contain at least 8 characters";
        formIsValid = false;
      } else {
        if (this.updatePasswordForm.confirmPassword == "") {
          this.invalidFields.confirmPassword = "Re-enter your new password";
          formIsValid = false;
        } else {
          if (
            this.updatePasswordForm.confirmPassword != this.updatePasswordForm.newPassword
          ) {
            this.invalidFields.confirmPassword = "Password validation failed";
            formIsValid = false;
          }
        }
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },
    validateAndUpdatePassword: function () {
      if (this.validateUpdatePasswordForm()) {
        this.submitUpdatePassword();
      }
    },
    submitUpdatePassword: function () {
      NetworkManager.apiRequest(
        "backend/user/changeTemporaryPassword",
        this.updatePasswordForm,
        (e) => {
          if (e.success === true) {
            BuefyHelper.showToastMessage("Password Updated successfully", "is-success");
            // this.$router.push("user");
            this.showTerms();
            //this.loginNavigation();
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(e.data.message, "is-danger"); 
          }
        }
      );
    },
    showTerms() {
      this.forcedPasswordChangeVisible = false;
      this.termsVisible = true;
    },

    clearLoggedUserDataIfAvailable() {
      const comp = this;

      if (localStorage.getItem("token") != null) {
        // console.log("Logged user data available. Clearing user details ...");

        if(jwtUtils.validateToken()){
          const self = this
          self.loginNavigation();

        }else{
          localStorage.removeItem("token");
          PiniaStoreHelper.clearLocalStorage(comp.authStore);
          PiniaStoreHelper.clearLocalStorage(comp.videoKycStore);
        }
      }
    },
    handlePopState(event) {
      // Refresh the page when the "popstate" event occurs (e.g., when user clicks "back" button)
      this.clearLoggedUserDataIfAvailable();
      window.location.reload();
    },

    
  },
  mounted() {
    this.clearLoggedUserDataIfAvailable();
    // Retrieve saved values from local storage on component mount
    if (localStorage.userRememberMe === "true") {
      this.user.rememberMe = true;
    }

    if (localStorage.username) {
      this.user.username = localStorage.username;
    }
    window.addEventListener("popstate", this.handlePopState);
  },
  beforeDestroy() {
    const comp = this;
    window.removeEventListener("popstate", this.handlePopState);
  },

  watch: {
    "user.rememberMe": function (newVal) {
      // Update local storage when the checkbox state changes
      localStorage.userRememberMe = newVal;
      if (!newVal) {
        // Clear the email from local storage when "Remember Me" is unchecked
        localStorage.username = "";
      }
    },
    "user.username": function (newVal) {
      // Update local storage when the email input changes
      if (this.user.rememberMe) {
        localStorage.username = newVal;
      }
    },
  },
};
</script>

<style scoped>

.main-container{
    background: #ffff;
    width: 400px;
    height: 560px;
    border-radius:12px;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    margin: 0 auto;
   
}

.discription{
  margin: 10px auto;
  padding: 0 50px;
  color: gray;

}

.main-container img{
  width : 100px;
  height: 100px;
  margin: 25px auto;
}

.inpt-fields{
  width: 80%;
  margin: 10px auto;
}

.input-felds-styles {
  margin: 25px auto;
}

.save-btn{
  margin-top: 30px;
}

.agree-btn{
  margin-top: 30px;
 
}
.login-page-bg {
  background-image: url("../../assets/images/Login_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
}

.wrapper {
  display: flex;
  width: fit-content;
  border-radius: 8px;
  flex-direction: row;
  background: white;
  height: 650px;
  margin: auto;
}

.cover-img {
  background-image: url("../../assets/images/Login_Page_Image.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 560px;
  height: inherit;
}

.input-area {
  padding: 20px;
  height: 735px;
  width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: inherit;
}

.inputs {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.remember-me-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 14px auto;
}

</style>
