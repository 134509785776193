<template>
    <div>
        <div v-for="option in myData" :key="option">
            <b-input v-if="option.type === 'INPUT'" v-model="option.value" placeholder="Search" expanded />
            <b-select v-else v-model="option.value" placeholder="Search" expanded>
                <option v-for="op in option.options" :value="op" :key="op">{{ op }}</option>
            </b-select>
        </div>
        <b-button type="is-primary" @click="$emit('valueChanged', myData)">Submit</b-button>
    </div>
</template>

<script>
export default {
    name: "MyComp",
    props: ['myData'],
    emits: ['valueChanged'],
    data() {
        return {
            array: []
        }
    },
    watch: {
        array(n) {
            this.array = n
        }
    }
}

export class MyFilters {
    constructor(value, type, options) {
        this.value = value;
        this.type = type;
        this.options = options;
    }
}
</script>