<template>
  <div
    style="max-height: 90%; min-height: 90%; overflow-x: scroll; overflow-y: scroll"
    class="pending-table-container"
  >
    <div
      style="
        width: 100%;
        color: gray;
        font-weight: 400;
        font-size: 15px;
        margin: 20px auto;
      "
    >
      <!-- Data table with full feature -->
    </div>
    <!-- <vue-excel-xlsx
        :data="pendingList"
        :columns="columns"
        :file-name="'filename'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        style="width: 100px; font-size: 20px; height: 50px"
        >
        Download
    </vue-excel-xlsx> -->

    <TableComponent 
     height="40vh"
    :columns="columns" 
    :excelColumns="excelColumns" 
    :tableData="pendingList" 
    :objectList="filterFields"  
    :isLoading="isLoading"
      :shouldShowExcelComponent="true" :name=name @tableFilter="() => {
          tableFilterRequestHandler();
        }
      "
      @resetFilter="
        () => {
          resetFilter();
        }
      "
      @changeValues="
        (item) => {
          changeValues(item);
        }
      "
      @applicationViewer="debitCardApplicationViewer"

    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import NetworkManager from "@/network";
import { FilterInputFiled } from "@/helpers/FilterInputFiled";
import { useAuthStore } from "@/store/authStore";
import DateConverter from "@/helpers/DateConverter";

export default {
  name: "DebitCardReport",
  components: {
    TableComponent,
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      pendingList: [],
      columns: [
        {
          field: "id",
          label: "Application ID",
        },
        {
          field: "branch_name",
          label: "Branch Name",
        },
        {
          field: "branch_code",
          label: "Branch Code",
        },
        // {
        //   field: "applicant_individual_account_type",
        //   label: "Product Type",
        // },
        {
          field: "product_code",
          label: "Product Code",
        },
        {
          field: "account_number",
          label: "Account Number",
        },
        {
          field: "title",
          label: "Title",
        },
        {
          field: "full_name",
          label: "Full Name",
        },
        {
          field: "nic",
          label: "NIC",
        },
        {
          field: "primary_mobile_number",
          label: "Mobile Number",
        },
        {
          field: "updated_at",
          label: "Updated Time",
        },
        {
          field: "created_at",
          label: "Applied Time",
        },

        {
          field: "signed",
          label: "Signed",
        },

        {
          field: "debit_card_status",
          label: "Status",
        },
        {
          field: "applicationView",
          label: "Actions",
        },
      ],

      excelColumns: [
        {
          field: "id",
          label: "Application ID",
        },
        {
          field: "branch_name",
          label: "Branch Name",
        },
        {
          field: "branch_code",
          label: "Branch Code",
        },
        // {
        //   field: "applicant_individual_account_type",
        //   label: "Product Type",
        // },
        {
          field: "product_code",
          label: "Product Code",
        },
        {
          field: "account_number",
          label: "Account Number",
        },
        {
          field: "title",
          label: "Title",
        },
        {
          field: "full_name",
          label: "Full Name",
        },
        {
          field: "nic",
          label: "NIC",
        },
        {
          field: "primary_mobile_number",
          label: "Mobile Number",
        },
        {
          field: "updated_at",
          label: "Updated Time",
        },
        {
          field: "created_at",
          label: "Applied Time",
        },

        {
          field: "signed",
          label: "Signed",
        },

        {
          field: "debit_card_status",
          label: "Status",
        },
        {
          field: "applicationView",
          label: "Actions",
        },
      ],

      isLoading:false,
      isFullPage: false,
      name: "",

      filterFields: [
        new FilterInputFiled(
          "Account Ownership",
          "",
          ["All", "Individual Account", "Joint Account"],
          "SELECT"
        ),
        new FilterInputFiled(
          "Select Status",
          "",
          ["All", "pending", "issued", "rejected"],
          "SELECT"
        ),
        new FilterInputFiled("Select Branch", "", [], "SELECT"),
        new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("Search", "", "", "BUTTON"),
        new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
      ],
      accOwnership: "",

     
      // Create a new Date object
      // currentDate : new Date().getFullYear() + new Date().getMonth() +1 + new Date().getDate(),

      // isTableComponentLoading: false
    };
  },

  // watch: {
  //   filterFields: {
  //     handler(newValue) {
  //       const valueToWatch = newValue[1].value;
  //       console.log(newValue[1].value)
  //       if (valueToWatch === 'Individual Account') {
  //       console.log("Individual Account...")
  //         this.filterFields[2].options = ["1", "2", "3"];
  //       } else if (valueToWatch === 'Joint Account') {
  //         console.log("Joint Account...")
  //         this.filterFields[2].options = ["99", "22", "33"];
  //       } else {
  //         this.options = [];
  //       }
  //     },
  //     deep: true
  //   },
  // },

  methods: {
    tableFilterRequestHandler: function () {
      this.isLoading= true;
      if(this.filterFields[0].value == "Joint Account"){
        this.accOwnership = "Joint"
      }else if(this.filterFields[0].value == "Individual Account"){
        this.accOwnership = "Individual"
      }
      NetworkManager.apiRequest(
        "backend/applicant/filterDebitCardApplications",
        {
          acc_ownership:
            this.filterFields[0].value === "All" ? "" : this.accOwnership,
          status:
            this.filterFields[1].value === "All" ? "" : this.filterFields[1].value,
          branch:
            this.filterFields[2].value === "All" ? "" : this.filterFields[2].value,
          from_date: this.filterFields[3].value
            ? DateConverter.dateFormater(this.filterFields[3].value)
            : "",
          to_date: this.filterFields[4].value
            ? DateConverter.dateFormater(this.filterFields[4].value)
            : "",
        },
        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
            if (data.application_approved_date != null) {
              data.application_approved_date = DateConverter.dateFormater(
                data.application_approved_date
              );
            }
            if (data.debit_is_customer_interest == 1) {
              data.debit_is_customer_interest = "Yes";
            } else if (data.debit_is_customer_interest == 0) {
              data.debit_is_customer_interest = "No";
            }
          });
          this.pendingList = e.data.list;
          // console.log("this.dataList ", e.data);
          this.isLoading  = false;
         
          
        },
        false
      );
    },

    debitCardApplicationViewer: function (tableValue) {
      // console.log("debitCardApplicationViewer");
      // console.log("tableValue",tableValue);
      if(tableValue.debit_card_status === 'pending'){
        // console.log("debitCardApplicationViewer");
        this.$router.push({
          name: "debit-pending-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc},
        });
      }
      if(tableValue.debit_card_status === 'issued'){
        this.$router.push({
          name: "debit-issued-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc},
        });
      }
      if(tableValue.debit_card_status === 'rejected'){
        this.$router.push({
          name: "debit-rejected-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc},
        });
      }
    },

    changeValues(item) {

    },

    resetFilter: function () {
      
      // Reset filter fields
      this.filterFields.forEach((field) => {
        field.value = "";
       
      });
      this.filterFields[2].options = [];
      this.filterFields[3].options = [];
      this.filterFields[4].options = [];
      // Clear the table
      this.pendingList = [];
      // this.filterFields[2].isButtonDisabled = true;
      // this.filterFields[3].isButtonDisabled = true;
      // this.filterFields[4].isButtonDisabled = true;

      // Trigger the table filter request handler to reload the table data
      // this.tableFilterRequestHandler();
    },


    filterButtonStateHandler: function () {
      if (
        this.filterFields[0].value !== "" ||
        this.filterFields[1].value !== "" ||
        this.filterFields[2].value !== "" ||
        this.filterFields[3].value !== "" ||
        this.filterFields[4].value !== "" 
      ) {
        this.filterFields[5].isButtonDisabled = false;
        this.filterFields[6].isButtonDisabled = false;
      } else {
        this.filterFields[5].isButtonDisabled = true;
        this.filterFields[6].isButtonDisabled = true;
      }
    },
  },

  watch: {
    filterFields: {
      handler() {
        this.filterButtonStateHandler();
      },
      deep: true,
    },
  },

  mounted() {
    var date = new Date();
    var month = date.getMonth() + 1;
    this.name = "Debit Card Report " + date.getFullYear() + "/" + month + "/" + date.getDate();
    // NetworkManager.apiRequest(
    //   "backend/branch/list",
    //   {},
    //   (e) => {
    //     console.log("e.data", e.data);

    //     e.data.list.filter((branchData) => {
    //       this.branchList.push(branchData.branch_name);
    //     });
    //     this.filterFields[1].options = this.branchList;
    //   },
    //   true
    // );

    this.branchListLoader(this.filterFields[2].options);
  },
};
</script>
