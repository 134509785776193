<template>
  <div style="padding: 0px 20px">
    <div class="columns is-multiline" style="margin-top: 2.0vh">
      <!-- <div class="column">
        <label style="color: gray; font-weight: 400; font-size: 15px">User List</label>
      </div> -->
      <div class="column" style="text-align: right; margin-top:10px; margin-bottom:-20px">
        <b-button
          v-if="hasPermission('user-create')"
          type="is-primary"
          @click="navigateTo"
          class="float-right"
          >Create User</b-button
        >
      </div>
    </div>
    <div style="height: 80vh; overflow-y: hidden !important">
      <TableComponent
        :objectList="filterList"
         height="52vh"
        :columns="displayedColumns"
        :tableData="dataList"
        :isHidden="true"
        :isLoading="isLoading"
        @inputChange="
          () => {
            valueOnChangee();
          }
        "
        @tableFilter="
          () => {
            userFilterRequestHandler();
          }
        "
        @resetFilter="
          () => {
            resetFilter();
          }
        "
        @showResetPasswordsModal="
          (id) => {
            showResetPasswordsModal(id);
          }
        "
        @showResetAttemptModal="
          (data) => {
            showResetAttemptModal(data);
          }
        "
        type="text"
      />
    </div>
    <b-modal
      v-model="resetPasswordModalVisible"
      :width="400"
      scroll="keep"
      animation="zoom-out"
    >
      <div class="model-card">
        <header class="modal-card-head has-text-centered">
          <p class="modal-card-title">Reset User Password?</p>
          <!-- <button
            @click="resetPasswordModalVisible = false"
            type="button"
            class="delete"
          ></button> -->
        </header>

        <!-- <div class="modal-card-body">
          <div class="content pt-2">
            <p>Generate a new password for user</p>
          </div>

          <section>
            <b-field
              grouped
              :type="invalidFields.invalidUserPassword == '' ? '' : 'is-danger'"
              :message="invalidFields.invalidUserPassword"
            >
              <b-input
                @input="resetInvalidStatus('invalidUserPassword')"
                v-model="formData.userPassword"
                expanded
              ></b-input>
              <p class="control">
                <b-tooltip label="Regenerate Password" position="is-left">
                  <b-button
                    @click="generatePassword"
                    type="is-primary"
                    icon-right="autorenew"
                  />
                </b-tooltip>
              </p>
              <p class="control">
                <b-tooltip label="Copy Password" position="is-left">
                  <b-button
                    @click="copyToClipboard"
                    type="is-primary"
                    icon-right="content-copy"
                  />
                </b-tooltip>
              </p>
            </b-field>
          </section>
        </div> -->

        <footer class="modal-card-foot has-text-centered">
            <div class="column">
              <div class="columns">
                <div class="buttons" style="margin-left: 20px;">
                  <b-button
                    id="apply_new"
                    rounded
                    :loading="isButtonLoading"
                    @click="validateAndResetPassword"
                    type="is-success is-small"
                    >Apply New Password</b-button
                  >
                  <b-button
                  style="margin-left: 75px;"
                    rounded
                    @click="resetPasswordModalVisible = false"
                    type="is-danger is-small"
                    >Cancel</b-button
                  >
                </div>
              </div>
            </div>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="resetAttemptModalVisible" :width="400" scroll="keep" animation="zoom-out">
  <div class="model-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Reset Password Attempts</p>
    </header>

    <section class="modal-card-body">
      Reset the {{ userName }}'s password attempts?
    </section>

    <footer class="modal-card-foot has-text-centered">
      <div class="buttons is-centered">
        <b-button id="apply_new" rounded :loading="isButtonLoading" @click="resetPasswordAttempts()" type="is-success is-small">
          Reset
        </b-button>
        <b-button rounded @click="resetAttemptModalVisible = false" type="is-danger is-small">
          Cancel
        </b-button>
      </div>
    </footer>
  </div>
</b-modal>

  </div>
</template>

<script>
import NetworkManager from "@/network";
import TableComponent from "@/components/TableComponent.vue";
import { FilterInputFiled } from "@/helpers/FilterInputFiled";
import DateConverter from "@/helpers/DateConverter";
import BuefyHelper from "@/helpers/bufeyHelper";
// import BranchListSender from "@/network"
// import NavBar from "@/layout/components/NavBar";
// import SideBar from "@/layout/components/SideBar";

export default {
  name: "UserList",
  components: {
    TableComponent,
    // NavBar,
    // SideBar
  },
  data() {
    return {
      columns: [
        {
          field: "username",
          label: "Employee ID",
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "role_name",
          label: "Role",
        },
        {
          field: "email",
          label: "Email Address",
        },

        {
          field: "contact",
          label: "Mobile",
        },
        {
          field: "branch_name",
          label: "Branch  Name",
        },
        {
          field: "disabled",
          label: "Status",
        },
        {
          field: "created_at",
          label: "Created At",
        },
        {
          field: "updated_at",
          label: "Updated At",
        },
        {
          field: "actions",
          label: "Actions",
        },
      ],
      invalidFields: {
        invalidUserPassword: "",
      },
      formData: {
        userPassword: "",
      },
      data: {
        userId: "",
        userPassword: "",
      },
      isButtonLoading: false,
      resetPasswordModalVisible: false,
      resetAttemptModalVisible: false,
      isLoading: true,
      isFullPage: false,
      dataList: [],
      branchList: [],
      valueOne: "",
      resetPasswordRowId: null,
      resetAttemptRowId: null,
      userName: "",

      filterList: [
        new FilterInputFiled("Select Branch", "", [], "SELECT"),
        new FilterInputFiled("EPF", "", "", "INPUT"),
        new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("Search", "", "", "BUTTON"),
        new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
      ],
    };
  },
  computed: {
    displayedColumns() {
      const hasUserCreatePermission = this.hasPermission(
        "user-create" || "user-edit" || "user-delete" || "password-reset"
      );
      return hasUserCreatePermission
        ? this.columns
        : this.columns.filter((column) => column.field !== "actions");
    },
  },
  methods: {
    valueOnChangee(value) {
      const comp = this;
      // console.log("Parent value chnage ", value);
      comp.valueOne = value;
    },
    navigateTo() {
      this.$router.push({ name: "create-user" });
    },

    showResetPasswordsModal(id) {
      const component = this;
      component.generatePassword();
      component.resetPasswordRowId = id;
      component.resetPasswordModalVisible = true;
    },

    showResetAttemptModal(data) {
      const component = this;
      component.resetAttemptRowId = data.id;
      component.userName = data.name
      component.resetAttemptModalVisible = true;
    },

    resetPasswordAttempts(){
      const component = this;
      NetworkManager.apiRequest("backend/user/resetLoginAttempts", {userId:this.resetAttemptRowId}, (e) => {
        if (e.success === true) {
          component.isButtonLoading = false;
          component.resetAttemptModalVisible = false;
          BuefyHelper.showToastMessage(e.data.message, "is-success");
          location.reload();
        } else if (e.success === false) {
          component.isButtonLoading = false;
          component.resetAttemptModalVisible = false;
          BuefyHelper.showToastMessage(e.data.message, "is-danger");
        }
      });
    },

    generatePassword: function () {
      var normalCharSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var numericCharSet = "0123456789012345678901234567890123456789";
      var specialCharSet = "!@#$%^&*_=+-!@#$%^&*_=+-";

      var characters = normalCharSet + numericCharSet + specialCharSet;
      var charactersLength = characters.length;
      var length = 16;
      var result = "";
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.formData.userPassword = result;
    },

    copyToClipboard: function () {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.formData.userPassword;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      BuefyHelper.showToastMessage("Copied to Clipboard", "is-info");
    },

    validateAndResetPassword: function () {
      if (this.validatePassword()) {
        this.resetPassword();
        this.openLoading();
      }
    },

    validatePassword: function () {
      var component = this;
      var formIsValid = true;

      if (component.formData.userPassword == "") {
        formIsValid = false;
        component.invalidFields.invalidUserPassword = "Please enter a password";
      } else if (component.formData.userPassword.length < 8) {
        formIsValid = false;
        component.invalidFields.invalidUserPassword =
          "Password must contain at least 8 characters";
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 1000);
    },

    resetPassword: function () {
      var component = this;
      component.isButtonLoading = true;
      this.data.userId = component.resetPasswordRowId;
      this.data.userPassword = component.formData.userPassword;
      NetworkManager.apiRequest("backend/user/resetPasswordForUsers", this.data, (e) => {
        if (e.success === true) {
          component.isButtonLoading = false;
          component.resetPasswordModalVisible = false;
          BuefyHelper.showToastMessage(e.data.message, "is-success");
          this.$router.push("user");
        } else if (e.success === false) {
          component.isButtonLoading = false;
          component.resetPasswordModalVisible = false;
          BuefyHelper.showToastMessage(e.data.message, "is-danger");
        }
      });
    },

    userFilterRequestHandler: function () {
      this.isLoading=true;
      NetworkManager.apiRequest(
        "backend/user/filterUsers",
        {
          branch: this.filterList[0].value === "All" ? "" : this.filterList[0].value,
          userName: this.filterList[1].value ? this.filterList[1].value : "",
          from_date: this.filterList[2].value
            ? DateConverter.dateFormater(this.filterList[2].value)
            : "",
          to_date: this.filterList[3].value
            ? DateConverter.dateFormater(this.filterList[3].value)
            : "",
        },

        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
            data.contact = '+94' + data.contact.slice(1);
          });
          this.dataList = e.data.list;
          // console.log("this.dataList ", e.data);
          this.isLoading=false;
        },
        false
      );
    },

    resetFilter: function () {
      this.isLoading=true;
      NetworkManager.apiRequest(
        "backend/user/list",
        {},
        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
          });
          // Reset filter fields
          this.filterList.forEach((field) => {
                field.value = "";
          });
          this.dataList = e.data.list;
          // console.log("this.dataList ", e.data);
          this.isLoading=false;
        },
        false
      );
    },

    getUserList(){
      NetworkManager.apiRequest("backend/user/list", {}, (e) => {
      if (e.success === true) {
        this.isLoading = false;
        // console.log("user list", e.data);
        if(e.data.contact != null){
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
            data.contact = '+94' + data.contact.slice(1);
          });
        }else{
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
          });
        }
        this.dataList = e.data.list;
        // console.log("this.dataList ", this.dataList );
      } else {
        this.isLoading = false;
      }
    });
    },

    filterButtonStateHandler : function(){
      if(this.filterList[0].value !== "" || this.filterList[1].value !== "" || this.filterList[2].value !== "" || this.filterList[3].value !== ""){
        this.filterList[4].isButtonDisabled = false
        this.filterList[5].isButtonDisabled = false
      }
      else{
        this.filterList[4].isButtonDisabled = true
        this.filterList[5].isButtonDisabled = true
      }
    }
  },

  watch : {
    filterList: {
      handler(){
        this.filterButtonStateHandler()
      },
      deep: true

    }
  },

  mounted() {
    this.getUserList();

    // BranchListSender.branchListHandler(this.branchList);

    // NetworkManager.apiRequest(
    //   "backend/branch/list",
    //   {},
    //   (e) => {
    //     console.log("branch list", e.data);

    //     e.data.list.filter((branchData) => {
    //       this.branchList.push(branchData.branch_name);
    //     });
    //     this.filterList[0].options = this.branchList;
    //   },
    //   true
    // );
    this.branchListLoader(this.filterList[0].options);
  },
};
</script>
