<!-- <template>
  <section>
    <nav-bar />
    <div class="columns">
      <div class="columns is-one-fifth" v-if="!$root.isSideBarHidden">
        <div class="column pr-0">
          <side-bar style="height: 95vh" v-if="opened" />
        </div>
        <div class="column pt-5 pl-0">
       
        </div>
      </div>
      <div class="column" style="max-height: 93vh">
        <app-main />
       
      </div>
    </div>
  </section>
</template> -->

<template>
  <div style="overflow:hidden" class="index-class">
    <nav-bar />
    <div class="main-container">
      <side-bar v-if="opened" />
    
      <app-main />

      <!-- <div class="second-container">asdfsdfsd</div> -->
    </div>
  </div>
</template>

<script>
import AppMain from "@/layout/components/AppMain";
import NavBar from "@/layout/components/NavBar";
import SideBar from "@/layout/components/SideBar";

export default {
  name: "MainLayout",
  components: {
    AppMain,
    NavBar,
    SideBar,
  },
  data() {
    return {
      opened: true,
    };
  },
  methods: {
    openpanal() {
      this.opened = !this.opened;
    },
  },
  // mounted() {
  //   const self = this;

  //   // this.startTokenTimer()
  //   setTimeout(() => {
  //     self.hideFullScreenLoader();
  //   }, 500);
  // },
};
</script>

<style scoped>
.app-main {
  /* overflow-x: auto !important; */
  /* height: calc(100vh - 102px) !important; */

  /* margin: 0 0 10px 0; */
  /* max-width: calc(150vw - 350px); */
 
  /* padding-top: 25px;
  padding-bottom: 50px; */
  flex-grow: 1;
  /* background: #44f3b9; */
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: scroll;
  /* padding-top: 25px;
  padding-bottom: 50px; */
 
}



.second-container{
  background: #023853;
  flex-grow: 4
}


.main-container{
  /* background: #6e8997; */
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
  
  
}


.main-container::-webkit-scrollbar {
  display: none;
}

.main-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



.second-container{
  background: #023853;
  flex-grow: 4
}


.main-container{
  /* background: #6e8997; */
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
  
  
}



.custom-button-color {
  background-color: #0083ca;
  /* Replace with your desired color code */
  color: #000000;
  /* Replace with your desired text color */
}

.app-main-sec {
  overflow: auto !important;
  /* overflow-x: hidden !important; */
  height: 88vh;
  padding-top: 10px;
  max-width: 100vw;
}
</style>
