// import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { ToastProgrammatic as Toast } from 'buefy'
/*
========================================================================================================================
This helper can be use to define reusable buefy functions
* */
const BuefyHelper = {

    
    /**
     * =================================================================================================================
     * This function can be used to show a buefy toast message
     * @param message
     * @param type
     * @param duration
     */
    showToastMessage(message,type,duration=5000){
        Toast.open({
            duration: duration,
            message: message,
            type: type,
        })
    }
}

export default BuefyHelper;