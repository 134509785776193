<template>
  <div
    style="max-height: 90%; min-height: 90%; overflow-x: scroll; overflow-y: scroll"
    class="pending-table-container"
  >
    <div
      style="
        width: 100%;
        color: gray;
        font-weight: 400;
        font-size: 15px;
        margin: 20px auto;
      "
    >
      <!-- Data table with full feature -->
    </div>
    <!-- <vue-excel-xlsx
        :data="pendingList"
        :columns="columns"
        :file-name="'filename'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        style="width: 100px; font-size: 20px; height: 50px"
        >
        Download
    </vue-excel-xlsx> -->

    <TableComponent 
     height="40vh"
    :columns="columns" 
    :excelColumns="excelColumns" 
    :tableData="pendingList" 
    :objectList="filterFields"  
    :isLoading="isLoading"
      :shouldShowExcelComponent="true" :name=name @tableFilter="() => {
          tableFilterRequestHandler();
        }
      "
      @resetFilter="
        () => {
          resetFilter();
        }
      "
      @changeValues="
        (item) => {
          changeValues(item);
        }
      "
      @applicationViewer="pendingApplicationViewer"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import NetworkManager from "@/network";
import { FilterInputFiled } from "@/helpers/FilterInputFiled";
import { useAuthStore } from "@/store/authStore";
import DateConverter from "@/helpers/DateConverter";

export default {
  name: "PendingApplications",
  components: {
    TableComponent,
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      pendingList: [],
      columns: [
        {
          field: "id",
          label: "Application ID",
        },
        {
          field: "branch_name",
          label: "Branch Name",
        },
        {
          field: "branch_code",
          label: "Branch Code",
        },
        {
          field: "account_number",
          label: "Account Number",
        },
        {
          field: "applicant_individual_account_type",
          label: "Product Type",
        },
        {
          field: "title",
          label: "Title",
        },
        {
          field: "full_name",
          label: "Full Name",
        },
        {
          field: "nic",
          label: "NIC",
        },
        {
          field: "primary_mobile_number",
          label: "Mobile Number",
        },
        {
          field: "applicant_status",
          label: "Applicant Status",
        },
        {
          field: "product_code",
          label: "Product Code",
        },
        {
          field: "account_type",
          label: "Account Ownership",
        },
        {
          field: "applicant_individual_account_type",
          label: "Account Type",
        },
        {
          field: "savings_account_type",
          label: "Product Type",
        },
        {
          field: "updated_at",
          label: "Updated Time",
        },
        {
          field: "created_at",
          label: "Applied Time",
        },

        {
          field: "signed",
          label: "Signed",
        },

        {
          field: "application_status",
          label: "Status",
        },
        {
          field: "applicationView",
          label: "Actions",
        },
      ],
      excelColumns: [
        {
          field: "id",
          label: "Application ID",
        },
        {
          field: "branch_name",
          label: "Branch Name",
        },
        {
          field: "branch_code",
          label: "Branch Code",
        },
        {
          field: "account_number",
          label: "Account Number",
        },
        {
          field: "applicant_individual_account_type",
          label: "Product Type",
        },
        {
          field: "title",
          label: "Title",
        },
        {
          field: "full_name",
          label: "Full Name",
        },
        {
          field: "address_1",
          label: "Address 1",
        },
        {
          field: "address_2",
          label: "Address 2",
        },
        {
          field: "address_3",
          label: "Address 3",
        },
        {
          field: "address_4",
          label: "Address 4",
        },
        {
          field: "nic",
          label: "NIC",
        },
        {
          field: "primary_mobile_number",
          label: "Mobile Number",
        },
        {
          field: "applicant_status",
          label: "Applicant Status",
        },
        {
          field: "product_code",
          label: "Product Code",
        },
        {
          field: "debit_is_customer_interest",
          label: "Debit Card Requested Or Not",
        },
        {
          field: "fd_cus_fd_renewal_notice",
          label: "Requested Renewal Notice Type",
        },
        {
          field: "fd_how_cus_need_certificate",
          label: "FD Certificate Type",
        },
        {
          field: "account_type",
          label: "Account Ownership",
        },
        {
          field: "applicant_individual_account_type",
          label: "Account Type",
        },
        {
          field: "savings_account_type",
          label: "Product Type",
        },
        {
          field: "submitted_by_email",
          label: "BDO",
        },
        {
          field: "reviewed_by",
          label: "Approving Officer",
        },
        {
          field: "ops_staff",
          label: "Central Ops Review Officer",
        },
        {
          field: "updated_at",
          label: "Updated Time",
        },
        {
          field: "created_at",
          label: "Applied Time",
        },

        {
          field: "signed",
          label: "Signed",
        },

        {
          field: "application_status",
          label: "Status",
        },
      ],

      isLoading:false,
      isFullPage: false,
      name: "",

      filterFields: [
        //   new FilterInputFiled(
        //     "Product Type",
        //     "",
        //     ["Savings Account", "Fixed Account"],
        //     "SELECT"
        //   ),
        //   new FilterInputFiled("Branch", "", [], "SELECT"),
        new FilterInputFiled(
          "Applicant Status",
          "",
          [
            "All",
            "Housewife",
            "Unemployed",
            "Government Pensioner",
            "Retired (Non-Government)",
            "Entrepreneur / Self Employee",
            "Employed (Salaried)",
          ],
          "SELECT"
        ),
        new FilterInputFiled(
          "Account Ownership",
          "",
          ["Individual Account", "Joint Account"],
          "SELECT"
        ),
        new FilterInputFiled("Account Type", "", [], "SELECT", "", true),
        new FilterInputFiled("Minor Account Type", "", [], "SELECT", "", true),
        new FilterInputFiled("Product Type", "", [], "SELECT", "", true),
        new FilterInputFiled(
          "Select Status",
          "",
          ["All", "Pending", "Approved", "Request To Improve", "Reject","Reviewed"],
          "SELECT"
        ),
        new FilterInputFiled("Select Branch", "", [], "SELECT"),
        new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("Search", "", "", "BUTTON"),
        new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
      ],

     
      // Create a new Date object
      // currentDate : new Date().getFullYear() + new Date().getMonth() +1 + new Date().getDate(),

      // isTableComponentLoading: false
    };
  },

  // watch: {
  //   filterFields: {
  //     handler(newValue) {
  //       const valueToWatch = newValue[1].value;
  //       console.log(newValue[1].value)
  //       if (valueToWatch === 'Individual Account') {
  //       console.log("Individual Account...")
  //         this.filterFields[2].options = ["1", "2", "3"];
  //       } else if (valueToWatch === 'Joint Account') {
  //         console.log("Joint Account...")
  //         this.filterFields[2].options = ["99", "22", "33"];
  //       } else {
  //         this.options = [];
  //       }
  //     },
  //     deep: true
  //   },
  // },

  methods: {
    tableFilterRequestHandler: function () {
      this.isLoading= true;
      NetworkManager.apiRequest(
        "backend/applicant/filterApplicantList",
        {
          applicant_status:
            this.filterFields[0].value === "All" ? "" : this.filterFields[0].value,
          account_ownership:
            this.filterFields[1].value === "All" ? "" : this.filterFields[1].value,
          acc_type:
            this.filterFields[2].value === "All" ? "" : this.filterFields[2].value,
          mnr_acc_type:
            this.filterFields[3].value === "All" ? "" : this.filterFields[3].value,
          product_type:
            this.filterFields[4].value === "All" ? "" : this.filterFields[4].value,
          status: this.filterFields[5].value === "All" ? "" : this.filterFields[5].value,
          branch: this.filterFields[6].value === "All" ? "" : this.filterFields[6].value,
          from_date: this.filterFields[7].value
            ? DateConverter.dateFormater(this.filterFields[7].value)
            : "",
          to_date: this.filterFields[8].value
            ? DateConverter.dateFormater(this.filterFields[8].value)
            : "",
        },
        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
            if (data.application_approved_date != null) {
              data.application_approved_date = DateConverter.dateFormater(
                data.application_approved_date
              );
            }
            if (data.debit_is_customer_interest == 1) {
              data.debit_is_customer_interest = "Yes";
            } else if (data.debit_is_customer_interest == 0) {
              data.debit_is_customer_interest = "No";
            }
          });
          this.pendingList = e.data.list;
          // console.log("this.dataList ", e.data);
          this.isLoading  = false;
         
          
        },
        false
      );
    },

    changeValues(item) {
      if (item.value == "Individual Account") {
        this.filterFields[2].options = ["Savings Account", "Fixed Deposit"];
        this.filterFields[2].isButtonDisabled = false;
      }
      if (item.value == "Joint Account") {
        this.filterFields[2].options = [
          "Savings Account",
          "Fixed Deposit",
          "Minor Account",
        ];
        this.filterFields[2].isButtonDisabled = false;
      }
      if (item.label == "Account Type") {
        if (item.value == "Savings Account") {
          this.getSavingsTypes(this.filterFields[1].value, "Savings Account");
          // this.filterFields[4].options = ["Normal Savings Account", "Javaya Savings Account", "UPay Business Account", "SDB Agree Saving Account", "SDB Ayojana Account", "Uththamavi Savings", "Top Saver", "SDB Business Saver", "Upahara Savings Account", "Invesment Savings Deposit"];
          this.filterFields[3].options = [];
          this.filterFields[3].isButtonDisabled = true;
          this.filterFields[4].isButtonDisabled = false;
        }
        if (item.value == "Fixed Deposit") {
          this.getFixedTypes(this.filterFields[1].value, "Fixed Deposit");
          // this.filterFields[4].options = ["Normal Fixed Deposit", "Upahara Fixed Deposit"];
          this.filterFields[3].options = [];
          this.filterFields[3].isButtonDisabled = true;
          this.filterFields[4].isButtonDisabled = false;
        }
      } else if (item.label == "Minor Account Type") {
        this.getMinorTypes(item.value);
        // if (item.value == "Savings Account") {
        //   this.filterFields[4].options = [
        //     "Lakdaru Children's Savings",
        //     "Dayada Children's Savings",
        //   ];
        // }
        // if (item.value == "Fixed Deposit") {
        //   this.filterFields[4].options = ["Minor Fixed Deposit"];
        // }
      }
      if (item.value == "Minor Account") {
        this.filterFields[3].isButtonDisabled = false;
        this.filterFields[4].isButtonDisabled = false;
        this.filterFields[3].options = ["Savings Account", "Fixed Deposit"];
      }
    },

    resetFilter: function () {
      
      // Reset filter fields
      this.filterFields.forEach((field) => {
        field.value = "";
       
      });
      this.filterFields[2].options = [];
      this.filterFields[3].options = [];
      this.filterFields[4].options = [];
      // Clear the table
      this.pendingList = [];
      this.filterFields[2].isButtonDisabled = true;
      this.filterFields[3].isButtonDisabled = true;
      this.filterFields[4].isButtonDisabled = true;

      // Trigger the table filter request handler to reload the table data
      // this.tableFilterRequestHandler();
    },

    pendingApplicationViewer: function (tableValue) {
      this.authStore.applicationID = tableValue.id;

      if (tableValue.joint_acc) {
        this.authStore.jointAcc = tableValue.joint_acc;
      } else {
        this.authStore.jointAcc = null;
      }

      if (tableValue.application_status === "pending") {
        this.$router.push({
          name: "pending-applications-view",
          query: { tableValue: tableValue.id },
        });
      }
      if (tableValue.application_status === "Reject") {
        this.$router.push({
          name: "rejected-applications-view",
          query: { tableValue: tableValue.id },
        });
      }
      if (tableValue.application_status === "Approved") {
        this.$router.push({
          name: "approved-applications-view",
          query: { tableValue: tableValue.id },
        });
      }
      if (tableValue.application_status === "Request To Improve") {
        this.$router.push({
          name: "req-to-improve-applications-view",
          query: { tableValue: tableValue.id },
        });
      }
      if (tableValue.application_status === "Reviewed") {
        this.$router.push({
          name: "reviewed-by-cop",
          query: { tableValue: tableValue.id },

        });
      }
      // this.$router.push({
      //   name: "pending-applications-view",
      //   query: { tableValue: tableValue },
      // });
      // console.log("tableValue ", tableValue.id);
    },

    getSavingsTypes(accountOwnership, accountType) {
      this.filterFields[4].options = [];
      NetworkManager.apiRequest(
        "backend/applicant/getProductTypes",
        {
          account_ownership: accountOwnership,
          acc_type: accountType,
        },
        (e) => {
          // console.log("e.data", e.data);
          e.data.list.filter((productData) => {
            this.filterFields[4].options.push(productData.product_name);
          });
        },
        true
      );
    },

    getFixedTypes(accountOwnership, accountType) {
      this.filterFields[4].options = [];
      NetworkManager.apiRequest(
        "backend/applicant/getProductTypes",
        {
          account_ownership: accountOwnership,
          acc_type: accountType,
        },
        (e) => {
          // console.log("e.data", e.data);
          e.data.list.filter((productData) => {
            this.filterFields[4].options.push(productData.product_name);
          });
        },
        true
      );
    },

    getMinorTypes(minorAccountType) {
      this.filterFields[4].options = [];
      NetworkManager.apiRequest(
        "backend/applicant/getProductTypes",
        {
          minor_acc_type: minorAccountType,
        },
        (e) => {
          // console.log("e.data", e.data);
          e.data.list.filter((productData) => {
            this.filterFields[4].options.push(productData.product_name);
          });
        },
        true
      );
    },

    filterButtonStateHandler: function () {
      if (
        this.filterFields[0].value !== "" ||
        this.filterFields[1].value !== "" ||
        this.filterFields[2].value !== "" ||
        this.filterFields[3].value !== "" ||
        this.filterFields[4].value !== "" ||
        this.filterFields[5].value !== "" ||
        this.filterFields[6].value !== "" ||
        this.filterFields[7].value !== "" ||
        this.filterFields[8].value !== ""
      ) {
        this.filterFields[9].isButtonDisabled = false;
        this.filterFields[10].isButtonDisabled = false;
      } else {
        this.filterFields[9].isButtonDisabled = true;
        this.filterFields[10].isButtonDisabled = true;
      }
    },
  },

  watch: {
    filterFields: {
      handler() {
        this.filterButtonStateHandler();
      },
      deep: true,
    },
  },

  mounted() {
    var date = new Date();
    var month = date.getMonth() + 1;
    this.name = "Audit Report " + date.getFullYear() + "/" + month + "/" + date.getDate();
    // NetworkManager.apiRequest(
    //   "backend/branch/list",
    //   {},
    //   (e) => {
    //     console.log("e.data", e.data);

    //     e.data.list.filter((branchData) => {
    //       this.branchList.push(branchData.branch_name);
    //     });
    //     this.filterFields[1].options = this.branchList;
    //   },
    //   true
    // );

    this.branchListLoader(this.filterFields[6].options);
  },
};
</script>
