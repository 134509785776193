<template>
    <div>
    <div style="margin-top: 10px;" v-for="debit_comment in debitCommentArray" :key="debit_comment.commentText">
            <div style="display: flex; flex-direction: row; justify-content: space-between">
                <div>
                    Commented by: <span class="has-text-weight-medium">{{ debit_comment.debit_commented_by }}</span>
                </div>
                <div>
                    <span>{{ debit_comment.debit_commented_at }}</span>
                </div>
            </div>
        <div>
            <b-input style="margin-top: 10px;" type="text" v-model="debit_comment.debit_comment" readonly/>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "DebitCommentField",

    props: {
        debitCommentArray : Array
    }
}
</script>

<style scoped>

*{
    color: gray;
}

</style>