<template>
  <div class="login-page-bg">
    <div class="wrapper">
      <div class="cover-img"></div>

      <div class="input-area">
        <!-- First Column: Display Picture -->
        <div>
          <img
            src="../../assets/images/Forgot Password.png"
            alt="SDB Bank Logo"
            style="width: 6vw; margin: 0 40%"
          />
        </div>

        <div class="has-text-centered is-size-3 has-text-primary has-text-weight-bold">
          OTP Verification
        </div>
        <div
          class="has-text-centered is-size-6 has-text-black has-text-weight-semibold"
          style="color: #757575; width: 75%; margin: 0 auto 50px auto"
        >
          We just sent an OTP to your registered email address
          <span class="has-text-black-bis">{{ this.userEmail.email }}</span>
        </div>
        <div class="inputs">
          <!-- <div class="control"> -->
          <div class="input-felds-styles" style="margin: 0 auto; width: 75%">
            <b-field
              :type="invalidFields.otpCode == '' ? '' : 'is-danger'"
              :message="invalidFields.otpCode"
            >
              <b-input
                @input="resetInvalidStatus('otpCode')"
                id="partitioned"
                type="text"
                v-model="user.otpCode"
                maxlength="6"
              ></b-input>
            </b-field>
          </div>
          <!-- </div> -->
          <div
            class="has-text-centered is-size-6 has-text-black has-text-weight-semibold"
            style="color: #757575; width: 75%; margin: 0 auto"
          >
            Resend OTP In : {{ countDown }}
          </div>

          <div class="action-buttons">
            <ActionButton
              inverted
              type="is-primary"
              buttonLabel="Resend OTP"
              expanded=" expanded"
              size="is-medium"
              :buttonAction="ResendOTP"
              divStyles="input-felds-styles-buttons"
              :disabled="!isTimeout"
            />

            <ActionButton
              type="is-primary"
              buttonLabel="Verify"
              expanded=" expanded"
              size="is-medium"
              :buttonAction="validateAndVerifyOtp"
              divStyles="input-felds-styles-buttons"
            />
            <!-- <button class="button is-primary is-medium is-rounded"
          style="width: 107%; margin-top: 24.3vh; margin-right: 34.3vh" @click="verifyOTP()">Verify</button> -->

             <ActionButton
              inverted
              type="is-primary"
              buttonLabel="Go Back"
              expanded=" expanded"
              size="is-medium"
              :buttonAction="Back"
              divStyles="input-felds-styles-buttons"
              icon-left="arrow-left"
          />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.align-right {
  text-align: right;
}
.input-felds-styles {
  margin: 20px auto;
}

.action-buttons {
  margin-top: 80px;
}

#partitioned {
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 300px;
  outline: none;
}

.login-page-bg {
  background-image: url("../../assets/images/Login_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  position: fixed;
  width: 100%;
  display: flex;
}

.wrapper {
  display: flex;
  width: fit-content;
  border-radius: 8px;
  flex-direction: row;
  background: white;
  height: 650px;
  margin: auto;
}
.inputs {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.cover-img {
  background-image: url("../../assets/images/Login_Page_Image.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 560px;
  height: inherit;
}
.input-area {
  padding: 20px;
  height: 735px;
  width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: inherit;
}

.input-felds-styles-buttons {
  margin: 5px auto;
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";
import ActionButton from "@/components/support/buttons/ActionButton.vue";
export default {
  props: ["otpData"],
  name: "OTPVerification",
  components: {
    ActionButton,
  },
  data() {
    return {
      userEmail: JSON.parse(localStorage.getItem("otpData")),
      otpInfo: "",
      countDown: 60,
      isTimeout: false,
      userData: null,
      otpData: "",
      invalidFields: {
        otpCode: "",
      },
      user: {
        otpCode: "",
      },
    };
  },

  methods: {
    verifyOTP: function () {
      // console.log("Inside verifyOTP");
      const otpInfo = JSON.parse(localStorage.getItem("otpData"));

      const requestData = {
        otpInfo: otpInfo.user,
        otpCode: this.user.otpCode,
      };

      NetworkManager.apiRequest("verifyOTP", requestData, (e) => {
        if (e.success === true) {
          this.userData = e.data.userId;
          this.$router.push({
            name: "PasswordReset",
            params: { userData: this.userData },
          });

          localStorage.setItem("userInfo", JSON.stringify(this.userData));
        } else if (e.success === false) {
          BuefyHelper.showToastMessage(e.data.message, "is-danger");
        }
      });
    },

    validateAndVerifyOtp: function () {
      // console.log("Inside validateAndVerifyOtp");
      if (this.validateVerifyOtp()) {
        this.verifyOTP();
      }
    },

    validateVerifyOtp: function () {
      // console.log("Inside validateVerifyOtp");

      var formIsValid = true;

      if (this.user.otpCode == "") {
        this.invalidFields.otpCode = "Enter the OTP code";
        formIsValid = false;
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    resetInvalidStatus: function (key) {
      this.invalidFields[key] = "";
    },

    ResendOTP: function () {

      const otpInfo = JSON.parse(localStorage.getItem("otpData"));
      const requestData = {
        otpInfo: otpInfo.user,
        // otpCode: this.user.otpCode,
      };

      NetworkManager.apiRequest("ResendInitialOTPEmail", requestData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage("OTP Code is sent to your email", "is-success");
          this.isTimeout = false;
          this.countDown = 60;
          this.resendCountDown();
          //  this.otpData = e.data.user;
          //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
        } else if (e.success === false) {
          BuefyHelper.showToastMessage("OTP code sending failed", "is-danger");
        }
      });
    },

    resendCountDown: function () {
      let timer = setInterval(() => {
        this.countDown = this.countDown - 1;
        if (this.countDown === 0) {
          this.isTimeout = true;
          clearInterval(timer);
        }
      }, 1000);
    },

     Back: function () {
      this.$router.push("/user/forgotPassword");
    },
  },

  mounted() {
    // const routeQuery = this.$route.query;
    // if (routeQuery.otpData) {
    //   this.otpData = routeQuery.otpData;
    // }

    this.resendCountDown();
  },
};
</script>
