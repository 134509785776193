<template>

  <div style="overflow: hidden; max-height: 100%; padding-top:10px; padding-bottom:10px">
    <!-- <div style="position: sticky; margin-left: 40px; "><label style="color: gray; font-weight: 400; font-size: 23px">Create Role</label> </div> -->
  <div  style="overflow-y: scroll; overflow-x: hidden; max-height: 100vh; min-height: 100vh; padding-top:50px; padding-bottom: 180px; padding-left: 20px; padding-right: 20px" >
    <div class="tile is-ancestor">

      <div class="tile is-vertical is-12">
        <div class="tile">
          <div class="tile is-parent is-vertical">
           
            <div class="tile is-child custom-tile">
              <section>
                <b-field
                  horizontal
                  :type="invalidRole == '' ? '' : 'is-danger'"
                  :message="invalidRole"
                >
                  <template slot="label"
                    ><span class="permission-group-title">Role </span><span class="has-text-danger">*</span></template
                  >
                  <b-input
                    v-model="formData.role_name"
                    placeholder="Enter role name"
                    expanded
                    @input="resetInvalidStatus('invalidRole')"
                  ></b-input>
                </b-field>

                <!-- <b-field
                  horizontal
                  :type="invalidUserLevel == '' ? '' : 'is-danger'"
                  :message="invalidUserLevel"
                >
                  <template slot="label"
                    ><span class="permission-group-title">User Level </span><span class="has-text-danger">*</span></template
                  >
                  <b-select
                    v-model="formData.user_level"
                    @input="resetInvalidStatus('invalidUserLevel')"
                    placeholder="Select user level"
                  >
                    <option
                      v-for="userLevel in userLevelList"
                      :value="userLevel.user_level"
                      :key="userLevel.id"
                    >
                      {{ userLevel.title }}
                    </option>
                  </b-select>
                </b-field> -->
  

                <b-field horizontal>
                  <div class="block"></div>
                </b-field>

                <b-field
                  horizontal
                  :type="invalidPermissions == '' ? '' : 'is-danger'"
                  :message="invalidPermissions"
                >
                  <template slot="label"><span class="permission-group-title">Permissions</span> <span class="has-text-danger">*</span></template>

                  <div class="permissions-container">
                    <div
                      class="permission-group-wrapper"
                      v-for="permissionData of permissionList"
                      :key="permissionData.rowId"
                    >
                      <div class="permission-group-title">
                        {{ permissionData.group_name }}
                      </div>
                      <div class="permission-group-permission-list">
                        <div
                          class="field"
                          v-for="permissionItem of permissionData.permission_list"
                          :key="permissionItem"
                        >
                          <b-checkbox
                            @input="addOrRemovePermission(permissionItem)"
                            v-model="checkBoxBindings[permissionItem]"
                            size="is-small"
                            type="is-primary"
                            >{{ permissionItem }}</b-checkbox
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-field>

                <b-field horizontal message=" ">
                  <template slot="label"
                    ><span class="permission-group-title">Status</span><span class="has-text-danger">*</span>
                    </template>
                  <div class="block">
                  
                    <b-radio
                    style="margin-right: 10px;"
                      v-model="formData.disabled"
                      name="status"
                      native-value="0"
                      type="is-primary"
                    >
                      Active
                    </b-radio>
                    <b-radio
                      v-model="formData.disabled"
                      name="status"
                      native-value="1"
                      type="is-primary"
                    >
                      Not Active
                    </b-radio>
                  </div>
                </b-field>
              </section>
            </div>

            <div class="tile custom-tile-narrow">
              <div class="column">
                <!-- <section class="mb-5" v-if="formIsInvalid">
                  <b-message type="is-danger">
                    Form contains invalid fields. Please make sure you have filled the
                    form properly.
                  </b-message>
                </section> -->
                <div class="columns">
                  <div class="column">
                    <p class="has-text-danger is-italic">
                      Fields marked with <big>*</big> are mandatory
                    </p>
                  </div>
                  <!-- <div class="buttons mb-6">
                  <div class="buttons mb-6">
                    <b-button @click="validateAndSubmit" class="is-info">Save</b-button>
                    <b-button @click="goBack()" class="is-danger">Cancel</b-button>
                  </div> -->

                  <div class="align-right mt-5">
                    <b-button
                      type="is-primary is-light"
                      class="mr-5"
                      size="is-normal"
                      @click="goBack()"
                      >Cancel</b-button
                    >
                    <b-button type="is-primary" size="is-normal" @click="validateAndSubmit"
                      >Create Role</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<style>
.align-right {
  text-align: right;
}

.permission-level-input {
  max-width: 50px;
}
.permission-group-wrapper {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;
  vertical-align: top;
}
.permission-group-title {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
.permission-group-permission-list {
  padding-left: 0.1rem;
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";

export default {
  name: "CreateRole",
  components: {},
  data() {
    return {
      formData: {
        role_name: "",
        user_level: null,
        selectedPermissions: [],
        disabled: 0,
      },
      //userLevelList: [],
      permissionList: [],
      checkBoxBindings: {},
      groupWisePermissions: {},
      permissionGroup: [],
      formIsInvalid: false,
      invalidRole: "",
      invalidName: "",
      invalidUserLevel: "",
      invalidPermissions: "",

      regex: {
        name: /^[a-zA-Z][a-zA-Z ]+$/,
      },
    };
  },
  methods: {
    validateForm: async function () {
      var formIsValid = true;
      this.btnDisabled = true;
      // Role Name
      if (this.formData.role_name == "") {
        this.invalidRole = "This field cannot left blank";
        formIsValid = false;
      }

      // else if (!this.regex.name.test(this.formData.role_name)) {
      //   this.invalidName = "Role name cannot contain numbers or special characters";
      //   formIsValid = false;
      // } else if (this.formData.role_name.length > 64) {
      //   this.invalidName = "Role name should be below 64 characters";
      //   formIsValid = false;
      // } else {
      //   var roleNameStatus = await this.validateRoleName();

      //   if (!roleNameStatus) {
      //     formIsValid = false;
      //   }
      // }

      // if (this.formData.user_level == null) {
      //   this.invalidUserLevel = "This field cannot left blank";
      //   formIsValid = false;
      // }

      if (this.formData.selectedPermissions.length < 1) {
        this.invalidPermissions = "Please select at least one permission";
        formIsValid = false;
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    resetInvalidStatus: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;
    },

    validateAndSubmit: async function () {
      var formValidStatus = await this.validateForm();

      if (formValidStatus) {
        this.submitForm();
      } else {
        this.formIsInvalid = true;
      }
    },

    goBack() {
      this.$router.go(-1);
    },
    addOrRemovePermission: function (permissionItem) {
      if (this.formData.selectedPermissions.includes(permissionItem)) {
        var index = this.formData.selectedPermissions.indexOf(permissionItem);
        this.formData.selectedPermissions.splice(index, 1);
      } else {
        this.formData.selectedPermissions.push(permissionItem);
      }

      this.resetInvalidStatus("invalidPermissions");
    },
    submitForm() {
      var permissionText = "";
      this.formData.selectedPermissions.forEach(function (permission) {
        permissionText += "|";
        permissionText += permission;
      });
      permissionText = permissionText.substring(1);

      var postData = this.formData;
      postData["permissions"] = permissionText;

      NetworkManager.apiRequest("backend/roles/create", postData, (e) => {
        if (e.success === true) {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval successfully",
            "is-success"
          );
          this.$router.push("/role");
        } else {
          BuefyHelper.showToastMessage(
            "Request has been sent for approval failed",
            "is-danger"
          );
        }
      });
    },
  },
  mounted() {
    NetworkManager.apiRequest("backend/roles/requiredFormData", {}, (e) => {
      // console.log(e);
      this.userLevelList = e.data.userLevelList;
      this.permissionList = e.data.permissionList;
      // console.log("this.userLevelList", this.userLevelList);

      var tmpPermissionGroup = [];
      var bindings = {};
      var permissionKeys = {};

      this.permissionList.forEach(function (element) {
        tmpPermissionGroup.push(element.group_name);
        permissionKeys[element.group_name] = [];
        element.permission_list.forEach(function (permisssion) {
          bindings[permisssion] = false;
          permissionKeys[element.group_name].push(permisssion);
        });
      });

      this.checkBoxBindings = bindings;
      this.groupWisePermissions = permissionKeys;
      this.permissionGroup = tmpPermissionGroup;
    });
  },
};
</script>
