<template>
  <b-modal v-model="rejectModalActive">
    <div class="main-container" >
      <div class="header-container">
        <div>
          <div style="margin-top:10px;"> <span class=" has-text-weight-semibold" style="color:#FF505A; font-size:22px">Reject Debit Card</span> <span style="color:#757575; font-size:15px">{{clientNic}}</span> </div>
        </div>
        <div>
           <b-button @click="closeModal" class="is-pulled-right close-btn" style="border:none;">
                    <b-icon icon="close" size="16px" style="color: #757575; cursor: pointer; " class="b-icon" onMouseOver="this.style.color='black'" onMouseOut="this.style.color='gray'"></b-icon>
                </b-button>
        </div>
      </div>
      <div class="body-container">
        
        <div style=" margin: 0 15px 0 0">
          <div>
            <img src="../../../assets/images/RejectDebitCard.png" />

            <div style="width: 85%; margin: 0 auto auto 40px">
              <span style="font-size:14px; font-weight:600; color:black;">Reject Reason</span> <span class="has-text-danger">*</span>
              <b-input
                style="border-radius:32px; margin-top:10px"
                placeholder="Enter reject reason"
                v-model="reason"
              ></b-input>

              <b-button
                type="is-danger"
                expanded
                size="is-medium"
                style="margin-top: 30px; background-color:#FF505A; border-radius:10px; font-size:18px"
                @click="$emit('debitCardReject', reason)"
                >Reject</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "DebitCardIssueCard",
  props: [
    "debitCardRejectModalActive",
    "clientNic"
  ],
  emits : ['debitCardReject'],

  data() {
    return {
      reason: "",
    };
  },

  methods:{
    closeModal(){
      this.$emit('update:debitCardRejectModalActive', false);
    },
  },

  computed: {
      rejectModalActive() {
        return this.debitCardRejectModalActive;
      }
  },

};
</script>

<style scoped>
.main-container {
  background: #ffff;
  width: 460px;
  height: 470px;
  border-radius: 32px;
  margin: 0 auto;

  /* display: flex;
    justify-content: space-between; */
}

.main-container img {
     width: 250px;
     margin-bottom: 20px;
     margin-left: 110px;
     margin-right: 20px;
     margin-top:40px;
}
.header-container {
  height: 10%;
  /* background-color: #474eaf; */
  display: flex;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  padding: 10px 35px;
  justify-content: space-between;
  background: white;
  margin-top:20px;
}
.close-btn {
    
    top: 6px;
    left:30px;
    margin-right:10px;
}
img{
  width: 25%;	
}

.gray-txt {
  color: #757575;
}
</style>
