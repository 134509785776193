
    // http://127.0.0.1:8000/api - local baseUrl 
    // https://sdb-f2f-api.paymediasolutions.com/public/api - paymedia server baseUrl
    //http://10.100.32.140/SDBF2FAPIBackend/public/api - sdb bank server baseUrl

    // usingSDBServer - this should be true if you are using sdb bank server baseUrl and  false if you are using paymedia server baseUrl or local baseUrl

    // ----- NEED TO CHANGE THE appSettingsStorage.js BASE URL ALSO -----

    let appSettings = {  
        // baseUrl : "http://127.0.0.1:8000/api",
        baseUrl : "https://sdb-f2f-api.paymediasolutions.com/public/api",
        // baseUrl : "http://10.100.32.140/SDBF2FAPIBackend/public/api",
        // baseUrl : "https://conboard.sdb.lk:8000/api",
        usingSDBServer : false,  
        // usingSDBServer : true,
        version : "Copyright©2024 SDB Bank All Rights Reserved | V1.0.0+1"
    }
    

    export {appSettings}
