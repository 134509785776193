<template>
  <div class="drpdown" style="margin: 0 20px;">
    <div class="columns is-multiline" style="margin-top: 2.8vh">
      <!-- <div class="column is-full">
        <label style="color: gray; font-weight: 400; font-size: 23px">Create User</label>
      </div> -->
      <div class="column is-full">
        <label style="color: #0083ca; font-size: 20px">User Information</label>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :type="invalidEmpId == '' ? '' : 'is-danger'" :message="invalidEmpId">
          <!-- <template slot="label"
            >Employee ID <span class="has-text-danger">*</span></template
          > -->
          <template slot="label"
            ><span class="group-title">Employee ID </span><span class="has-text-danger">*</span></template
          >
          <!-- <b-input
            v-model="user.user_name"
            placeholder="Enter employee ID number"
            @input="resetInvalidStatus('invalidEmpId')"
          ></b-input> -->
          <b-input
            v-model="user.user_name"
            placeholder="Enter employee Employee ID"
            @blur="onBlur"
            @focus="isFocused = true"
            @input="onInput"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidName == '' ? '' : 'is-danger'" :message="invalidName">
          <template slot="label"><span class="group-title">Name </span><span class="has-text-danger">*</span></template>
          <b-input
            @input="validateName('invalidName')"
            v-model="user.name"
            placeholder="Enter employee Name"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidMobile == '' ? '' : 'is-danger'" :message="invalidMobile">
          <template slot="label"><span class="group-title">Mobile </span><span class="has-text-danger">*</span></template>
          <b-input
            @input="validateMobile('invalidMobile')"
            v-model="user.contact"
            placeholder="Enter employee Mobile"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :type="invalidEmail == '' ? '' : 'is-danger'" :message="invalidEmail">
          <template slot="label"
            ><span class="group-title">Email</span> <span class="has-text-danger">*</span></template
          >
          <b-input
            @input="resetInvalidStatus('invalidEmail')"
            v-model="user.email"
            placeholder="Enter employee Email Address"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidRole == '' ? '' : 'is-danger'" :message="invalidRole">
          <template slot="label"><span class="group-title">Role</span><span class="has-text-danger">*</span></template>
          <b-select
            @input="resetInvalidStatus('invalidRole')"
            expanded
            placeholder="Select role"
            v-model="user.role"
          >
            <option v-for="role in roleList" :key="role.id" :value="role.id">
              {{ role.role_name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field :type="invalidBranch == '' ? '' : 'is-danger'" :message="invalidBranch">
          <template slot="label"><span class="group-title">Branch</span><span class="has-text-danger">*</span></template>
          <b-select
            @input="resetInvalidStatus('invalidBranch')"
            expanded
            v-model="user.branch"
            placeholder="Select branch"
          >
            <option v-for="branch in branchList" :key="branch.id" :value="branch.branch_code">
              {{ branch.branch_name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <!-- <div class="columns is-multiline" style="margin-top: 1.5vh">
      <div class="column is-full">
        <label style="color: #0083ca; font-size: 20px"
          >Login Credentials<span class="has-text-danger">*</span></label
        >
      </div>
      <div class="column is-full">
        <div class="block">
          <b-field
            grouped
            :type="invalidPassword == '' ? '' : 'is-danger'"
            :message="invalidPassword"
          >
            <b-input
              v-model="user.password"
              @input="resetInvalidStatus('invalidPassword')"
              disabled="true"
            >
            </b-input>
            <p class="control">
              <button @click="generatePassword" class="button is-info">Generate</button>
            </p>
            <p class="control">
              <button @click="copyToClipboard" class="button is-info">Copy</button>
            </p>
          </b-field>
        </div>
      </div>
    </div> -->
    <div class="columns is-multiline" style="margin-top: 1.5vh">
      <div class="column is-full">
        <label style="color: #0083ca; font-size: 20px"
          >Status<span class="has-text-danger">*</span></label
        >
      </div>
      <div class="column is-full">
        <div class="block">
          <div class="block">
            <b-radio
              v-model="user.disabled"
              type="is-primary"
              name="status"
              native-value="0"
              style="margin-right: 10px"
            >
              Active
            </b-radio>
            <b-radio
              v-model="user.disabled"
              type="is-primary"
              name="status"
              native-value="1"
            >
              Not Active
            </b-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="align-right mt-5">
      <b-button type="is-primary is-light" class="mr-5" size="is-normal" @click="goBack"
        >Cancel</b-button
      >
      <b-button type="is-primary" size="is-normal" @click="validateAndSubmit" :loading = buttonIsLoading
        >Create User</b-button
      >
    </div>

    <div class="tile custom-tile-narrow">
      <div class="column">
        <div class="columns">
          <div class="column">
            <p class="has-text-danger is-italic">
              Fields marked with <big>*</big> are mandatory
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <section class="mb-5" v-if="formIsInvalid">
      <b-message type="is-danger">
        Form contains invalid fields. Please make sure you have filled the form properly.
      </b-message>
    </section> -->
  </div>
</template>

<style>
.align-right {
  text-align: right;
}

.drpdown{
  line-height : 1.3;
}

.group-title {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";
import { appSettings } from "@/appSettings"

export default {
  name: "CreateUser",
  components: {},
  data() {
    return {
      user: {
        user_name: "",
        name: "",
        contact: "",
        email: "",
        role: "",
        branch: "",
        password: "",
        disabled: 0,

        //new fields for SDB bank requirement
        emp_title: "",
        emp_name: "",
        emp_finit: "",
        emp_designation: "",
        emp_Department: "",
        emp_Department_code: "",
        branch_coordinator: "",
        supervisor: "",
        second_supervisor: "",
        emp_mob1: "",
        emp_mob2: "",
        emp_cat: "",
        branch_previous_one: "",
        branch_previous_two: "",
        classfication_name: "",
        emp_status: "",
        emp_lifecycle_status: "",
      },
      branchList: null,
      roleList: null,
      invalidName: "",
      invalidMobile: "",
      invalidEmail: "",
      invalidEmpId: "",
      invalidRole: "",
      invalidBranch: "",
      invalidPassword: "",
      formIsInvalid: false,
      buttonIsLoading: false,
      regex: {
        name: /^[a-zA-Z][a-zA-Z ]{1,128}$/,
        // userName: /^[a-zA-Z0-9._]{1,100}$/,
        userName: /^\d+$/,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        contact: /^[0-9]{10}$/,
      },
      employeeBranch: "",
      employeeId: "",
      isFocused: false,
    };
  },
  methods: {
    validateForm: function () {
      var formIsValid = true;
      this.btnDisabled = true;
      // name
      if (this.user.name == "") {
        this.invalidName = "This field cannot left blank";
        formIsValid = false;
      } else if (!this.regex.name.test(this.user.name)) {
        this.invalidName =
          "Name cannot contain numbers or special characters and should be below 128 characters";
        formIsValid = false;
      }

      // userName
      if (this.user.user_name == "") {
        this.invalidEmpId = "This field cannot left blank";
        formIsValid = false;
      }
      // else if (this.user.user_name.length != 4) {
      //   this.invalidEmpId = "Employee ID should be 4 characters";
      //   formIsValid = false;
      // }
      // else if (!this.regex.userName.test(this.user.user_name)) {
      //   this.invalidEmpId =
      //     "Employee ID cannot contain special characters and strings";
      //   formIsValid = false;
      // }

      //email
      if (this.user.email == "") {
        this.invalidEmail = "This field cannot left blank";
        formIsValid = false;
      } else if (!this.regex.email.test(this.user.email)) {
        this.invalidEmail = "Please enter a valid Email Address";
        formIsValid = false;
      }
      // else if (this.formData.email.length > 128) {
      //   this.invalidEmail = "Email should be below 128 characters";
      //   formIsValid = false;
      // }

      // contact
      if (this.user.contact == "") {
        this.invalidMobile = "This field cannot left blank";
        formIsValid = false;
      } else if (!this.regex.contact.test(this.user.contact)) {
        this.invalidMobile = "Please enter a valid Mobile";
        formIsValid = false;
      }

      // role
      if (this.user.role == "") {
        this.invalidRole = "This field cannot left blank";
        formIsValid = false;
      }

      // branch
      if (this.user.branch == "") {
        this.invalidBranch = "This field cannot left blank";
        formIsValid = false;
      }

      // password
      // if (this.user.password == "") {
      //   this.invalidPassword = "This field cannot left blank";
      //   formIsValid = false;
      // }
      // else if (this.formData.password.length < 8) {
      //   this.invalidPassword = "Password must contain at least 8 characters";
      //   formIsValid = false;
      // }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },
    generatePassword: function () {
      var normalCharSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var numericCharSet = "0123456789012345678901234567890123456789";
      var specialCharSet = "!@#$%^&*_=+-!@#$%^&*_=+-";

      var characters = normalCharSet + numericCharSet + specialCharSet;
      var charactersLength = characters.length;
      var length = 16;
      var result = "";
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.user.password = result;
    },

    onInput() {
      if (!this.isFocused) {
        this.validateEmpId('invalidEmpId');
      }
    },

    onBlur() {
      this.isFocused = false;
      this.validateEmpId('invalidEmpId');
    },
    // copyToClipboard: function () {
    //   var dummy = document.createElement("textarea");
    //   document.body.appendChild(dummy);
    //   dummy.value = this.user.password;
    //   dummy.select();
    //   document.execCommand("copy");
    //   document.body.removeChild(dummy);

    //   this.$buefy.toast.open({
    //     message: "Copied to Clipboard",
    //   });
    // },
    goBack() {
      this.$router.go(-1);
    },

    checkEmployeeId() {
      if(appSettings.usingSDBServer == true){
        const empId = this.user.user_name;
        if (empId.length === 4 && /^\d+$/.test(empId)) {
          // console.log('Employee ID is valid');
          this.fillValues();
        } 
        // else {
        //   console.log('Employee ID is invalid');
        //   BuefyHelper.showToastMessage(
        //       "Employee ID is invalid",
        //       "is-danger"
        //     );
        // }
      }
    },
    submitData: function () {
      this.buttonIsLoading = true;
      NetworkManager.apiRequest("backend/user/create", this.user, (e) => {
        if (e.success === true) {
        this.buttonIsLoading = false;
          BuefyHelper.showToastMessage(
            "Request has been sent for approval successfully",
            "is-success"
          );
          this.$router.push("/user");
        } else if ((e.data.message == "User with given username already exists")) {
          this.buttonIsLoading = false;
          BuefyHelper.showToastMessage(
            "User with given Employee ID already exists",
            "is-danger"
          );
        } else {
          this.buttonIsLoading = false;
          BuefyHelper.showToastMessage(
            "Request has been sent for approval failed",
            "is-danger"
          );
        }
        // console.log(e.data);
        // console.log(this.user);
      });
    },
    validateAndSubmit: function () {
      if (this.validateForm()) {
        // this.generatePassword();
        this.submitData();
      } else {
        this.formIsInvalid = true;
      }
    },
    resetInvalidStatus: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;
    },
    validateName: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;

      const pattern = /^[a-zA-Z ]*$/;

      if (!pattern.test(this.user.name)) {
        this.invalidName = "Please enter only letters in the name field";
      } else {
        this.invalidName = ""; // Clear the error message if the input is valid
      }
    },

    validateEmpId: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;

      if (this.user.user_name == "") {
        this.invalidEmpId = "This field cannot left blank";
      }
      // else if (this.user.user_name.length != 4) {
      //   this.invalidEmpId = "Employee ID should be 4 characters";
      // }
      // else if (!this.regex.userName.test(this.user.user_name)) {
      //   this.invalidEmpId =
      //     "Employee ID cannot contain special characters and strings";
      // }
      else{
        this.invalidEmpId = "";
        this.fillValues();
      }
    },

    fillValues(){
      this.employeeId = this.user.user_name;
      // if(this.employeeId.startsWith('0')){
      //   this.employeeId =  this.employeeId.slice(1);
      // }
      let digits = this.employeeId.match(/\d+/);
      let convertedEmpId =  digits ? digits[0] : '';
      // console.log("convertedEmpId",convertedEmpId);

      NetworkManager.apiRequest("backend/sdb/getEmployerDetail", {employeeId:convertedEmpId}, (e) => {
          if (e.success == false) {
            // BuefyHelper.showToastMessage(e.message, "is-danger");
            // BuefyHelper.showToastMessage(
            //   "Employee ID is invalid",
            //   "is-danger"
            // );
          };
          if (e.data.emp_branch_code.startsWith('0')) {
            this.employeeBranch =  e.data.emp_branch_code.slice(1);
            if(this.employeeBranch == "1"){
              if(e.data.emp_Department_code != ""){
                this.employeeBranch = "99";
              }else{
                this.employeeBranch = "1";
              }
            }
          }
          // console.log("employee details",e.data);
          this.user.name = e.data.emp_fullname;
          this.user.contact = e.data.emp_mob1;
          this.user.email = e.data.emp_email;
          this.user.branch = this.employeeBranch;

          //new fields for SDB bank requirement
          this.user.emp_title = e.data.emp_title
          this.user.emp_name = e.data.emp_name
          this.user.emp_finit = e.data.emp_finit
          this.user.emp_designation = e.data.emp_designation
          this.user.emp_Department = e.data.emp_Department
          this.user.emp_Department_code = e.data.emp_Department_code
          this.user.branch_coordinator = e.data.branch_coordinator
          this.user.supervisor = e.data.supervisor
          this.user.second_supervisor = e.data.second_supervisor
          this.user.emp_mob1 = e.data.emp_mob1
          this.user.emp_mob2 = e.data.emp_mob2
          this.user.emp_cat = e.data.emp_cat
          this.user.branch_previous_one = e.data.branch_previous_one
          this.user.branch_previous_two = e.data.branch_previous_two
          this.user.classfication_name = e.data.classfication_name
          this.user.emp_status = e.data.emp_status
          this.user.emp_lifecycle_status = e.data.emp_lifecycle_status
        });
    },

    validateMobile: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;

      const mobileRegex = /^07.*/;

      if (this.user.contact.length >= 2 && this.user.contact.substr(0, 2) !== "07") {
        this.invalidMobile = "Please enter a valid Mobile number.";
      } else {
        this.invalidMobile = "";
      }
    },
  },
  mounted() {
    NetworkManager.apiRequest("backend/user/requiredFormData", {}, (e) => {
      // console.log("e.statusCode",e.success);
      this.branchList = e.data.branchList;
      this.roleList = e.data.roleList;
    });
  },
};
</script>
