<template>
    <div>
        <b-modal v-model="isCardModalActive" :width="800" :can-cancel="false" scroll="clip" class="notification-modal">
            <div class="card" style="overflow:hidden">
                <div style="display:flex; flex-direction: row-reverse; margin: 4px 4px 4px 5px;">
                <b-button @click="closeNotificationWindow" style="border: none !important; outline: none !important;">
                    <span style="font-weight: bold; font-size:x-large;">×</span>
                </b-button>
            </div>
                <div class="card-image">
                    <div class="center-image">
                        <img src="../../assets/images/notificationIcon.png" alt="notificationIcon" style="margin-left: 688px; margin-top:10px;">
                    </div>
                </div>
                <div class="card-content">
                    <div class="notification-title">
                        <p class="title is-4" style="margin-left: 311px; margin-bottom: 20px">Notification</p>
                    </div>

                    <div class="content">
                        <div v-for="notification in notifications" :key="notification.id">

                            <NotificationBoxVue :notification="notification" @close="(id) => {handleClose(id)}"></NotificationBoxVue>
                            <!-- <div class="notification-card mb-20">
                                <div class="columns">
                                    <div class="column  justify-content: left is-2">
                                        <img src="../../assets/images/notification-icon1.png" alt="notification-icon1" style="width:37px; height:37px; margin-left:20px; margin-right:10px; margin-top:10px">
                                    </div>
                                    <div class="column justify-content: left">
                                        <p class="notifi-title" style="margin-bottom: 0em">{{notification.title}}</p>
                                        <p class="notification-body" style="margin-top: 0em">{{notification.body}}</p>
                                    </div>
                                    <div class="column is-one-quarter justify-content: right">
                                        <p class="notifi-time">1 min ago</p>
                                    </div>
                                    <div class="column is-one-fifth justify-content: end">
                                        <b-button @click="handleClose(notification.id)" style="border: none !important; outline: none !important;">
                                            <span style="font-weight: bold; font-size:x-large;">×</span>
                                        </b-button>
                                    </div>
                                </div>
                            </div> -->


                        </div>
                        <!-- <div v-for="notification in notifications" :key="notification.id">
                            <b-notification icon="credit-card-outline"
                                has-icon
                                aria-close-label="Close notification" @close="handleClose(notification.id)">
                                <div class="columns">
                                    <div class="column">
                                        <p class="notifi-title" style="margin-bottom: 0em">{{notification.title}}</p>
                                        <p class="notification-body" style="margin-top: 0em">{{notification.body}}</p>
                                    </div>
                                    <div class="column">
                                        <p class="notifi-time">{{notification.time}}</p>
                                    </div>
                                </div>
                            </b-notification>
                        </div> -->
                        <!-- <b-notification icon="credit-card-outline"
                            has-icon
                            aria-close-label="Close notification" @close="handleClose">
                            <div class="columns">
                                <div class="column">
                                    <p class="notifi-title" style="margin-bottom: 0em">Payment Successful</p>
                                    <p class="notification-body" style="margin-top: 0em">Your payment of $1000 was successful</p>
                                </div>
                                <div class="column">
                                    <p class="notifi-time">1 min</p>
                                </div>
                            </div>
                        </b-notification> -->
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import NetworkManager from "@/network";
import NotificationBoxVue from './NotificationBox.vue';

export default {
  name: "NotificationCard",
  props: ["isCardModalActive"],
  components: {
    NotificationBoxVue
  },
  data() {
    return {
    //   isCardModalActive: false,
      notifications: [],
    };
  },

  methods: {
    handleClose(id) {
    //   console.log("Closed notification", id);
      NetworkManager.apiRequest("backend/notification/readNotifications", {id:id}, (e) => {
        // console.log("Notification ID",id)
        });
        // Remove the closed notification from the notifications array
        this.notifications = this.notifications.filter(notification => notification.id !== id);   
    },
    closeNotificationWindow() {
        this.$emit('isCardModalActive')
    },

    getTimeAgo(createdTime) {
      const currentTime = new Date();
      const timeDifference = currentTime - new Date(createdTime);
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      if (minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
      } else {
        const hoursDifference = Math.floor(minutesDifference / 60);
        if (hoursDifference < 24) {
          return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else {
          const daysDifference = Math.floor(hoursDifference / 24);
          return `${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`;
        }
      }
    },
  },

  mounted() {
    NetworkManager.apiRequest("backend/notification/getNotifications", {}, (e) => {
        e.data.notifications.map((data) => {
          data.created_at=this.getTimeAgo(data.created_at);
        });
        // console.log("Notification Dataaa",e.data.notifications)
        this.notifications = e.data.notifications;
    });
  },
}
</script>

<style scoped>


.center-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 126.74px;
    height: 126px;
}

.title {
    color: #0083CA;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    margin-left: 220px;
}

.card {
    border-radius: 32px;
    overflow:hidden !important;
}


/* .notifi-title{
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0px;
} */

/* .notifi-time{
    color: #757575;
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0px;
    padding-top: 0.2em; 
    margin-left:5em;
} */

/* .card{
    color: #757575;
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
} */

/* .card{
    width: 755px;
    height: 80px;
    background-color: #FAFAFA;
} */

.button {
    background-color: #fafafa00;
    border-color: #fafafa00;
}

/* @media screen and (min-width: 769px), print {
  .column.is-one-fifth,
  .column.is-one-fifth-tablet {
    flex: none;
    width: 70px !important;
  }
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.notification-modal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification-modal {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>