<template>
<div class="notification-modal-container">
     <b-modal v-model="isReqNotificationModalActive" >
          <div class="main-container">
               <img :src="imgSrc"/>
               <div class="heading-text">
                    Request To Improve <br>
                   <span class="app-stat-text">  {{applicationStatText}}</span>
               </div>

               <!-- <div class="custom-select-container">
               <select v-model="selectedOption" size="1" @change="addTextBox">
                    <option disabled value="">Select an option</option>
                    <option v-for="option in dropdownOptions" :key="option" :value="option">{{ option }}</option>
               </select>
               </div> -->

                <div class="custom-select-container">
               <v-select
                    v-model="selectedOption"
                    :options="this.dropdownOptions.map(option => option.key)"
                    label="Select an option"
                    @input="addTextBox"
                    placeholder="Search or select an option"
               ></v-select>
               </div>

               
                <!-- Text Boxes -->
               <div v-for="(textBox, index) in textBoxes" :key="index" class="text-box-container">
               <div class="text-box-header">
                    <span class="header-text" style="background-color: #FEDA04;">{{ textBox.header }}</span>
                    <button class="close-button" @click="removeTextBox(index)">✖</button>
               </div>
               <input v-model="textBox.value" type="text" class="text-box-input" placeholder="What specifically needs improvement?" maxlength="100"/>
               </div>

               <div class="button-container">
                    <div class="button-column">
                         <b-button class="cancel-button" expanded @click="$emit('closeAction')">
                              Cancel
                         </b-button>
                    </div>

                    <div class="button-column">
                         <b-button class="submit-button" expanded :type="buttonType"  @click="submitRequestToImprove">
                               {{ buttonActionText }}
                         </b-button>
                    </div>
                    </div>
               </div>

        
     </b-modal>
     </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
     name : "NotificationModal",
     props : ["isReqNotificationModalActive", "applicationType", "applicantId", "review_status", "joint_main_owner","account_number","req_improve_reasons",
     "applicant_status", "account_type", "applicant_individual_account_type", "dropdownOptions", "branch"],
     emits : ["reqToImproveapplicationEmitHandler", 'closeAction'],
     components: { vSelect, },

     data(){
          return{
               applicationStatText : "",
               imgSrc : "",
               buttonType : "",
               buttonActionText : "",
               isReqNotificationModalActive: false,
               selectedOption: '',
               // dropdownOptions: [],
               textBoxes: [],
            
          }
     },


     methods:{
          valueChanger :function(){
               if(this.applicationType === 'Reject'){
                    this.applicationStatText = 'You Want To Reject This Application?'
                    this.imgSrc = require('../../../assets/images/RejectNotification.png')
                    this.buttonType = 'is-danger';
                    this.buttonActionText = 'Reject'
                  
               }
               else if(this.applicationType === "Request To Improve"){
                    this.applicationStatText = 'Please select the fields that you want to improve'
                    this.imgSrc = require('../../../assets/images/ReqToImprove.png')
                    this.buttonType = 'is-info'
                    this.buttonActionText = 'Request'
               }
              
          },
          addTextBox: function(){
               // Check if this.selectedOption is already in this.textBoxes as header
               const optionExists = this.textBoxes.some(textBox => textBox.header === this.selectedOption);
               if (this.selectedOption && !optionExists) {
                    this.textBoxes.push({ header: this.selectedOption, value: '', key: this.dropdownOptions.find(option => option.key === this.selectedOption)?.value });
               }
          },

          removeTextBox(index) {
               this.textBoxes.splice(index, 1);
          },

          submitRequestToImprove() {
               const requestData = {
                    applicationType: this.applicationType,
                    textBoxes: this.textBoxes,
                    id: this.applicantId,
                    review_status: this.review_status,
                    joint_main_owner: this.joint_main_owner,
                    account_number : this.account_number,
                    account_number : this.req_improve_reasons,
                    branch : this.branch,

               };

               NetworkManager.apiRequest(
                    "backend/applicant/submitRequestToImprove", requestData,
                    (e) => {
                         // console.log('successs' , e.success);
                    if (e.success === true) {
                         BuefyHelper.showToastMessage("Request to improve submission successful", "is-success");
                         // console.log("success")
                         location.reload();
                    } else if (e.success === false) {
                         BuefyHelper.showToastMessage("Request to improve submission failed", "is-danger");
                         // console.log("failed")
                         location.reload();
                    }
               },
                    false
          );
     },

         
          
     },

      watch: {
          applicationType: {
               handler(){
               this.valueChanger()
               },
                deep : true
               
          }
  },

     mounted(){
           this.valueChanger();
     }


}
</script>

<style scoped>

.main-container{
   background: #ffff;
    width: 558px;
    height: 470px;
    border-radius:32px;
    margin: auto;
    overflow: auto;
    box-sizing: border-box;
    Padding:42px, 0px, 42px, 0px
}



.main-container img{
     width: 100px;
     height:100px;
     margin: 30px 221px 5px 221px;
  
}
.heading-text{
     text-align: center;
     font-weight:700;
     font-size:26px;
     margin-bottom: 5px;
     color:#424242
}
.app-stat-text{
     color: #757575;
     text-align: center;
     font-weight:400;
     font-size:14px;
     letter-spacing: 0.5px;
     
}
.custom-select-container {
  text-align: center;
  margin: 40px 10px 10px 60px;
  width:80%;
  
}

.custom-select-container select {
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}
.button-container{
     margin: 60px auto 0 auto; 
     width: 80%;
     display: flex;
     justify-content: space-between;
     
}

.button-column {
  width: 45%;
  
  
}
.button-container button{
     margin: 10px 0;
}
.cancel-button{
height: 50px; 
border-radius:13px ;
font-weight:600;
font-size:17px;
color: #757575;
border:none;
box-shadow: 5px 5px 5px #E1E1E680;
}
.submit-button{
height: 50px; 
border-radius:13px ;
font-weight:600;
font-size:17px;
}
.searchable-dropdown-container {
  margin-top: 10px;
  margin-left: 100px;
  
}
/* .custom-select-container {
  text-align: center;
  margin: 20px 20px;
} */


.text-box-container {
  margin-top: 10px;
}

.text-box-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 60px;
  border: 1px solid #ccc;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;

}

.text-box-header {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-left: 60px;
  margin-right: 60px;
  background-color: #FEDA04;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.header-text {
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 15px;
}

.close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: black;
  margin-left: 10px;
  margin-right: 10px;
}

.vs__search {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 0 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>