<template> 

<SMSActivationView />

</template>

<script>

import SMSActivationView from "./SMSActivationView.vue";

export default {
    name: "SMSPendingView",

    props:[
    ],
    components: {
      SMSActivationView,

    },
    

    data(){
      return {

      };
    },

    methods: {

    },
  };

</script>

<style scoped>


</style>