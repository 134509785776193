<template>
  <div style="margin: 0 20px" class="scroll-bar">
    <div style="width: 100%; color: gray; font-weight: 400; font-size: 30px" class="is-size-5">
      <!-- Profile Management -->
    </div>
    <div class="mt-7 mt-5">
      <div style="display: flex; align-items: flex-end;">
        <b-upload v-model="file" class="file-label" accept=".jpg, .jpeg, .png" >
          <div style="position: relative; display: inline-block;">
            <img v-if="!imageChanged && formData.image" class="round-image-box" :src="baseUrl + formData.image" />
            <img v-if="imageChanged == true && formData.image != null" class="round-image-box" :src="imageUrl" />
            <img v-if="formData.image == '' || formData.image == null" class="round-image-box" :src="imageUrl" />
            <div style="position: absolute; bottom: 0; right: 0; margin: 5px;">
                <b-icon icon="camera-plus" size="is-medium" type="is-primary"></b-icon>
            </div>
          </div>
        </b-upload>
        <div>
          <h1 style="margin-left: 20px" class="application-headers">
              {{ formData.name }}
          </h1>
          <h3 style="margin-left: 20px">{{ formData.role }}</h3>
          <!-- <h4 style="margin-left: 20px" v-if="formData.disabled === 0" class="statusActive" >
              Active
          </h4>
          <h4
            style="margin-left: 20px"
            v-if="formData.disabled === 1"
            class="statusNotActive"
          >Not Active
          </h4> -->
        </div>
      </div>
    </div>

    <div style="margin-top: 20px">
      <label style="color: #0083ca; font-size: 20px" class="application-sub-headers">User Information</label>
    </div>
    <div class="columns">
      <div class="column">
        <b-field :type="invalidEmpId == '' ? '' : 'is-danger'" :message="invalidEmpId">
          <template slot="label">Employee ID</template>
          <b-input
            readonly
            v-model="formData.username"
            @input="resetInvalidStatus('invalidEmpId')"
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field :type="invalidName == '' ? '' : 'is-danger'" :message="invalidName">
          <template slot="label">Name</template>
          <b-input
            readonly
            @input="validateName('invalidName')"
            v-model="formData.name"
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field :type="invalidNIC == '' ? '' : 'is-danger'" :message="invalidNIC">
          <template slot="label"
            >NIC Number</template
          >
          <b-input
            readonly
            @input="validateName('invalidNIC')"
            v-model="formData.nic"
          ></b-input>
        </b-field>
      </div>
    </div>


    <div class="columns">
      <div class="column">
        <b-field :type="invalidMobile == '' ? '' : 'is-danger'" :message="invalidMobile">
          <template slot="label">Mobile</template>
          <b-input
            readonly
            v-model="formData.mobile"
            @input="resetInvalidStatus('invalidMobile')"
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field :type="invalidEmail == '' ? '' : 'is-danger'" :message="invalidEmail">
          <template slot="label"
            >Email</template
          >
          <b-input
            readonly
            v-model="formData.email"
            @input="resetInvalidStatus('invalidEmail')"
          ></b-input>
        </b-field>
      </div>

      <div class="column">
        <b-field :type="invalidRole == '' ? '' : 'is-danger'" :message="invalidRole">
          <template slot="label">Role</template>
          <!-- <b-select
              expanded
              placeholder="Select role"
              v-model="formData.role"
              @input="resetInvalidStatus('invalidRole')"
            > -->
          <b-input readonly v-model="formData.role"></b-input>
          <!-- <option v-for="role in roleList" :key="role.id" :value="role.id">
                {{ role.role_name }}
              </option>
            </b-select> -->
        </b-field>
      </div>
    </div>


    <div class="columns">
      <div class="column">
        <b-field :type="invalidBranch == '' ? '' : 'is-danger'" :message="invalidBranch">
          <template slot="label">Branch</template>
          <b-input readonly v-model="formData.branch" class="custom-width"></b-input>
          <!-- <b-select
              expanded
              v-model="formData.branch"
              @input="resetInvalidStatus('invalidBranch')"
            >
              <option v-for="branch in branchList" :key="branch.id" :value="branch.id">
                {{ branch.branch_name }}
              </option>
            </b-select> -->
        </b-field>
      </div>
    </div>


    <!-- <div class="mb-5">
      <label style="color: #0083ca; font-size: 20px" class="application-sub-headers"
        >Login Credentials</label>
    </div>

    <div class="columns">
      <div class="column">
        <b-field :type="invalidResetEmail == '' ? '' : 'is-danger'" :message="invalidResetEmail">
          <template slot="label"
            >Email</template
          >
          <b-input
            class="custom-width"
            v-model="credentials.email"
            @input="resetInvalid('invalidResetEmail')"
          ></b-input>
        </b-field>
      </div>
    </div>

    <div>
      <b-button type="is-primary" @click="changePassword()">Change Password</b-button>
    </div> -->
    <!-- <div>
      <b-button v-if="imageChanged" type="is-primary" @click="submitImage()">Save Changes</b-button>
    </div> -->

    <b-modal v-model="passwordResetModalVisible" :can-cancel="true">
      <div class="password-reset-container">
        <img src="@/assets/images/Forgot Password.png">
        <p class="is-size-3 has-text-centered has-text-weight-bold has-text-primary">Set A New Password</p>
       

        <div class="inpt-fields">
          <b-field :type="invalidFields.currentPassword == '' ? '' : 'is-danger'" :message="invalidFields.currentPassword">
            <template v-slot:label>
              <span>Enter Your Current Password <span class="required">*</span></span>
            </template>
            <b-input @input="resetPasswordInvalidStatus('currentPassword')" v-model="updatePasswordForm.currentPassword" type="password" password-reveal></b-input>
          </b-field>

          <b-field :type="invalidFields.newPassword == '' ? '' : 'is-danger'" :message="invalidFields.newPassword">
            <template v-slot:label>
              <span>Enter Your New Password <span class="required">*</span></span>
            </template>
            <b-input @input="resetPasswordInvalidStatus('newPassword')" v-model="updatePasswordForm.newPassword" type="password" password-reveal></b-input>
          </b-field>

          <b-field :type="invalidFields.confirmPassword == '' ? '' : 'is-danger'" :message="invalidFields.confirmPassword">
            <template v-slot:label>
              <span>Re-enter Password <span class="required">*</span></span>
            </template>
            <b-input @input="resetPasswordInvalidStatus('confirmPassword')" v-model="updatePasswordForm.confirmPassword" type="password" password-reveal></b-input>
          </b-field>

          <div class="button-container2">
            <b-button :disabled="updatePasswordForm.newPassword === '' || updatePasswordForm.confirmPassword === '' || updatePasswordForm.currentPassword === ''" @click="validateAndUpdatePassword()" class="save-btn" type="is-primary" expanded>Change password</b-button>
            <b-button @click="passwordResetModalVisible=false" class="cancel-btn" type="is-danger" expanded>Cancel</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="imageChanged" :width="640" scroll="keep" >
      <b-tag style="margin-left: 216px;color: red; font-weight: bold;">
        Please Upload Files less than 2MB
        </b-tag>
      <!-- <b-message class="is-info has-text-centered" style="padding: 1px; font-size: medium;"> Accept Files less than 2MB </b-message> -->
        <imgCropper :imgSrc="imgSrc" @crop-image="submitImage" @cancel-upload="cancelUpload"></imgCropper>
    </b-modal>

  </div>
</template>

<style>

.align-right {
  text-align: right;
}

.check-box-container {
  display: flex;
  width: 20%;
  justify-content: space-between;
  margin-left: 15px;
}

.custom-width {
  width: 32.2%;
}

.round-image-box {
  border-radius: 80%;
  border: 2px solid #009ec2;
  width: 140px;
  height: 140px;
  margin-right: 10px;
}

.required {
  color: red;
  margin-left: 2px; /* Adjust the margin as needed for spacing */
}

.application-headers {
  font-size: 1.7rem;
  font-weight: bolder;
  color: #0083ca;
}
/* .statusActive {
  color: #05ff75;
  font-weight: bold;
} */

.button-container{
     margin: 100px auto 0 auto; 
     width: 80%;
     
}
.button-container button{
     margin: 10px 0;
}

.cancel-btn{
  margin-left: 20px;
}

/* .statusNotActive {
  color: #ff0000;
  font-weight: bold;
} */

.scroll-bar {
  overflow-y: scroll;
  max-height: 100%;
  padding: 25px 10px 80px 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.button-container2 {
  display: flex;
  justify-content: space-between; /* Adjust this property as needed */
}

.password-reset-container{
    background: #ffff;
    width: 400px;
    height: 520px;
    border-radius:12px;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    margin: 0 auto;
}

.password-reset-container img{
  width : 100px;
  height: 100px;
  margin: 25px auto;
}

.input-felds-styles {
  margin: 25px auto;
}

.inpt-fields{
  width: 80%;
  margin: 10px auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-bar::-webkit-scrollbar {
  display: none;
}

.confirmation-modal-container{
     background: #ffff;
    width: 480px;
    height: 500px;
    border-radius:12px;
    margin: auto;
}

.confirmation-modal-container img{
     width: 120px;
     margin: 20px 160px 10px 181px;
  
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

<script>
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";
import imgCropper from "@/components/ImageCropper.vue";
import { appSettingsStorage } from "@/appSettingsStorage";
import JwtUtils from "@/helpers/jwtUtils";

export default {
  name: "ProfileManagement",
  components: {imgCropper},
  data() {
    return {
      user: {
        user_name: "",
        name: "",
        contact: "",
        email: "",
        branch: "",
        password: "",
        disabled: 0,
      },
      isNotificationModalActive: true,
      file: null,
      imgSrc:null,
      formData: {
        username: "",
        role: "",
        mobile: "",
        email: "",
        branch: "",
        name: "",
        image: "",
        disabled: 0,
      },
      baseUrl: appSettingsStorage.baseUrl,
      invalidName: "",
      invalidNIC: "",
      invalidMobile: "",
      invalidEmail: "",
      invalidResetEmail: "",
      invalidEmpId: "",
      invalidRole: "",
      invalidBranch: "",
      formIsInvalid: false,
      imageChanged: false,
      regex: {
        name: /^[a-zA-Z][a-zA-Z ]{1,128}$/,
        userName: /^[a-zA-Z0-9._]{1,100}$/,
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        contact: /^[0-9]{10}$/,
      },
      credentials:{
        email : "",
      },
      invalidFields: {
        currentPassword: "",
        username: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      updatePasswordForm: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      passwordResetModalVisible: false,
    };
  },
  watch: {
    file: {
        handler: "watcher", 
    },
  },
  computed: {
    imageUrl() {
    if (this.file && this.file.type.startsWith("image/")) {
        return URL.createObjectURL(this.file);
      } else {
        return '../../assets/images/default-image.png'
      }
    },
  },
  methods: {
    watcher() {
      this.imageChanged = true;
      this.imgSrc = URL.createObjectURL(this.file);
      // console.log("Updated");
    },
    validateForm: function () {
      const comp = this;
      var formIsValid = true;
      comp.btnDisabled = true;

      if (
        comp.oldName === comp.formData.name &&
        comp.oldUsername === comp.formData.username &&
        comp.oldEmail === comp.formData.email &&
        comp.oldContact === comp.formData.contact &&
        comp.oldBranch === comp.formData.branch &&
        comp.oldRole === comp.formData.role &&
        comp.oldPermissions === comp.formData.permissions &&
        comp.oldDisabled === comp.formData.disabled
      ) {
        BuefyHelper.showToastMessage("No changes made", "is-danger");
        formIsValid = false;
      }

      // name
      if (comp.formData.name == "") {
        comp.invalidName = "This field cannot left blank";
        formIsValid = false;
      } else if (!comp.regex.name.test(comp.formData.name)) {
        comp.invalidName =
          "Name cannot contain numbers or special characters and should be below 128 characters";
        formIsValid = false;
      }

      // userName
      if (comp.formData.username == "") {
        comp.invalidEmpId = "This field cannot left blank";
        formIsValid = false;
      }
      // else if (!this.regex.userName.test(this.formData.username)) {
      //   this.invalidUsername =
      //     "Username cannot contain special characters and should be below 100 characters";
      //   formIsValid = false;
      // }

      //email
      if (comp.formData.email == "") {
        comp.invalidEmail = "This field cannot left blank";
        formIsValid = false;
      } else if (!comp.regex.email.test(comp.formData.email)) {
        comp.invalidEmail = "Please enter a valid Email";
        formIsValid = false;
      }
      // else if (this.formData.email.length > 128) {
      //   this.invalidEmail = "Email should be below 128 characters";
      //   formIsValid = false;
      // }

      // contact
      if (comp.formData.contact == "") {
        comp.invalidMobile = "This field cannot left blank";
        formIsValid = false;
      } else if (!this.regex.contact.test(this.formData.contact)) {
        comp.invalidMobile = "Please enter a valid Mobile number.";
        formIsValid = false;
      }

      // role
      if (comp.formData.role == "") {
        comp.invalidRole = "This field cannot left blank";
        formIsValid = false;
      }

      // branch
      if (comp.formData.branch == "") {
        comp.invalidBranch = "This field cannot left blank";
        formIsValid = false;
      }

      // password
      if (comp.formData.password == "") {
        comp.invalidPassword = "This field cannot left blank";
        formIsValid = false;
      }
      // else if (this.formData.password.length < 8) {
      //   this.invalidPassword = "Password must contain at least 8 characters";
      //   formIsValid = false;
      // }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    changePassword(){
      if(this.validateEmail()){
        this.checkEmailExists();
      }
    },

    checkEmailExists(){
      NetworkManager.apiRequest("backend/user/checkEmailIsValid", {email: this.credentials.email}, (e) => {
        if (e.success === true) {
          this.passwordResetModalVisible = true;
        } else if (e.success === false) {
          BuefyHelper.showToastMessage(e.data.message, "is-danger");
        }
      });
    },

    validateEmail(){
      const comp = this;
      var resetFormIsValid = true;
      if (comp.credentials.email == "") {
        comp.invalidResetEmail = "This field cannot left blank";
        resetFormIsValid = false;
      } else if (!comp.regex.email.test(comp.credentials.email)) {
        comp.invalidResetEmail = "Please enter a valid Email";
        resetFormIsValid = false;
      }
      if (resetFormIsValid) {
        return true;
      } else {
        return false;
      }
    },


    validateAndUpdatePassword: function () {
      if (this.validateUpdatePasswordForm()) {
        NetworkManager.apiRequest("backend/user/checkCurrentPassword", {currentPassword: this.updatePasswordForm.currentPassword}, (e) => {
          if (e.success === false) {
            BuefyHelper.showToastMessage(e.data.message, "is-danger");
            this.invalidFields.currentPassword = "Your current password is not correct";
          } else if(e.success === true){
            this.submitUpdatePassword();
          }
      });
          // console.log("Password validation success")

      }
    },

    validateUpdatePasswordForm: function () {
      var formIsValid = true;

      if (this.updatePasswordForm.currentPassword == "") {
        this.invalidFields.currentPassword = "Enter the current password";
        formIsValid = false;
      } else if (this.updatePasswordForm.newPassword == "") {
          this.invalidFields.newPassword = "Enter a new password";
          formIsValid = false;
      } else if (this.updatePasswordForm.newPassword.length < 8) {
          this.invalidFields.newPassword = "Password must contain at least 8 characters";
          formIsValid = false;
      } else {
        if (this.updatePasswordForm.confirmPassword == "") {
          this.invalidFields.confirmPassword = "Re-enter your new password";
          formIsValid = false;
        } else {
          if (
            this.updatePasswordForm.confirmPassword != this.updatePasswordForm.newPassword
          ) {
            this.invalidFields.confirmPassword = "Password validation failed";
            formIsValid = false;
          }
        }
      }

      if (formIsValid) {
        return true;
      } else {
        return false;
      }
    },

    validateCurrentPassword(){
      var formIsValid = true;
      NetworkManager.apiRequest("backend/user/checkCurrentPassword", {currentPassword: this.updatePasswordForm.currentPassword}, (e) => {
        if (e.success === false) {
          formIsValid = false;
        this.invalidFields.currentPassword = "Your current password is not correct";
        } else if(e.success === true){
          formIsValid = true;
        }
        
        if (formIsValid) {
          return true;
        } else {
          return false;
        }
      });
    },

    submitUpdatePassword: function () {
      NetworkManager.apiRequest(
        "backend/user/changeTemporaryPassword",
        this.updatePasswordForm,
        (e) => {
          if (e.success === true) {
            BuefyHelper.showToastMessage("Password Updated successfully", "is-success");
            setTimeout(() => {
              BuefyHelper.showToastMessage("Please login again", "is-success");
            }, 1000);
            setTimeout(() => {
              JwtUtils.logoutTheUser(true);
            }, 2000);
            // location.reload();
          } else if (e.success === false) {
            // console.log("e", e);
            BuefyHelper.showToastMessage(e.data.message, "is-danger"); 
          }
        }
      );
    },

    validateAndSubmit: function () {
      if (this.validateForm()) {
        this.submitData();
      } else {
        this.formIsInvalid = true;
      }
    },
    resetInvalidStatus: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;
    },

    resetInvalid: function (key) {
      this[key] = "";
    },
    
    validateName: function (key) {
      this[key] = "";
      this.formIsInvalid = false;
      this.btnDisabled = false;

      const pattern = /^[a-zA-Z ]*$/;

      if (!pattern.test(this.formData.name)) {
        this.invalidName = "Please enter only letters in the name field";
      } else {
        this.invalidName = ""; // Clear the error message if the input is valid
      }
    },

    resetPasswordInvalidStatus: function (key) {
      this.invalidFields[key] = "";
    },
cancelUpload(){
  // console.log("aa");
  this.imageChanged=false;
},
    submitImage(croppedImg) {
      if (croppedImg.size > 2 * 1024 * 1024) {
        // Show a message indicating the file size is too large
        this.$buefy.toast.open({
            message: "File size exceeds 2MB. Please select a smaller image.",
            type: "is-danger",
        });
        return; // Exit the function
    }
      this.file = croppedImg;
        const comp = this;
        const formData = new FormData();
        formData.append('userImage', comp.file);
        // console.log(formData);
      NetworkManager.apiRequest(
        "backend/user/submitUserImage",
        formData ,
        (e) => {
          if (e.success === true) {
            this.$buefy.toast.open({
            message: "Profile picture updated successfully",
            type: "is-success",
          });
          location.reload();
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(e.data.message, "is-danger");
          }
          // console.log("this.file", comp.file);
        },true,true
      );
    },

    getUserData() {
      NetworkManager.apiRequest("backend/user/getUserData", {}, (e) => {
        this.formData = e.data;
      });
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>
