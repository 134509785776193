<template>
    <b-modal v-model="fdModalActive">
        <div class="main-container">
            <div class="header-container">
                <div>  <span class="is-size-4 has-text-weight-bold has-text-primary">Digital FD Cretificate </span> <span>{{clientData.nic}}</span> </div>
                    <div>
                        <b-button style="border:none; background-color:#edf0f0" @click="closeModal">
                            <b-icon icon="close" size="is-medium" style="color: gray; cursor: pointer;" class="b-icon" onMouseOver="this.style.color='red'" onMouseOut="this.style.color='gray'"></b-icon>
                        </b-button>
                    </div>
            </div>
            <div class="body-container">
                   <div style="width: 50%; margin:0 0 0 15px">
                        <div class="profile-container">
                            <img :src="baseUrl + clientData.image_customer_img" class="round-image-box" alt="profile picture"/>
                            <div style="width:55%"> 
                                <div class="title-container-one">
                                    <span class="is-size-4 has-text-weight-semibold has-text-primary"> {{clientData.title}}  {{ clientData.full_name }} </span>
                                </div>
                                <div class="title-container-two">
                                    <span class="has-text-weight-medium gray-txt mr-4">{{clientData.nic}}</span>
                                    <span style="border-left:2px solid gray; width:0%" ></span>
                                   
                                    <span class="has-text-weight-medium gray-txt" style="padding-left:20px">{{clientData.sex}}</span>
                                    
                                    
                                </div>
                                <span class="has-text-weight-medium gray-txt">
                                    Approved
                                </span>
                        </div>
                        </div>
                        <div class="info-container">
                          <div class="application-sub-header">Account Information</div>

                                  <SpanFields
                                     fieldTitle="Account Type"
                                     :fieldValue="clientData.account_type"
                                  />
                                    <SpanFields
                                    fieldTitle="Individual acc: type"
                                    :fieldValue="clientData.applicant_individual_account_type"
                                    />
                                    <SpanFields
                                    fieldTitle="Fixed Deposit acc: type"
                                    :fieldValue="clientData.account_type"
                                    />
                                    <SpanFields fieldTitle="Customer CIF" fieldValue="CIF" />

                                    <SpanFields
                                    fieldTitle="PEP"
                                    :fieldValue="clientData.kyc_is_customer_pep"
                                    />

                            <!-- <div class="application-sub-headers" style="margin-top:25px">Debit card Info</div>

                                    <SpanFields
                                    fieldTitle="Mother's Maiden Name"
                                    :fieldValue="clientData.debit_Security_question"
                                    
                                    />           -->

                        </div> 
                    </div>
                    <div style="width:50%; margin:0 15px 0 0">
                        <div class="dynamic-container">
                             <div class="application-sub-header">FD Account Info</div>

                             <SpanFields
                                     fieldTitle="FD Period"
                                     :fieldValue="clientData.fd_period"
                                  />
                            <SpanFields
                                fieldTitle="Deposit Value"
                                :fieldValue="clientData.fd_deposit"
                            />
                            <SpanFields
                                fieldTitle="Mode of intrest payment"
                                :fieldValue="clientData.fd_interest_payable_at"
                            />
                            <SpanFields fieldTitle="Interest payable method" :fieldValue="clientData.fd_interest_disposal_method" />

                            <SpanFields
                                fieldTitle="Interest Rate"
                                :fieldValue="clientData.fd_rate"
                            />

                        <div style="width:95%; margin:0 auto">
                            <label style="font-weight:500; font-size:16px">Certificate ID  <span class="has-text-danger">*</span></label>
                                <b-field>
                                    <b-input v-model="certificateId" >
                                    </b-input>
                                </b-field>

                        </div>
                            <div style="margin: 25px auto; width: 95%">
                             <b-checkbox v-model="isVarifyed" size="is-small" ><span class="has-text-primary check-box-text">The Digitals certificate was issued by the bank.</span></b-checkbox>
                            </div>
                        
                            <div class="download-btn">
                                <b-button style="padding: 25px; font-size: 20px" icon-left="certificate-outline" type="is-primary" expanded :disabled="isVarifyed==false||certificateId.trim()==''" @click="goToNextStep()">Issue Certificate</b-button>
                            </div>  
                        </div>
                    </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import SpanFields from "../fields/SpanFields.vue"
import { appSettingsStorage } from "@/appSettingsStorage";

export default{
    name : 'FdCardIssueModal',
    props : ['isFdModalActive', 'clientData'],
    // emits : ['certificateIssue'],

    components :{
        SpanFields
    },

    data() {
        return {

            isVarifyed : false,
            certificateId : "",
            baseUrl: appSettingsStorage.baseUrl, 
        }
    
    },

    methods:{
        goToNextStep(){
            // this.isFdModalActive = false;
            this.$emit('next-step', {certificateId : this.certificateId})
            
        },

        closeModal(){
            this.$emit('close-modal', false)
        },

        // nextStepHandler(){
        //     // call axios inside 1this function
        //     this.$emit('next-step', true)
        // }


    },

   computed: {
    fdModalActive() {
        // console.log("isFdModalActive:", this.isFdModalActive);
        return this.isFdModalActive;
    }
    }


 
    
}

</script>

<style scoped>

.main-container{
    background: #ffff;
    width: 950px;
    height: 600px;
    border-radius: 32px ;
    margin-top: 55px;
    
    /* display: flex;
    justify-content: space-between; */
}

.check-box-text{
    font-size: 15px;
    font-weight: 500;
}

.round-image-box {
  border-radius: 80%;
  border: 2px solid #009ec2;
  width: 140px;
  height: 120px;
  margin-right: 10px;
}

.title-container-one{
    padding-bottom: 8px;
    border-bottom: 1px solid gray;

}
.title-container-two{
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

}

.title-container-two span{
    width: 100%;
}

.header-container{
    height: 10%; 
    /* background-color: #474eaf; */
    display: flex;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 10px 35px;
    justify-content: space-between;
    background: #edf0f0;
    /* border-radius: 32px */
       border-top-left-radius: 32px;
    border-top-right-radius: 32px;

}
.profile-container{
    display: flex;
    margin: 20px 30px 30px 5px;
}

.body-container{
    display: flex;
    height: 90%;
    padding-left: 15px;
}

.dynamic-container{
    width: 98%;
    height: 90%;
    border: 1.5px solid #a1a1a1;
    margin : 5% auto;
    border-radius: 10px;
    padding: 15px;
}

.download-btn{
    width: 95%;
    margin: 20px auto;
}

.application-sub-header{
    font-size: 16px;
    font-weight: 700;
    color: #0083ca;
}
</style>