<template>
    <!-- <div class="column">
        <div class="columns">
            <div class="column" style="margin: 0; padding: 0">
                <b-icon icon="check-circle" type="is-success" style="margin: 0; padding: 0"></b-icon>
            </div>
            <div class="column is-size-7 has-text-weight-bold" style="margin: 0; padding: 0">
                Card Issued
            </div>
            <div class="column" style="margin: 0; padding: 0">
                <b-icon icon="check-circle" type="is-success"></b-icon>
            </div>
        </div>
    </div> -->
    <div class="icon-text">
        <span class="mdi mdi-check-circle"></span>
        <span>Card Issued</span>
        <span class="mdi mdi-check-circle"></span>


    </div>
</template>

<script>
export default {

    name: "IssuedItem"
}

</script>

<style></style>