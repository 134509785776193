var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0px 20px"}},[_c('div',{staticClass:"columns is-multiline",staticStyle:{"margin-top":"2.5vh"}},[_c('div',{staticClass:"column",staticStyle:{"text-align":"right"}},[(_vm.hasPermission('role-create'))?_c('b-button',{staticClass:"float-right",staticStyle:{"margin-top":"10px","margin-bottom":"0px"},attrs:{"type":"is-primary"},on:{"click":_vm.navigateTo}},[_vm._v("Create Role")]):_vm._e()],1)]),_c('div',{staticStyle:{"height":"60vh","margin-top":"-88px"}},[_c('TableComponent',{attrs:{"columns":_vm.displayedColumns,"height":"60vh","tableData":_vm.dataList,"isHidden":true,"isLoading":_vm.isLoading,"objectList":_vm.filterList,"type":"text"},on:{"tableFilter":() => {
          _vm.roleFilterRequestHandler();
        },"inputChange":() => {
          _vm.valueOnChangee();
        },"resetFilter":() => {
          _vm.resetFilter();
        }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }