<template>
    <div class="main-modal">
        <b-modal v-model="modalActive">
        <div class="main-container">
            <div class="container-one">
               <div class="has-text-centered"><span class="is-size-3 has-text-weight-bold has-text-primary has-text-centered">Verify <span style="font-weight:500;">Your</span> Customer</span></div> 
                <img :src="require(`../../../assets/images/${imageName}`)">
            </div>

            <div class="container-two">
                <b-button @click="closeModal" class="is-pulled-right close-btn" style="border:none;">
                    <b-icon icon="close" size="16px" style="color: #757575; cursor: pointer; " class="b-icon" onMouseOver="this.style.color='black'" onMouseOut="this.style.color='gray'"></b-icon>
                </b-button>
                <div class="has-text-primary has-text-centered is-size-4 has-text-weight-semibold" style="margin-top:40px">Verify Mobile Number</div>
                <div class="has-text-centered is-size-6 mt-2" style="color:#757575;">Please enter the 6-digit code sent to <br>your<span class="has-text-weight-semibold"> {{ clientNo }} </span> </div>
                <div class="otp-container">
                    <input
                    v-for="index in otpLength"
                    :key="index"
                    maxlength="1"
                    class="otp-field"
                    :ref="'otpDigit' + index"
                    v-model="otpCode[index]"
                    @input="otpInputHandler(index, $event)"
                    @keyup.enter="triggerVerifyButton"
                    />
                </div>


            <div class="has-text-centered"
            style="color: #757575; width: 85%; margin: 18px auto; font-weight:300px; font-size:14px">
            Resend OTP In : <span style="font-size:16px; color:#0083CA; font-weight:600;">{{ countDown }}s</span>
                 </div>


                <div class="button-container">
                    <b-button type="is-primary" style="font-size:16px" @click="$emit('reSendOtp',clientNo)" expanded inverted class="mb-2" :disabled="!isTimeout">Resend OTP</b-button>
                    <b-button type="is-primary" style="font-size:18px; border-radius:7px;" size="is-medium" @click="$emit('verifyOtp',otpCode)" expanded>Verify</b-button>
                </div>

                <!-- <ActionButton
            type="is-primary"
            buttonLabel="Verify"
            expanded=" expanded"
            size="is-medium"
            :buttonAction="validateAndSendOtp"
            divStyles="input-felds-styles-buttons"
            :loading="isButtonLoading"
          /> -->
            </div>
            <div>

            </div>



        </div>
        </b-modal>
    </div>
</template>

<script>
// import ActionButton from '../buttons/ActionButton.vue'
import NetworkManager from "@/network";
import BuefyHelper from "@/helpers/bufeyHelper";
import CardIssueModal from "./CardIssueModal.vue";

export default{
    name : 'ApplicationSupportModal',
    props  : ['isModalActive', 'clientNo', "countDown", "isTimeout", "otpCode", "imageName"],
    emits : ['reSendOtp', 'verifyOtp'],
    components:{
        // ActionButton,
        CardIssueModal,
    },

    data() {
        return{
            otpLength : 6,
           
            // otpCode : Array(6).fill(''),
            // isCardModalActive : false,
            // countDown: 30,
            // isTimeout: false,
      
            
        }
    },

    computed: {
        modalActive() {
        return this.isModalActive;
        },
        
    },


    mounted(){
        // console.log('refssss',this.$refs);
    },
    methods:{
        closeModal(){
            this.$emit('update:isModalActive', false);
        },

        triggerVerifyButton() {
            this.$emit('verifyOtp', this.otpCode);
        },


      otpInputHandler(index, event) {
      
    //   if (index < this.otpLength - 1 && event.target.value !== '') {
        setTimeout(() => {
          const nextInput = document.querySelector(`.otp-field:nth-child(${index + 1})`);
          if (nextInput) {
            nextInput.focus();
          }
        }, 0);
    //   }
    },

}
}

</script>

<style scoped>

.main-container{
    background: #ffff;
    width: 870px;
    height: 480px;
    border-radius:28px;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    
}


.container-one{
    width: 40%;
    padding: 0 15px;
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
    padding: 40px 

    
    /* display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: space-around; */
  
}

/* .container-two{
    padding: 50px 0 0 0
} */

.otp-field{
    width: 50px;
    height: 50px;
    margin: 0 5px;
    border-radius: 5px;
    background-color: #FAFAFA;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(92, 90, 90, 0.19);
    text-align: center;
    font-size: 24px;
    font-weight: bold
}

.main-container :-webkit-scrollbar{
  display: none;
}

.main-container 

.container-one img{
    width: 100%;
    margin-top: 70px 
}

.otp-container{
    margin-top:50px
}

.button-container{
    width: 70%;
    margin: 60px auto
}

.close-btn {
    position: relative;
    top: 10px;
    left:80px;
    margin-left:200px;
    margin-right:10px;
}


/* .container-one div{
    padding: 0 15px
} */

</style>