<template>
  <div style="padding: 0px 20px">
    <div class="columns is-multiline" style="margin-top: 2.5vh">
      <!-- <div class="column">
        <label style="color: gray; font-weight: 400; font-size: 23px">Role</label>
      </div> -->
      <div class="column" style="text-align: right ">
        <b-button
        style="margin-top: 10px; margin-bottom: 0px;"
          v-if="hasPermission('role-create')"
          type="is-primary"
          @click="navigateTo"
          class="float-right"
          >Create Role</b-button
        >
      </div>
    </div>
    <div style="height: 60vh; margin-top: -88px;">
      <TableComponent
        :columns="displayedColumns"
         height="60vh"
        :tableData="dataList"
        :isHidden="true"
        :isLoading="isLoading"
        :objectList="filterList"
        @tableFilter="
          () => {
            roleFilterRequestHandler();
          }
        "
        @inputChange="
          () => {
            valueOnChangee();
          }
        "
        @resetFilter="
          () => {
            resetFilter();
          }
        "
        type="text"
      />
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import TableComponent from "@/components/TableComponent.vue";
import { FilterInputFiled } from "@/helpers/FilterInputFiled";
import DateConverter from "@/helpers/DateConverter";
// import NavBar from "@/layout/components/NavBar";
// import SideBar from "@/layout/components/SideBar";

export default {
  name: "UserList",
  components: {
    TableComponent,
    // NavBar,
    // SideBar
  },
  data() {
    return {
      columns: [
        {
          field: "role_name",
          label: "Role",
        },
        {
          field: "permissions",
          label: "Permissions",
        },
        {
          field: "disabled",
          label: "Status",
        },
        {
          field: "created_at",
          label: "Created At",
          width: "130",
        },
        {
          field: "updated_at",
          label: "Updated At",
          width: "130",
        },
        {
          field: "Roleaction",
          label: "Action",
           width: "80",
        },
      ],
      dataList: [],
      valueOne: "",
      isLoading: true,
      isFullPage: false,

      filterList: [
        new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("Search", "", "", "BUTTON"),
        new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
      ],
    };
  },
  computed: {
    displayedColumns() {
      const hasUserCreatePermission = this.hasPermission(
        "role-create" || "role-edit" || "role-delete"
      );
      return hasUserCreatePermission
        ? this.columns
        : this.columns.filter((column) => column.field !== "Roleaction");
    },
  },
  methods: {
    valueOnChangee(value) {
      const comp = this;
      // console.log("Parent value chnage ", value);
      comp.valueOne = value;
    },
    navigateTo() {
      this.$router.push({ name: "create-role" });
    },

    roleFilterRequestHandler: function () {
      this.isLoading=true;
      NetworkManager.apiRequest(
        "backend/roles/filterRoles",
        {
          from_date: DateConverter.dateFormater(this.filterList[0].value),
          to_date: DateConverter.dateFormater(this.filterList[1].value),
        },
        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
          });
          this.dataList = e.data.list;
          // console.log("this.dataList ", e.data);
          this.isLoading=false;
        },
        false
      );
    },

    resetFilter: function () {
      this.isLoading=true;
      NetworkManager.apiRequest(
        "backend/roles/list",
        {},
        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
          });
          this.dataList = e.data.list;
          // Reset filter fields
          this.filterList.forEach((field) => {
                field.value = "";
                this.isLoading=false;
          });
        },
        false
      );
    },

    filterButtonStateHandler : function(){
      if(this.filterList[0].value !== "" || this.filterList[1].value !== ""){
        this.filterList[2].isButtonDisabled = false
        this.filterList[3].isButtonDisabled = false
      }
      else{
        this.filterList[2].isButtonDisabled = true
        this.filterList[3].isButtonDisabled = true
      }
    }
  },

  watch : {
    filterList: {
      handler(){
        this.filterButtonStateHandler()
      },
      deep: true

    }
  },

  mounted() {
    this.filterButtonStateHandler();
    NetworkManager.apiRequest("backend/roles/list", {}, (e) => {
      if (e.success === true) {
        this.isLoading = false;
        // console.log("role list", e);
        e.data.list.map((data) => {
          data.updated_at = DateConverter.dateFormater(data.updated_at);
          data.created_at = DateConverter.dateFormater(data.created_at);
        });
        this.dataList = e.data.list;
        // console.log("this.dataList ", this.columns);
      } else {
        this.isLoading = false;
      }
    });
  },
};
</script>
