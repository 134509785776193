<template>
    <div :class="divStyles">

        <b-button @click="buttonAction" :type="type" :class="buttonStyles" :expanded="expanded" :size="size"
            :disabled="disabled" :inverted="inverted" :icon-left="iconLeft" :icon-right="iconRight" :loading="loading" @Click="$emit('buttonEmitAction')">{{
                buttonLabel 
            }}</b-button>

    </div>
</template>

<script>
export default {
    name: "ActionButton",

props: ["buttonLabel", "divStyles", "expanded", "size", "buttonAction", "iconLeft", "type", "buttonStyles", "iconRight", "inverted", "disabled", "loading", ],
emits : ["buttonEmitAction"]
}


</script>