<template>
  <div
    style="
      max-height: 100%;
      min-height: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
    "
    class="pending-table-container"
  >
    <div
      style="
        width: 100%;
        color: gray;
        font-weight: 400;
        font-size: 15px;
        margin: 10px auto 0 auto;
        font-family: Poppins;
      "
    >
      <!-- Data table with full feature -->
    </div>

    <TableComponent
      :columns="columns"
      height="52vh"
      :tableData="pendingList"
      :isLoading="isLoading"
      :objectList="filterFields"
      @tableFilter="
        () => {
          tableFilterRequestHandler();
        }
      "
      @resetFilter="
        () => {
          resetFilter();
        }
      "
      @applicationViewer="pendingApplicationViewer"
    />
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import NetworkManager from "@/network";
import { FilterInputFiled } from "@/helpers/FilterInputFiled";
import { useAuthStore } from "@/store/authStore";
import DateConverter from "@/helpers/DateConverter";

export default {
  name: "PendingApplications",
  components: {
    TableComponent,
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      pendingList: [],

      columns: [
        {
          field: "flag",
          label: "",
        },
        {
          field: "id",
          label: "Application ID",
        },
        // {
        //   field: "branch_name",
        //   label: "Branch Name",
        // },
        {
          field: "branch_code",
          label: "Branch Code",
        },
        // {
        //   field: "applicant_individual_account_type",
        //   label: "Product Type",
        // },
        {
          field: "product_code",
          label: "Product Code",
        },
        {
          field: "account_number",
          label: "Account Number",
        },
        {
          field: "account_type",
          label: "Account Ownership",
        },
        {
          field: "title",
          label: "Title",
        },
        {
          field: "full_name",
          label: "Full Name",
        },
        {
          field: "nic",
          label: "NIC",
        },
        // {
        //   field: "primary_mobile_number",
        //   label: "Mobile Number",
        // },
        {
          field: "updated_at",
          label: "Updated Time",
        },
        {
          field: "created_at",
          label: "Applied Time",
        },

        {
          field: "signed",
          label: "Signed",
        },

        {
          field: "application_status",
          label: "Status",
        },
        {
          field: "applicationView",
          label: "Actions",
        },
      ],
      filterFields: [
        new FilterInputFiled(
          "Select Application Status",
          "",
          ["Pending", "Approved", "Reject", "Request To Improve"],
          "SELECT"
        ),
        new FilterInputFiled(
          "Product Type",
          "",
          ["All", "Savings Account", "Fixed Deposit", "Minor Account"],
          "SELECT"
        ),
        new FilterInputFiled("Branch", "", [], "SELECT"),
        new FilterInputFiled("NIC", "", "", "INPUT"),
        new FilterInputFiled(
          "Account Ownership",
          "",
          ["Individual", "Joint"],
          "SELECT"
        ),
        new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
        new FilterInputFiled("Search", "", "", "BUTTON"),
        new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
      ],
      isLoading: true,
      isFullPage: false,
      // isTableComponentLoading: false
    };
  },

  watch: {
    filterFields: {
      handler() {
        this.filterButtonStateHandler();
      },
      deep: true,
    },
  },

  methods: {
    tableFilterRequestHandler: function () {
      this.isLoading = true;
      NetworkManager.apiRequest(
        "backend/applicant/filterApplicantList",
        {
          application_status:
            this.filterFields[0].value === "Pending"
              ? "Pending"
              : this.filterFields[0].value,
          acc_type:
            this.filterFields[1].value === "All"
              ? ""
              : this.filterFields[1].value,
          branch:
            this.filterFields[2].value === "All"
              ? ""
              : this.filterFields[2].value,
          nic: this.filterFields[3].value,
          account_type:
            this.filterFields[4].value === "All"
              ? ""
              : this.filterFields[4].value,
          from_date: this.filterFields[5].value,
          to_date: this.filterFields[6].value,
        },
        (e) => {
          // console.log(e.data.list)
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
            if (data.application_approved_date != null) {
              data.application_approved_date = DateConverter.dateFormater(
                data.application_approved_date
              );
            }
          });
          this.pendingList = e.data.list;
          this.isLoading = false;
          // console.log("this.dataList ", e.data);
        },
        false
      );
    },

    filterButtonStateHandler: function () {
      if (
        this.filterFields[0].value !== "" ||
        this.filterFields[1].value !== "" ||
        this.filterFields[2].value !== "" ||
        this.filterFields[3].value !== "" ||
        this.filterFields[4].value !== "" ||
        this.filterFields[5].value !== "" ||
        this.filterFields[6].value !== "" 
      ) {
        // Check the additional conditions for filterFields[5] and filterFields[6]
        if ((this.filterFields[5].value !== "" && this.filterFields[6].value === "") ||
            (this.filterFields[5].value === "" && this.filterFields[6].value !== "")) {
          this.filterFields[7].isButtonDisabled = true;
          this.filterFields[8].isButtonDisabled = true;
        } else {
          this.filterFields[7].isButtonDisabled = false;
          this.filterFields[8].isButtonDisabled = false;
        }
      } else {
        this.filterFields[7].isButtonDisabled = true;
        this.filterFields[8].isButtonDisabled = true;
      }
    },

    resetFilter: function () {
      this.isLoading = true;
      NetworkManager.apiRequest(
        "backend/applicant/getApplicantDataList",
        {},
        (e) => {
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
            if (data.application_approved_date != null) {
              data.application_approved_date = DateConverter.dateFormater(
                data.application_approved_date
              );
            }
          });

          this.pendingList = e.data.list;
          this.isLoading = false;
        },
        false
      );
      // Reset filter fields
      this.filterFields.forEach((field) => {
        field.value = "";
      });
    },

    pendingApplicationViewer: function (tableValue) {
      if (tableValue.application_status === "pending") {
        const route = this.$router.resolve({
          name: "pending-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc },
        });
        window.open(route.href, "_blank");
      }
      if (tableValue.application_status === "Reject") {
        const route = this.$router.resolve({
          name: "rejected-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc },
        });
        window.open(route.href, "_blank");
      }
      if (tableValue.application_status === "Approved") {
        const route = this.$router.resolve({
          name: "approved-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc },
        });
        window.open(route.href, "_blank");
      }
      if (tableValue.application_status === "Request To Improve") {
        const route = this.$router.resolve({
          name: "req-to-improve-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc },
        });
        window.open(route.href, "_blank");
      }
      if (tableValue.application_status === "Reviewed") {
        const route = this.$router.resolve({
          name: "reviewed-applications-view",
          params: { id: tableValue.id, jointAcc: tableValue.joint_acc },
        });
        window.open(route.href, "_blank");
      }
    },
  },

  mounted() {
    NetworkManager.apiRequest(
      "backend/applicant/getApplicantDataList",
      {},
      (e) => {
        if (e.success === true) {
          this.isLoading = false;
          // console.log(e.data.list)
          e.data.list.map((data) => {
            data.updated_at = DateConverter.dateFormater(data.updated_at);
            data.created_at = DateConverter.dateFormater(data.created_at);
          });
          this.pendingList = e.data.list;
          // console.log("this.dataList ", e.data);
        } else {
          this.isLoading = false;
        }
      },
      false
    );

    // NetworkManager.apiRequest(
    //   "backend/branch/list",
    //   {},
    //   (e) => {
    //     console.log("e.data", e.data);

    //     e.data.list.filter((branchData) => {
    //       this.branchList.push(branchData.branch_name);
    //     });
    //     this.filterFields[1].options = this.branchList;
    //   },
    //   true
    // );

    this.branchListLoader(this.filterFields[2].options);
  },
};
</script>
