<template>
     <div class="notification-div" style="backgroundColor : '#c3e7b7'">
        <!-- <img :src="applicationType === 'Approved' ? '../../../assets/images/Success.png' : applicationType === 'Rejected' ? '../../../assets/images/Reject.png' : '' "  /> -->

 
        <div style="display: flex; flex-direction: column; align-items: center;">
              <!-- <span class="has-text-weight-semibold is-size-5" style="color : #62AF47">{{ applicationMessage }}</span> -->
              <span  class="is-size-6 has-text-weight-medium" style="color: gray;">Debit Card Number</span>
              <span class="has-text-weight-semibold is-size-5" style="background-color: aliceblue; width: 100%; text-align: center; border-radius: 5px; margin-top: 5px;">{{ debitCardNumber }}</span>
        </div>
      </div>
</template>

<script>
export default {
    name : 'DebitCardIssueCard',
    props : ["debitCardNumber"],

    data(){
        return{
          
        }
    },


    // computed: {

    //     getImageSourcePath(){
    //         if(this.applicationType === 'Approved'){
    //           return '../../../assets/images/Success.png'
    //         }
    //         else if(this.applicationType === 'Rejected'){
    //             return '../../../assets/images/Reject.png'
    //         }
    //     }

    // }
    
}
</script>

<style>
    .notification-div{
        background-color: #75dd5163;
        display: flex;
        padding: 15px 30px;
        border-radius: 8px;
        justify-content: space-around;
        display: flex;
        margin-bottom: 10px;
        align-items: center
    }


    .notification-div img{
        width: 25%;
    }

</style>