<template>
  <div class="main-box">
    <div class="sub-box-one">
      <div class="column is-half">
        <div class="columns is-gapless">
          <div class="column is-narrow">
            <img class="round-image-box" :src="baseUrl + smsData.customer_img" />
          </div>
          <div class="column">
            <div class="column is-full is-size-4 full-name">
              {{ smsData.full_name ? smsData.full_name : "-" }}
            </div>
            <hr style="margin: 0" />
            <div class="column">
              <div class="columns mb-0">
                <div class="column personal-info">
                  {{ smsData.nic ? smsData.nic : "-" }}
                </div>
                <div class="column personal-info" style="flex-grow: 0">
                  <span class="vl"></span>
                </div>
                <div class="column personal-info">
                  {{ smsData.sex ? smsData.sex : "-" }}
                </div>
              </div>
              <div class="column" style="width: 235px; padding-top: 0; padding-left: 0">
                <div class="personal-info-status">
                  {{ smsData.applicant_status ? smsData.applicant_status : "-" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <span class="application-sub-headers">Personal Info</span>
        <SpanFields
          fieldTitle="Date of Birth"
          :fieldValue="smsData.birth_date + '/' + smsData.birth_month + '/' + smsData.birth_year"
        />
        <SpanFields
          fieldTitle="Primary Mobile"
          :fieldValue="smsData.primary_mobile_number ? smsData.primary_mobile_number : '-'"
        />
        <SpanFields
          fieldTitle="Email"
          :fieldValue="smsData.email ? smsData.email : '-'"
        />
        <SpanFields
          fieldTitle="Address"
          :fieldValue="smsData.address_1 + ' , ' + smsData.address_2 + ' , ' + smsData.address_3 + ' , ' + smsData.address_4"
        />
      </div>

      <div class="column">
        <span class="application-sub-headers">Account Info</span>
        <SpanFields
          fieldTitle="Account Ownership"
          :fieldValue="smsData.account_ownership ? smsData.account_ownership : '-'"
        />
        <SpanFields
          fieldTitle="Individual acc: type"
          :fieldValue="smsData.applicant_individual_account_type ? smsData.applicant_individual_account_type : '-'"
        />
        <SpanFields
          fieldTitle="Savings acc: type"
          :fieldValue="smsData.savings_account_type ? smsData.savings_account_type : '-'"
        />
        <SpanFields
          fieldTitle="Account Number/s"
          :fieldValue="smsData.account_number ? smsData.account_number : '-'"
        />
        <SpanFields
          fieldTitle="Customer CIF"
          :fieldValue="smsData.customer_cif ? smsData.customer_cif : '-'"
        />
      </div>

      <div class="column">
        <span class="application-sub-headers">SMS Facility Info</span>
        <div v-if="smsData.sms_status === 'Activated' || smsData.sms_status === 'Reject'">
          <SpanFields
            fieldTitle="SMS Facility"
            :fieldValue="smsData.sms_status ? smsData.sms_status : '-'"
          />
        </div>
        <SpanFields
          fieldTitle="Mobile Number"
          :fieldValue="smsData.primary_mobile_number"
        />
      </div>

      <div class="column">
        <span class="application-sub-headers">Application Submitted By</span>
        <SpanFields
          fieldTitle="BDO"
          :fieldValue="smsData.submitted_by_email ? smsData.submitted_by_email : '-'"
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="smsData.submitted_user_epf ? smsData.submitted_user_epf : '-'"
        />
        <SpanFields
          fieldTitle="Branch"
          :fieldValue="smsData.submitted_user_branch ? smsData.submitted_user_branch : '-'"
        />
        <SpanFields
          fieldTitle="App Version"
          :fieldValue="version"
        />
        <SpanFields
          fieldTitle="Location"
          :fieldValue="smsData.submitted_location ? smsData.submitted_location : '-'"
        />
      </div>

      <!-- SMS Status Handling -->
      <div v-if="smsData.sms_status === 'Pending'">
        <div class="checkbox-button notification is-info mt-0 info-class">
          <b-checkbox
            class="application-checkbox specific"
            v-model="is_signature_verified"
            @input="updateSignatureVerification"
            :disabled="isDisabled"
          >Verify Customer Signature</b-checkbox>
        </div>

        <b-button
          id="approveBtn"
          size="is-medium"
          type="is-primary"
          expanded
          class="mb-2"
          :disabled="(!is_signature_verified || smsData.sms_status === 'Reject' || areDisabled)"
          @click="updateSmsStatus('Activated')"
        >Approve</b-button>

        <div v-if="notificationMessage" class="notification is-success">
          {{ notificationMessage }}
        </div>

        <b-button
          id="rejectBtn"
          size="is-medium"
          type="is-danger"
          expanded
          class="mb-2"
          :disabled="(!is_signature_verified || smsData.sms_status === 'Activated' || areDisabled)"
          @click="updateSmsStatus('Reject')"
        >Reject</b-button>
      </div>

      <div v-if="smsData.sms_status === 'Activated'">
        <div class="checkbox-button notification is-primary mt-0 info-class">
          <b-checkbox
            class="application-checkbox specific"
            :value="smsData.is_signature_verified == 1 ? true : false"
            disabled
          >Customer Signature Verified</b-checkbox>
        </div>
        <b-button
          id="approvedBtn"
          size="is-medium"
          type="is-primary"
          expanded
          class="mb-2"
        >Approved</b-button>
      </div>

      <div v-if="smsData.sms_status === 'Reject'">
        <b-button
          id="rejectedBtn"
          size="is-medium"
          type="is-danger"
          expanded
          class="mb-2"
        >Rejected</b-button>
      </div>

      <div v-if="smsData.sms_status !== 'Pending'">
        <span class="application-sub-headers">Signature Verified by</span>
        <SpanFields
          fieldTitle="Verified by"
          :fieldValue="smsData.signature_approved_by ? smsData.signature_approved_by : '-'"
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="smsData.signature_approved_epf ? smsData.signature_approved_epf : '-'"
        />
        <SpanFields
          fieldTitle="Branch"
          :fieldValue="smsData.signature_approved_branch ? smsData.signature_approved_branch : '-'"
        />

        <div v-if="smsData.sms_status === 'Activated'">
          <span class="application-sub-headers">Approved by</span>
          <SpanFields
            fieldTitle="Approved by"
            :fieldValue="smsData.application_approved_by ? smsData.application_approved_by : '-'"
          />
          <SpanFields
            fieldTitle="EPF"
            :fieldValue="smsData.application_approved_epf ? smsData.application_approved_epf : '-'"
          />
          <SpanFields
            fieldTitle="Branch"
            :fieldValue="smsData.application_approved_branch ? smsData.application_approved_branch : '-'"
          />
        </div>

        <div v-if="smsData.sms_status === 'Reject'">
          <span class="application-sub-headers">Rejected by</span>
          <SpanFields
            fieldTitle="Rejected by"
            :fieldValue="smsData.application_approved_by ? smsData.application_approved_by : '-'"
          />
          <SpanFields
            fieldTitle="EPF"
            :fieldValue="smsData.application_approved_epf ? smsData.application_approved_epf : '-'"
          />
          <SpanFields
            fieldTitle="Branch"
            :fieldValue="smsData.application_approved_branch ? smsData.application_approved_branch : '-'"
          />
        </div>
      </div>

      <!-- Update Info Section -->
      <span class="application-sub-headers">Update Info</span>
      <SpanFields
        fieldTitle="Applied Date"
        :fieldValue="formattedCreatedAt"
      />
      <div v-if="smsData.sms_status === 'Activated'">
        <SpanFields
          fieldTitle="Approved Date"
          :fieldValue="formattedUpdatedAt"
        />
      </div>
      <div v-if="smsData.sms_status === 'Reject'">
        <SpanFields
          fieldTitle="Rejected Date"
          :fieldValue="formattedUpdatedAt"
        />
      </div>
    </div>
         
   <div class="sub-box-two">
    <div style="display: flex; flex-direction: row-reverse" class="my-3">

    <!-- <b-button>Download PDF</b-button> -->
        <b-button
          @click="downloadPdf()"
          type="is-primary"
          iconRight="file-download-outline"
          class="ml-2"
          >Download PDF</b-button
        >
      </div>

      <!-- comment field add-->
      <div class="column notification"> 
        <SmsCommentField :smsCommentArray="sms_comments" />
        <div class="column">Add Comment</div>
        <div class="columns">
          <div class="column is-four-fifths">
            <b-input
              type="text"
              placeholder="Comment"
              v-model="userComment"
              maxlength="134"
            />
          </div>
          <div class="column">
            <b-button
              :disabled="userComment === ''"
              type="is-primary"
              @click="smsUserCommentSubmitHandler(userComment)"
              >Comment</b-button
            >
          </div>
        </div>
      </div>

      <div class="column notification"> 
        
      <div class="column">
        <span class="application-sub-headers">Terms & Conditions</span>
        <p> Terms and Conditions pertaining to opening & maintaining savings deposit
            accounts and other banking facilities.</p>
        <b-checkbox size="is-small"> I have agreed with general T & C</b-checkbox>
      </div>
      </div>


      <div class="column container application-view-media-warp-box">
        <div class="column is-size-3">Captured Information</div>
         <div class="column">
          <div style="width: 40%; height: 50%">
            <p>Signature Image </p>
            <img class="square-image-box" :src="baseUrl + this.smsData.customer_signature" />
          </div>
           
            <br />
            <br />
              <!-- <p><a :href="`${baseUrl}/terms_pdf/terms_and_conditions.pdf`">Terms and Conditions pertaining to opening & maintaining savings deposit
                accounts and other banking facilities</a></p> -->
         </div>
        </div>
    </div>
  </div>
  
    
  
</template>
  
  <script>
  import SpanFields from "../components/support/fields/SpanFields.vue";
  import NetworkManager from "@/network";
  import BuefyHelper from "@/helpers/bufeyHelper";
  import { appSettingsStorage } from "@/appSettingsStorage";
  import { appSettings } from "@/appSettings"
  import ImageContainerCard from "@/components/support/Cards/ImageContainerCard.vue";
  import SmsCommentField from "./support/fields/SmsCommentField.vue";
  import DateConverter from "@/helpers/DateConverter";


  export default {
    name: "SMSActivationView",

    props:[
        "smsComments" ,
    ],
    components: {
      SpanFields,
      SmsCommentField,
    },

    data(){
      return {
        smsData: null,
        baseUrl: appSettingsStorage.baseUrl,
        userComment: "",
        userCommentArray: [],
        sms_comments: [],   
        usingTheSDBServer: false,
        customerCif: null,
        version: appSettings.version,
        is_signature_verified:false,
        isDisabled: false,
        notificationMessage: '',
        areDisabled: false,


      };
    },
      computed: {
      formattedCreatedAt() {
        return DateConverter.dateFormater(this.smsData.created_at);
      },
      formattedUpdatedAt() {
        return DateConverter.dateFormater(this.smsData.updated_at);
      }
    },
    watch: {
      smsData: {
        handler() {
          this.getCustomerCif();
        },
        deep: true, // Watch for nested changes in smsData
    },
    },

    mounted() {
      this.sendSmsNotificationById();
      this.getCustomerCif();
    },

    methods: {   
      downloadPdf() {
      const comp = this;
      NetworkManager.apiRequest(
        "backend/applicant/pdfDownload",
        {
          id: this.smsData.id,
          pdf_type: "sms",
        },
        (e) => {
        },
        false
      );
      comp.$router.push({
        name: "SmsActivationPdf",
        query: { id: this.smsData.id },
        
      });
    },
    smsUserCommentSubmitHandler(sms_comment) {
      if (!sms_comment.trim()) {
        this.$buefy.toast.open({
          message: "Please enter a comment",
          type: "is-danger",
        });
        return;
      }

      NetworkManager.apiRequest(
        "backend/applicant/addCommentForSmsApplication",
        { sms_comment: sms_comment, id: this.smsData.id },
        (e) => {
          // Comment added successfully
          this.$buefy.toast.open({
            message: "Comment added successfully",
            type: "is-success",
          });
          window.location.reload();
        },
        true
      );
    },

    getCustomerCif(){
      if(appSettings.usingSDBServer == true){
        this.usingTheSDBServer = true;
        NetworkManager.apiRequest(
          "backend/sdb/getCustomerInfoUsingNic",
          { id: this.$route.params.id },
          (e) => {
            if (e.success === true) {
              // console.log("success getCustomerInfoUsingNic", e.data.CustomerDetailList[0].Customer_Number);
              this.customerCif = e.data.CustomerDetailList[0].Customer_Number;
              this.saveCustomerCif(this.customerCif);
            } else if (e.success === false) {
              // console.log("failed getCustomerInfoUsingNic");
              this.customerCif = null
            }
          },
          false
        );
      }
    },
    saveCustomerCif(cif){
      NetworkManager.apiRequest(
        "backend/applicant/saveCustomerCifInSmsApplications",
        { cif: cif, id: this.$route.params.id},
        (e) => {
          if (e.success === true) {
            // console.log("CIF saved successfully")
          } else if (e.success === false) {
            // console.log("CIF saved failed");
          }
        },
        false
      );
    },
    sendSmsNotificationById(){
        
      // console.log("sendSmsNotificationById called");
        NetworkManager.apiRequest(
          "backend/applicant/sendSmsNotificationById",
          { id: this.$route.params.id},
          (e) => {
           
            if (e.success === true) {
              this.smsData = e.data[0];
              this.sms_comments = JSON.parse(e.data[0].sms_comment); 
              // console.log("success")
            } else if (e.success === false) {
              // console.log("failed")
            }else{
              // console.log("failed")
            }
          },
          false
        );
    },

    updateSignatureVerification() {
    console.log("Signature verification value before update:", this.is_signature_verified);

    NetworkManager.apiRequest(
      "backend/applicant/updateSignatureVerification",
      {
        id:this.$route.params.id,
        isSignatureVerified:1,

      },
      (e) => {
        if (e.success) {
          this.$buefy.toast.open({
            message: "Signature verification updated successfully",
            type: "is-success",
           
          });
          this.isDisabled = true;
          console.log("Signature verification value after update:", this.is_signature_verified);
        } else {
          this.$buefy.toast.open({
            message: "Failed to update signature verification",
            type: "is-danger",
          });
        }
      },
      true
    );
    },

    updateSmsStatus(status) {
    console.log("SMS status value before update:", status);

    NetworkManager.apiRequest(
      "backend/applicant/updateSmsStatusById",
      {
        id: this.$route.params.id,
        status: status,
      },
      (e) => {
        if (e.success) {
          this.$buefy.toast.open({
            message: `SMS status updated to ${status} successfully`,
            type: "is-success",
          });
          if (status === 'Activated') {
            this.notificationMessage = 'SMS registration Activated successfully.';
          } else if (status === 'Reject') {
            this.notificationMessage = 'SMS registration Rejected successfully.';
          }
          this.isDisabled = true;
          this.areDisabled = true;
          location.reload();
          console.log("SMS status value after update:", status);
        } else {
          this.$buefy.toast.open({
            message: "Failed to update SMS status",
            type: "is-danger",
          });
        }
      },
      true
    );
  }

  },

 
  };
  
  </script>
  

  <style scoped>
.round-image-box {
  border-radius: 80%;
  border: 2px solid #009ec2;
  width: 150px;
  height: 130px;
}

/* .notification-div{
  background-color: #c3e7b7;
  display: flex;
  padding: 15px 30px;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 10px;
 

} */

.checkbox-button {
  padding: 10px 35px;
  width: 100%;
  /* background-color: #0083ca; */
  border-radius: 5px;
}

.notification-div img {
  width: 85px;
}

.application-checkbox[disabled] {
  cursor: not-allowed !important; 
}

.application-checkbox:not([disabled]):hover {
  cursor: pointer !important; 
}

/* .sideOne.scroll {
        overflow-x: hidden;
        overflow-y: auto;
    } */

.main-box {
  display: flex;
  max-height: 100%;
  /* overflow-y: scroll; */
  overflow: hidden;
  padding: 5px 20px;
}

.button-container {
  width: 100%;
  /* margin-bottom: 25%/ */
  margin: 0;
}

.address-proof{
  padding-left: 0px;
  margin-left: 11px;
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.sub-box-one {
  width: 35%;
  overflow-y: scroll;
  max-height: 100%;
  padding: 25px 10px 80px 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sub-box-one::-webkit-scrollbar {
  display: none;
}

.sub-box-two::-webkit-scrollbar {
  display: none;
}

.custom {
  color: #0083ca;
  font-weight: bold;
  font-size: 14px;
}

.full-name {
  font-size: 2rem;
  font-weight: bolder;
  color: #0083ca;
}

.notification-msg {
  font-size: 0.8rem;
}

.sub-box-two {
  flex-grow: 7;
  margin-left: 15px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 100%;
  padding-bottom: 100px;
  width: 65%;
}

.personal-info {
  font-size: 1rem;
  font-weight: bold;
  color: #757575;
  width: 105%;
}
.personal-info-status{
  font-size: 1rem;
  font-weight: bold;
  color: #ec2b2b;
  width: 105%;
}

video {
  width: 100%;
  height: 94%;
  overflow: hidden;
}

.vl {
  border-left: 1px solid green;
  height: 100%;
}

.info-class{

  color: #000000;
}

.square-image-box{
  border: 2px ;
  width: 150px;
  height: 150px;
 
}
/* .column button-container{
  margin-bottom:70%;
} */
</style>

 