import JwtUtils from '@/helpers/jwtUtils'

const authMixin = {
    hasPermission: function (permission){
        try {
            let isOk = false

            const pair = JwtUtils.loadToken()
            // console.log("pair in authMixings",pair)
            const jwtObj = pair.jwtObj
            // const permissions = jwtObj.permissions
            const permissions = jwtObj.permissions.split('|');

            // console.log("permissions in authMixins", permissions)

            if(this.hasSuperPermission()){
                // console.log("has superpermissions in authMixins")
                isOk = true
            }
            else {
                // console.log("hasn't superpermissions in authMixins")
                permissions.forEach(function (item){
                // console.log("item",item)
                    if(item === permission){
                        isOk = true
                    }
                })
            }
            // console.log("final result of hasPermission in authMixins", isOk)


            return isOk
        }
        catch {
            return false
        }
    },

    hasSuperPermission: function (){
        // console.log("inside hasSuperPermission in authMixins")
        let isOk = false

        const pair = JwtUtils.loadToken()
        const jwtObj = pair.jwtObj

        // const permissions = jwtObj.permissions
        const permissions = jwtObj.permissions.split('|');

        // console.log("permissions inside hasSuperPermission in authMixins", permissions)
        try{
            permissions.forEach(function (item){
                // console.log("each permissions of hasSuperPermission in authMixins", item)
                if(item === 'super-permission'){
                    isOk = true
                }
            })
        }catch(err){
            // console.log("err in hasSuperPermission in authMixins", err)
        }
        

        // console.log("result of hasSuperPermission in authMixins", isOk)

        return isOk
    },

    authUser: function (){
        const pair = JwtUtils.loadToken()
        return pair.jwtObj
    }
}

export default authMixin
