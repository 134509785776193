import Vue from 'vue'
import VueRouter from 'vue-router'
import Pipeline from '@/helpers/middlewarePipeline'
import store from "@/store"
import routes from "@/router/router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
});

// console.log("inside router index",router)


router.beforeEach((to, from, next) => {
  // console.log("inside beforeEach method")
  // console.log("router to", to)
  // console.log("router from", from)
  const middleware = to.meta.middleware
  // console.log("middleware", middleware)
  const context = { to, from, next, router, store }
  // console.log("context", context)


  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: Pipeline(context, middleware, 1),
  });

 
});

export default router
