<template>
    <div
      style="max-height: 100%; min-height: 100%; overflow-x: scroll; overflow-y: hidden"
      class="pending-table-container"
    >
      <div
        style="
          width: 100%;
          color: gray;
          font-weight: 400;
          font-size: 15px;
          margin: 20px auto;
        "
      >
        <!-- Data table with full feature -->
      </div>
  
      <TableComponent
        :columns="columns"
         height="52vh"
        :excelColumns="excelColumns"
        :tableData="pendingList"
        :objectList="filterFields"
        :shouldShowExcelComponent="true"
        :name = name
        :isLoading="isLoading"
        @tableFilter="
          () => {
            tableFilterRequestHandler();
          }
        "
        @resetFilter="
          () => {
            resetFilter();
          }
        "
        @changeValues="
          (item) => {
            changeValues(item);
          }"
      />
    </div>
</template>
  
<script>
  import TableComponent from "@/components/TableComponent.vue";
  import NetworkManager from "@/network";
  import { FilterInputFiled } from "@/helpers/FilterInputFiled";
  import { useAuthStore } from "@/store/authStore";
  import DateConverter from "@/helpers/DateConverter";
  
  export default {
    name: "ActivityLog",
    components: {
      TableComponent,
    },
    setup() {
      const authStore = useAuthStore();
      return { authStore };
    },
  
    data() {
      return {
        pendingList: [],
  
        excelColumns: [
          // {
          //   field: "level",
          //   label: "Level",
          // },
          {
            field: "description",
            label: "Description",
          },
          {
            field: "username",
            label: "User",
          },
          {
            field: "branch_name",
            label: "Branch Name",
          },
          {
            field: "timestamp",
            label: "Timestamp",
          }
        ],
        columns: [
          // {
          //   field: "level",
          //   label: "Level",
          // },
          {
            field: "timestamp",
            label: "Timestamp",
          },
          {
            field: "description",
            label: "Description",
          },
          {
            field: "username",
            label: "User",
          },
          {
            field: "branch_name",
            label: "Branch Name",
          },

        ],
        filterFields: [
            new FilterInputFiled("Select Branch", "", [], "SELECT"),
            new FilterInputFiled("Select User", "", [], "USERSELECT","",true),
            new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
            new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
            new FilterInputFiled("Search", "", "", "BUTTON"),
            new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
        ],
        isLoading: false,
        isFullPage: false,
        name: "",
        userListArrary: [],
        
        // isTableComponentLoading: false
      };
    },
  
    methods: {
      tableFilterRequestHandler: function () {
        this.isLoading=true;
        NetworkManager.apiRequest(
          "backend/activityLog/list",
          {
            branch: this.filterFields[0].value === 'All' ? '' : this.filterFields[0].value,
            user: this.filterFields[1].value === 0 ? '' : this.filterFields[1].value,
            from_date: DateConverter.dateFormater(this.filterFields[2].value),
            to_date: DateConverter.dateFormater(this.filterFields[3].value),
          },
          (e) => {
            // e.data.list.map((data) => {
            //   data.updated_at = DateConverter.dateFormater(data.updated_at);
            //   data.created_at = DateConverter.dateFormater(data.created_at);
            //   if (data.application_approved_date != null) {
            //     data.application_approved_date = DateConverter.dateFormater(
            //       data.application_approved_date
            //     );
            //   }
            // });
            this.pendingList = e.data.list;
            this.isLoading=false;
           
            // console.log("this.dataList ", e.data);
            // this.filterFields[4].isButtonDisabled = false
           
          },
          false
        );
      },

      changeValues: function (item){
        this.filterFields[1].options = [];
        this.filterFields[1].isButtonDisabled=false;
        if(this.filterFields[1].value != "All"){
          this.userListLoad(this.filterFields[1].options,item.value)
        }
      },
      userListLoad(userListArrary, value){
        NetworkManager.apiRequest("backend/user/userList", {branch:value}, (e) => {
          // console.log("userListtttttttttttttttttt", e.data);
          userListArrary.push({
            "id": 0,
            "username": "All"
          });
          e.data.list.filter((userData) => {
            userListArrary.push(userData);
          });

          

          // console.log("Branch list loader")
          // console.log("Branch list array", userListArrary)
        

      
        },true);
      },
  
      resetFilter: function () {
       
         // Reset filter fields
         this.filterFields.forEach((field) => {
            field.value = "";
        });
        this.filterFields[1].isButtonDisabled = true;

        // Clear the table
        this.pendingList = [];
        this.filterFields[1].options = [];
      },
  
  
      filterButtonStateHandler : function(){
        if(this.filterFields[0].value !== "" || this.filterFields[1].value !== "" || this.filterFields[2].value !== ""|| this.filterFields[3].value !== ""){
          this.filterFields[4].isButtonDisabled = false
          this.filterFields[5].isButtonDisabled = false
        }
        else{
          this.filterFields[4].isButtonDisabled = true
          this.filterFields[5].isButtonDisabled = true
        }
      }
    },
  
    watch : {
      filterFields: {
        handler(){
          this.filterButtonStateHandler()
        },
        deep: true
  
      }
    },
  
    mounted() {
      this.branchListLoader(this.filterFields[0].options);
      // this.userListLoader(this.filterFields[1].options);
      var date = new Date();
      var month = date.getMonth() + 1;
      this.name = "Activity Report " + date.getFullYear() + "/" + month + "/" + date.getDate()
    },
  };
  </script>
  