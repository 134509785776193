<template>
  <!-- <div id="app">
 <UserList />
  </div> -->
  <router-view />

  <!-- <div>

    <section>
      <nav-bar />
      <div class="columns mt-1 mx-1">
        <div class="columns is-one-fifth">
          <div class="column">
            <side-bar style="height: 86vh" />
          </div>
        </div>
        <div class="column" style="max-height:93vh"> -->
          <!-- <TableComponent /> -->
          <!-- <router-view /> -->
<!-- 
        </div>
      </div>
    </section>
  </div> -->
</template>

<script>

// import UserList from './views/User/UserList.vue';
// import NavBar from "@/layout/components/NavBar";
// import SideBar from "@/layout/components/SideBar";

export default {
  name: 'App',
  components: {
    // UserList
    // NavBar,
    // SideBar
  }
}
</script>

<style>
*{
  font-family: Poppins, Helvetica, Arial, sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>