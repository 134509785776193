import { separateByThousands } from "separate-user-input-by-thousands";

const ThousandsSeparator = {
    /** This function used to separate a number by thousands
    * @param stringValue
    * @param propertyValue
    */
    separateByThousands(stringValue, propertyValue) {
        const getValue = separateByThousands(

            stringValue,
            propertyValue
        );

        return getValue;
    }
}

export default ThousandsSeparator;