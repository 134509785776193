<template>
     <b-modal v-model="isDebitCardIssueModalActive">
       <div class="main-container">
         <img src="@/assets/images/DebitCardIssue.png" alt="Debit Card Issue" style="margin-top: 15px;"/>
         <div class="content-container">
           <div class="title is-size-4 has-text-weight-bold has-text-primary" style="width:70%; margin:0 auto; margin-bottom: 25px">
              Debit Card issuance successfull 
           </div>
           <DebitCardIssueCard :debitCardNumber="debitCardNumber" />
           <div class="is-size-7 has-text-weight-medium has-text-grey-light" style="font-size:24px;">Debit card issue data has been successfully sent to the backend.</div>
         </div>
         <div class="button-container">
           <b-button type="is-primary" size="is-medium" @click="$emit('downloadDebitCardPdf',debitCardNumber)" class="download-pdf-button has-background-white-bis" inverted icon-right="download">
            <b>Download PDF</b> 
           </b-button>
           <b-button type="is-primary" size="is-medium" @click="$emit('closeModal')" class="done-button">
             Done
           </b-button>
         </div>
       </div>
     </b-modal>
</template>
   
   <script>

   import DebitCardIssueCard from '../Cards/DebitCardIssueCard.vue';
   
   export default {
     name: 'DebitCardNotificationModal',
     props: ['isDebitCardIssueModalActive', 'debitCardNumber'],
     emits: ['closeModal', 'downloadDebitCardPdf'],
     components: {
       DebitCardIssueCard,
     },
   };
   </script>
   
   <style scoped>
   .main-container {
     background: #ffffff;
     width: 420px;
     height: 600px; /* Increased height */
     border-radius: 16px; /* Slightly increased border radius */
     margin: 30px auto; /* Increased margin */
     padding: 20px;
     text-align: center;
     height: 480px;
   }
   
   .main-container img {
     width: 120px;
     margin-bottom: 20px;
   }
   
   .content-container {
     margin-bottom: 15px; /* Increased margin */
   }
   
   .has-text-primary {
     color: #3273dc; /* Blue text color */
   }
   
   .button-container {
    margin-top: 5px;
     display: flex;
     justify-content: space-around;
   }
   
   .download-pdf-button {
     background-color: #ffffff;
     color: #3273dc; /* Blue text color */
     border-radius: 8px; /* Slightly rounded corners */
     height: 40px;
     font-size: 20px;
   }
   
   .done-button {
     border-radius: 8px; /* Slightly rounded corners */
     height: 40px;
     
   }
   </style>
   