<template>
  <div :class="imageBoxStyle">
    <div :class="imageTitleStyle">
      {{ imageTitle }}
    </div>
    <div>
      <b-image :src="getImagePath"
        ratio="1by1"
      ></b-image>
    </div>
  </div>
</template>

<script>
import { appSettingsStorage } from "@/appSettingsStorage";
export default {
  name: "ImageContainerCard",
  props: ["imageTitle", "imageBoxStyle", "imageSource", "imageTitleStyle", "checkExistingImage"],

data() {
  return {
    baseUrl: appSettingsStorage.baseUrl,
    existingImageBaseUrl: appSettingsStorage.existingImageBaseUrl,
  };
},


  computed: {
    getImagePath() {
      if(this.checkExistingImage != null && this.checkExistingImage != ""){
        if(this.checkExistingImage == 1 && this.imageSource.startsWith("data:image")){
          return this.imageSource;
        }else if(this.checkExistingImage == 1){
          return this.existingImageBaseUrl + this.imageSource;
        }else{
          return this.baseUrl + this.imageSource;
        }
      }else{
        return this.baseUrl + this.imageSource;
      }
      // console.log("this.baseUrl + this.imageSource ", this.baseUrl + this.imageSource)
    },
  }


};

</script>
