<template>
    <div class="notification-card mb-20">
        <div class="columns">
            <div class="column  justify-content: left is-2">
                <img v-if="notification.status == 'new app'" src="../../assets/images/notification-icon1.png" alt="notification-icon1" style="width:37px; height:37px; margin-left:20px; margin-right:10px; margin-top:10px">
                <img v-if="notification.status == 'improved app'" src="../../assets/images/notification-icon2.png" alt="notification-icon2" style="width:37px; height:37px; margin-left:20px; margin-right:10px; margin-top:10px">
                <img v-if="notification.status == 'req to improve'" src="../../assets/images/notification-icon2.png" alt="notification-icon2" style="width:37px; height:37px; margin-left:20px; margin-right:10px; margin-top:10px">
                <img v-if="notification.status == 'reject'" src="../../assets/images/notification-icon3.png" alt="notification-icon3" style="width:37px; height:37px; margin-left:20px; margin-right:10px; margin-top:10px">
                <!-- <img v-else src="../../assets/images/notification-icon1.png" alt="notification-icon1" style="width:37px; height:37px; margin-left:20px; margin-right:10px; margin-top:10px"> -->
            </div>
            <div class="column justify-content: left">
                <p class="notifi-title" style="margin-bottom: 0em">{{notification.title}}</p>
                <p class="notification-body" style="margin-top: 0em">{{notification.body}}</p>
            </div>
            <div class="column is-one-quarter justify-content: right">
                <p class="notifi-time">{{notification.created_at}}</p>
            </div>
            <div class="column is-one-fifth justify-content: end">
                <b-button @click="handleClose(notification.id)" style="border: none !important; outline: none !important;">
                    <span style="font-weight: bold; font-size:x-large;">×</span>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "NotificationBox",
  props: ["notification"],
  data() {
    return {
      notifications: [],
    };
  },

  methods: {
    handleClose(id) {
        this.$emit("close", id); 
    },
    closeNotificationWindow() {
        this.$emit('isCardModalActive')
    }
  },

  mounted() {
   
  },
}
</script>

<style scoped>

.notification-card{
    width: 755px;
    height: 80px;
    background-color: #FAFAFA;
    overflow: hidden;
}

.notifi-title{
    color: #333333;
    font-size: 13px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0px;
}

.notification-body{
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
}

.notifi-time{
    color: #757575;
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0px;
    padding-top: 0.2em; 
    margin-left:5em;
}

.button {
    background-color: #fafafa00;
    border-color: #fafafa00;
}



@media screen and (min-width: 769px), print {
  .column.is-one-fifth,
  .column.is-one-fifth-tablet {
    flex: none;
    width: 70px !important;
  }
}

</style>