<template>
  <b-sidebar
    position="static"
    :expand-on-hover="expandOnHover"
    :reduce="reduce"
    :delay="expandWithDelay ? 500 : null"
    fullheight
    open
    style="display: flex; justify-content: space-between"
  >
    <div class="pl-3 pb-5 pt-1">
      <div class="profile-section">
        <img v-if="formData && formData.image" :src="baseUrl + formData.image" alt="Profile Image" class="profile-image" />
        <img v-else src="../../assets/images/default-image.png" alt="Profile Image" class="profile-image" />
          <p v-if="formData && formData.name" class="user-name">{{ getnameWithInitials (formData.name) }}</p>
          <p v-if="formData && formData.name" class="user-role">{{ formData.role }}</p>
      </div>
      <b-menu class="is-custom-mobile" style="text-align: left" :activable="false">
        <b-menu-list style="text-align: left">
          <div v-for="item in menuItems" :key="item.name">
            <b-menu-item
              v-if="item.isVisible()"
              :label="item.meta.title"
              :active="item.children.length > 1 ? false : item.meta.isActive"
              :expanded="item.meta.isActive"
              :icon="item.meta.icon"
              class="main-sidenav pt-0 pl-1"
              @click="menuClick(item.redirect)"
            >
              <div v-for="child in item.children" :key="child.name">
                <b-menu-item
                  v-if="child.isVisible()"
                  :label="child.meta.title"
                  :active="child.meta.isActive"
                  :icon="child.meta.icon"
                  class="child-sidenav"
                  @click="menuClick(child.name)"
                ></b-menu-item>
              </div>
            </b-menu-item>
          </div>
          <!-- logout the user -->
         <b-menu-item class="fixed-menu-item main-sidenav logout" icon="exit-to-app" @click="logout()" label="Log Out" expanded></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
    <div class="copyright-text">
      <p>{{ version }}</p>
      </div>
    <!-- <div  class="pl-3 py-5" style="margin-bottom: 70px"> <b-icon icon="exit-to-app" size="is-medium"></b-icon><span id="log-out-text">Log Out</span></div> -->
    <!-- <b-menu-item icon="exit-to-app" label="Log Out" active expanded></b-menu-item> -->
  </b-sidebar>
</template>

<script>
import routes from "@/router/router";
import { MenuItem } from "@/layout/components/Support";
import JwtUtils from "@/helpers/jwtUtils";
import NetworkManager from "@/network"
import { appSettingsStorage } from "@/appSettingsStorage";
import { appSettings } from "@/appSettings";

export default {
  name: "SideBar",
  data() {
    return {
      expandOnHover: true,
      expandWithDelay: false,
      mobile: "fullwidth",
      reduce: false,
      currentRoute: this.$router.currentRoute,
      formData: null,
      baseUrl: appSettingsStorage.baseUrl,
      version: appSettings.version,

      menuItems: [],
    };
  },

  methods: {
    checkPermissions: function () {},

    logout: function () {
      NetworkManager.apiRequest('backend/user/logout', {}, (e) => {
          // console.log("user logged out", e);
      });
      JwtUtils.logoutTheUser(true);
    },

    menuClick: function (name) {
      if (name !== null && name !== undefined && name !== "") {
        // console.log(name);
        this.navigateTo(name);
      }
    },

    navigateTo: function (name, params = null) {
      if (this.$router.currentRoute.name !== name) {
        if (params !== null) {
          this.$router.push({ name: name, params: params }).then(null);
        } else {
          this.$router.push({ name: name }).then(null);
        }
      }
    },

    loadMenuItems: function () {
      this.menuItems = [];

      for (let i = 0; i < routes.length; i++) {
        let item = routes[i];
        if (item.meta) {
          if (item.meta.type === "menu") {
            let menuItem = new MenuItem();
            menuItem.name = item.name;
            menuItem.isVisible = item.isVisible;
            menuItem.redirect = item.redirect;
            menuItem.meta = item.meta;
            menuItem.meta.isActive = false;

            // console.log("menuItem.isVisibleZXZxzX",item.meta.middleware)
            item.children.forEach(function (child) {
              if (child.meta.type === "menu-item") {
                child.meta.isActive = false;
                menuItem.children.push(child);
                // console.log("menuItem.isVisiblchild.meta.",child.meta.middleware)
              }
            });

            this.menuItems.push(menuItem);
            // console.log(this.menuItems);
          }
        }
      }
    },

    /**
     * A function to check third level routes parent route selection
     * @param to requested route
     * @param child child route
     * @returns {boolean}
     */
    checkThirdLevelRoutesParentSelection(to, child) {
      return (
        ((to.name === "CreateUser" || to.name === "EditUser") &&
          child.name === "Users") ||
        ((to.name === "CreateRole" || to.name === "EditRole") &&
          child.name === "Roles") ||
        ((to.name === "CreateBranch" || to.name === "EditBranch") &&
          child.name === "Branches")
      );
    },

    getUserData: function(){
      NetworkManager.apiRequest("backend/user/getUserData", {}, (e) => {
        this.formData = e.data;
    });
    },

    getnameWithInitials(name) {
      const firstLetters = name.split(" ").map((word) => word[0]);
      const lastString = name.split(" ").pop();
      const result = firstLetters.slice(0, -1).join(" ") + " " + lastString;
      return result;
    },
  },

  watch: {
    $route(to) {
      // console.log("Route changed to: " + to.name);
      const comp = this;
      let activeParent = null;

      this.menuItems.forEach(function (item) {
        if (item.redirect === to.name) {
          activeParent = item;
        } else {
          item.meta.isActive = false;
        }

        if (item.children !== null && item.children !== undefined) {
          item.children.forEach(function (child) {
            if (child.name === to.name) {
              child.meta.isActive = true;
              activeParent = item;
            } else {
              child.meta.isActive = false;
              item.meta.isActive = false;
            }

            // handle parents with 3 level child routes (only for user, branch, role)
            if (comp.checkThirdLevelRoutesParentSelection(to, child)) {
              // debug logs
              // console.log("Parent : ");
              // console.log(item);
              // console.log("child : ");
              // console.log(child);

              item.meta.isActive = true;
              child.meta.isActive = true;
              activeParent = item;
            }
          });
        }
      });

      if (activeParent !== null) {
        // console.log(activeParent.name);
        activeParent.meta.isActive = true;
      }
    },
  },

  mounted() {
    this.loadMenuItems();
    this.$router.push({ path: this.$router.currentRoute.path + "#" });

    this.getUserData();
  },
};
</script>

<style lang="scss">
html {
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

.p-1 {
  padding: 1em;
}

.fixed-menu-item {
  margin-top: 585px; 
  position: absolute;
  top: 0;
}

.main-sidenav {
  font-size: 14px; /* Adjust the font size as needed */
  font-family:Poppins;
  font-weight: normal !important;
}


.child-sidenav {
  font-size: 14px; /* Adjust the font size as needed */
  // font-family:Poppins;
  font-weight:normal !important;
  
}
.child-sidenav a{
  padding:3px !important;
}

.profile-section {
  text-align: center;
  padding: 0px;
  margin: 2.5px 0;
}
.menu-list a{
  margin: 1px; /* Adjust the margin values as needed */
}
.icon-text {
  padding:4px !important;
}

.profile-info {
  margin-top: 0px; /* Adjust the margin as needed */
}

.user-name {
  font-size: 13px; /* Adjust the font size as needed */
  font-weight: bold;
}

.user-role {
  font-size: 11px; /* Adjust the font size as needed */
  color: rgba(239, 234, 234, 0.754); /* Adjust the color as needed */
}

.logout{
  margin-left: 3.5px;
}

.profile-image {
  width: 70px; /* Adjust the width as needed */
  height: 70px; /* Adjust the height as needed */
  border-radius: 50%; /* Make it a circle */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border: 2px solid white; /* Add a white border with a thickness of 3px */
  margin-top: 0px;
}
.copyright-text p {
    font-weight: 300;
    font-family: poppins;
    margin-bottom: 80px;
    font-size: 7px;
    transform: scale(1, 1.1);
    margin-left: 23px;
    letter-spacing: 0.5px;
    font-family: "Montserrat", sans-serif;
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  // min-height: 100vh;
  .sidebar-layout {
    
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}

@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.menu-list a.is-active {
  background-color: hsla(0, 0%, 100%, 0.594) !important;
  color: #ffffff;
}

.b-sidebar .sidebar-content.is-fullheight {
  width: 239px;
  background-color: #0083ca !important;
  color: #fff;
}

// .py-5 {
//     padding-top: 0.8rem !important;
//     padding-bottom: 1.5rem !important;
// }

/*.b-sidebar .sidebar-content.is-fullheight {
    height: 100% !important;
    max-height: 100% !important;
    overflow: hidden;
    overflow-y: auto !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch;
}*/
</style>
