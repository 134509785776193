<template>
  <div id="app">
    <cropper ref="cropper" class="cropper" 
    :transitions="true"
			image-restriction="fit-area"
       :src="imgSrc" 
    :stencil-props="{aspectRatio: 1/1}" />
      
    <div class="button-wrapper">
      <button class="button" @click="flip(false,true)"><b-icon icon="mdi mdi-flip-vertical" size="is-medium"></b-icon></button> 
      <button class="button" @click="flip(true,false)"><b-icon icon="mdi mdi-flip-horizontal" size="is-medium"></b-icon></button>
      <button class="button" @click="rotate(90)"><b-icon icon="mdi mdi-rotate-right" size="is-medium"></b-icon></button>
      <button class="button" @click="rotate(-90)"><b-icon icon="mdi mdi-rotate-left" size="is-medium"></b-icon></button>
    </div>
    <div class="button-wrapper">
      <button class="button" @click="cropImage">Crop image</button>
      <button class="button" @click="cancelUpload">Cancel</button>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "App",
  props:{
    imgSrc:String
  },
  data() {
    return {
      image: {
        src: String,
        type: "image/jpg",
      },
    };
  },
  methods: {
    cropImage() {
  const result = this.$refs.cropper.getResult();
  result.canvas.toBlob((blob) => {
    const croppedFile = new File([blob], 'cropped-image.png', { type: this.image.type });
    // console.log(croppedFile);
    this.$emit('crop-image', croppedFile);
  }, this.image.type);
  
},cancelUpload(){
  this.$emit('cancel-upload',false);
},
flip(x, y) {
			const { image } = this.$refs.cropper.getResult();
			if (image.transforms.rotate % 180 !== 0) {
				this.$refs.cropper.flip(!x, !y);
			} else {
				this.$refs.cropper.flip(x, y);
			}
		},
    
		rotate(angle) {
			this.$refs.cropper.rotate(angle);
		},
  },
  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
  components: {
    Cropper,
  },
};
</script>

<style scoped>
#app {
  width: 60%; /* Adjusted width */
  height: auto; /* Changed height to auto for responsive design */
  margin: 20px auto; /* Centered the container */
}

.cropper {
  max-width: 100%; /* Make sure cropper image doesn't overflow */
}

.button-wrapper {
  display: flex;
  justify-content: space-around; /* Adjusted button alignment */
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 30%;  /* Adjusted button width */
  background: #151515;
  cursor: pointer;
  transition: background 0.5s;
  border: none;
  /* &:not(:last-of-type) {
    margin-right: 10px;
  }
  &:hover {
    background: #ffffff;
  } */
}

.input-file {
  display: none; /* Hidden by default */
}

.button-wrapper label {
  cursor: pointer;
}

/* Optional: Add some space between buttons and the cropper */
.button-wrapper + .cropper {
  margin-top: 20px;
}
</style>
