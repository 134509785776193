<template>
    <b-modal v-model="modalActive">
        <div class="main-container">
            <div class="img-container">
                <img :src="require(`../../../assets/images/${imageName}`)">
            </div>

            <div class="has-text-primary" style="font-weight:700; font-size:30px; margin: 20px auto; width: 90%;text-align: center;"><p>{{ mainText }}</p></div>
    
            <div style="width: 40%; margin: 78px auto">
                <b-button type="is-primary" @click="actionHandler" style="padding: 25px 60px;"><span class="btn-txt-class">{{ btnText }}</span></b-button>
            </div>
        </div>

    </b-modal>
</template>


<script>
export default {
    name : 'CommonNotificationModal',
    props : ['isCommonModalEnabled', 'imageName', 'mainText', 'btnText'],
    

    data(){
        return{
            isTrue: true

        }
    },

    methods:{

        actionHandler(){
            this.$emit('common-action-handler')
        }

    },

    computed:{
        modalActive(){
            return this.isCommonModalEnabled
        }
    }
    
}

</script>

<style scoped>

.main-container{

    background: #ffff;
    width: 474px;
    height: 502px;
    border-radius:12px;
    margin: 28px auto ;
}

.img-container{
    width: 150px;
    margin: 0px auto;
}

.img-container img{
    margin-top: 55px;
}

.btn-txt-class{
    font-size: 20px;
    font-weight: 600
}


</style>