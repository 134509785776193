<template>
    <div style="padding: 50px" class="page-container">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  
      <!--PDF DOWNLOAD CONTROLLER -->
      <!--IF DOWNLOAD IS NOT WORKING. MAKE SURE :enable-download= true and in hasDownloaded() this.goback is not commented-->
      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :htmlToPdfOptions="{
          filename: smsData.id,
          margin: 0.3,
          image: {
            type: 'jpeg',
            quality: 1,
          },
          enableLinks: false,
          html2canvas: {
            scale: 2,
            useCORS: true,
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait',
            putOnlyUsedFonts: true,
          },
        }"
        :paginate-elements-by-height="1800"
        :pdf-quality="2"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :manual-pagination="false"
        @progress="onProgress($event)"
        @startPagination="startPagination()"
        @hasPaginated="hasPaginated()"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="columns">
            <div class="column is-one-fifth">
              <img class="imgStyle" src="../../assets/images/pdfLogo.png" />
            </div>
            <div class="column pdfTitle">
              <div class="titleStyle">
                <p>SMS ALERT SERVICE REGISTRATION APPLICATION</p>
              </div>
              <div class="subTitleStyle">
                <span>{{ smsData.branch }} | {{ convertDate(smsData.created_at) }}</span>
              </div>
            </div>
          </div>
          
  
          <div class="column">
          <SpanFields
            fieldTitle="Branch"
            :fieldValue="smsData.branch ? smsData.branch : '-'"
          />
          <SpanFields
            fieldTitle="Date"
            :fieldValue="smsData.created_at.substring(0, 10)"
          />
          <SpanFields
            fieldTitle="Title"
            :fieldValue="smsData.title ? smsData.title : '-'"
          />
          <SpanFields
            fieldTitle="Full Name"
            :fieldValue="smsData.full_name ? smsData.full_name : '-'"
          />
          <SpanFields
            fieldTitle="NIC"
            :fieldValue="smsData.nic ? smsData.nic : '-'"
  
          />

          <SpanFields
              fieldTitle="Date of birth"
              :fieldValue="smsData.birth_date + '/' + smsData.birth_month + '/' + smsData.birth_year"
            />
  

          <SpanFields
              fieldTitle="Primary Mobile Number"
              :fieldValue="smsData.primary_mobile_number ? smsData.primary_mobile_number : '-'"
            />
        
        <SpanFields
            fieldTitle="Email"
            :fieldValue="smsData.email ? smsData.email : '-'"
        />
        
        <SpanFields
                fieldTitle="Address"
                :fieldValue="
                  concatenateAddresses(smsData)
                "
            />

          <SpanFields fieldTitle="Customer CIF" :fieldValue="smsData.customer_cif" />

          <SpanFields
            fieldTitle="Account Ownership"
            :fieldValue="smsData.account_ownership ? smsData.account_ownership : '-'"
          />
          <SpanFields
            fieldTitle="Account Type"
            :fieldValue="smsData.savings_account_type ? smsData.savings_account_type : '-'"
          />
          <SpanFields
            fieldTitle="Account Number"
            :fieldValue="smsData.account_number ? smsData.account_number : '-'"
          />

          <div class="columns">
            <div class="column">
              <img class="imgAttachment" :src="baseUrl + smsData.customer_signature" />
              <p style="margin-left: 25px;"> Customer Signature </p>
            </div>
          </div>

          <div style="page-break-before: always;">
            <div class="columns" v-if="comments != null">
                <div class="column is-two-thirds titleHeading">
                  <p style="margin-top: 20px;">COMMENTS</p>
                  <hr class="hrStyle">
              </div>
            </div>
            <SmsCommentField  :smsCommentArray="comments" v-if="comments != null"/>
          <br/>
          <SpanFields
                fieldTitle="Application submitted By"
                :fieldValue="
                  smsData.submitted_by_email ? smsData.submitted_by_email : '-'
                "
          />

          <SpanFields
            fieldTitle="EPF"
            :fieldValue="
              smsData.submitted_user_epf
                ? smsData.submitted_user_epf
                : '-'
            "
          />

          <SpanFields
            fieldTitle="Branch"
            :fieldValue="
              smsData.submitted_user_branch
                ? smsData.submitted_user_branch
                : '-'
            "
          />

          <hr class="application-view-hr" />

          <SpanFields
                fieldTitle="Branch Approving Officer"
                :fieldValue="
                  smsData.reviewed_by ? smsData.reviewed_by : '-'
                "
          />

          <SpanFields
            fieldTitle="EPF"
            :fieldValue="
              smsData.branch_apprv_epf
                ? smsData.branch_apprv_epf
                : '-'
            "
          />

          </div>
  
          <!-- <div class="columns">
            <div class="column">
              <img class="imgAttachment" :src="customer_img" />
              <p style="margin-left: 25px;"> Customer Image </p>
            </div>
          </div> -->
          
  
          <br>
        </div>
  
        </section>
      </vue-html2pdf>
    </div>
</template>
  
  <script>
  import VueHtml2pdf from "vue-html2pdf";
  import NetworkManager from "../../network";
  import SpanFields from "@/components/support/fields/SpanFields.vue";
  import { appSettingsStorage } from "@/appSettingsStorage";
  import SmsCommentField from "@/components/support/fields/SmsCommentField.vue";
  import DateConverter from "@/helpers/DateConverter";

  
  export default {
    name: "SmsActivationPdf",
    components: {
      VueHtml2pdf,
      SpanFields,
      SmsCommentField
    },
    data() {
      return {
        isLoading: true,
        smsData: null,
        contentRendered: false,
        progress: 0,
        generatingPdf: false,
        pdfDownloaded: false,
        customer_img: "",
        customer_signature: "",
        comments: [],
        baseUrl: appSettingsStorage.baseUrl,
       
      };
    },
    methods: {

      goback() {
        this.$router.go(-1);
        // location.reload();
      },
  
      loadImageToBase64(imageUrl, variableName) {
        const image = new Image();
  
        image.crossOrigin = "anonymous"; // Set cross-origin attribute to allow loading external images
  
        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext("2d");
  
          // Clear the canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);
  
          // Draw the image on the canvas
          ctx.drawImage(image, 0, 0, image.width, image.height);
  
          try {
            const base64String = canvas.toDataURL("image/jpeg"); // You can change 'image/jpeg' to the desired image format
            // console.log(base64String);
            this.$set(this, variableName, base64String);
          } catch (error) {
            // console.error("Failed to convert the image to base64:", error);
          }
        };
  
        image.src = imageUrl;
      },
  
      formatAddress(address) {
      if(address != null ){
        if (!address.trim().endsWith(',')) {
          return address.trim() + ',';
        }
        return address.trim();
      }
      },
      concatenateAddresses(smsData) {
        const address1 = this.formatAddress(smsData.address_1);
        const address2 = this.formatAddress(smsData.address_2);
        const address3 = this.formatAddress(smsData.address_3);
        const address4 = this.formatAddress(smsData.address_4);
        if(address4 != null){
          return `${address1} ${address2} ${address3} ${address4}`.replace(/,\s*$/, '');
        }else{
          return `${address1} ${address2} ${address3}`.replace(/,\s*$/, '');
        }
      },
        
  
      generatePDF() {
        // Get the HTML content of the component
        // console.log("Generating PDF");
        this.$refs.html2Pdf.generatePdf();
      },

      convertDate(date){
        return DateConverter.dateFormater(date)
      },

      onProgress(progress) {
        this.progress = progress;
        // console.log(`PDF generation progress: ${progress}%`);
      },
      startPagination() {
        // console.log(`PDF has started pagination`);
      },
      hasPaginated() {
        // console.log(`PDF has been paginated`);
      },
      async beforeDownload({ html2pdf, options, pdfContent }) {
        // console.log(`On Before PDF Generation`);
        // this.loadImageToBase64();
        // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        // 	const totalPages = pdf.internal.getNumberOfPages()
        // 	for (let i = 1; i <= totalPages; i++) {
        // 		pdf.setPage(i)
        // 		pdf.setFontSize(10)
        // 		pdf.setTextColor(150)
        // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        // 	}
        // }).save()
      },
      hasDownloaded(blobPdf) {
        // console.log(`PDF has downloaded`);
        this.pdfDownloaded = true;
        // console.log(blobPdf);
        this.goback();
        window.close();
      },
  
      
  
      getFormattedDateTime(date) {
        //   return DateTimeManger.formatCustomerInputDates(date);
      },
  
      
  
      GetSmsApplicationData: function () {
        const comp = this;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get("id");
        const data = {
          id: id,
          // status: "pending"
        };
        NetworkManager.apiRequest(
          "backend/applicant/sendSmsNotificationById",
          data,
          (e) => {
            if (e.success === true) {
              comp.smsData = e.data[0];
  
              // comp.loadImageToBase64(
              //   appSettingsStorage.baseUrl + this.smsData.customer_img,
              //   "customer_img"
              // );
              // comp.loadImageToBase64(
              //   appSettingsStorage.baseUrl + this.smsData.customer_signature,
              //   "customer_signature"
              // );
             
            }
          }
        );
      },
      getComments() {
      const comp = this;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("id");
      NetworkManager.apiRequest(
        "backend/applicant/getSmsCommentData",
        { id: id },
        (e) => {
          if (e.success === true) {
            comp.comments = e.data.comment;
          }
        },
        false
      );
    },
    },
    mounted() {
      this.GetSmsApplicationData();
      this.getComments();
      
      
  
      setTimeout(() => {
        this.generatePDF();
      }, 5000);
      // setTimeout(() => {
      //     this.goback();
      // }, 5000);
    },
  };
  </script>
  <style scoped>
  
  .header{
    margin-bottom:10px;
  }
  
  .paragraph{
    margin-right: 5px;
  }
  
  .heading{
  font-size:medium;
  /* margin-right: 5px; */
  /* background-color: rgb(196, 194, 191); */
  /* align-items: center; */
  margin-left: 140px;
  margin-top:15px;
  }
  
  /* .heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #9da1af; 
    padding: 10px; 
  } */
  
  .input-area {
    display: flex;
    align-items:left;
    margin-top:10px;
  }
  
  .page-container {
    width: 70vw; /* Desired width */
    padding: 50px;
   
  }

  .titleStyle{
    font-size: 20px;
    font-weight: bold;
  }

  .subTitleStyle{
    font-size: 15px;
    /* font-weight: bold; */
    color: #757575;
  }

  .pdfTitle{
    text-align: center;
  }
  
  .columns {
    display: flex;
    flex-direction: column;
  }
  
  
  
  .imgAttachment {
    border: 2px solid #efefef;
    padding: 1px;
    width: 300px;
    height: 200px;
  }
  .imgStyle {
    padding: 1px;
    width: 250px;
    height: 45px;
  }
  .img-title-size {
    font-size: 15px;
  }
  
  html {
    /* Disable resizing of the page */
    resize: none;
  }
  
  body {
    overflow: hidden;
    color: rgb(140, 135, 135);
  }
  
  .columns {
    display: flex; 
    flex-direction: row; 
  }
  
  .column {
    flex: 1; 
    
  }
  
  
  </style>
  