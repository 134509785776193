export class FilterInputFiled {
    label = '';
    value = null;
    options = '';
    type = '';
    maxDate = null;
    isButtonDisabled = false
    constructor(label,value, options, type, maxDate, isButtonDisabled) {
        this.label = label;
        this.value = value;
        this.options = options;
        this.type = type;
        this.maxDate = maxDate;
        this.isButtonDisabled = isButtonDisabled;
    }
    

}