<template>

    <!-- <div> -->
  <ApplicationViewVue
    :viewData="applicationData"
    :comments="comments"
    :isLoading="isLoading"
    :isFullPage="isFullPage"
    :listLength="listLength"
    :approveBtnLoading="approveBtnLoading"
    :jointOwnerId="mainOwnerId"
  
    @userCommentSubmitHandler="
      (userComment) => {
        userCommentSubmitHandler(userComment);
      }
    "
    @aprroveRequestHandler="
      (stat) => {
        applicationStatusChangeHandler(stat);
      }
    "
    @improveRequestHandler="
      (stat) => {
        applicationStatusChangeHandler(stat);
      }
    "
    @rejetRequestHandler="
      (stat) => {
        applicationStatusChangeHandler(stat);
      }
    "
    @checkboxChangeHandler="
      (value) => {
        pepCheckHandler(value);
      }
    "
    @agreedRadioButton="
      (value) => {
        agreedRadioButton(value);
      }
    "

    @centralizedOpsChangeHandler="
      (value) => {
        centralizedOpsChangeHandler(value);
      }
    "	
    @sanctionListChangeHandler="
      (value) => {
        sanctionListChangeHandler(value);
      }
    "	
    @userTwo="()=>{
      jointOwner(1);
    }
    "
    @userThree="()=>{
      jointOwner(2);
    }
    "
    @userOne="()=>{
      jointOwner(0);
    }
    "
  />

  <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
<!-- </div> -->
</template>

<script>
import ApplicationViewVue from "@/components/ApplicationView.vue";
import NetworkManager from "@/network";
import { useAuthStore } from "@/store/authStore";
import { appSettings } from "@/appSettings"
import ThousandsSeparator from "../../helpers/thousandSeperator";

export default {
  name: "PendingApplicationView",
  components: {
    ApplicationViewVue,
  },

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      applicationData: [],
      comments: [],
      isLoading: true,
      isFullPage: false,
      listLength: 0,
      mainOwnerId: null,
      Customer_Number: null,
      julianDate: "",
      marital_status: "",
      sex: "",
      surname: "",
      initials: "",
      initialsForShortName: "",
      julianDob: "",
      unixEpochTime: "",
      dateOfBirth: null,
      customer_type: "",
      approveBtnLoading: false,
      fdDepositAmount: "",
      fdInterestDisposalMethod: "",
      fdPeriodMonth: "",
      paymentFreCy: "",
      anchorProfileNo: "",
      monthlyIncomeValue : "",
      convertedMonthlyIncomeValue : "",
      convertedFdDepositValue : "",
      fdDepositValue:"",
    };
  },

  methods: {
    userCommentSubmitHandler(comment) {
      if (!comment.trim()) {
        this.$buefy.toast.open({
          message: "Please enter a comment",
          type: "is-danger",
        });
        return;
      }
      NetworkManager.apiRequest(
        "backend/applicant/addCommentForReviewedApplication",
        { comment: comment, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          // console.log(e.data.list)

          // console.log("comment send");
          window.location.reload();
        },
        true
      );
    },

    applicationStatusChangeHandler(stat) {
      
      let colorType;
      let message;
      if (stat.applicationStat === "Approved") {
        this.isLoading= true;

        colorType = "is-primary";
        if(this.applicationData.applicant_individual_account_type == "Savings Account" && this.applicationData.account_type == "Individual" && this.applicationData.converted_savings == false){
          if(appSettings.usingSDBServer == true){
            this.approveSavingApplication();
          }else{
            
            // this.downloadPdf();
            
              NetworkManager.apiRequest(
                "backend/applicant/updateReviewStatus",
                { review_status: stat.applicationStat, applicant_id: this.applicationData.id, joint_main_owner : this.mainOwnerId, applicant_name : this.applicationData.display_name, email : this.applicationData.email,account_number : this.applicationData.account_number, branch: this.applicationData.branch, reviewAndNext: stat.reviewAndNext},
    
                (e) => {
                  stat.isApplicationReviewed = 1;
                  stat.applicationRequestType = stat.applicationStat;
                  this.isLoading = false;
                  location.reload();
                },
                true
              );
           
          }
        }
        else if((this.applicationData.applicant_individual_account_type == "Fixed Deposit"  && this.applicationData.account_type == "Individual") || this.applicationData.converted_savings == true){
          if(appSettings.usingSDBServer == true){
            this.approveFixedApplication();
          }else{
            this.downloadPdf();

            setTimeout(() => {
              NetworkManager.apiRequest(
                "backend/applicant/updateReviewStatus",
                { review_status: stat.applicationStat, applicant_id: this.applicationData.id, joint_main_owner : this.mainOwnerId, applicant_name : this.applicationData.display_name, email : this.applicationData.email,account_number : this.applicationData.account_number, branch: this.applicationData.branch, reviewAndNext: stat.reviewAndNext,individual_account_type: this.applicationData.individual_account_type,fd_how_cus_need_certificate: this.applicationData.fd_how_cus_need_certificate,mobile_number:this.applicationData.primary_mobile_number},

                (e) => {
                  stat.isApplicationReviewed = 1;
                  stat.applicationRequestType = stat.applicationStat;
                  location.reload();
                },
                true
              );
            }, 8000);
          }
        }else if(this.applicationData.applicant_individual_account_type == "Minor Account" && this.applicationData.minor_account_type == "Savings Account"){
          if(appSettings.usingSDBServer == true){
            this.approveMinorSavingsAccount();
          }
        }else if(this.applicationData.account_type == "Joint" && this.applicationData.applicant_individual_account_type == "Savings Account"){
          if(appSettings.usingSDBServer == true){
            this.approveJointSavingsAccount(stat);
          }
        }
        // else{
        //   this.$buefy.toast.open({
        //       message: "Joint account creation is not implemented yet",
        //       type: "is-danger",
        //   });
        // }
      }
      if (stat.applicationStat === "Reject") {
        colorType = "is-danger";
        NetworkManager.apiRequest(
          "backend/applicant/updateReviewStatus",
          { review_status: stat.applicationStat, applicant_id: this.applicationData.id, joint_main_owner : this.mainOwnerId, branch: this.applicationData.branch },

          (e) => {
            stat.isApplicationReviewed = 1;
            stat.applicationRequestType = stat.applicationStat;
            // this.$router.push("rejected");
            location.reload();
          },
          true
        );
      }
      if (stat.applicationStat === "Request To Improve") {
        colorType = "is-info";
        NetworkManager.apiRequest(
        "backend/applicant/updateReviewStatus",
        { review_status: stat.applicationStat, applicant_id: this.applicationData.id, joint_main_owner : this.mainOwnerId, branch: this.applicationData.branch },

        (e) => {
          stat.isApplicationReviewed = 1;
          stat.applicationRequestType = stat.applicationStat;
          // this.$router.push("req-to-improve");
          location.reload();
        },
        true
      );
      }
    },

    downloadPdf(){
      const route = this.$router.resolve({
      name: "FdCertificatePdf",
      params: {id: this.applicationData.id },
      });
      window.open(route.href, "_blank");
    },

    getMarriedStatus(){
      if(this.applicationData.marital_status == "Not Married"){
        this.marital_status = "S"
      }else if(this.applicationData.marital_status == "Married"){
        this.marital_status = "M"
      }
    },

    getGender(){
      if(this.applicationData.sex == "Male"){
        this.sex = "M"
      }else if (this.applicationData.sex == "Female"){
        this.sex = "F"
      }
    },

    getCustomerType(){
      if(this.applicationData.kyc_is_customer_pep != 'Customer is not a politically exposed person'){
        if(this.applicationData.kyc_is_customer_pep == 'Customer is a politically exposed person'){
          this.customer_type = '004';
        }else if(this.applicationData.kyc_is_customer_pep == 'Customer is a family member of such person'){
          this.customer_type = '007';
        }else if(this.applicationData.kyc_is_customer_pep == 'Customer is a close associate of such a person'){
          this.customer_type = '008';
        }
      }else if(this.applicationData.applicant_status == "Unemployed" || this.applicationData.applicant_status == "Retired (Non-Government)" || this.applicationData.applicant_status == "Housewife"){
        this.customer_type = '001';
      }else if(this.applicationData.applicant_status == "Employed (Salaried)" || this.applicationData.applicant_status == "Entrepreneur / Self Employee"){
        this.customer_type = '002';
      }else if(this.applicationData.applicant_status == "Government Pensioner"){
        this.customer_type = '003';
      }

      // console.log("this.applicationData.kyc_is_customer_pep", this.applicationData.kyc_is_customer_pep)
      // console.log("this.customer_type", this.customer_type)
    },

    getCustomerFullName(){
      const full_name = this.applicationData.full_name;
      const name = full_name.split(" ");
      this.surname = name[name.length - 1];

      // console.log("this.surname", this.surname);
    },

    getInitials(){
      const full_name = this.applicationData.full_name;
      const name = full_name.split(" ");
      this.initials = '';
      for(let i = 0; i< name.length; i++) {
        this.initials += name[i][0].toUpperCase() + '.';
      }

      // console.log("this.initials", this.initials);
    },

    getShortName(){
      const full_name = this.applicationData.full_name;
      const parts = full_name.split(" ");
      let lastName = parts.pop();
      this.initialsForShortName = '';
      this.initialsForShortName += lastName.charAt(0).toUpperCase() + lastName.slice(1) + ' '; 

      for (let i = 0; i < parts.length; i++) {
        this.initialsForShortName += parts[i][0].toUpperCase(); 
      }

      // console.log("this.initialsForShortName", this.initialsForShortName)
    },

    getDateOfBirthInJulian() {
    return new Promise((resolve, reject) => {
      this.dateOfBirth = this.applicationData.birth_date + this.applicationData.birth_month + this.applicationData.birth_year;
      // console.log("this.dateOfBirth", this.dateOfBirth);
      if(appSettings.usingSDBServer == true){
        NetworkManager.apiRequest(
          "backend/sdb/getJulianDate",
          { cdate: this.dateOfBirth },
          (e) => {
            // console.log("e", e)
            if (e.success == true) {
              this.julianDob = e.julianDate;
              // console.log("getDateOfBirthInJulian", this.julianDob);
              resolve(); // Resolve the promise when the operation is completed
            } else {
              reject(new Error("Failed to get Julian date of birth")); // Reject the promise if there's an error
            }
          },
          true
        );
      }
    });
  },

    currentTimeInSeconds(){
      const currentTimeInMillis = new Date().getTime();
      this.unixEpochTime = Math.floor(currentTimeInMillis / 1000);

      // console.log("unixEpochTime",this.unixEpochTime);
    },

    getFdDepositAmount(){
      const fdDepositAmount = this.applicationData.fd_deposit;
      let numericAmount = fdDepositAmount.replace('Rs.', '').replace(/,/g, '');
      numericAmount = parseInt(numericAmount, 10); 
       this.fdDepositAmount = numericAmount.toString();   
    },

    geIdispostionCode(){
      const fdInterestDisposalMethod = this.applicationData.fd_interest_disposal_method;
      if(fdInterestDisposalMethod == "Capitalized"){
        this.fdInterestDisposalMethod = "T"
      }else if(fdInterestDisposalMethod == "Transfer"){
        this.fdInterestDisposalMethod = "T"
      }
    },

    getmonthFromTheFdPeriod(){
      const fdPeriod = this.applicationData.fd_period;
      this.fdPeriodMonth = parseInt(fdPeriod.match(/\d+/)[0]);
      // console.log("fdPeriodMonth",this.fdPeriodMonth); 
    },

    getPaymentFreCy(){
      const fdInterestPayableAt = this.applicationData.fd_interest_payable_at;
      if(fdInterestPayableAt == "Annually"){
        this.paymentFreCy = "12"
      }else if(fdInterestPayableAt == "Monthly"){
        this.paymentFreCy = "1"
      }else if(fdInterestPayableAt == "Maturity"){
        this.paymentFreCy = this.fdPeriodMonth;
      }
    },

    getAnchorProfileNo(){
      if(this.fdInterestDisposalMethod == "C"){
        this.anchorProfileNo = "0"
      }else if(this.fdInterestDisposalMethod == "T"){
        this.anchorProfileNo = "45"
      }
    },

    async approveFixedApplication(){
      this.approveBtnLoading = true;
      await this.getDateOfBirthInJulian();
      this.currentTimeInSeconds();
      if(this.applicationData.converted_savings != true){
        this.getFdDepositAmount();
        this.getmonthFromTheFdPeriod();
        this.getPaymentFreCy();
      }
      const currentDate = new Date();
      const day = currentDate.getDate();

      NetworkManager.apiRequest(
        "backend/sdb/createFixedAccount",
        { 
          id: this.applicationData.id,
          tin_activity_date : this.julianDate,
          date :this.julianDate,
          date_of_birth : this.julianDob,
          // date_of_birth : "1998234",
          // moved_in_date :this.julianDate,
          moved_in_date : "2024083",
          // customer_open_date :this.julianDate,
          customer_open_date : "2024083",
          cust_doc_activity :this.julianDate,
          time : this.unixEpochTime,
          // open_date : this.julianDate,
          open_date : "2024089",// this can changed (current julian date)
          face_amount : this.fdDepositAmount,
          i_dispostion_code : this.fdInterestDisposalMethod,
          deposit_amount : this.fdDepositAmount,
          // st_accrual_date : this.julianDate,
          st_accrual_date : "2024011",
          // funds_avail_date : this.julianDate,
          funds_avail_date : "2024011",
          renewal_frequency : this.fdPeriodMonth,
          renewal_spec_day : day,
          payment_fre_cy : this.paymentFreCy,
          payment_spe_day : day,
          // account_open_date : this.julianDate,
          account_open_date : "2024089", // this can changed (current julian date)
          anchor_profile_no : this.anchorProfileNo,
        },
        (e) => {
          // console.log("Fixed Account", e);
          if(e.success == true){
            // this.$buefy.toast.open({
            //   message: "Account Created Successfully",
            //   type: "is-success",
            // });
            // console.log("e.account_number",e.account_number)
            if(this.applicationData.fd_how_cus_need_certificate == "Digital Certificate"){
              this.downloadPdf();
            }

            setTimeout(() => {
              NetworkManager.apiRequest(
                "backend/applicant/sendSmsOrEmail",
                { review_status: "Approved", applicant_id: this.applicationData.id, account_number : e.account_number, account_type: "Fixed Deposit"},

                (e) => {
                  if(e.success == true){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else if(e.success == false){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }
                },
                true
              );
            }, 5000);
          }else if(e.success == false){
            this.$buefy.toast.open({
              message: e.message,
              type: "is-danger",
            });
          }else{
            this.$buefy.toast.open({
              message: "Failed to create account",
              type: "is-danger",
            });
          }
        },
        true
      );
    },

    approveMinorSavingsAccount(){
      NetworkManager.apiRequest(
        "backend/sdb/createMinorAccount",
        { 
          application_id : this.applicationData.id,
        },
        (e) => {
          // console.log("Minor Savings Account", e);
          if(e.success == true){
            NetworkManager.apiRequest(
              "backend/applicant/sendSmsOrEmail",
              { review_status: "Approved", applicant_id: this.applicationData.id, account_number : e.account_number, account_type: "Savings Account"},

              (e) => {
                if(e.success == true){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else if(e.success == false){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }
              },
              true
            );
          }else if(e.success == false){
            this.$buefy.toast.open({
              message: e.message,
              type: "is-danger",
            });
          }else{
            this.$buefy.toast.open({
              message: "Failed to create account",
              type: "is-danger",
            });
          }
        },
        true
      );
    },
    approveJointSavingsAccount(stat){
      if(stat.reviewAndNext == 1){
        NetworkManager.apiRequest(
          "backend/applicant/reviewedAndNextOwner",
          { 
            applicationId: this.applicationData.id
          },
          (e) => {
          if(e.success == true){
            this.$buefy.toast.open({
              message: e.data.message,
              type: "is-success",
            });
            this.jointOwner(1); //need to remove this in 2nd phase. (assume that the joint account has only 2 owners)
            this.isLoading = false;
            // location.reload();
          }else if(e.success == false){
            this.$buefy.toast.open({
              message: e.data.message,
              type: "is-danger",
            });
            location.reload();
          }
          },
          true
        );
      }else{
        NetworkManager.apiRequest(
        "backend/sdb/createJointSavingsAccount",
        { 
          applicationReferenceNum : this.$route.params.jointAcc,
        },
        (e) => {
          if(e.success == true){
            NetworkManager.apiRequest(
              "backend/applicant/sendSmsOrEmail",
              { review_status: "Approved", applicant_id: this.mainOwnerId, account_type: "Savings Account"},

              (e) => {
                if(e.success == true){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else if(e.success == false){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }
              },
              true
            );
          }else if (e.success == false){
            this.$buefy.toast.open({
              message: e.message,
              type: "is-danger",
            });
            setTimeout(() => {
              location.reload();
            }, 2000);
          }
        },
        true
      );
      }
    },

    async approveSavingApplication(){
      this.approveBtnLoading = true;
      await this.getDateOfBirthInJulian();
      this.currentTimeInSeconds();

      NetworkManager.apiRequest(
        "backend/sdb/createSavingAccount",
        { 
          id : this.applicationData.id,
          tin_activity_date : this.julianDate,
          date :this.julianDate,
          date_of_birth : this.julianDob,
          // date_of_birth : "1998234",
          // moved_in_date :this.julianDate,
          moved_in_date : "2020002",
          // customer_open_date :this.julianDate,
          customer_open_date : "2020002",
          cust_doc_activity :this.julianDate,

          user_epf : "002",
          time : this.unixEpochTime, 
          applicant_id: this.applicationData.id, 
        },
        (e) => {
          // console.log("Savings Account", e);
          if(e.success == true){
            NetworkManager.apiRequest(
              "backend/applicant/sendSmsOrEmail",
              { review_status: "Approved", applicant_id: this.applicationData.id, account_number : e.account_number, account_type: "Savings Account"},

              (e) => {
                if(e.success == true){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }else if(e.success == false){
                    this.$buefy.toast.open({
                      message: "Account Created Successfully",
                      type: "is-success",
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 2000);
                  }
              },
              true
            );
          }else if(e.success == false){
            this.$buefy.toast.open({
              message: e.message,
              type: "is-danger",
            });
          }else{
            this.$buefy.toast.open({
              message: "Failed to create account",
              type: "is-danger",
            });
          }
        },
        true
      );
    },
    
    pepCheckHandler(isPEPChecked) {
      NetworkManager.apiRequest(
        "backend/applicant/updatePEPStatus",
        { pep_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          isPEPChecked = 1;
          // console.log(e.data.list)
          this.$buefy.toast.open({
            message: "PEP Approved",
            type: "is-success",
          });


          // console.log(e.data);
          return isPEPChecked;
        },
        true
      );
    },

    centralizedOpsChangeHandler(isCentralizedOpsChecked) {
      NetworkManager.apiRequest(
        "backend/applicant/updateCentralizedOpsStatus",
        { centralized_ops_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id, owner_account_id: this.mainOwnerId },
        (e) => {
          if(e.success === true){
            isCentralizedOpsChecked = 1;
            // console.log(e.data.list)
            this.$buefy.toast.open({
              message: "Centralized Ops Approved",
              type: "is-success",
            });
            location.reload();

            return isCentralizedOpsChecked
          }
        },
        true

      );

    },

    sanctionListChangeHandler(isSanctionListChecked) {
      if(appSettings.usingSDBServer == true){
        NetworkManager.apiRequest(
          "backend/sdb/updateSanctionListStatus",
          { sanction_list_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id, nic_no: this.applicationData.nic },
          (e) => {
            if(e.success === true){
              isSanctionListChecked = 1;
              // console.log(e.data.list)
              this.$buefy.toast.open({
                message: e.message,
                type: "is-success",
              });
              if(this.applicationData.joint_acc && (this.mainOwnerId != this.applicationData.id)){ //if the application is a joint account and the application is not the first application
                this.jointOwner(1); //need to remove this in 2nd phase. (assume that the joint account has only 2 owners)
                this.isLoading = false;
              }else{
                location.reload();
              }
              return isSanctionListChecked

            }else if(e.success === false){
              this.$buefy.toast.open({
                message: e.message,
                type: "is-danger",
              });
              location.reload();
            }
          },
          true
        );
      }else{
        NetworkManager.apiRequest(
        "backend/applicant/updateSanctionListStatus",
        { sanction_list_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id, nic_no: this.applicationData.nic },
        (e) => {
          if(e.success === true){
            isSanctionListChecked = 1;
            // console.log(e.data.list)
            this.$buefy.toast.open({
              message: "Sanction List Checked",
              type: "is-success",
            });
            if(this.applicationData.joint_acc && (this.mainOwnerId != this.applicationData.id)){ //if the application is a joint account and the application is not the first application
                this.jointOwner(1); //need to remove this in 2nd phase. (assume that the joint account has only 2 owners)
                this.isLoading = false;
            }else{
              location.reload();
            }
            return isSanctionListChecked
          }
        },
        true

      );
      }
    },

    agreedRadioButton(isAgreed) {
      NetworkManager.apiRequest(
        "backend/applicant/updateDebitCardTermsAndConditions",
        { debit_card_declaration: isAgreed, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          isAgreed = 1;
          // console.log(e.data);
          return isAgreed;
        },
        true
      );
    },

    //Joint acc applications with 2 or 3 owners
    jointOwner(id){
      NetworkManager.apiRequest(
      "backend/applicant/getApplicantDataListById",
      { applicant_id: this.$route.params.id, joint_acc: this.$route.params.jointAcc},
      (e) => {
        if (e.success === true) {
          this.$router.push({
            name: "pending-applications-view",
            params: { id: e.data.list[id].id, jointAcc: e.data.list[id].joint_acc},
          });
          // console.log(id,e.data.list.length );
          //get the list length
          this.listLength = e.data.list.length;
          //Set the main owner id as the first object id of the list (The first object is the main owner)
          this.mainOwnerId = e.data.list[0].id;

          if(this.listLength-1 == id){ //if the last object of the list is selected
            e.data.list[id].reviewAndNxt = 0; //set the reviewAndNxt value to 0
            for (let index = (id -1); index >= 0; index--) { //loop through the list from the last object to the first object
              e.data.list[index].reviewAndNxt = 1; 
            }      
          }else{ //if the first or the middle object of the list is selected
              e.data.list[id].reviewAndNxt = 1;
              for (let index = 0; index < this.listLength; index++) { 
                if(index < id){
                  e.data.list[index].reviewAndNxt = 1;
                }else if(index > id){
                  e.data.list[index].reviewAndNxt = 0;
                } 
              }
            }
            
          if(id == 0){
            e.data.list[0].jointReviewStatus = 'firstOwner'; 
          }else{
            e.data.list[id].jointReviewStatus = e.data.list[id-1].review_status; //set the review status of the previous object to the jointReviewStatus of the current object
          }

          //------- convert the monthly_income value format using thousands separator start --------
          if(e.data.list[id].monthly_income && e.data.list[id].monthly_income != null){
              const monthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[id].monthly_income);
              if (monthlyIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = monthlyIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[id].monthly_income = "Rs." + monthlyIncome + "0";
                }else{
                  e.data.list[id].monthly_income = "Rs." + monthlyIncome;
                }
              } else {
                e.data.list[id].monthly_income = "Rs." + monthlyIncome + ".00";
              }
          }
          //------- convert the monthly_income value format using thousands separator end ------- 

          //------- convert the workplace_monthly_income value format using thousands separator start ------- 
          if(e.data.list[id].workplace_monthly_income && e.data.list[id].workplace_monthly_income != null){
            const workplaceMonthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[id].workplace_monthly_income);
            if (workplaceMonthlyIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = workplaceMonthlyIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + "0";
              }else{
                e.data.list[id].workplace_monthly_income = "Rs." + workplaceMonthlyIncome;
              }
            } else {
              e.data.list[id].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + ".00";
            }
          }
          //------- convert the workplace_monthly_income value format using thousands separator end -------

          //------- convert the business_income value format using thousands separator start ------- 
          if(e.data.list[id].business_income && e.data.list[id].business_income != null){
            const businessIncome = ThousandsSeparator.separateByThousands(e.data.list[id].business_income);
            if (businessIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = businessIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].business_income = "Rs." + businessIncome + "0";
              }else{
                e.data.list[id].business_income = "Rs." + businessIncome;
              }
            } else {
              e.data.list[id].business_income = "Rs." + businessIncome + ".00";
            }
          }
          //------- convert the business_income value format using thousands separator end ------- 

          //------- convert the business_total_income value format using thousands separator start ------- 
          if(e.data.list[id].business_total_income && e.data.list[id].business_total_income != null){
            const businessTotalIncome = ThousandsSeparator.separateByThousands(e.data.list[id].business_total_income);
            if (businessTotalIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = businessTotalIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].business_total_income = "Rs." + businessTotalIncome + "0";
              }else{
                e.data.list[id].business_total_income = "Rs." + businessTotalIncome;
              }
            } else {
              e.data.list[id].business_total_income = "Rs." + businessTotalIncome + ".00";
            }
          }
          //------- convert the business_total_income value format using thousands separator end ------- 

          //------- convert the workplace_pension_amount value format using thousands separator start ------- 
          if(e.data.list[id].workplace_pension_amount && e.data.list[id].workplace_pension_amount != null){
            const workplacePensionAmount = ThousandsSeparator.separateByThousands(e.data.list[id].workplace_pension_amount);
            if (workplacePensionAmount.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = workplacePensionAmount.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].workplace_pension_amount = "Rs." + workplacePensionAmount + "0";
              }else{
                e.data.list[id].workplace_pension_amount = "Rs." + workplacePensionAmount;
              }
            } else {
              e.data.list[id].workplace_pension_amount = "Rs." + workplacePensionAmount + ".00";
            }
          }
          //------- convert the workplace_pension_amount value format using thousands separator end ------- 

          //------- convert the workplace_other_income value format using thousands separator start ------- 
          if(e.data.list[id].workplace_other_income && e.data.list[id].workplace_other_income != null){
            const workplaceOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[id].workplace_other_income);
            if (workplaceOtherIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = workplaceOtherIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].workplace_other_income = "Rs." + workplaceOtherIncome + "0";
              }else{
                e.data.list[id].workplace_other_income = "Rs." + workplaceOtherIncome;
              }
            } else {
              e.data.list[id].workplace_other_income = "Rs." + workplaceOtherIncome + ".00";
            }
          }
          //------- convert the workplace_other_income value format using thousands separator end ------- 

          //------- convert the business_other_income value format using thousands separator start ------- 
          if(e.data.list[0].business_other_income != null){
              const businessOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_other_income);
              if (businessOtherIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessOtherIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_other_income = "Rs." + businessOtherIncome + "0";
                }else{
                  e.data.list[0].business_other_income = "Rs." + businessOtherIncome;
                }
              } else {
                e.data.list[0].business_other_income = "Rs." + businessOtherIncome + ".00";
              }
            }     
            //------- convert the business_other_income value format using thousands separator end -------

          //------- convert the lakdaru_transfer_amount value format using thousands separator start ------- 
          if(e.data.list[id].lakdaru_transfer_amount && e.data.list[id].lakdaru_transfer_amount != null){
            const lakdaruTransferAmount = ThousandsSeparator.separateByThousands(e.data.list[id].lakdaru_transfer_amount);
            if (lakdaruTransferAmount.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = lakdaruTransferAmount.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + "0";
              }else{
                e.data.list[id].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount;
              }
            } else {
              e.data.list[id].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + ".00";
            }
          }
          //------- convert the lakdaru_transfer_amount value format using thousands separator end ------- 

          //------- convert the fd_deposit value format using thousands separator start ----------
          if(e.data.list[id].applicant_individual_account_type && e.data.list[id].applicant_individual_account_type == 'Fixed Deposit'){
            // this.fdDepositValue = e.data.list[0].fd_deposit.toString(); //  This should comment after updating the fd_deposit to the text
            this.fdDepositValue = e.data.list[id].fd_deposit; //  This should uncomment after updating the fd_deposit to the text
            const fdDeposit = ThousandsSeparator.separateByThousands(this.fdDepositValue, this.convertedFdDepositValue) 
            this.convertedFdDepositValue = fdDeposit;
          
            // Check if the value is a decimal number
            if (this.convertedFdDepositValue.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = this.convertedFdDepositValue.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[id].fd_deposit = "Rs." + fdDeposit + "0";
                }else{
                  e.data.list[id].fd_deposit = "Rs." + fdDeposit;
                }
            } else {
                e.data.list[id].fd_deposit = "Rs." + fdDeposit + ".00";
            }
          }
          //------- convert the fd_deposit value format using thousands separator end ----------

          //------- convert the investment_deposit value format using thousands separator start --------
          if(e.data.list[id].investment_deposit && (e.data.list[id].investment_deposit != null)){
            const investmentDeposit = ThousandsSeparator.separateByThousands(e.data.list[id].investment_deposit);
            if (investmentDeposit.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = investmentDeposit.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].investment_deposit = "Rs." + investmentDeposit + "0";
              }else{
                e.data.list[id].investment_deposit = "Rs." + investmentDeposit;
              }
            } else {
              e.data.list[id].investment_deposit = "Rs." + investmentDeposit + ".00";
            }
          }
          //------- convert the investment_deposit value format using thousands separator end --------

          this.applicationData = e.data.list[id]; //set the current object to the applicationData
          // console.log("jointOwner",this.applicationData)
          this.comments = JSON.parse(e.data.list[id].comment); 
          } else {
            this.isLoading = false;
          }
        },
        true
      );
    },

    getCurrentJulianDate(){
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formattedDay = day < 10 ? '0' + day : day;
      const formattedMonth = month < 10 ? '0' + month : month;
      const formattedDate = formattedDay + formattedMonth + year;
      if(appSettings.usingSDBServer == true){
        NetworkManager.apiRequest(
          "backend/sdb/getJulianDate",
          { cdate: formattedDate},
          (e) => {
            
            if (e.success === true) {
              this.julianDate = e.julianDate;
              // console.log("getCurrentJulianDate",e.julianDate);
            } else {
              // console.log("Error in getting julian date")
            }
          },
          true
        );
      }
    },

    formDataRequestHandler() {
      // const queryString = window.location.search;
      // let formID = queryString.replace(/^\D+/g, '')

      // console.log(">>>>>>",formID);
      this.isLoading = true;
      setTimeout(() => {
          this.isLoading = false
      }, 2000)
      NetworkManager.apiRequest(
        "backend/applicant/getApplicantDataListById",
        { applicant_id: this.$route.params.id, joint_acc: this.$route.params.jointAcc},
        (e) => {
          
          if (e.success === true) {
            this.listLength = e.data.list.length;

            //If list length is 2 or 3 then it's a joint account
            if(this.listLength == 2 && e.data.list[0].joint_acc != null){
              e.data.list[0].reviewAndNxt = 1;
              this.mainOwnerId = e.data.list[0].id;
            }if(this.listLength == 3 && e.data.list[0].joint_acc != null){
              e.data.list[0].reviewAndNxt = 1;
              e.data.list[1].reviewAndNxt = 1;
              this.mainOwnerId = e.data.list[0].id
            }
            e.data.list[0].jointReviewStatus = 'firstOwner';

            //------- convert the saving_maturity_value value format using thousands separator start --------
            if(e.data.list[0].saving_maturity_value && (e.data.list[0].saving_maturity_value != null && e.data.list[0].saving_maturity_value != "null")){
              const savingMaturityValue = ThousandsSeparator.separateByThousands(e.data.list[0].saving_maturity_value);
              if (savingMaturityValue.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = savingMaturityValue.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].saving_maturity_value = "Rs." + savingMaturityValue + "0";
                }else{
                  e.data.list[0].saving_maturity_value = "Rs." + savingMaturityValue;
                }
              } else {
                e.data.list[0].saving_maturity_value = "Rs." + savingMaturityValue + ".00";
              }
            }
            //------- convert the saving_maturity_value value format using thousands separator end --------

            //------- convert the monthly_income value format using thousands separator start --------
            if(e.data.list[0].monthly_income && e.data.list[0].monthly_income != null){
              const monthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[0].monthly_income);
              if (monthlyIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = monthlyIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].monthly_income = "Rs." + monthlyIncome + "0";
                }else{
                  e.data.list[0].monthly_income = "Rs." + monthlyIncome;
                }
              } else {
                e.data.list[0].monthly_income = "Rs." + monthlyIncome + ".00";
              }
            }
            //------- convert the monthly_income value format using thousands separator end --------

            //------- convert the workplace_monthly_income value format using thousands separator start ------- 
            if(e.data.list[0].workplace_monthly_income != null){
              const workplaceMonthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[0].workplace_monthly_income);
              if (workplaceMonthlyIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = workplaceMonthlyIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + "0";
                }else{
                  e.data.list[0].workplace_monthly_income = "Rs." + workplaceMonthlyIncome;
                }
              } else {
                e.data.list[0].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + ".00";
              }
            }       
            //------- convert the workplace_monthly_income value format using thousands separator end ------- 

            //------- convert the workplace_other_income value format using thousands separator start ------- 
            if(e.data.list[0].workplace_other_income != null){
              const workplaceOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[0].workplace_other_income);
              if (workplaceOtherIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = workplaceOtherIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].workplace_other_income = "Rs." + workplaceOtherIncome + "0";
                }else{
                  e.data.list[0].workplace_other_income = "Rs." + workplaceOtherIncome;
                }
              } else {
                e.data.list[0].workplace_other_income = "Rs." + workplaceOtherIncome + ".00";
              }
            }     
            //------- convert the workplace_other_income value format using thousands separator end ------- 

            //------- convert the business_other_income value format using thousands separator start ------- 
            if(e.data.list[0].business_other_income != null){
              const businessOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_other_income);
              if (businessOtherIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessOtherIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_other_income = "Rs." + businessOtherIncome + "0";
                }else{
                  e.data.list[0].business_other_income = "Rs." + businessOtherIncome;
                }
              } else {
                e.data.list[0].business_other_income = "Rs." + businessOtherIncome + ".00";
              }
            }     
            //------- convert the business_other_income value format using thousands separator end ------- 

            //------- convert the business_income value format using thousands separator start ------- 
            if(e.data.list[0].business_income != null){
              const businessIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_income);
              if (businessIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_income = "Rs." + businessIncome + "0";
                }else{
                  e.data.list[0].business_income = "Rs." + businessIncome;
                }
              } else {
                e.data.list[0].business_income = "Rs." + businessIncome + ".00";
              }
            }
            //------- convert the business_income value format using thousands separator end ------- 

            //------- convert the business_total_income value format using thousands separator start ------- 
            if(e.data.list[0].business_total_income != null){
              const businessTotalIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_total_income);
              if (businessTotalIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessTotalIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_total_income = "Rs." + businessTotalIncome + "0";
                }else{
                  e.data.list[0].business_total_income = "Rs." + businessTotalIncome;
                }
              } else {
                e.data.list[0].business_total_income = "Rs." + businessTotalIncome + ".00";
              }
            }
            //------- convert the business_total_income value format using thousands separator end ------- 

            //------- convert the workplace_pension_amount value format using thousands separator start ------- 
            if(e.data.list[0].workplace_pension_amount != null){
              const workplacePensionAmount = ThousandsSeparator.separateByThousands(e.data.list[0].workplace_pension_amount);
              if (workplacePensionAmount.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = workplacePensionAmount.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].workplace_pension_amount = "Rs." + workplacePensionAmount + "0";
                }else{
                  e.data.list[0].workplace_pension_amount = "Rs." + workplacePensionAmount;
                }
              } else {
                e.data.list[0].workplace_pension_amount = "Rs." + workplacePensionAmount + ".00";
              }
            }
            //------- convert the workplace_pension_amount value format using thousands separator end ------- 

            //------- convert the lakdaru_transfer_amount value format using thousands separator start ------- 
            if(e.data.list[0].lakdaru_transfer_amount != null){
              const lakdaruTransferAmount = ThousandsSeparator.separateByThousands(e.data.list[0].lakdaru_transfer_amount);
              if (lakdaruTransferAmount.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = lakdaruTransferAmount.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + "0";
                }else{
                  e.data.list[0].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount;
                }
              } else {
                e.data.list[0].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + ".00";
              }
            }
            //------- convert the lakdaru_transfer_amount value format using thousands separator end ------- 

            //------- convert the fd_deposit value format using thousands separator start ----------
            if(e.data.list[0].applicant_individual_account_type && e.data.list[0].applicant_individual_account_type == 'Fixed Deposit'){
              // this.fdDepositValue = e.data.list[0].fd_deposit.toString(); //  This should comment after updating the fd_deposit to the text
              this.fdDepositValue = e.data.list[0].fd_deposit; //  This should uncomment after updating the fd_deposit to the text
              const fdDeposit = ThousandsSeparator.separateByThousands(this.fdDepositValue, this.convertedFdDepositValue);

              this.convertedFdDepositValue = fdDeposit;
        
              // Check if the value is a decimal number
              if (this.convertedFdDepositValue.includes('.')) {
                  // Split the string at the decimal point and count the characters after the dot
                  let decimalPart = this.convertedFdDepositValue.split('.')[1];
                  let numberOfDecimalPlaces = decimalPart.length;
                  if(numberOfDecimalPlaces == 1){
                    e.data.list[0].fd_deposit = "Rs." + fdDeposit + "0";
                  }else{
                    e.data.list[0].fd_deposit = "Rs." + fdDeposit;
                  }
              } else {
                  e.data.list[0].fd_deposit = "Rs." + fdDeposit + ".00";
              }
            }
            //------- convert the fd_deposit value format using thousands separator end ----------

            //------- convert the investment_deposit value format using thousands separator start --------
            if(e.data.list[0].investment_deposit && (e.data.list[0].investment_deposit != null)){
              const investmentDeposit = ThousandsSeparator.separateByThousands(e.data.list[0].investment_deposit);
              if (investmentDeposit.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = investmentDeposit.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].investment_deposit = "Rs." + investmentDeposit + "0";
                }else{
                  e.data.list[0].investment_deposit = "Rs." + investmentDeposit;
                }
              } else {
                e.data.list[0].investment_deposit = "Rs." + investmentDeposit + ".00";
              }
            }
            //------- convert the investment_deposit value format using thousands separator end --------

            this.applicationData = e.data.list[0];
            this.comments = JSON.parse(e.data.list[0].comment);
          } else {
            this.isLoading = false;
          }
        },
        true
      );
    },
  },
  
  mounted() {
    this.formDataRequestHandler();
    this.getCurrentJulianDate();
  },
};
</script>
