<template>

  <ApplicationViewVue
    :viewData="applicationData"
    :comments="comments"
    :isLoading="isLoading"
    :isFullPage="isFullPage"
    :listLength="listLength"
  
    @userCommentSubmitHandler="
      (userComment) => {
        userCommentSubmitHandler(userComment);
      }
    "
    @checkboxChangeHandler="
      (value) => {
        pepCheckHandler(value);
      }
    "
    @agreedRadioButton="
      (value) => {
        agreedRadioButton(value);
      }
    "
    @centralizedOpsChangeHandler="
      (value) => {
        centralizedOpsChangeHandler(value);
      }
    "	
    @sanctionListChangeHandler="
      (value) => {
        sanctionListChangeHandler(value);
      }
    "	
    @userTwo="()=>{
      jointOwner(1);
    }
    "
    @userThree="()=>{
      jointOwner(2);
    }
    "
    @userOne="()=>{
      jointOwner(0);
    }
    "
  />

</template>

<script>
import ApplicationViewVue from "@/components/ApplicationView.vue";
import NetworkManager from "@/network";
import { useAuthStore } from "@/store/authStore";
import ThousandsSeparator from "../../helpers/thousandSeperator";

export default {
  name: "RejectedApplicationView",
  components: {
    ApplicationViewVue,
  },

  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },

  data() {
    return {
      applicationData: [],
      comments: [],
      isLoading: true,
      isFullPage: false,
      listLength: 0,
      mainOwnerId: null,
      monthlyIncomeValue : "",
      convertedMonthlyIncomeValue : "",
      convertedFdDepositValue : "",
      fdDepositValue:"",
    };
  },

  methods: {
    userCommentSubmitHandler(comment) {
      if (!comment.trim()) {
        this.$buefy.toast.open({
          message: "Please enter a comment",
          type: "is-danger",
        });
        return;
      }
      NetworkManager.apiRequest(
        "backend/applicant/addCommentForReviewedApplication",
        { comment: comment, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          // console.log(e.data.list)

          // console.log("comment send");
          window.location.reload();
        },
        true
      );
    },

    centralizedOpsChangeHandler(isCentralizedOpsChecked) {
      NetworkManager.apiRequest(
        "backend/applicant/updateCentralizedOpsStatus",
        { centralized_ops_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          if(e.success === true){
            isCentralizedOpsChecked = 1;
            // console.log(e.data.list)
            this.$buefy.toast.open({
              message: "Centralized Ops Approved",
              type: "is-success",
            });
            location.reload();

            return isCentralizedOpsChecked
          }
        },
        true

      );

    },

    sanctionListChangeHandler(isSanctionListChecked) {
      NetworkManager.apiRequest(
        "backend/applicant/updateSanctionListStatus",
        { sanction_list_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          if(e.success === true){
            isSanctionListChecked = 1;
            // console.log(e.data.list)
            this.$buefy.toast.open({
              message: "Sanction List Checked",
              type: "is-success",
            });
            location.reload();

            return isSanctionListChecked
          }
        },
        true

      );

    },

    agreedRadioButton(isAgreed) {
      NetworkManager.apiRequest(
        "backend/applicant/updateDebitCardTermsAndConditions",
        { debit_card_declaration: isAgreed, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          isAgreed = 1;
          // console.log(e.data);
          return isAgreed;
        },
        true
      );
    },

    pepCheckHandler(isPEPChecked) {
      NetworkManager.apiRequest(
        "backend/applicant/updatePEPStatus",
        { pep_status: 1, applicant_id: this.applicationData.id, id: this.applicationData.id },
        (e) => {
          isPEPChecked = 1;
          // console.log(e.data.list)
          this.$buefy.toast.open({
            message: "PEP Approved",
            type: "is-success",
          });

          // console.log(e.data);
          return isPEPChecked;
        },
        true
      );
    },

    //Joint acc applications with 2 or 3 owners
    jointOwner(id){
      NetworkManager.apiRequest(
      "backend/applicant/getApplicantDataListById",
      { applicant_id: this.$route.params.id, joint_acc: this.$route.params.jointAcc},
      (e) => {
        if (e.success === true) {
          this.$router.push({
            name: "rejected-applications-view",
            params: { id: e.data.list[id].id, jointAcc: e.data.list[id].joint_acc},
          });
          // console.log(id,e.data.list.length );
          //get the list length
          this.listLength = e.data.list.length;
          //Set the main owner id as the first object id of the list (The first object is the main owner)
          this.mainOwnerId = e.data.list[0].id;

          if(this.listLength-1 == id){ //if the last object of the list is selected
            e.data.list[id].reviewAndNxt = 0; //set the reviewAndNxt value to 0
            for (let index = (id -1); index >= 0; index--) { //loop through the list from the last object to the first object
              e.data.list[index].reviewAndNxt = 1; 
            }      
          }else{ //if the first or the middle object of the list is selected
              e.data.list[id].reviewAndNxt = 1;
              for (let index = 0; index < this.listLength; index++) { 
                if(index < id){
                  e.data.list[index].reviewAndNxt = 1;
                }else if(index > id){
                  e.data.list[index].reviewAndNxt = 0;
                } 
              }
            }
          
          if(id == 0){
            e.data.list[0].jointReviewStatus = 'firstOwner'; 
          }else{
            e.data.list[id].jointReviewStatus = e.data.list[id-1].review_status; //set the review status of the previous object to the jointReviewStatus of the current object
          }

          //------- convert the monthly_income value format using thousands separator start --------
          if(e.data.list[id].monthly_income && e.data.list[id].monthly_income != null){
              const monthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[id].monthly_income);
              if (monthlyIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = monthlyIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[id].monthly_income = "Rs." + monthlyIncome + "0";
                }else{
                  e.data.list[id].monthly_income = "Rs." + monthlyIncome;
                }
              } else {
                e.data.list[id].monthly_income = "Rs." + monthlyIncome + ".00";
              }
          }
          //------- convert the monthly_income value format using thousands separator end ------- 

          //------- convert the workplace_monthly_income value format using thousands separator start ------- 
          if(e.data.list[id].workplace_monthly_income && e.data.list[id].workplace_monthly_income != null){
            const workplaceMonthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[id].workplace_monthly_income);
            if (workplaceMonthlyIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = workplaceMonthlyIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + "0";
              }else{
                e.data.list[id].workplace_monthly_income = "Rs." + workplaceMonthlyIncome;
              }
            } else {
              e.data.list[id].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + ".00";
            }
          }
          //------- convert the workplace_monthly_income value format using thousands separator end -------

          //------- convert the business_income value format using thousands separator start ------- 
          if(e.data.list[id].business_income && e.data.list[id].business_income != null){
            const businessIncome = ThousandsSeparator.separateByThousands(e.data.list[id].business_income);
            if (businessIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = businessIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].business_income = "Rs." + businessIncome + "0";
              }else{
                e.data.list[id].business_income = "Rs." + businessIncome;
              }
            } else {
              e.data.list[id].business_income = "Rs." + businessIncome + ".00";
            }
          }
          //------- convert the business_income value format using thousands separator end ------- 

          //------- convert the business_total_income value format using thousands separator start ------- 
          if(e.data.list[id].business_total_income && e.data.list[id].business_total_income != null){
            const businessTotalIncome = ThousandsSeparator.separateByThousands(e.data.list[id].business_total_income);
            if (businessTotalIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = businessTotalIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].business_total_income = "Rs." + businessTotalIncome + "0";
              }else{
                e.data.list[id].business_total_income = "Rs." + businessTotalIncome;
              }
            } else {
              e.data.list[id].business_total_income = "Rs." + businessTotalIncome + ".00";
            }
          }
          //------- convert the business_total_income value format using thousands separator end ------- 

          //------- convert the workplace_pension_amount value format using thousands separator start ------- 
          if(e.data.list[id].workplace_pension_amount && e.data.list[id].workplace_pension_amount != null){
            const workplacePensionAmount = ThousandsSeparator.separateByThousands(e.data.list[id].workplace_pension_amount);
            if (workplacePensionAmount.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = workplacePensionAmount.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].workplace_pension_amount = "Rs." + workplacePensionAmount + "0";
              }else{
                e.data.list[id].workplace_pension_amount = "Rs." + workplacePensionAmount;
              }
            } else {
              e.data.list[id].workplace_pension_amount = "Rs." + workplacePensionAmount + ".00";
            }
          }
          //------- convert the workplace_pension_amount value format using thousands separator end ------- 

          //------- convert the workplace_other_income value format using thousands separator start ------- 
          if(e.data.list[id].workplace_other_income && e.data.list[id].workplace_other_income != null){
            const workplaceOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[id].workplace_other_income);
            if (workplaceOtherIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = workplaceOtherIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].workplace_other_income = "Rs." + workplaceOtherIncome + "0";
              }else{
                e.data.list[id].workplace_other_income = "Rs." + workplaceOtherIncome;
              }
            } else {
              e.data.list[id].workplace_other_income = "Rs." + workplaceOtherIncome + ".00";
            }
          }
          //------- convert the workplace_other_income value format using thousands separator end ------- 

          //------- convert the business_other_income value format using thousands separator start ------- 
          if(e.data.list[id].business_other_income && e.data.list[id].business_other_income != null){
            const businessOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[id].business_other_income);
            if (businessOtherIncome.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = businessOtherIncome.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].business_other_income = "Rs." + businessOtherIncome + "0";
              }else{
                e.data.list[id].business_other_income = "Rs." + businessOtherIncome;
              }
            } else {
              e.data.list[id].business_other_income = "Rs." + businessOtherIncome + ".00";
            }
          }
          //------- convert the business_other_income value format using thousands separator end -------


          //------- convert the lakdaru_transfer_amount value format using thousands separator start ------- 
          if(e.data.list[id].lakdaru_transfer_amount && e.data.list[id].lakdaru_transfer_amount != null){
            const lakdaruTransferAmount = ThousandsSeparator.separateByThousands(e.data.list[id].lakdaru_transfer_amount);
            if (lakdaruTransferAmount.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = lakdaruTransferAmount.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + "0";
              }else{
                e.data.list[id].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount;
              }
            } else {
              e.data.list[id].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + ".00";
            }
          }
          //------- convert the lakdaru_transfer_amount value format using thousands separator end ------- 

          //------- convert the fd_deposit value format using thousands separator start ----------
          if(e.data.list[id].applicant_individual_account_type && e.data.list[id].applicant_individual_account_type == 'Fixed Deposit'){
            // this.fdDepositValue = e.data.list[0].fd_deposit.toString(); //  This should comment after updating the fd_deposit to the text
            this.fdDepositValue = e.data.list[id].fd_deposit; //  This should uncomment after updating the fd_deposit to the text
            const fdDeposit = ThousandsSeparator.separateByThousands(this.fdDepositValue, this.convertedFdDepositValue) 
            this.convertedFdDepositValue = fdDeposit;
          
            // Check if the value is a decimal number
            if (this.convertedFdDepositValue.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = this.convertedFdDepositValue.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[id].fd_deposit = "Rs." + fdDeposit + "0";
                }else{
                  e.data.list[id].fd_deposit = "Rs." + fdDeposit;
                }
            } else {
                e.data.list[id].fd_deposit = "Rs." + fdDeposit + ".00";
            }
          }
          //------- convert the fd_deposit value format using thousands separator end ----------

          //------- convert the investment_deposit value format using thousands separator start --------
          if(e.data.list[id].investment_deposit && (e.data.list[id].investment_deposit != null)){
            const investmentDeposit = ThousandsSeparator.separateByThousands(e.data.list[id].investment_deposit);
            if (investmentDeposit.includes('.')) {
              // Split the string at the decimal point and count the characters after the dot
              let decimalPart = investmentDeposit.split('.')[1];
              let numberOfDecimalPlaces = decimalPart.length;
              if(numberOfDecimalPlaces == 1){
                e.data.list[id].investment_deposit = "Rs." + investmentDeposit + "0";
              }else{
                e.data.list[id].investment_deposit = "Rs." + investmentDeposit;
              }
            } else {
              e.data.list[id].investment_deposit = "Rs." + investmentDeposit + ".00";
            }
          }
          //------- convert the investment_deposit value format using thousands separator end --------

          this.applicationData = e.data.list[id]; //set the current object to the applicationData
          // console.log("jointOwner",this.applicationData)
          this.comments = JSON.parse(e.data.list[id].comment); 
          } else {
            this.isLoading = false;
          }
        },
        true
      );
    },

    formDataRequestHandler() {
      // const queryString = window.location.search;
      // let formID = queryString.replace(/^\D+/g, '')

      // console.log(">>>>>>",formID);
      this.isLoading = true;
      setTimeout(() => {
          this.isLoading = false
      }, 2000)
      NetworkManager.apiRequest(
        "backend/applicant/getApplicantDataListById",
        { applicant_id: this.$route.params.id, joint_acc: this.$route.params.jointAcc},
        (e) => {
          
          if (e.success === true) {
            // console.log(e.data);
            this.listLength = e.data.list.length;

            //If list length is 2 or 3 then it's a joint account
            if(this.listLength == 2){
              e.data.list[0].reviewAndNxt = 1;
              this.mainOwnerId = e.data.list[0].id;
            }if(this.listLength == 3){
              e.data.list[0].reviewAndNxt = 1;
              e.data.list[1].reviewAndNxt = 1;
              this.mainOwnerId = e.data.list[0].id;
            }
            e.data.list[0].jointReviewStatus = 'firstOwner';

            //------- convert the saving_maturity_value value format using thousands separator start --------
            if(e.data.list[0].saving_maturity_value && (e.data.list[0].saving_maturity_value != null && e.data.list[0].saving_maturity_value != "null")){
              const savingMaturityValue = ThousandsSeparator.separateByThousands(e.data.list[0].saving_maturity_value);
              if (savingMaturityValue.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = savingMaturityValue.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].saving_maturity_value = "Rs." + savingMaturityValue + "0";
                }else{
                  e.data.list[0].saving_maturity_value = "Rs." + savingMaturityValue;
                }
              } else {
                e.data.list[0].saving_maturity_value = "Rs." + savingMaturityValue + ".00";
              }
            }
            //------- convert the saving_maturity_value value format using thousands separator end --------
            
            //------- convert the monthly_income value format using thousands separator start --------
            if(e.data.list[0].monthly_income != null){
              const monthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[0].monthly_income);
              if (monthlyIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = monthlyIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].monthly_income = "Rs." + monthlyIncome + "0";
                }else{
                  e.data.list[0].monthly_income = "Rs." + monthlyIncome;
                }
              } else {
                e.data.list[0].monthly_income = "Rs." + monthlyIncome + ".00";
              }
            }
            //------- convert the monthly_income value format using thousands separator end --------

            //------- convert the workplace_monthly_income value format using thousands separator start ------- 
            if(e.data.list[0].workplace_monthly_income != null){
              const workplaceMonthlyIncome = ThousandsSeparator.separateByThousands(e.data.list[0].workplace_monthly_income);
              if (workplaceMonthlyIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = workplaceMonthlyIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + "0";
                }else{
                  e.data.list[0].workplace_monthly_income = "Rs." + workplaceMonthlyIncome;
                }
              } else {
                e.data.list[0].workplace_monthly_income = "Rs." + workplaceMonthlyIncome + ".00";
              }
            }       
            //------- convert the workplace_monthly_income value format using thousands separator end ------- 

            //------- convert the workplace_other_income value format using thousands separator start ------- 
            if(e.data.list[0].workplace_other_income != null){
              const workplaceOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[0].workplace_other_income);
              if (workplaceOtherIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = workplaceOtherIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].workplace_other_income = "Rs." + workplaceOtherIncome + "0";
                }else{
                  e.data.list[0].workplace_other_income = "Rs." + workplaceOtherIncome;
                }
              } else {
                e.data.list[0].workplace_other_income = "Rs." + workplaceOtherIncome + ".00";
              }
            }     
            //------- convert the workplace_other_income value format using thousands separator end ------- 

            //------- convert the business_other_income value format using thousands separator start ------- 
            if(e.data.list[0].business_other_income != null){
              const businessOtherIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_other_income);
              if (businessOtherIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessOtherIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_other_income = "Rs." + businessOtherIncome + "0";
                }else{
                  e.data.list[0].business_other_income = "Rs." + businessOtherIncome;
                }
              } else {
                e.data.list[0].business_other_income = "Rs." + businessOtherIncome + ".00";
              }
            }
            //------- convert the business_other_income value format using thousands separator end -------


            //------- convert the business_income value format using thousands separator start ------- 
            if(e.data.list[0].business_income != null){
              const businessIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_income);
              if (businessIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_income = "Rs." + businessIncome + "0";
                }else{
                  e.data.list[0].business_income = "Rs." + businessIncome;
                }
              } else {
                e.data.list[0].business_income = "Rs." + businessIncome + ".00";
              }
            }
            //------- convert the business_income value format using thousands separator end ------- 

            //------- convert the business_total_income value format using thousands separator start ------- 
            if(e.data.list[0].business_total_income != null){
              const businessTotalIncome = ThousandsSeparator.separateByThousands(e.data.list[0].business_total_income);
              if (businessTotalIncome.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = businessTotalIncome.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].business_total_income = "Rs." + businessTotalIncome + "0";
                }else{
                  e.data.list[0].business_total_income = "Rs." + businessTotalIncome;
                }
              } else {
                e.data.list[0].business_total_income = "Rs." + businessTotalIncome + ".00";
              }
            }
            //------- convert the business_total_income value format using thousands separator end ------- 

            //------- convert the workplace_pension_amount value format using thousands separator start ------- 
            if(e.data.list[0].workplace_pension_amount != null){
              const workplacePensionAmount = ThousandsSeparator.separateByThousands(e.data.list[0].workplace_pension_amount);
              if (workplacePensionAmount.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = workplacePensionAmount.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].workplace_pension_amount = "Rs." + workplacePensionAmount + "0";
                }else{
                  e.data.list[0].workplace_pension_amount = "Rs." + workplacePensionAmount;
                }
              } else {
                e.data.list[0].workplace_pension_amount = "Rs." + workplacePensionAmount + ".00";
              }
            }
            //------- convert the workplace_pension_amount value format using thousands separator end -------

            //------- convert the lakdaru_transfer_amount value format using thousands separator start ------- 
            if(e.data.list[0].lakdaru_transfer_amount != null){
              const lakdaruTransferAmount = ThousandsSeparator.separateByThousands(e.data.list[0].lakdaru_transfer_amount);
              if (lakdaruTransferAmount.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = lakdaruTransferAmount.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + "0";
                }else{
                  e.data.list[0].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount;
                }
              } else {
                e.data.list[0].lakdaru_transfer_amount = "Rs." + lakdaruTransferAmount + ".00";
              }
            }
            //------- convert the lakdaru_transfer_amount value format using thousands separator end ------- 

            //------- convert the fd_deposit value format using thousands separator start ----------
            if(e.data.list[0].applicant_individual_account_type == 'Fixed Deposit'){
              // this.fdDepositValue = e.data.list[0].fd_deposit.toString(); //  This should comment after updating the fd_deposit to the text
              this.fdDepositValue = e.data.list[0].fd_deposit; //  This should uncomment after updating the fd_deposit to the text
              const fdDeposit = ThousandsSeparator.separateByThousands(this.fdDepositValue, this.convertedFdDepositValue);

              this.convertedFdDepositValue = fdDeposit;
        
              // Check if the value is a decimal number
              if (this.convertedFdDepositValue.includes('.')) {
                  // Split the string at the decimal point and count the characters after the dot
                  let decimalPart = this.convertedFdDepositValue.split('.')[1];
                  let numberOfDecimalPlaces = decimalPart.length;
                  if(numberOfDecimalPlaces == 1){
                    e.data.list[0].fd_deposit = "Rs." + fdDeposit + "0";
                  }else{
                    e.data.list[0].fd_deposit = "Rs." + fdDeposit;
                  }
              } else {
                  e.data.list[0].fd_deposit = "Rs." + fdDeposit + ".00";
              }
            }
            //------- convert the fd_deposit value format using thousands separator end ----------

            //------- convert the investment_deposit value format using thousands separator start --------
            if(e.data.list[0].investment_deposit && (e.data.list[0].investment_deposit != null)){
              const investmentDeposit = ThousandsSeparator.separateByThousands(e.data.list[0].investment_deposit);
              if (investmentDeposit.includes('.')) {
                // Split the string at the decimal point and count the characters after the dot
                let decimalPart = investmentDeposit.split('.')[1];
                let numberOfDecimalPlaces = decimalPart.length;
                if(numberOfDecimalPlaces == 1){
                  e.data.list[0].investment_deposit = "Rs." + investmentDeposit + "0";
                }else{
                  e.data.list[0].investment_deposit = "Rs." + investmentDeposit;
                }
              } else {
                e.data.list[0].investment_deposit = "Rs." + investmentDeposit + ".00";
              }
            }
            //------- convert the investment_deposit value format using thousands separator end --------

            this.applicationData = e.data.list[0];
            this.comments = JSON.parse(e.data.list[0].comment);
          } else {
            this.isLoading = false;
          }
        },
        true
      );
    },
  },
  mounted() {
    this.formDataRequestHandler();
  },
};
</script>
