var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ApplicationViewVue',{attrs:{"viewData":_vm.applicationData,"debitComments":_vm.debit_comments,"isLoading":_vm.isLoading,"isFullPage":_vm.isFullPage,"listLength":_vm.listLength,"jointOwnerId":_vm.mainOwnerId},on:{"debitUserCommentSubmitHandler":(userComment) => {
      _vm.debitUserCommentSubmitHandler(userComment);
    },"checkboxChangeHandler":(value) => {
      _vm.pepCheckHandler(value);
    },"agreedRadioButton":(value) => {
      _vm.agreedRadioButton(value);
    },"userTwo":()=>{
    _vm.jointOwner(1);
  },"userThree":()=>{
    _vm.jointOwner(2);
  },"userOne":()=>{
    _vm.jointOwner(0);
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }