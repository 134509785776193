
export default class UserInteractionDetails{

    lastInteractionAt

    constructor() {
        this.lastInteractionAt = null
    }

    setLastInteractionTime() {
        let currentTimestamp = new Date()
        // console.log("Current timestamp : ",currentTimestamp)
        this.lastInteractionAt = currentTimestamp
    }

}