<template>
  <div
    style="max-height: 100%; min-height: 100%; width:auto; overflow-x: scroll; overflow-y: "
    class="pending-table-container"
  >
    <div
      v-if="this.userData"
      style="
        width: 100%;
        color: black;
        font-weight: bold;
        font-size: 15px;
        margin-left: 20px;
        margin-top: 20px;
        font-family: Poppins
        
      "
      class="is-size-4"
    >
      Hello, {{ getnameWithInitials (this.userData.name) }}
    </div>
    <div
      style="
        color: gray;
        font-weight: 400;
        font-size: 10px;
        margin-left: 20px;
        padding-bottom: 20px;
        font-family:Poppins
      "
      class="is-size-6"
    >
      Let's finish your task today!
    </div>

    <div
      style="
        color: black;
        font-weight: bold;
        font-size: 15px;
        margin: 5px;
        padding: 20px;
        background-color: #f9fafb;
        border-radius: 25px;
        font-family:Poppins
      "
      class="pt-5"
    >
      <div class="columns">
        <div class="column">
          <div
            style="
              color: black;
              font-weight: bold;
              font-size: 13px;
              margin-left: 20px;
              margin-top: 25px;
              font-family:Poppins;
            "
            class="is-size-5"
          >
            Account Openings and Debit Card Approvals
          </div>
        </div>
        <div class="column" v-if="hasPermission('all-branch-access')">
          <div class="columns" >
            <div class="column" style="padding-right:3px;" >
              <b-field label="Branch">
                <b-select expanded v-model="accOpeningitem.value" style="width:215px">
                  <option
                    v-for="items in accOpeningitem"
                    :value="items"
                    :key="items"
                  >
                    {{ items }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column" style="padding-right:3px" >
              <b-field label="From">
                <section>
                  <b-datepicker
                    v-model="accFromDate"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    trap-focus
                    :max-date="maxDate"
                    style="width:125px;"
                  >
                  </b-datepicker>
                </section>
              </b-field>
            </div>
            <div class="column" style="padding-right:3px" >
              <b-field label="To">
                <section>
                  <b-datepicker
                    v-model="accToDate"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    trap-focus
                    :max-date="maxDate"
                    style="width:125px;"
                  >
                  </b-datepicker>
                </section>
              </b-field>
            </div>
            <div class="column" style="margin-top: 25px; padding-right:3px" >
              <b-button type="is-primary" @click="filterAccData" expanded style="width:80px"
                >Search</b-button
              >
            </div>
            <div class="column" style="margin-top: 25px; padding-right:20px" >
              <b-button type="is-success" @click="resetAccData" expanded style="width:80px"
                >Reset</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          color: grey;
          font-weight: bold;
          font-size: 13px;
          margin-left: 20px;
        "
        class="is-size-6"
      >
        Account Opening Application
      </div>

      <div v-if="accApplicationCount != null" class="columns" style="margin-left: 10px; margin-top: 4px">
        <div
          class="column custom-card-column"
          v-for="(items, index) in accApplicationCount"
          :key="index"
        >
          <div class="card custom-card">
            <div class="card-content">
              <div class="custom-icon">
                <img :src="require(`@/assets/images/${items.icon}`)" />
              </div>
              <div class="custom-text">
                {{ items.name }}
              </div>
              <div
                :class="{
                  'item-content': true,
                  'special-item-content': index !== 4 && index !== 5,
                }"
              >
                {{ items.count }}
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card acc-all-applications-card">
            <div class="card-content">
              <div class="acc-all-applications-icon">
                <img
                  src="@/assets/images/application_icon.png"
                  class="iconClass"
                />
              </div>
              <div class="acc-all-applications-text">
                {{ allAccountApplications.name }}
              </div>
              <div class="acc-all-applications-content">
                {{ allAccountApplications.count }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          color: grey;
          font-weight: bold;
          font-size: 13px;
          margin-left: 20px;
        "
        class="is-size-6"
      >
        Debit Card Application
      </div>

      <div
        class="columns"
        style="margin-left: 12px; margin-top: 4px; margin-right: 20px"
        v-if="debitCardAppCount != null"
      >
        <div
          class="column"
          v-for="(items, index) in debitCardAppCount"
          :key="index"
          style="padding-left: 0px; padding-right: 20px"
        >
          <div class="card custom-card2">
            <div class="card-content">
              <div class="media">
                <div class="media-left debit-custom-icon">
                  <!-- Icon here -->
                  <img :src="require(`@/assets/images/${items.icon}`)" />
                </div>
                <div class="media-content">
                  <!-- Title here -->
                  <p class="title is-7">{{ items.name }}</p>
                  <p class="subtitle">{{ items.count }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card debit-all-applications-card">
            <div class="card-content">
              <div class="media">
                <div class="media-left all-applications-debit-custom-icon">
                  <!-- Icon here -->

                  <img
                    src="@/assets/images/application_icon.png"
                    class="debitIconClass"
                  />
                </div>
                <div class="media-content">
                  <!-- Title here -->
                  <p class="all-debit-applications-title is-7">
                    {{ allDebitCards.name }}
                  </p>
                  <p class="alll-debit-applications-subtitle">
                    {{ allDebitCards.count }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SMS Facility Activation  -->
      <div style="
      color: grey;
      font-weight: bold;
      font-size: 13px;
      margin-left: 20px;
    " class="is-size-6">
    SMS Facility Activation
  </div>

  <div class="columns" style="margin-left: 12px; margin-top: 4px; margin-right: 20px" v-if="smsActivationCount != null">
    <div class="column-sms" v-for="(items, index) in smsActivationCount" :key="index" style="padding-left: 0px;padding-right: 20px">
     
       <div class="card custom-card2">
        <div class="card-content">
          <div class="media">
            <div class="media-left debit-custom-icon">
              <!-- Icon here -->
              <img :src="require(`@/assets/images/${items.icon}`)" />
            </div>
            <div class="media-content">
              <!-- Title here -->
              <p class="title is-7">{{ items.name }}</p>
              <p class="subtitle">{{ items.count }}</p>
            </div>
          </div>
        </div>
  
    </div>
    </div>
    <div class="column">
      <div class="card debit-all-applications-card" style= "margin-top:-10px">
        <div class="card-content">
          <div class="media">
            <div class="media-left all-applications-debit-custom-icon">
              <!-- Icon here -->
              <img src="@/assets/images/all_sms_icon.png" class="debitIconClass" />
            </div>
            <div class="media-content">
              <!-- Title here -->
              <p class="all-debit-applications-title is-7">{{ allSmsActivation.name }}</p>
              <p class="alll-debit-applications-subtitle">{{ allSmsActivation.count }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>

    <div class="columns">
      <div class="column graphical-column">
        <div
          style="
            color: black;
            font-weight: bold;
            font-size: 13px;
            margin-left: 40px;
            margin-top: 25px;
            font-family:Poppins;
            
          "
          class="is-size-5"
        >
          Graphical information
        </div>
      </div>
      <div class="column" v-if="hasPermission('all-branch-access')">
        <div class="columns">
          <div class="column" style="padding-right:3px" >
            <b-field label="Branch">
              <b-select expanded v-model="graphicalInfoitem.value" style="width:215px">
                <option
                  v-for="items in graphicalInfoitem"
                  :value="items"
                  :key="items"
                >
                  {{ items }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column" style="padding-right:3px">
            <b-field label="From">
              <section>
                <b-datepicker
                  v-model="graphicalInfoFromDate"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                  :max-date="maxDate"
                  style="width:125px;"
                >
                </b-datepicker>
              </section>
            </b-field>
          </div>
          <div class="column" style="padding-right:3px">
            <b-field label="To">
              <section>
                <b-datepicker
                  v-model="graphicalInfoToDate"
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                  :max-date="maxDate"
                  style="width:125px;"
                >
                </b-datepicker>
              </section>
            </b-field>
          </div>
          <div class="column" style="margin-top: 25px; padding-right:3px">
            <b-button type="is-primary" @click="filterGraphicalInfo" expanded style="width:90px" 
              >Search</b-button
            >
          </div>
          <div class="column" style="margin-top: 25px; padding-right:50px">
            <b-button type="is-success" @click="resetGraphicalInfo" expanded style="width:90px"
              >Reset</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column" style="margin-left: 37px">
        <div class="account-opening-card">
          <div style="color: grey; font-size: 13px; text-align: left">
            Accounts opening overview
          </div>
          <div style="font-weight: bold; font-size: 15px; text-align: left">
            Account Types
          </div>
          <div class="card-content">
            <apexchart
              width="400"
              height="260"
              type="donut"
              :options="accountOptions"
              :series="accountSeries"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="account-opening-card" style="margin-right: 20px">
          <div style="color: grey; font-size: 13px; text-align: left">
            Debit Card Approvals Overview
          </div>
          <div style="font-weight: bold; font-size: 15px; text-align: left">
            Debit Cards
          </div>
          <div class="card-content">
            <apexchart
              width="400"
              height="260"
              type="donut"
              :options="debitOptions"
              :series="debitSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import DateConverter from "@/helpers/DateConverter";
//import firebaseConfig from "../../firebase";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: "Dashboard",
  components: {},

  data() {
    return {
      userData: null,
      accOpeningitem: [],
      graphicalInfoitem: [],
      accApplicationCount: null,
      debitCardAppCount: null,
      smsActivationCount: null,
      accountOptions: {
        labels: [
          "Individual Savings Accounts",
          "Individual Fixed Deposits",
          "Joint Savings Accounts",
          "Joint Fixed Deposits",
          "Minor Accounts",
        ],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            customScale: 1.4,
            expandOnClick: false,
            offsetX: -10,
            offsetY: 48,
            donut: {
              size: "65%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "15px",
                  fontFamily: "Poppins",
                  color: undefined,
                  offsetY: 10,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total Accounts",
                  fontSize: "11px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: "#64676A",
                  // formatter: function (w) {
                  //   return w.globals.seriesTotals.reduce((a, b) => {
                  //     return a + b
                  //   }, 0)
                  // }
                },
              },
            },
          },
        },
        legend: {
          position: "left",
          offsetY: 10,
          offsetX: -35,
          show: true,
          
          
        },
      
           itemMargin: {
          horizontal: 5,
          vertical: 0,
      },
      },
      accountSeries: [],
      debitOptions: {
        labels: [
          "Pending Debit Cards ",
          "Approved Debit Cards&nbsp&nbsp",
          "Rejected Debit Cards",
        ],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            customScale: 1.1,
            expandOnClick: false,
            offsetX: -10,
            offsetY: 15,
            donut: {
              size: "65%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  color: undefined,
                  offsetY: 16,
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total Debit Cards",
                  fontSize: "13px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  color: "#64676A",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        legend: {
          show: true,
          position:"left",
          offsetY: 10,
          offsetX: -35,
          
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0
      },
      },
      debitSeries: [],
      accFromDate: null,
      accToDate: null,
      maxDate: new Date(),
      graphicalInfoFromDate: null,
      graphicalInfoToDate: null,
      allAccountApplications: null,
      allDebitCards: null,
    };
  },

  watch: {},

  methods: {
    getUserData() {
      NetworkManager.apiRequest("backend/user/getUserData", {}, (e) => {
        this.userData = e.data;
      });
    },
    accountApplicationsCounts() {
      NetworkManager.apiRequest(
        "backend/dashBoard/countApplicationsForDashboard",
        {},
        (e) => {
          // console.log("accountApplicationsCounts", e);
          this.accApplicationCount = e.data.accApplications;
          this.debitCardAppCount = e.data.debitCardApplications;
          this.smsActivationCount = e.data.smsActivationApplications;
          
          this.allAccountApplications = e.data.allAccountApplications;
          this.allDebitCards = e.data.allDebitCards;
          this.allSmsActivation = e.data.allSmsActivation;

          // console.log("allAccountApplications", this.allAccountApplications);
          // console.log("allDebitCards", this.allDebitCards);
          // console.log("app", this.accApplicationCount);
        }
      );
    },
    graphicalInfoCounts() {
      NetworkManager.apiRequest(
        "backend/dashBoard/graphicalInfoForDashboard",
        {},
        (e) => {
          // console.log("graphicalInfoForDashboard", e);
          this.accountSeries = e.data.accountsCount;
          this.debitSeries = e.data.debitCardCounts;
        }
      );
    },
    filterAccData() {
      var fromDate =
        this.accFromDate != null
          ? new Date(DateConverter.dateFormater(this.accFromDate))
          : this.accFromDate;
      var toDate =
        this.accToDate != null
          ? new Date(DateConverter.dateFormater(this.accToDate))
          : this.accToDate;
      var branch = this.accOpeningitem.value == "All" ? "" : this.accOpeningitem.value;
      // console.log("accData", fromDate, toDate, this.accOpeningitem.value);
      NetworkManager.apiRequest(
        "backend/dashBoard/countApplicationsForDashboard",
        {
          fromDate: fromDate,
          toDate: toDate,
          branch: branch,
        },
        (e) => {
          this.accApplicationCount = e.data.accApplications;
          this.debitCardAppCount = e.data.debitCardApplications;
          this.smsActivationCount = e.data.smsActivationApplications;
          // console.log("accApplicationCount", this.accApplicationCount);
          // console.log("smsActivationCount", this.smsActivationCount);

          this.allAccountApplications = e.data.allAccountApplications;
          this.allDebitCards = e.data.allDebitCards;
          this.allSmsActivation = e.data.allSmsActivation;

        }
      );
    },
    resetAccData() {
      this.accFromDate = null;
      this.accToDate = null;
      this.accOpeningitem = [];
      this.branchListLoader(this.accOpeningitem);
      this.accountApplicationsCounts();
    },

    getnameWithInitials(name) {
      const firstLetters = name.split(" ").map((word) => word[0]);
      const lastString = name.split(" ").pop();
      const result = firstLetters.slice(0, -1).join(" ") + " " + lastString;
      return result;
    },

    filterGraphicalInfo() {
      var fromDate =
        this.graphicalInfoFromDate != null
          ? new Date(DateConverter.dateFormater(this.graphicalInfoFromDate))
          : this.graphicalInfoFromDate;
      var toDate =
        this.graphicalInfoToDate != null
          ? new Date(DateConverter.dateFormater(this.graphicalInfoToDate))
          : this.graphicalInfoToDate;
      var branch = this.graphicalInfoitem.value == "All" ? "" : this.graphicalInfoitem.value;
      NetworkManager.apiRequest(
        "backend/dashBoard/graphicalInfoForDashboard",
        {
          fromDate: fromDate,
          toDate: toDate,
          branch: branch,
        },
        (e) => {
          // console.log("graphicalInfoForDashboard", e);
          this.accountSeries = e.data.accountsCount;
          this.debitSeries = e.data.debitCardCounts;
        }
      );
    },
    resetGraphicalInfo() {
      this.graphicalInfoFromDate = null;
      this.graphicalInfoToDate = null;
      this.graphicalInfoitem = [];
      this.branchListLoader(this.graphicalInfoitem);
      this.graphicalInfoCounts();
    },

    generateFcmToken() {
      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        // console.log("Message received. ", payload);
        // ...
      });
      getToken(messaging, { vapidKey: firebaseConfig.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("Token is: ", currentToken);
            NetworkManager.apiRequest(
              "backend/notification/setToken",
              { fcm_token: currentToken },
              (e) => {
                if (e.success === true) {
                  // console.log(e.data.message);
                } else if (e.success === false) {
                  // console.log("fcm_token saving failed");
                } else {
                  // console.log("fcm_token saving failed");
                }
              },
              false
            );
          } else {
            // console.log(
              // "No registration token available. Request permission to generate one."
            // );
          }
        })
        .catch((err) => {
          // console.log("An error occurred while retrieving token. ", err);
        });
    },
  },

  mounted() {
    // const messaging = getMessaging();
    //   onMessage(messaging, (payload) => {
    //     console.log('Message received. ', payload);
    //     // ...
    //   });
//    this.generateFcmToken();
    this.getUserData();
    this.branchListLoader(this.accOpeningitem);
    this.branchListLoader(this.graphicalInfoitem);
    this.accountApplicationsCounts();
    this.graphicalInfoCounts();
    // console.log("item", this.accApplicationCount);
  },
};
</script>

<style scoped>

.custom-card {
  width: 130px;
  height: 150px;
  border-radius: 15px;
  padding: 2px;
  text-align: center;
  margin: 3px;
}
.custom-card-column{
  padding:0px !important;
}

.custom-sms{
  padding-right:5px;
}
.acc-all-applications-card {
  width: 270px;
  height: 145px;
  border-radius: 15px;
  padding: 2px;
  text-align: center;
  background-color: #0083ca;
  margin:-7px;
}
.custom-card2 {
  width: 290px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

/* .column-sms{
  padding-right: 5px;
} */


.debit-all-applications-card {
  width:270px;
  height: 90px;
  border-radius: 10px;
  padding: 10px;
  background-color: #0083ca;
}

.iconClass {
   margin: 0 auto;
   margin-right: 15px;
}

.debitIconClass {
   margin: 0 auto;
   margin-right: 15px;
}

.account-opening-card {
  width: 570px;
  height: 350px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom:100px;
  /* text-align: center; */
  /* overflow: hidden;  */
}
.debit-custom-icon {
  display: block;
  text-align: center;
  background-color: #f4f6fe;
  border: 2px #f4f6fe;
  border-radius: 118px;
  height: 48px;
  width: 49px;
  margin: 0 auto;
  margin-right: 15px;
}
.all-applications-debit-custom-icon {
  /* display: block; */
  text-align: center;
  /* background-color: #f4f6fe6e; */
  border: 2px #f4f6fe6e;
  /* border-radius: 118px; */
  height: 48px;
  width: 49px;
 
}
.custom-icon {
  display: block;
  text-align: center;
  background-color: #f4f6fe;
  border: 2px #f4f6fe;
  border-radius: 118px;
  height: 48px;
  width: 49px;
  margin: 0 auto;
}

.acc-all-applications-icon {
  /* display: block; */
  text-align: center;
  /* background-color: #f4f6fe49; */
  border: 2px #f4f6fe67;
  /* border-radius: 118px; */
  height: 48px;
  width: 49px;
  margin: 0 auto;
}
.custom-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 0.8rem;
}
.acc-all-applications-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 0.8rem;
  color: white;
}

.debit-custom-card {
  width: 250px;
  height: 75px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}
.debit-custom-icon {
  display: block;
  text-align: center;
}
.debit-custom-text {
  text-align: center;
}

.item-content {
  text-align: center;
  color: #0083ca;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: -0.02em;
  padding-top: 0.04em;
}
.special-item-content {
  padding-top: 1.2rem;
  text-align: center;
  color: #0083ca;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: -0.02em;
}
.acc-all-applications-content {
  text-align: center;
  color: #ffffff !important;
  /* font-weight: 700; */
  /* font-size: 1.8rem; */
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: -0.02em;
  padding-top: 0.8rem;
  
}

.subtitle {
  color: #0083ca !important;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 25px;
  padding-top: 4px;
}
.alll-debit-applications-subtitle {
  color: #ffffff !important;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 25px;
  padding-top: 5px;
}

.all-debit-applications-title {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: white;
}

.card-content {
  padding: 10px;
}
.media{
  margin-left:20px;
}
.media-content{
  margin-left:10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pending-table-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pending-table-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

</style>
