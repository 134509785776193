<template>
  <!-- viewData.debit_is_customer_interest == true (if user wants a debit card)-->
  <!-- !viewData.debitCardApplication (user is not coming from the debit card application flow)-->
  <!-- viewData.debitCardApplication (user is coming from the debit card application flow)-->

  <!-- Main Application view for Approve,Reject,Req to Improve, Pending applications and Issued, Rejected, Pending debit card applications -->
  <div class="main-box">
    <div class="sub-box-one">
      <!-- If application is a joint account below buttons will display -->

      <div v-if="viewData.joint_acc">
        <b-button type="is-primary" class="ml-2" @click="$emit('userOne')"
          >1st Owner</b-button
        >
        <b-button
          type="is-primary"
          class="ml-2"
          @click="$emit('userTwo')"
          v-if="listLength == 2 || listLength == 3"
          >2nd Owner</b-button
        >
        <b-button
          type="is-primary"
          class="ml-2"
          @click="$emit('userThree')"
          v-if="listLength == 3"
          >3rd Owner</b-button
        >
      </div>
      <div class="column is-half">
        <div class="columns is-gapless">
          <div class="column is-narrow">
            <img
              class="round-image-box"
              :src="viewData.is_existing_data == 1 ? existingImageBaseUrl + viewData.image_customer_img : baseUrl + viewData.image_customer_img"
            />
          </div>

          <div class="column">
            <div class="column is-full is-size-4 full-name">
              {{
                viewData.full_name
                  ? getnameWithInitials(viewData.full_name)
                  : "-"
              }}
            </div>
            <hr style="margin: 0" />
            <div class="column">
              <div class="columns mb-0">
                <div class="column personal-info">
                  {{ viewData.nic ? viewData.nic : "-" }}
                </div>

                <div class="column personal-info" style="flex-grow: 0">
                  <span class="vl"></span>
                </div>
                <div class="column personal-info" v-if="viewData.sex">
                  {{ viewData.sex ? viewData.sex : "-" }}
                </div>
              </div>
              <div
                class="column"
                style="width: 235px; padding-top: 0px; padding-left: 0px"
              >
                <div class="personal-info-status" v-if="viewData.applicant_status">
                  {{
                    viewData.applicant_status ? viewData.applicant_status : "-"
                  }}
                </div>
                <div style="display: flex; flex-direction: column">
                  <span
                    v-if="viewData.debit_card_status === 'issued'"
                    class="has-text-success notification-msg"
                  >
                    <b-icon
                      icon="credit-card-multiple-outline"
                      size="is-small"
                    ></b-icon>
                    Card Approved
                    <b-icon icon="check-circle" size="is-small"></b-icon
                  ></span>
                  <span
                    v-if="viewData.fd_certificate_issued == 'true'"
                    class="has-text-success notification-msg"
                  >
                    <b-icon icon="certificate-outline" size="is-small"></b-icon>
                    Certificate Issued
                    <b-icon icon="check-circle" size="is-small"></b-icon
                  ></span>
                </div>
                <span
                  class="has-text-danger notification-msg"
                  v-if="viewData.debit_card_status === 'rejected'"
                >
                  <b-icon
                    icon="credit-card-multiple-outline"
                    size="is-small"
                  ></b-icon>
                  Card Rejected
                  <b-icon icon="close-circle" size="is-small"></b-icon
                ></span>
              </div>
            </div>
            <!-- <div class="column" v-if="viewData.debit_card_status === 'issued'">
              <span class="has-text-success">Card Issued</span>
            </div>
            <div class="column" v-if="viewData.debit_card_status === 'rejected'">
              <span class="has-text-danger">Card Rejected</span>
            </div> -->
          </div>
        </div>
      </div>
      <div style="width: 96%" v-if="viewData.fd_certificate_issued == 'true'">
        <ApplicationStatCard
          applicationType="Approved"
          applicationMessage="Digital FD certificate issuance successful!"
        />
      </div>
      <div class="column">
        <span class="application-sub-headers">Account Info</span>
        <SpanFields
          v-if="!viewData.debitCardApplication"
          fieldTitle="Account Ownership"
          :fieldValue="viewData.account_type ? viewData.account_type : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.account_type != viewData.previous_applications.account_type
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="viewData.debitCardApplication"
          fieldTitle="Account Ownership"
          fieldValue="Individual"
          :valueStyles="
            viewData.previous_applications &&
            viewData.account_type != viewData.previous_applications.account_type
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="viewData.applicant_individual_account_type && viewData.account_type == 'Joint'"
          fieldTitle="Joint acc: type"
          :fieldValue="
            viewData.applicant_individual_account_type
              ? viewData.applicant_individual_account_type
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.applicant_individual_account_type !=
              viewData.previous_applications.applicant_individual_account_type
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-else-if="viewData.debitCardApplication"
          fieldTitle="Individual acc: type"
          fieldValue="Savings Account"
          :valueStyles="
            viewData.previous_applications &&
            viewData.applicant_individual_account_type !=
              viewData.previous_applications.applicant_individual_account_type
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-else
          fieldTitle="Individual acc: type"
          :fieldValue="
            viewData.applicant_individual_account_type
              ? viewData.applicant_individual_account_type
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.applicant_individual_account_type !=
              viewData.previous_applications.applicant_individual_account_type
              ? `has-text-danger`
              : ``
          "
        />
        <div
          v-if="viewData.applicant_individual_account_type == 'Savings Account' && !viewData.debitCardApplication"
        >
          <SpanFields
            fieldTitle="Saving acc: type"
            :fieldValue="
              viewData.savings_account_type
                ? viewData.savings_account_type
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.savings_account_type !=
                viewData.previous_applications.savings_account_type
                ? `has-text-danger`
                : ``
            "
          />
        </div>

        <div
          v-if="viewData.debitCardApplication"
        >
          <SpanFields
            fieldTitle="Saving acc: type"
            :fieldValue="
              viewData.savings_account_type
                ? viewData.savings_account_type
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.savings_account_type !=
                viewData.previous_applications.savings_account_type
                ? `has-text-danger`
                : ``
            "
          />
        </div>

        <div
          v-if="viewData.applicant_individual_account_type == 'Minor Account'  || viewData.applicant_individual_account_type == 'Lakdaru Savings'"
        >
          <SpanFields
            fieldTitle="Minor acc: type "
            :fieldValue="
              viewData.minor_account_type
                ? viewData.minor_account_type
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.minor_account_type !=
                viewData.previous_applications.minor_account_type
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            v-if="viewData.minor_account_type == 'Savings Account'"
            fieldTitle="Minor savings acc: type "
            :fieldValue="
              viewData.savings_account_type
                ? viewData.savings_account_type
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.savings_account_type !=
                viewData.previous_applications.savings_account_type
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            v-if="viewData.minor_account_type == 'Fixed Deposit'"
            fieldTitle="Minor fixed deposit acc: type "
            :fieldValue="
              viewData.savings_account_type
                ? viewData.savings_account_type
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.savings_account_type !=
                viewData.previous_applications.savings_account_type
                ? `has-text-danger`
                : ``
            "
          />
        </div>

        <div
          v-if="viewData.applicant_individual_account_type == 'Fixed Deposit'"
        >
          <SpanFields
            fieldTitle="Fixed Deposit acc: type "
            :fieldValue="
              viewData.savings_account_type
                ? viewData.savings_account_type
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.savings_account_type !=
                viewData.previous_applications.savings_account_type
                ? `has-text-danger`
                : ``
            "
          />
        </div>
        <!-- add other income to Entreprenuer -->
        <!-- <SpanFields
          v-if="viewData.business_other_income"
          fieldTitle="Other Income "
          :fieldValue="
            viewData.business_other_income
              ? viewData.business_other_income
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.business_other_income != viewData.business_other_income
              ? `has-text-danger`
              : ``
          "
        /> -->

        <!-- <SpanFields
          v-if="viewData.business_total_income"
          fieldTitle="Total Income "
          :fieldValue="
            viewData.business_total_income
              ? viewData.business_total_income
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.business_total_income != viewData.business_total_income
              ? `has-text-danger`
              : ``
          "
        /> -->

        <SpanFields 
        v-if="usingTheSDBServer == true"
        fieldTitle="Customer CIF" :fieldValue="
        customerCif
              ? customerCif
              : '-'
          "
        />
        <SpanFields 
        v-if="usingTheSDBServer == true && minorCif != null && minorCif != '' && !viewData.debitCardApplication"
        fieldTitle="Minor CIF" :fieldValue="
        minorCif
              ? minorCif
              : '-'
          "
        />
        
        <SpanFields 
        v-if="viewData.lakdaru_minor_cif != null && viewData.lakdaru_minor_cif != '' && !viewData.debitCardApplication && minorCif == null"
        fieldTitle="Minor CIF" 
        :fieldValue="
              viewData.lakdaru_minor_cif
                ? viewData.lakdaru_minor_cif
                : '-'
            "
        />

        <!-- converted savings (othersaving) details start-->

        <!-- <div v-if="viewData.investment_deposit != null">
          <SpanFields
            fieldTitle="Contractual Amount"
            :fieldValue="
              viewData.investment_deposit
                ? viewData.investment_deposit
                : '-'
            "
            :valueStyles="viewData.previous_applications && viewData.investment_deposit != viewData.previous_applications.investment_deposit ? `has-text-danger` : ``"
          />
        </div>
        <div v-if="viewData.investment_period != null">
          <SpanFields
            fieldTitle="Period"
            :fieldValue="
              viewData.investment_period
                ? viewData.investment_period
                : '-'
            "
            :valueStyles="viewData.previous_applications && viewData.investment_period != viewData.previous_applications.investment_period ? `has-text-danger` : ``"
          />
        </div>
        <div v-if="viewData.saving_maturity_value != null">
          <SpanFields
            fieldTitle="Maturity Value"
            :fieldValue="
              viewData.saving_maturity_value
                ? viewData.saving_maturity_value
                : '-'
            "
            :valueStyles="viewData.previous_applications && viewData.saving_maturity_value != viewData.previous_applications.saving_maturity_value ? `has-text-danger` : ``"
          />
        </div>
        <div v-if="viewData.saving_maturity_date != null">
          <SpanFields
            fieldTitle="Maturity Date"
            :fieldValue="
              viewData.saving_maturity_date
                ? viewData.saving_maturity_date
                : '-'
            "
            :valueStyles="viewData.previous_applications && viewData.saving_maturity_date != viewData.previous_applications.saving_maturity_date ? `has-text-danger` : ``"
          />
        </div> -->

        <!-- converted savings (othersaving) details end-->

        <div v-if="viewData.account_number != null">
          <SpanFields
            fieldTitle="Account Number"
            :fieldValue="
              viewData.account_number ? viewData.account_number : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.account_number !=
                viewData.previous_applications.account_number
                ? `has-text-danger`
                : ``
            "
          />
        </div>
        <div v-if="viewData.is_customer_related_party == 1 || relatedPartyCustomer == true">
          <SpanFields
            fieldTitle="Related Party Customer"
            fieldValue="Customer is a related party customer"
            valueStyles="has-text-danger"
          />
        </div>

        <SpanFields
          fieldTitle="PEP"
          v-if="viewData.kyc_is_customer_pep == 'Customer is not a politically exposed person' || viewData.kyc_is_customer_pep == 'I am not a politically exposed person'"
          fieldValue='I am not a politically exposed person'
          titleStyles="has-text-danger"
          valueStyles='valueFontStyles'
        />

        <SpanFields
          fieldTitle="PEP"
          v-if="viewData.kyc_is_customer_pep == 'Customer is a family member of such person'"
          fieldValue="I am a family member of such a person"
          titleStyles="has-text-danger"
          valueStyles='has-text-danger'
        />
        
        <SpanFields
          fieldTitle="PEP"
          v-if="viewData.kyc_is_customer_pep == 'Customer is a close associate of such a person'"
          fieldValue="I am a close associate of such a person"
          titleStyles="has-text-danger"
          valueStyles='has-text-danger'
        />

        <SpanFields
          fieldTitle="PEP"
          v-if="viewData.kyc_is_customer_pep == 'Customer is a politically exposed person'"
          fieldValue="I am a politically exposed person"
          titleStyles="has-text-danger"
          valueStyles='has-text-danger'
        />

        <!-- //show pep info -->

        <div
          v-if="
            viewData.kyc_is_customer_pep ===
            'Customer is a family member of such person'
          "
        >
          <div class="column">
            <span class="application-sub-headers">PEP Info</span>
            <SpanFields
              fieldTitle="Relationship to the PEP"
              :fieldValue="
                viewData.kyc_pep_relationship
                  ? viewData.kyc_pep_relationship
                  : '-'
              "
              :valueStyles="
                viewData.previous_applications &&
                viewData.kyc_pep_relationship !=
                  viewData.previous_applications.kyc_pep_relationship
                  ? `has-text-danger`
                  : ``
              "
            />
            <SpanFields
              fieldTitle="PEP Name"
              :fieldValue="viewData.kyc_pep ? viewData.kyc_pep : '-'"
              :valueStyles="
                viewData.previous_applications &&
                viewData.kyc_pep != viewData.previous_applications.kyc_pep
                  ? `has-text-danger`
                  : ``
              "
            />
            <SpanFields
              fieldTitle="PEP Designation"
              :fieldValue="
                viewData.kyc_designation_pep
                  ? viewData.kyc_designation_pep
                  : '-'
              "
              :valueStyles="
                viewData.previous_applications &&
                viewData.kyc_designation_pep !=
                  viewData.previous_applications.kyc_designation_pep
                  ? `has-text-danger`
                  : ``
              "
            />
          </div>
        </div>

        <div
          v-if="
            viewData.kyc_is_customer_pep ===
            'Customer is a close associate of such a person'
          "
        >
          <div class="column">
            <span class="application-sub-headers">PEP Info</span>
            <SpanFields
              fieldTitle="Association to the PEP"
              :fieldValue="
                viewData.kyc_pep_relationship
                  ? viewData.kyc_pep_relationship
                  : '-'
              "
              :valueStyles="
                viewData.previous_applications &&
                viewData.kyc_pep_relationship !=
                  viewData.previous_applications.kyc_pep_relationship
                  ? `has-text-danger`
                  : ``
              "
            />
            <SpanFields
              fieldTitle="PEP Name"
              :fieldValue="viewData.kyc_pep ? viewData.kyc_pep : '-'"
              :valueStyles="
                viewData.previous_applications &&
                viewData.kyc_pep != viewData.previous_applications.kyc_pep
                  ? `has-text-danger`
                  : ``
              "
            />
            <SpanFields
              fieldTitle="PEP Designation"
              :fieldValue="
                viewData.kyc_designation_pep
                  ? viewData.kyc_designation_pep
                  : '-'
              "
              :valueStyles="
                viewData.previous_applications &&
                viewData.kyc_designation_pep !=
                  viewData.previous_applications.kyc_designation_pep
                  ? `has-text-danger`
                  : ``
              "
            />
          </div>
        </div>

        <div
          v-if="isPEPChecked == 0 && viewData.pep_status != 1 && viewData.kyc_is_customer_pep != 'Customer is not a politically exposed person' && viewData.kyc_is_customer_pep != 'I am not a politically exposed person'"
          class="checkbox-button notification"
          :class="
            isPEPChecked === 1 || viewData.pep_status === 1
              ? 'is-primary'
              : 'is-danger'
          "
        >
          <!-- here -->
          <b-checkbox
            class="application-checkbox specific"
            :true-value="1"
            :false-value="0"
            v-model="isPEPChecked"
            @input="$emit('checkboxChangeHandler', isPEPChecked)"
            :disabled="
              isPEPChecked === 1 ||
              viewData.pep_status === 1 ||
              (viewData.kyc_is_customer_pep !=
                'Customer is not a politically exposed person' && viewData.kyc_is_customer_pep != 'I am not a politically exposed person' &&
                viewData.pep_approval_document == '' &&
                viewData.pep_approval_document2 == '')
            "
            v-if="isPEPChecked == 0 && viewData.pep_status != 1"
            >PEP Approvel Pending</b-checkbox
          >
          <b-checkbox
            class="application-checkbox specific"
            :value="true"
            @input="$emit('checkboxChangeHandler', isPEPChecked)"
            :disabled="isPEPChecked === 1 || viewData.pep_status === 1"
            v-if="isPEPChecked === 1 || viewData.pep_status === 1"
            >PEP Approved</b-checkbox
          >
        </div>
        <ApplicationStatCard
          applicationType="Rejected"
          applicationMessage="Debit Card Rejected"
          v-if="viewData.debit_card_status == 'rejected'"
          :debitCardNumber="viewData.debit_card_number"
        />
        <ApplicationStatCard
          applicationType="Approved"
          applicationMessage="Debit Card Approved"
          v-if="viewData.debit_card_status == 'issued'"
          :number="maskCardNumber(viewData.debit_card_number)"
        />
        <!-- <ActionButton buttonLabel="PEP Approvel Pending" expanded size="is-medium" type="is-danger" divStyles="mt-4"/> -->
        <!-- <b-button
            size="is-medium"
            type="is-danger"
            expanded
            class="mb-2"
            @click="$emit('pepApprovelCheckHandler')"
            >PEP Approvel Pending</b-button
          > -->
      </div>

      <hr class="application-view-hr" v-if="viewData.pep_status === 1" />

      <!-- <div class="column" v-if="viewData.pep_status === 1">
        <span class="application-sub-headers">PEP Approving Officer</span>
        <SpanFields
          v-if="viewData.pep_status === 1"
          fieldTitle="EPF"
          :fieldValue="
            viewData.pep_approved_epf ? viewData.pep_approved_epf : '-'
          "
        />
        <SpanFields
          v-if="viewData.pep_status === 1"
          fieldTitle="Approved Date"
          :fieldValue="
            viewData.pep_approved_date ? viewData.pep_approved_date : '-'
          "
        />
        <SpanFields
          fieldTitle="Email"
          :fieldValue="
            viewData.pep_approved_email ? viewData.pep_approved_email : '-'
          "
        />
      </div>
      <hr class="application-view-hr" /> -->

      <!-- Savings Certificate Acc Info start-->
      <div
        class="column"
        v-if="viewData.savings_account_type == 'Savings Certificate'"
      >
        <span class="application-sub-headers"
          >Savings Certificate Acc Info</span
        >
        <SpanFields
          fieldTitle="Contractual Amount"
          :fieldValue="
            viewData.investment_deposit ? viewData.investment_deposit : '-'
          "
        />
        <SpanFields
          fieldTitle="Maturity Value"
          :fieldValue="
            viewData.saving_maturity_value
              ? viewData.saving_maturity_value
              : '-'
          "
        />
        <SpanFields
          fieldTitle="Maturity Date"
          :fieldValue="
            viewData.saving_maturity_date ? viewData.saving_maturity_date : '-'
          "
        />
        <SpanFields
          fieldTitle="Period"
          :fieldValue="
            viewData.investment_period ? viewData.investment_period : '-'
          "
        />
      </div>
      <!-- Savings Certificate Acc Info end-->

      <!-- SDB Invesment Savings Acc Info start-->
      <div
        class="column"
        v-if="viewData.savings_account_type == 'Investment Savings'"
      >
        <span class="application-sub-headers">Invesment Savings Acc Info</span>
        <SpanFields
          fieldTitle="Contractual Amount"
          :fieldValue="
            viewData.investment_deposit ? viewData.investment_deposit : '-'
          "
        />
        <SpanFields
          fieldTitle="Period"
          :fieldValue="
            viewData.investment_period ? viewData.investment_period : '-'
          "
        />
      </div>
      <!-- SDB Invesment Savings Acc Info end-->

      <!-- SDB Ayojana Saving Acc Info start-->
      <div
        class="column"
        v-if="viewData.savings_account_type == 'SDB Ayojana Savings Account'"
      >
        <span class="application-sub-headers">SDB Ayojana Saving Acc Info</span>
        <SpanFields
          fieldTitle="Contractual Amount"
          :fieldValue="
            viewData.investment_deposit ? viewData.investment_deposit : '-'
          "
        />
        <SpanFields
          fieldTitle="Maturity Value"
          :fieldValue="
            viewData.saving_maturity_value
              ? viewData.saving_maturity_value
              : '-'
          "
        />
        <SpanFields
          fieldTitle="Maturity Date"
          :fieldValue="
            viewData.saving_maturity_date ? viewData.saving_maturity_date : '-'
          "
        />
        <SpanFields
          fieldTitle="Period"
          :fieldValue="
            viewData.investment_period ? viewData.investment_period : '-'
          "
        />
      </div>
      <!-- SDB Ayojana Saving Acc Info end-->

      <!-- SDB Uththamavi Ayojana Saving Acc Info start-->
      <div
        class="column"
        v-if="viewData.savings_account_type == 'SDB Uththamavi Ayojana'"
      >
        <span class="application-sub-headers">SDB Uththamavi Ayojana Saving Acc Info</span>
        <SpanFields
          fieldTitle="Contractual Amount"
          :fieldValue="
            viewData.investment_deposit ? viewData.investment_deposit : '-'
          "
        />
        <SpanFields
          fieldTitle="Maturity Value"
          :fieldValue="
            viewData.saving_maturity_value
              ? viewData.saving_maturity_value
              : '-'
          "
        />
        <SpanFields
          fieldTitle="Maturity Date"
          :fieldValue="
            viewData.saving_maturity_date ? viewData.saving_maturity_date : '-'
          "
        />
        <SpanFields
          fieldTitle="Period"
          :fieldValue="
            viewData.investment_period ? viewData.investment_period : '-'
          "
        />
      </div>
      <!-- SDB Uththamavi Ayojana Saving Acc Info end-->

      <div class="column">
        <span class="application-sub-headers">{{
          viewData.applicant_individual_account_type === "Minor Account"
            ? "Guardian Info"
            : "Personal Info"
        }}</span>

        <SpanFields
          fieldTitle="Title"
          :fieldValue="viewData.title ? viewData.title : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.title != viewData.previous_applications.title
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          fieldTitle="Full Name"
          :fieldValue="viewData.full_name ? viewData.full_name : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.full_name != viewData.previous_applications.full_name
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          fieldTitle="Calling Name"
          :fieldValue="viewData.display_name ? viewData.display_name : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.display_name != viewData.previous_applications.display_name
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          fieldTitle="Date of birth"
          :fieldValue="
            viewData.birth_date +
            '/' +
            viewData.birth_month +
            '/' +
            viewData.birth_year
          "
          :valueStyles="
            viewData.previous_applications &&
            (viewData.birth_date != viewData.previous_applications.birth_date ||
              viewData.birth_month !=
                viewData.previous_applications.birth_month ||
              viewData.birth_year != viewData.previous_applications.birth_year)
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Mobile Number"
          :fieldValue="
            viewData.primary_mobile_number
              ? viewData.primary_mobile_number
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.primary_mobile_number !=
              viewData.previous_applications.primary_mobile_number
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          fieldTitle="Secondary Contact Number"
          :fieldValue="
            viewData.secondary_mobile_number
              ? viewData.secondary_mobile_number
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.secondary_mobile_number !=
              viewData.previous_applications.secondary_mobile_number
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          fieldTitle="Email"
          :fieldValue="viewData.email ? viewData.email : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.email != viewData.previous_applications.email
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          fieldTitle="Marital Status"
          :fieldValue="viewData.marital_status ? viewData.marital_status : '-'"
        />

        <SpanFields
          fieldTitle="Province"
          :fieldValue="viewData.province ? viewData.province : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.province != viewData.previous_applications.province
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="District"
          :fieldValue="viewData.district ? viewData.district : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.district != viewData.previous_applications.district
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Address"
          :fieldValue="
           concatenateAddresses(viewData)
          "
          :valueStyles="
            viewData.previous_applications &&
            (viewData.address_1 != viewData.previous_applications.address_1 ||
              viewData.address_2 != viewData.previous_applications.address_2 ||
              viewData.address_3 != viewData.previous_applications.address_3 ||
              viewData.address_4 != viewData.previous_applications.address_4)
              ? `has-text-danger`
              : ``
          "
        />
      </div>

      <hr class="application-view-hr" />

      <!-- <div class="column" v-if="viewData.debit_is_customer_interest == true"> -->
      <div class="column" v-if="viewData.debitCardApplication">
        <span class="application-sub-headers">Debit Card Info</span>
        <SpanFields
          v-if="viewData.debit_card_status === 'issued'"
          fieldTitle="Debit Card No."
          :fieldValue="maskCardNumber(viewData.debit_card_number)"
          :valueStyles="
            viewData.previous_applications &&
            viewData.debit_card_number !=
              viewData.previous_applications.debit_card_number
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="viewData.debit_card_status === 'rejected'"
          fieldTitle="Reject Reason"
          :fieldValue="viewData.reject_reason"
          titleStyles="has-text-danger"
          :valueStyles="
            viewData.previous_applications &&
            viewData.reject_reason !=
              viewData.previous_applications.reject_reason
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Mother's Maiden Name"
          :fieldValue="
            viewData.debit_Security_question
              ? viewData.debit_Security_question
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.debit_Security_question !=
              viewData.previous_applications.debit_Security_question
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="viewData.debit_card_status === 'rejected'"
          fieldTitle="Rejected Date"
          :fieldValue="
            viewData.debit_card_status_change_date
              ? viewData.debit_card_status_change_date.split('T')[0]
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.debit_card_status_change_date !=
              viewData.previous_applications.debit_card_status_change_date
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="viewData.debit_card_status === 'issued'"
          fieldTitle="Approved Date and Time"
          :fieldValue="
            viewData.debit_card_status_change_date
              ? viewData.debit_card_status_change_date.split('T')[0]
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.debit_card_status_change_date !=
              viewData.previous_applications.debit_card_status_change_date
              ? `has-text-danger`
              : ``
          "
        />
        <hr class="application-view-hr" />
      </div>

      <div
        class="column"
        v-if="viewData.applicant_individual_account_type == 'Minor Account' || viewData.applicant_individual_account_type == 'Lakdaru Savings'"
      >
        <span class="application-sub-headers">Minor's Info</span>
        <SpanFields
          fieldTitle="Birth Certificate Number"
          :fieldValue="
            viewData.lakdaru_birth_certificate_num
              ? viewData.lakdaru_birth_certificate_num
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_birth_certificate_num !=
              viewData.previous_applications.lakdaru_birth_certificate_num
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Minor's Full Name"
          :fieldValue="
            viewData.lakdaru_minor_name ? viewData.lakdaru_minor_name : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_minor_name !=
              viewData.previous_applications.lakdaru_minor_name
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Birth Date"
          :fieldValue="
            viewData.lakdaru_minor_birth_day
              ? viewData.lakdaru_minor_birth_day
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_minor_birth_day !=
              viewData.previous_applications.lakdaru_minor_birth_day
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="NIC"
          :fieldValue="
            viewData.lakdaru_minor_nic
              ? viewData.lakdaru_minor_nic
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_minor_nic !=
              viewData.previous_applications.lakdaru_minor_nic
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Gender"
          :fieldValue="
            viewData.lakdaru_minor_gender ? viewData.lakdaru_minor_gender : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_minor_gender !=
              viewData.previous_applications.lakdaru_minor_gender
              ? `has-text-danger`
              : ``
          "
        />
        <hr class="application-view-hr" />
      </div>
      <div
        class="column"
        v-if="viewData.lakdaru_want_to_apply_transfer_order == 0 && !viewData.debitCardApplication"
      >
        <span class="application-sub-headers">Account Transfer Order Details</span>
        <SpanFields
          fieldTitle="Account Number"
          :fieldValue="
            viewData.lakdaru_transfer_acc_num
              ? viewData.lakdaru_transfer_acc_num
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_transfer_acc_num !=
              viewData.previous_applications.lakdaru_transfer_acc_num
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Account Holder's Name"
          :fieldValue="
            viewData.lakdaru_transfer_acc_holder_name ? viewData.lakdaru_transfer_acc_holder_name : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_transfer_acc_holder_name !=
              viewData.previous_applications.lakdaru_transfer_acc_holder_name
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Transfer Amount"
          :fieldValue="
            viewData.lakdaru_transfer_amount
              ? viewData.lakdaru_transfer_amount
              : '-'
          "
        />
        <SpanFields
          fieldTitle="Transfer Frequency"
          :fieldValue="
            viewData.lakdaru_transfer_frequency ? viewData.lakdaru_transfer_frequency : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_transfer_frequency !=
              viewData.previous_applications.lakdaru_transfer_frequency
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Transfer Date"
          :fieldValue="
            viewData.lakdaru_minor_transfer_date ? viewData.lakdaru_minor_transfer_date : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_minor_transfer_date !=
              viewData.previous_applications.lakdaru_minor_transfer_date
              ? `has-text-danger`
              : ``
          "
        />
        <hr class="application-view-hr" />
      </div>

      <div
        class="column"
        v-if="
          (viewData.applicant_individual_account_type == 'Minor Account' || viewData.applicant_individual_account_type == 'Lakdaru Savings') &&
          viewData.lakdaru_want_to_apply_transfer_order == 1 && !viewData.debitCardApplication
        "
      >
        <span class="application-sub-headers">Account Transfer Order</span>
        <SpanFields
          fieldTitle="Account Number"
          :fieldValue="
            viewData.lakdaru_transfer_acc_num
              ? viewData.lakdaru_transfer_acc_num
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_transfer_acc_num !=
              viewData.previous_applications.lakdaru_transfer_acc_num
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Account Holder's Name"
          :fieldValue="
            viewData.lakdaru_transfer_acc_holder_name
              ? viewData.lakdaru_transfer_acc_holder_name
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_transfer_acc_holder_name !=
              viewData.previous_applications.lakdaru_transfer_acc_holder_name
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Transfer Amount"
          :fieldValue="
            viewData.lakdaru_transfer_amount
              ? viewData.lakdaru_transfer_amount
              : '-'
          "
        />
        <SpanFields
          fieldTitle="Transfer Frequency"
          :fieldValue="
            viewData.lakdaru_transfer_frequency
              ? viewData.lakdaru_transfer_frequency
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.lakdaru_transfer_frequency !=
              viewData.previous_applications.lakdaru_transfer_frequency
              ? `has-text-danger`
              : ``
          "
        />
        <hr class="application-view-hr" />
      </div>

      <div
        class="column"
        v-if="viewData.minor_account_type == 'Fixed Deposit' && !viewData.debitCardApplication"
      >
        <span class="application-sub-headers">Fixed Deposit Info</span>
        <SpanFields
          fieldTitle="FD Period"
          :fieldValue="viewData.fd_period ? viewData.fd_period : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_period != viewData.previous_applications.fd_period
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Deposit Value"
          :fieldValue="viewData.fd_deposit ? viewData.fd_deposit : '-'"
        />
        <SpanFields
          fieldTitle="Interest Payment at"
          :fieldValue="
            viewData.fd_interest_payable_at
              ? viewData.fd_interest_payable_at
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_interest_payable_at !=
              viewData.previous_applications.fd_interest_payable_at
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Interest Rate%"
          :fieldValue="viewData.fd_rate ? viewData.fd_rate : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_rate != viewData.previous_applications.fd_rate
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Interest Payable Method"
          :fieldValue="
            viewData.fd_interest_disposal_method
              ? viewData.fd_interest_disposal_method
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_interest_disposal_method !=
              viewData.previous_applications.fd_interest_disposal_method
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="FD Certificate Type"
          :fieldValue="
            viewData.fd_how_cus_need_certificate ? viewData.fd_how_cus_need_certificate : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_how_cus_need_certificate !=
              viewData.previous_applications.fd_how_cus_need_certificate
              ? `has-text-danger`
              : ``
          "
        />
      </div>

      <div
        class="column"
        v-if="viewData.applicant_individual_account_type == 'Fixed Deposit' && !viewData.debitCardApplication"
      >
        <span class="application-sub-headers">Fixed Deposit Info</span>
        <SpanFields
          fieldTitle="FD Period"
          :fieldValue="viewData.fd_period ? viewData.fd_period : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_period != viewData.previous_applications.fd_period
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Deposit Value"
          :fieldValue="viewData.fd_deposit ? viewData.fd_deposit : '-'"
        />
        <SpanFields
          fieldTitle="Interest Payment at"
          :fieldValue="
            viewData.fd_interest_payable_at
              ? viewData.fd_interest_payable_at
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_interest_payable_at !=
              viewData.previous_applications.fd_interest_payable_at
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Interest Rate%"
          :fieldValue="viewData.fd_rate ? viewData.fd_rate : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_rate != viewData.previous_applications.fd_rate
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Interest Payable Method"
          :fieldValue="
            viewData.fd_interest_disposal_method
              ? viewData.fd_interest_disposal_method
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_interest_disposal_method !=
              viewData.previous_applications.fd_interest_disposal_method
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="FD Certificate Type"
          :fieldValue="
            viewData.fd_how_cus_need_certificate ? viewData.fd_how_cus_need_certificate : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_how_cus_need_certificate !=
              viewData.previous_applications.fd_how_cus_need_certificate
              ? `has-text-danger`
              : ``
          "
        />

        <SpanFields
          v-if="viewData.fd_interest_disposal_method == 'Transfer'"
          fieldTitle="Interest Transfer Account"
          :fieldValue="
            viewData.fd_transfer_acc_type ? viewData.fd_transfer_acc_type : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_transfer_acc_type !=
              viewData.previous_applications.fd_transfer_acc_type
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="
            viewData.fd_interest_disposal_method == 'Transfer' &&
            viewData.fd_transfer_acc_type == 'Other bank account'
          "
          fieldTitle="Transfer Account Bank"
          :fieldValue="
            viewData.fd_interest_transfer_bank
              ? viewData.fd_interest_transfer_bank
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_interest_transfer_bank !=
              viewData.previous_applications.fd_interest_transfer_bank
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="
            viewData.fd_interest_disposal_method == 'Transfer' &&
            viewData.fd_transfer_acc_type == 'Other bank account'
          "
          fieldTitle="Transfer Account Branch"
          :fieldValue="
            viewData.fd_interest_transfer_branch
              ? viewData.fd_interest_transfer_branch
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_interest_transfer_branch !=
              viewData.previous_applications.fd_interest_transfer_branch
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="
            viewData.fd_interest_disposal_method == 'Transfer' &&
            viewData.fd_transfer_acc_type == 'SDB Bank PLC - Saving'
          "
          fieldTitle="Interest Transfer Account Number"
          :fieldValue="
            viewData.fd_sdb_savings_acc_num
              ? viewData.fd_sdb_savings_acc_num
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_sdb_savings_acc_num !=
              viewData.previous_applications.fd_sdb_savings_acc_num
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="
            viewData.fd_interest_disposal_method == 'Transfer' &&
            viewData.fd_transfer_acc_type == 'Other bank account'
          "
          fieldTitle="Interest Transfer Account Number"
          :fieldValue="
            viewData.fd_transfer_acc_num ? viewData.fd_transfer_acc_num : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_transfer_acc_num !=
              viewData.previous_applications.fd_transfer_acc_num
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="viewData.fd_interest_disposal_method == 'Transfer'"
          fieldTitle="Interest Transfer Account Holder's Name"
          :fieldValue="
            viewData.debit_account_holder_name
              ? viewData.debit_account_holder_name
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.debit_account_holder_name !=
              viewData.previous_applications.debit_account_holder_name
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          v-if="(viewData.account_type = 'Individual')"
          fieldTitle="FD Renewal Notice Type"
          :fieldValue="
            viewData.fd_cus_fd_renewal_notice
              ? viewData.fd_cus_fd_renewal_notice
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_cus_fd_renewal_notice !=
              viewData.previous_applications.fd_cus_fd_renewal_notice
              ? `has-text-danger`
              : ``
          "
        />

        <div
          v-if="
            viewData.fd_cus_fd_renewal_notice === 'Letter to permanent address'
          "
        >
          <SpanFields
            fieldTitle="Permanent Address"
            :fieldValue="
              viewData.address_1 +
              ', ' +
              viewData.address_2 +
              ', ' +
              viewData.address_3 +
              ', ' +
              viewData.address_4
            "
            :valueStyles="
              viewData.previous_applications &&
              (viewData.address_1 != viewData.previous_applications.address_1 ||
                viewData.address_2 !=
                  viewData.previous_applications.address_2 ||
                viewData.address_3 !=
                  viewData.previous_applications.address_3 ||
                viewData.address_4 != viewData.previous_applications.address_4)
                ? `has-text-danger`
                : ``
            "
          />
        </div>

        <div
          v-else-if="
            viewData.fd_cus_fd_renewal_notice ===
            'Letter to alternative address'
          "
        >
          <SpanFields
            fieldTitle="Alternative Address"
            :fieldValue="
              viewData.fd_letter_to_alt_address
                ? viewData.fd_letter_to_alt_address
                : '-'
            "
          />
        </div>
        <hr class="application-view-hr" />
      </div>

      <div
        class="column"
        v-if="
          viewData.applicant_individual_account_type == 'Fixed Deposit' &&
          viewData.fd_certificate_issued == 'true'
        "
      >
        <span class="application-sub-headers">Fixed Certificate Info</span>
        <SpanFields
          fieldTitle="Certificate ID"
          :fieldValue="
            viewData.fd_certificate_id ? viewData.fd_certificate_id : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_certificate_id !=
              viewData.previous_applications.fd_certificate_id
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Certificate Type"
          :fieldValue="
            viewData.fd_certificate_type ? viewData.fd_certificate_type : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_certificate_type !=
              viewData.previous_applications.fd_certificate_type
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Issued Date"
          :fieldValue="
            viewData.fd_certificate_issued_date
              ? viewData.fd_certificate_issued_date
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.fd_certificate_issued_date !=
              viewData.previous_applications.fd_certificate_issued_date
              ? `has-text-danger`
              : ``
          "
        />

        <div v-if="viewData.account_type != null">
          <spanFields
            :fieldTitle="
              viewData.fd_sms_aleart_num != null
                ? 'SMS Alert Number'
                : `Letter Will Be Send To`
            "
            :fieldValue="
              viewData.fd_sms_aleart_num != null
                ? viewData.fd_sms_aleart_num
                : viewData.fd_letter_to_alt_address != null
                ? viewData.fd_letter_to_alt_address
                : viewData.fd_letter_to_per_address
            "
          />
        </div>

        <SpanFields
          fieldTitle="How Your Customer Need FD Certificate"
          :fieldValue="
            viewData.fd_how_cus_need_certificate
              ? viewData.fd_how_cus_need_certificate
              : '-'
          "
        />

        <hr class="application-view-hr" />
      </div>

      <div class="column" v-if="viewData.fd_signature_requirement != null && !viewData.debitCardApplication">
        <span class="application-sub-headers">Operating Instructions</span>
        <div class="containerStyles">
            <div class="titleFontStyles" :class="titleStyles">
              Signature Requirement
            </div>
            <div class="seperatorContainer">
                :
            </div>
            <div class="valueFontStyles" :class="valueStyles" >
              {{viewData.fd_signature_requirement}}
            </div>
        </div>
        <hr class="application-view-hr" />
      </div>

      <div class="column" v-if="usingTheSDBServer == true && existingAccounts != null && !viewData.debitCardApplication">
        <span class="application-sub-headers">Existing Accounts</span>
        <div v-for="(item, index) in existingAccounts"
        :key="index"
        >
          <SpanFields
            fieldTitle="Account Number"
            :fieldValue="item.account_number"
          />
          <SpanFields
            v-if="item.applicant_individual_account_type"
            fieldTitle="Individual acc: type"
            :fieldValue="item.applicant_individual_account_type"
          />
          <SpanFields
            fieldTitle="Account Type"
            :fieldValue="item.account_type"
          />
          <SpanFields
            v-if="item.savings_account_type && item.applicant_individual_account_type == 'Savings Account'"
            fieldTitle="Saving Account Type"
            :fieldValue="item.savings_account_type"
          />
          <SpanFields
            v-if="item.applicant_individual_account_type == 'Minor Account' || viewData.applicant_individual_account_type == 'Lakdaru Savings'"
            fieldTitle="Minor Account Type"
            :fieldValue="item.minor_account_type"
          />
          <SpanFields
            fieldTitle="Application No"
            :fieldValue="item.id"
          />
          <br>
        </div>
        <hr class="application-view-hr" />
      </div>

        <span class="application-sub-headers">Application submitted By</span>
        <SpanFields
          fieldTitle="BDO"
          :fieldValue="
            viewData.submitted_by_email ? viewData.submitted_by_email : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.submitted_by_email !=
              viewData.previous_applications.submitted_by_email
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="
            viewData.submitted_user_epf ? viewData.submitted_user_epf : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.submitted_user_epf !=
              viewData.previous_applications.submitted_user_epf
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Branch"
          :fieldValue="
            viewData.submitted_user_branch
              ? viewData.submitted_user_branch
              : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.submitted_user_branch !=
              viewData.previous_applications.submitted_user_branch
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="Location"
          :fieldValue="
            viewData.submitted_location ? viewData.submitted_location : '-'
          "
          :valueStyles="
            viewData.previous_applications &&
            viewData.submitted_location !=
              viewData.previous_applications.submitted_location
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields fieldTitle="App Version" :fieldValue="version" />

        <!-- <GmapMap
          :center="mapCenter"
          :zoom="7"
          map-type-id="terrain"
          style="width: 400px; height: 300px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="centerMap(m.position)"
          />
        </GmapMap> -->

      <!-- <div class="column">
                    <span class="application-sub-headers">Internal Sanction Details</span>
                    <SpanFields fieldTitle="BDO" fieldValue="Nirmal" />
                    <div class="button-container">
                    <ActionButton buttonLabel="Review & Saction List Check" expanded size="is-medium" type="is-danger"
                        divStyles="mt-4" />
                    </div>
                </div> -->

      <div v-if="!viewData.debitCardApplication">
        <hr class="application-view-hr" />
      </div>

      <div v-if="!viewData.debitCardApplication" class="column">
        <!-- <span class="application-sub-headers" style="display: flex; margin: 10px 0;">Internal Sanction Details</span> -->

        <!-- Review & Sanction List not checked-->
        <div
          v-if="
            !viewData.debitCardApplication &&
            (viewData.sanction_list_status == 0 ||
              viewData.sanction_list_status == null)
          "
        >
          <div class="checkbox-button notification is-info mt-0 info-class">
            <b-checkbox
              class="application-checkbox specific"
              :true-value="1"
              :false-value="0"
              v-model="isSanctionListChecked"
              @input="$emit('sanctionListChangeHandler', isSanctionListChecked)"
              :disabled="
                isSanctionListChecked == 1 ||
                viewData.sanction_list_status == 1 ||
                (viewData.kyc_is_customer_pep !=
                'Customer is not a politically exposed person' && isPEPChecked == 0 && viewData.pep_status != 1) ||
                viewData.application_status == 'Reject' ||
                viewData.application_status == 'Request To Improve'
              "
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
              >Review & Sanction List Check</b-checkbox
            >
          </div>
        </div>

        <!-- Review & Sanction List checked-->
        <div
          v-if="
            !viewData.debitCardApplication && viewData.sanction_list_status == 1
          "
        >
          <div class="checkbox-button notification is-primary mt-0">
            <b-checkbox
              class="application-checkbox specific"
              :value="true"
              disabled
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
              >Reviewed & Sanction List Checked</b-checkbox
            >
          </div>
        </div>
      </div>

      <!-- Review & Sanction List agent info-->
      <div class="column" v-if="viewData.sanction_list_status == 1 && !viewData.debitCardApplication">
        <span class="application-sub-headers"
          >Sanction List Checked Agent Info</span
        >
        <SpanFields
          fieldTitle="Officer Details"
          :fieldValue="viewData.sanction_staff ? viewData.sanction_staff : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.sanction_staff !=
              viewData.previous_applications.sanction_staff
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="viewData.sanction_epf ? viewData.sanction_epf : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.sanction_epf != viewData.previous_applications.sanction_epf
              ? `has-text-danger`
              : ``
          "
        />
        <hr class="application-view-hr" />
      </div>

      <!-- Sanction List info-->
      <div
        class="column"
        v-if="
          !viewData.debitCardApplication && viewData.sanction_list_status == 1
        "
      >
        <span class="application-sub-headers">Sanction List Info</span>

        <div v-if="viewData.blacklist_customer == 'yes'">
          <SpanFields
            fieldTitle="Name"
            :fieldValue="
              viewData.blacklist_name ? viewData.blacklist_name : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_name !=
                viewData.previous_applications.blacklist_name
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="NIC No"
            :fieldValue="
              viewData.blacklist_nic_no ? viewData.blacklist_nic_no : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_nic_no !=
                viewData.previous_applications.blacklist_nic_no
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="Address Line 1"
            :fieldValue="
              viewData.blacklist_address_line_1
                ? viewData.blacklist_address_line_1
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_address_line_1 !=
                viewData.previous_applications.blacklist_address_line_1
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="Address Line 2"
            :fieldValue="
              viewData.blacklist_address_line_2
                ? viewData.blacklist_address_line_2
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_address_line_2 !=
                viewData.previous_applications.blacklist_address_line_2
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="Expire Date"
            :fieldValue="
              viewData.blacklist_expire_date
                ? viewData.blacklist_expire_date
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_expire_date !=
                viewData.previous_applications.blacklist_expire_date
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="Rec Type"
            :fieldValue="
              viewData.blacklist_rec_type ? viewData.blacklist_rec_type : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_rec_type !=
                viewData.previous_applications.blacklist_rec_type
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="Remarks Line 1"
            :fieldValue="
              viewData.blacklist_remarks_line_1
                ? viewData.blacklist_remarks_line_1
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_remarks_line_1 !=
                viewData.previous_applications.blacklist_remarks_line_1
                ? `has-text-danger`
                : ``
            "
          />
          <SpanFields
            fieldTitle="Remarks Line 2"
            :fieldValue="
              viewData.blacklist_remarks_line_2
                ? viewData.blacklist_remarks_line_2
                : '-'
            "
            :valueStyles="
              viewData.previous_applications &&
              viewData.blacklist_remarks_line_2 !=
                viewData.previous_applications.blacklist_remarks_line_2
                ? `has-text-danger`
                : ``
            "
          />
        </div>

        <div v-else class="valueFontStyles">No Data Available</div>

        <hr class="application-view-hr" />
      </div>

      <div
        v-if="!viewData.debitCardApplication"
        class="column button-container"
      >
        <!-- Application Approved message -->
        <div v-if="viewData.application_status == 'Approved'">
          <ApplicationStatCard
            applicationType="Approved"
            applicationMessage="Account Creation Success!"
            message="Account Number"
            :number="viewData.account_number"
          />
        </div>
        <!-- Approve button Start -->
        <b-button
          id="approveBtn"
          v-if="
            viewData.review_status != 'Reject' &&
            viewData.review_status != 'Request To Improve' &&
            hasPermission('application-approve') &&
            !viewData.joint_acc
          "
          :disabled="
            (viewData.pep_status === 0 &&
              viewData.kyc_is_customer_pep !=
                'Customer is not a politically exposed person') ||
            (viewData.pep_status === 1 &&
              viewData.review_status === 'Approved') ||
            (isSanctionListChecked == 0 &&
              viewData.sanction_list_status == null &&
              viewData.blacklist_customer != 'no') ||
            viewData.review_status == 'Approved' || viewData.blacklist_customer == 'yes'
          "
          size="is-medium"
          type="is-primary"
          expanded
          class="mb-2"
          :loading="approveBtnLoading"
          @click="
            () => {
              applicationStatData.applicationStat = 'Approved';
              $emit('aprroveRequestHandler', applicationStatData);
            }
          "
          >{{
            viewData.review_status == "Approved" ? "Approved" : "Approve"
          }}</b-button
        >

        <!-- //////////////////////  Joint account buttons start  //////////////////////-->

        <!-- Reviewed & Next Owner button -->
        <b-button
          id="approveBtn"
          v-if="
            !viewData.debitCardApplication &&
            viewData.review_status != 'Reject' &&
            viewData.review_status != 'Request To Improve' &&
            hasPermission('application-approve') &&
            viewData.review_status != 'Approved' &&
            viewData.reviewAndNxt == 1
          "
          :disabled="
            (viewData.pep_status === 0 &&
              viewData.kyc_is_customer_pep !=
                'Customer is not a politically exposed person') ||
            (viewData.pep_status === 1 &&
              viewData.review_status === 'Approved') ||
            viewData.jointReviewStatus == null ||
            viewData.jointReviewStatus == 'Reject' ||
            viewData.jointReviewStatus == 'Request To Improve' ||
            (isSanctionListChecked == 0 &&
              viewData.sanction_list_status == null &&
              viewData.blacklist_customer != 'no')
          "
          size="is-medium"
          type="is-primary"
          expanded
          class="mb-2"
          @click="
            () => {
              applicationStatData.applicationStat = 'Approved';
              applicationStatData.reviewAndNext = 1;
              $emit('aprroveRequestHandler', applicationStatData);
            }
          "
          >Reviewed & Next Owner</b-button
        >

        <!-- Reviewed & Approve button -->
        <b-button
          id="approveBtn"
          v-if="
            !viewData.debitCardApplication &&
            applicationStatData.applicationRequestType != 'Reject' &&
            applicationStatData.applicationRequestType !=
              'Request To Improve' &&
            hasPermission('application-approve') &&
            viewData.review_status != 'Approved' &&
            viewData.joint_acc &&
            viewData.reviewAndNxt == 0
          "
          :disabled="
            (viewData.pep_status === 0 &&
              viewData.kyc_is_customer_pep !=
                'Customer is not a politically exposed person') ||
            (viewData.pep_status === 1 &&
              viewData.review_status === 'Approved') ||
            viewData.jointReviewStatus == null ||
            viewData.jointReviewStatus == 'Reject' ||
            viewData.jointReviewStatus == 'Request To Improve' ||
            ( viewData.sanction_list_status == null &&
              viewData.blacklist_customer != 'no')
          "
          size="is-medium"
          type="is-primary"
          expanded
          class="mb-2"
          @click="
            () => {
              applicationStatData.applicationStat = 'Approved';
              applicationStatData.reviewAndNext = 0;
              $emit('aprroveRequestHandler', applicationStatData);
            }
          "
          >Reviewed & Approve</b-button
        >
        <!--////////////////////// Joint account buttons end //////////////////////-->

        <!-- Application reject message -->
        <div
          v-if="
            viewData.review_status == 'Reject' && !viewData.debitCardApplication
          "
        >
          <ApplicationStatCard
            applicationType="Rejected"
            applicationMessage="Account Rejected!"
          />
        </div>

        <!-- Request to improve button -->
        <b-button
          id="reqToImproveBtn"
          v-if="
            !viewData.debitCardApplication &&
            viewData.review_status != 'Reject' &&
            viewData.review_status != 'Approved' &&
            hasPermission('application-request-to-improve')
          "
          :disabled="
            (viewData.review_status === 'Request To Improve' &&
              viewData.req_improve_reasons == null) ||
            viewData.jointReviewStatus == null ||
            viewData.jointReviewStatus == 'Reject' ||
            viewData.jointReviewStatus == 'Request To Improve' ||
            viewData.kyc_is_customer_pep == 'Customer is not a politically exposed person'
          "
          size="is-medium"
          type="is-info"
          expanded
          class="mb-2"
          @click="
            () => {
              if (
                viewData.review_status === 'Request To Improve' &&
                viewData.req_improve_reasons != null
              ) {
                revokeRequestToImprove();
                isReqNotificationModalActive = false;
              } else {
                applicationStatData.applicationStat = 'Request To Improve';
                getDropdownOptions();
                isReqNotificationModalActive = true;
              }
            }
          "
          >{{
            viewData.review_status === "Request To Improve" &&
            viewData.req_improve_reasons != null
              ? "Revoke Request"
              : "Request To Improve"
          }}
        </b-button>

        <!-- Reject button -->
        <b-button
          id="rejectBtn"
          v-if="
            !viewData.debitCardApplication &&
            viewData.review_status != 'Request To Improve' &&
            viewData.review_status != 'Approved' &&
            hasPermission('application-reject') &&
            viewData.review_status != 'Reject'
          "
          :disabled="
            (viewData.pep_status === 1 &&
              viewData.review_status === 'Reject') ||
            viewData.jointReviewStatus == null ||
            viewData.jointReviewStatus == 'Reject' ||
            viewData.jointReviewStatus == 'Request To Improve'
          "
          size="is-medium"
          type="is-danger"
          expanded
          class="mb-2"
          @click="
            () => {
              applicationStatData.applicationStat = 'Reject';
              isNotificationModalActive = true;
            }
          "
          >Reject</b-button
        >
      </div>

      <div class="column" v-if="(viewData.application_status == 'Approved' || viewData.application_status == 'Reviewed') && !viewData.debitCardApplication">
        <span class="application-sub-headers">Branch Approving Officer</span>
        <SpanFields
          fieldTitle="Sanction Checked & Approved by"
          :fieldValue="viewData.reviewed_by ? viewData.reviewed_by : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.reviewed_by != viewData.previous_applications.reviewed_by
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="
            viewData.branch_apprv_epf ? viewData.branch_apprv_epf : '-'
          "
        />
        <!-- <div class="button-container"> -->
        <!-- <ActionButton
            buttonLabel="Review by centralized Ops"
            expanded
            size="is-medium"
            type="is-danger"
            divStyles="mt-4"
          /> -->
        </div> 
        <hr class="application-view-hr" />
        <!-- Review by centralized Ops not checked-->
        <div class="column" v-if="!viewData.debitCardApplication && (viewData.ops === 0 || viewData.ops === null)">
          <div class="checkbox-button notification is-info mt-0">
            <b-checkbox
              class="application-checkbox specific"
              :true-value="1"
              :false-value="0"
              v-model="isCentralizedOpsChecked"
              @input="$emit('centralizedOpsChangeHandler', isCentralizedOpsChecked)"
              :disabled="(isCentralizedOpsChecked === 1 || viewData.ops === 1)  || ((isPEPChecked == 0 && viewData.pep_status != 1) && viewData.kyc_is_customer_pep != 'Customer is not a politically exposed person')|| (viewData.review_status == 'Request To Improve') ||(viewData.review_status == 'Reject') ||(viewData.review_status == 'pending') ||(viewData.review_status == null) || !hasPermission('all-branch-access')"
              @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave"
              >Review by centralized Ops</b-checkbox
            >
          </div>
        <!-- </div> -->
      </div>

      <!-- Review by centralized Ops checked-->
      <div
        class="column"
        v-if="!viewData.debitCardApplication && viewData.ops === 1"
      >
        <div class="checkbox-button notification is-primary mt-0">
          <b-checkbox
            class="application-checkbox specific"
            :value="true"
            disabled
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
            >Reviewed by centralized Ops</b-checkbox
          >
        </div>
      </div>

      <!-- </div> -->

      <!-- Centralized Ops Agent Info -->
      <div
        class="column"
        v-if="isCentralizedOpsChecked === 1 || viewData.ops === 1"
      >
        <span class="application-sub-headers">Centralized Ops Agent Info</span>
        <SpanFields
          fieldTitle="Officer Details"
          :fieldValue="viewData.ops_staff ? viewData.ops_staff : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.ops_staff != viewData.previous_applications.ops_staff
              ? `has-text-danger`
              : ``
          "
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="viewData.ops_epf ? viewData.ops_epf : '-'"
          :valueStyles="
            viewData.previous_applications &&
            viewData.ops_epf != viewData.previous_applications.ops_epf
              ? `has-text-danger`
              : ``
          "
        />
        <hr class="application-view-hr" />
      </div>

      <div class="column" v-if="viewData.debit_card_status != 'pending' && viewData.debitCardApplication">
        <span class="application-sub-headers">{{ viewData.debit_card_status == 'issued' ? 'Debit Card Approved Agent Info' : 'Debit Card Rejected Agent Info' }}</span>
        <SpanFields
          :fieldTitle="viewData.debit_card_status == 'issued' ? 'Approved By' : 'Rejected By'"
          :fieldValue="
            viewData.debit_card_status_changed_by_email
              ? viewData.debit_card_status_changed_by_email
              : '-'
          "
        />
        <SpanFields
          fieldTitle="EPF"
          :fieldValue="
            viewData.debit_card_status_changed_by_epf
              ? viewData.debit_card_status_changed_by_epf
              : '-'
          "
        />
        <SpanFields
          fieldTitle="Branch"
          :fieldValue="
            viewData.debit_card_status_changed_by_branch
              ? viewData.debit_card_status_changed_by_branch
              : '-'
          "
        />
      </div>

      <div class="column">
        <span class="application-sub-headers">Update Info</span>
        <SpanFields
          fieldTitle="Applied Date"
          :fieldValue="
            viewData.application_applied_date
              ? viewData.application_applied_date
              : '-'
          "
        />
        <SpanFields
          v-if="viewData.application_status == 'Approved' || viewData.application_status == 'Reviewed'"
          fieldTitle="Sanction List Checked Date"
          :fieldValue="
            viewData.application_reviewed_date
              ? viewData.application_reviewed_date
              : '-'
          "
        />
        <SpanFields
          v-if="viewData.application_status == 'Approved' || viewData.application_status == 'Reviewed'"
          fieldTitle="Approved Date"
          :fieldValue="
            viewData.application_approved_date
              ? viewData.application_approved_date
              : '-'
          "
        />
        <SpanFields
          v-if="viewData.application_status == 'Request To Improve'"
          fieldTitle="Requested To Improve Date"
          :fieldValue="
            viewData.application_approved_date
              ? viewData.application_approved_date
              : '-'
          "
        />
        <SpanFields
          v-if="viewData.application_status == 'Reject'"
          fieldTitle="Rejected Date"
          :fieldValue="
            viewData.application_approved_date
              ? viewData.application_approved_date
              : '-'
          "
        />
       
      </div>

      <div class="column" v-if="!viewData.debitCardApplication">
        <span class="application-sub-headers">SMS/Email Delivery Status</span>
        <SpanFields
          v-if="viewData.approval_email !== null"
          fieldTitle="Account creation email send status"
          :fieldValue="
            viewData.approval_email == 'success'
              ? 'Success'
              : 'Failed'
          "
          :valueStyles="
            viewData.approval_email == 'success'
              ? `has-text-success`
              : `has-text-danger`
          "
        />
        <SpanFields
          v-if="viewData.approval_sms !== null"
          fieldTitle="Account creation SMS send status"
          :fieldValue="
            viewData.approval_sms == 'success'
              ? 'Success'
              : 'Failed'
          "
          :valueStyles="
            viewData.approval_sms == 'success'
              ? `has-text-success`
              : `has-text-danger`
          "
        />
        <SpanFields
          v-if="viewData.fd_email !== null"
          fieldTitle="FD certificate send as email"
          :fieldValue="
            viewData.fd_email == 'success'
              ? 'Success'
              : 'Failed'
          "
          :valueStyles="
            viewData.fd_email == 'success'
              ? `has-text-success`
              : `has-text-danger`
          "
        />
        <SpanFields
          v-if="viewData.fd_sms !== null"
          fieldTitle="FD certificate send as SMS"
          :fieldValue="
            viewData.fd_sms == 'success'
              ? 'Success'
              : 'Failed'
          "
          :valueStyles="
            viewData.fd_sms == 'success'
              ? `has-text-success`
              : `has-text-danger`
          "
        />
      </div>
    </div>

    <div class="sub-box-two">
      <div style="display: flex; flex-direction: row-reverse" class="my-3">
        <!-- <div class="column is-one-fifth"> -->
        <!-- <ActionButton
          buttonLabel="Message to BDO"
          type="is-primary"
          divStyles=" mt-1 "
          iconRight="message-text-outline"
        /> -->
        <!-- </div> -->

        <!-- <div class="column is-one-fifth"> -->
        <!-- <ActionButton
          buttonLabel="Certificate Issue"
          type="is-primary"
          divStyles=" mt-1 mx-3"
          iconRight="certificate-outline"
        /> -->
        <!-- </div> -->

        <!-- <div class="column is-one-fifth"> -->
        <!-- <ActionButton
          buttonLabel="Download PDF"
          type="is-primary"
          divStyles=" mt-1"
          iconRight="file-download-outline"
        />-->

        <!-- Add this button to dashboard later  -->
        <!-- <b-button
          @click="isFYCMOpen = true"
          type="is-primary"
          class="ml-2"
          >Issue Card</b-button
        > -->

        <!-- <b-button type="is-danger" class="ml-2" @click="$router.go(-1)">Close</b-button> -->
        <!-- <b-button
          v-if="
            viewData.officer_details.username == 'manula_pankaja' ||
            viewData.officer_details.username == 'Hansani' ||
            viewData.officer_details.username == 'sahan.darme' ||
            viewData.officer_details.username == 'ishara-backend'
          "
          type="is-danger"
          class="ml-2"
          @click="deleteApplication"
          >Delete (only for testing purposes)</b-button
        > -->
        <b-button
          v-if="!viewData.debitCardApplication"
          @click="downloadPdf()"
          type="is-primary"
          iconRight="file-download-outline"
          class="ml-2"
          >Download PDF</b-button
        >
        <b-button
          v-if="viewData.debitCardApplication"
          @click="downloadDebitCardPdf()"
          type="is-primary"
          iconRight="file-download-outline"
          class="ml-2"
          >Download PDF</b-button
        >


        <!-- <b-button
          v-if="
            !viewData.debitCardApplication &&
            viewData.applicant_individual_account_type == 'Fixed Deposit'
          "
          type="is-primary"
          iconRight="certificate-outline"
          class="ml-2"
          :disabled="
            viewData.fd_certificate_issued == 'true' ||
            viewData.application_status != 'Approved'
          "
          @click="sendOtpFD()"
        >
          {{
            viewData.fd_certificate_issued == "true"
              ? "Certificate Issued"
              : "Certificate Issue"
          }}</b-button
        > -->

        <!-- v-if="
            viewData.application_status == 'Approved' &&
          :disabled="viewData.application_status != 'Approved'"
          v-if="
            viewData.debit_card_status != 'rejected' &&
            (hasPermission('card-issue') || hasPermission('card-reject')) &&
            (viewData.debit_card_status == 'pending' || viewData.debit_card_status == null) &&
            viewData.debit_is_customer_interest == 1
          "  add this into below button before deploy -->

        <!-- debit card button can reuse has to -->
        <b-button
          @click="debitCardRejectModalActive = true"
          type="is-danger"
          iconRight="credit-card-outline"
          class="ml-2"
          v-if="
            (viewData.debit_card_status != 'rejected' &&
            (hasPermission('card-issue') || hasPermission('card-reject')) &&
            (viewData.debit_card_status == 'pending' ||
              viewData.debit_card_status == null) &&
            viewData.debitCardApplication) && viewData.debit_is_customer_interest == 1
          "
          :disabled="
            (viewData.application_status != 'Approved' &&
            viewData.application_status != 'Reviewed'  && viewData.application_status != 'existingDebitCard')
          "
          >Debit Card Reject</b-button
        >
        <b-button
          @click="sendOtp()"
          type="is-primary"
          iconRight="credit-card-outline"
          class="ml-2"
          v-if="
            (viewData.debit_card_status != 'rejected' &&
            (hasPermission('card-issue') || hasPermission('card-reject')) &&
            (viewData.debit_card_status == 'pending' ||
              viewData.debit_card_status == null) &&
            viewData.debitCardApplication) && viewData.debit_is_customer_interest == 1
          "
          :disabled="
            (viewData.application_status != 'Approved' &&
            viewData.application_status != 'Reviewed' && viewData.application_status != 'existingDebitCard') || viewData.terms_and_conditions != 1
          "
          >Debit Card Approve</b-button
        >
        <b-button
          type="is-primary"
          iconRight="credit-card-outline"
          class="ml-2"
          v-if="
             viewData.debit_card_status == 'issued' &&
            viewData.debitCardApplication
          "
          disabled
          >Debit Card Approved</b-button
        >
        <b-button
          type="is-danger"
          iconRight="file-download-outline"
          class="ml-2-vidu"
          v-if="
            viewData.debit_card_status == 'rejected' &&
            (hasPermission('card-issue') || hasPermission('card-reject'))
          "
          disabled
          >Debit Card Rejected</b-button
        >

        <!-- <b-button @click="()=> {isModalActive = true}" type="is-primary" iconRight="file-download-outline">Debit Card Issue</b-button> -->

        <!-- <b-button @click="()=> {isCardModalActive = true}" type="is-primary">Message to BDO</b-button> -->
      </div>

      <!-- Request to improve component -->

      <div
        v-if="viewData.req_improve_reasons != null"
        class="columnReq notificationReq"
        :class="{ expanded: isExpanded }"
      >
        <b-card>
          <b-collapse v-model="isExpanded">
            <template #trigger>
              <div class="header">
                <span
                  class="box-title"
                  style="
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 5px;
                    margin-left: 10px;
                  "
                  >{{ boxTitle }}</span
                >
                <i class="arrow-icon" :class="{ expanded: isExpanded }"
                  >&#x25BE;</i
                >
              </div>
            </template>
            <div class="content">
              <div
                v-for="item in reqReasons"
                :key="item.header"
                class="textbox"
              >
                <div class="textbox-header highlighted">{{ item.header }}</div>
                <div class="input-box-text">
                  <input
                    style="color: #000"
                    v-model="item.value"
                    :placeholder="item.header"
                    readonly
                  />
                </div>
              </div>
            </div>
          </b-collapse>
        </b-card>
      </div>
      <!-- </div> -->

      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 10px;
        "
      >
        <ApplicationViewCard
          v-if="!viewData.debitCardApplication"
          style="width: 45%"
          :cardData="cardDataTwo"
          cardTitle="Line of Work"
        />
        <KycApplicationViewCard
          v-if="!viewData.debitCardApplication"
          style="width: 45%"
          :cardData="cardData2"
          cardTitle="KYC"
        />
      </div>
      <div class="column notification" v-if="!viewData.debitCardApplication">   
        <!-- <span class="application-sub-headers">Internal Sanction Details</span> -->
        <CommentField :commentArray="comments" />
        <div class="column">Add Comment</div>
        <div class="columns">
          <div class="column is-four-fifths">
            <b-input
              type="text"
              placeholder="Comment"
              v-model="userComment"
              maxlength="134"
            />
          </div>
          <div class="column">
            <b-button
              :disabled="userComment === ''"
              type="is-primary"
              @click="$emit('userCommentSubmitHandler', userComment)"
              >Comment</b-button
            >
          </div>
        </div>
      </div>

      <div class="column notification" v-if="viewData.debitCardApplication"> 
        <DebitCommentField :debitCommentArray="debitComments" />
        <div class="column">Add Comment</div>
        <div class="columns">
          <div class="column is-four-fifths">
            <b-input
              type="text"
              placeholder="Comment"
              v-model="userComment"
              maxlength="134"
            />
          </div>
          <div class="column">
            <b-button
              :disabled="userComment === ''"
              type="is-primary"
              @click="$emit('debitUserCommentSubmitHandler', userComment)"
              >Comment</b-button
            >
          </div>
        </div>
      </div>

      <div
        class="column notification"
        id="debitCardDeclaration"
        v-if="
          viewData.debit_is_customer_interest == true &&
          viewData.debitCardApplication
        "
      >
        <span class="application-sub-headers">Debit Card Declaration</span>
        <p>
          <b
            >Declaration by the Applicant/s for Electronic Fund Transfer Cards</b
          >
        </p>
        <br/>
        <span>
          (To be filled by the Applicant/s to obtain foreign exchange against 
          Credit/Debit or any other Electronic Fund Transfer Card).
        </span>
        <br />
        <span>
          I/We(Primary / Supplementary Cardholder), (Primary/ Supplementary Cardholder)
          declare that all details given above by me/us on this form are true and correct.
        </span>
        <br/>
        <span id="originalText1">
          I/We hereby confirm that I/ We am/ are aware of the terms and conditions applicable 
          for the use of Electronic Fund Transfer Cards (EFTCs) as ..<span
              class="custom"
              @click="toggleReadMoreDeclaration()"
              >Read more</span
            >
        </span>
        <span id="extraText1" style="display: none">
        <span>
          I/We hereby confirm that I/ We am/ are aware of the terms and conditions applicable 
          for the use of Electronic Fund Transfer Cards (EFTCs) as detailed in the Directions 
          No. 03 of 2021 dated 18 March 2021 (Annexed) issued under the provisions of the
          Foreign Exchange Act, No. 12 of 2017 (the FEA) subject to which the card may be used 
          for transactions in foreign exchange and I/We hereby undertake to abide by the said conditions.
        </span>
        <br/>
        <span>
          I/ We further agree to provide any information on transactions carried out by me/ us in foreign
          exchange on the card issued to me/us as SDB Bank may require for the purpose of the FEA.
          I/ We am/ are aware that the bank is required to suspend availability of foreign exchange on 
          EFTC if reasonable grounds exist to suspect that foreign exchange transactions which are not
          permitted in terms of the annexed Directions issued under the provisions of the FEA are being 
          carried out on the EFTC issued to me/us and to report the matter to the Director - Department 
          of Foreign Exchange.
        </span>
        <br/>
        <span>
          I/ We also affirm that I/ We undertake to surrender the EFTCs to the bank, if I/ We migrate or 
          leave Sri Lanka for permanent residence or employment abroad, as applicable. Further, I/we also 
          agreed to notify my/our change in residential status to the bank, if any, accordingly.
        </span>
        <br />
        <span>
          I hereby confirm that all information given above is true & correct.
          I further confirm that the terms & conditions governing the issue of 
          the SDB Debit Card have been read & understood by me & agree to abide
          by the said terms & conditions and any amendments,variations and changes 
          there to, which may be effected by the bank from time to time.
        </span>
        <br />
        <br />

            <b>Important</b><br />

            • Daily Withdrawal limit : Rs. 100,000/-<br />

            • Corresponding daily purchase limit : Rs. 300,000/-<br />

            • This Account will be primary account for debit card<br />

            • Customer Should visit the branch to collect debit card<br />

            • A Joining Fee & Annual Fees will be applicable as decided by the bank<br />

            • Card will be valid for a period of 05 years only<br />

        <!-- <div v-if="viewData.joint_acc">
          <span>
            I/We agreed that, in the event of a death of any one of us, 
            existing credit balance of the account shall be paid to the 
            surviving Party/Parties without referring to the executor or 
            administrator of the deceased person.
          </span>
        </div> -->
            <span class="customText" @click="toggleReadMoreDeclaration()">Show Less</span>
        <br/>
      </span>

        <p>
          <b
            >Declaration by the Authorized Dealer for Electronic Fund Transfer Cards</b
          >
        </p>
        <p>
          <span id="originalText">
            I, as the Authorized Officer of the bank have carefully examined the information together with relevant 
            documents given by the applicant/s and satisfied with the bona-fide of these information and documents. 
            Further, I as ..<span
              class="custom"
              @click="toggleReadMore()"
              >Read more</span
            >
          </span>
          <span id="extraText" style="display: none">
            I, as the Authorized Officer of the bank have carefully examined the 
            information together with relevant documents given by the applicant/s 
            and satisfied with the bona-fide of these information and documents. Further, 
            I as the Authorized Officer of the bank undertake at all times, to exercise due 
            diligence on the transactions carried out by the cardholder on his/ her EFTC in 
            foreign exchange and to suspend the availability of foreign exchange on the EFTC if 
            reasonable grounds exist to suspect that foreign exchange transactions which are not 
            permitted in terms of Directions No. 03 of 2021 dated 18 March 2021 issued under the 
            provisions of the Foreign Exchange Act, No. 12 of 2017 are being carried out on the EFTC, 
            in violation of the undertaking given by the card holders and to bring the matter to the 
            attention of the Director - Department of Foreign Exchange. <br /><br />

            <br />
              <!-- <div v-if="viewData.joint_acc">
              <p>
                I/We agreed that, in the event of a death of any one of us,
                existing credit balance of the account shall be paid to the surviving Party/Parties
                without referring to the executor or administrator of the deceased person.
              </p>
              </div> -->

            <span class="custom" @click="toggleReadMore()">Show Less</span>
          </span>
        </p>
        <b-checkbox size="is-small" class="mt-2" :value="viewData.terms_and_conditions == 1 ? true : false" @input="handleCheckboxClick" :disabled="viewData.terms_and_conditions === 1">
          I have agreed with general T&C and the declaration.
        </b-checkbox>
      </div>

      <div
        class="column notification"
        id="debitCardDeclaration"
        v-if="
          viewData.debitCardApplication
        "
      >
        <div v-if="(viewData.id != this.jointOwnerId) && viewData.joint_acc != null">
          <span class="application-sub-headers">Debit Card Declaration</span>
            <p>
              <b
                >Debit Card consent for joint AC Holders</b
              >
            </p>
          <span id="originalText">
            I hearby give my consent to issue a debit card & active the SMS alert service for this Joint Account to the above applicant. 
          </span>
        </div>
        <div
          style="width: 40%; height: 50%"
          v-if="viewData.image_signature != ''"
          >
          <span class="is-size-5 has-text-primary mt-5"> Signature </span>
          <ImageContainerCard
            image-box-style="column"
            :imageSource="viewData.image_signature"
            :imageTitleStyle="
              viewData.previous_applications &&
              isValueInArray('signature')
                ? `has-text-danger`
                : ``
            "
            :checkExistingImage="viewData.is_existing_data"
          />
        </div>
      </div>

      <div
        v-if="!viewData.debitCardApplication"
        class="column container application-view-media-warp-box"
      >
        <div class="column is-size-3">Captured Information</div>

        <div class="column">
          <span class="is-size-5 has-text-primary"> NIC / DL Images </span>
          <div class="columns">
            <ImageContainerCard
              imageTitle="NIC / DL front Image"
              image-box-style="column"
              :imageSource="viewData.image_nic_front_img"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('nic_front_img')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
            <ImageContainerCard
              imageTitle="NIC / DL rear Image"
              image-box-style="column"
              :imageSource="viewData.image_nic_rear_img"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('nic_rear_img')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
          </div>
          <div
            v-if="viewData.applicant_individual_account_type == 'Minor Account' || viewData.applicant_individual_account_type == 'Lakdaru Savings'"
          >
            <span class="is-size-5 has-text-primary"> Birth Certificate </span>
            <div class="columns">
              <ImageContainerCard
                imageTitle="Birth Certificate Front"
                image-box-style="column"
                :imageSource="viewData.image_birth_certificate_front"
                :imageTitleStyle="
                  viewData.previous_applications &&
                  isValueInArray('birth_certificate_front')
                    ? `has-text-danger`
                    : ``
                "
                :checkExistingImage="viewData.is_existing_data"
              />
              <ImageContainerCard
                imageTitle="Birth Certificate Rear"
                image-box-style="column"
                :imageSource="viewData.image_birth_certificate_rear"
                :imageTitleStyle="
                  viewData.previous_applications &&
                  isValueInArray('birth_certificate_rear')
                    ? `has-text-danger`
                    : ``
                "
                :checkExistingImage="viewData.is_existing_data"
              />
            </div>
          </div>

          <span class="is-size-5 has-text-primary">
            Customer Image & a Video clip
          </span>
          <div class="columns">
            <ImageContainerCard
              imageTitle="Customer Image"
              image-box-style="column is-half"
              :imageSource="viewData.image_customer_img"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('customer_img')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
            <!-- <ImageContainerCard
              imageTitle="Sanction Checked & Approved by"
              image-box-style="column"
            /> -->
            <div class="column" v-if="viewData.image_customer_video">
              <span>Video clip</span>

              <video
                controls="controls"
                preload="none"
                style="margin: 2px solid black"
              >
                <source
                  :src="baseUrl + viewData.image_customer_video"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <span class="is-size-5 has-text-primary" v-if="viewData.living_place_diff =='Current living place is same as NIC/DL address' || 
          (viewData.image_address_proof_img_1 && viewData.image_address_proof_img_1 !== '')"> Address Proof </span>
          <div
            v-if="
              viewData.living_place_diff ==
              'Current living place is same as NIC/DL address'
            "
            class="columns"
          >
            <!-- <ImageContainerCard
              imageTitle="Document Image 01"
              image-box-style="column"
              :imageSource="viewData.image_nic_rear_img"
            /> -->
            <span class="address-proof"
              >Current living place is same as NIC/DL address</span
            >
          </div>
          <div v-else class="columns">
            <ImageContainerCard
              v-if="viewData.image_address_proof_img_1 && viewData.image_address_proof_img_1 !== ''"
              imageTitle="Document Image 01"
              image-box-style="column is-half"
              :imageSource="viewData.image_address_proof_img_1"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('address_proof_img_1')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
            <ImageContainerCard
              v-if="viewData.image_address_proof_img_2  && viewData.image_address_proof_img_2 !== ''"
              imageTitle="Document Image 02"
              image-box-style="column is-half"
              :imageSource="viewData.image_address_proof_img_2"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('address_proof_img_2')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
          </div>

          <span
            class="is-size-5 has-text-primary"
            v-if="
              viewData.image_additional_doc1 || viewData.image_additional_doc2
            "
          >
            Additional Relevant Documents
          </span>
          <div class="columns">
            <ImageContainerCard
              v-if="viewData.image_additional_doc1"
              :imageTitle="viewData.image_additional_doc_cmt1"
              image-box-style="column is-half"
              :imageSource="viewData.image_additional_doc1"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('previous_applications')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
            <ImageContainerCard
              v-if="viewData.image_additional_doc2"
              :imageTitle="viewData.image_additional_doc_cmt2"
              image-box-style="column is-half"
              :imageSource="viewData.image_additional_doc2"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('additional_doc2')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
          </div>
          <span
            v-if="
              viewData.pep_approval_document != '' ||
              viewData.pep_approval_document2
            "
            class="is-size-5 has-text-primary"
          >
            PEP Approval Images
          </span>
          <div class="columns">
            <ImageContainerCard
              v-if="viewData.pep_approval_document != ''"
              imageTitle="PEP Approval Front Image"
              image-box-style="column"
              :imageSource="viewData.pep_approval_document"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('pep_approval_document')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
            <ImageContainerCard
              v-if="viewData.pep_approval_document2 != ''"
              imageTitle="PEP Approval Rear Image"
              image-box-style="column"
              :imageSource="viewData.pep_approval_document2"
              :imageTitleStyle="
                viewData.previous_applications &&
                isValueInArray('pep_approval_document2')
                  ? `has-text-danger`
                  : ``
              "
              :checkExistingImage="viewData.is_existing_data"
            />
          </div>
          <!-- <span class="is-size-12 has-text-primary"> -->
          <div style="display: flex; flex-direction: column">
            <!-- <div class="row" style="flex: 1; padding: 0 10px;"> -->
            <div>
              <p>
                මෙම ගිණුම විවෘත කිරීමේ විද්‍යුත් අයදුම් පත්‍රය හා ක්‍රියාත්මක කිරීමට අදාළ වන 
                නියමයන් සහ කොන්දේසි මාගේ /අපගේ සුපුරුදු භාෂාවෙන් මා/අප වෙත පැහැදිලි 
                කරන ලද බව මම / අපි මෙහි සනාථ කරමි /කරමු.මෙම ගිණුම් විවෘත කිරීමේ 
                විද්‍යුත් අයදුම්පතේ දක්වා ඇති සියලුම තොරතුරු සත්‍ය සහ නිවැරදි බව මම / අපි 
                මෙයින් සහතික කරන අතර, ලබා දී ඇති තොරතුරුවල කිසියම් වෙනසක් / වෙනස්කමක් 
                සිදුවුවහොත් වහාම බැංකුව දැනුවත් කිරීමට කටයුතු කරමි/කරමු.
              </p>
              <br />
              <p>
                இந்த கணக்கைத் திறப்பதற்கான விண்ணப்பத்திற்கும் அதன் செயல்பாட்டிற்கும் 
                மற்றும் இலத்திரனியல் கணக்கு திறப்பு விண்ணப்ப படிவத்திற்குபொருந்தக்கூடிய 
                விதிமுறைகள் மற்றும் நிபந்தனைகள் எனக்கு / எங்களுக்கு வழக்கமான மொழியில் 
                எனக்கு / எங்களுக்கு விளக்கப்பட்டன மற்றும் எனக்கு / எங்களால் புரிந்து 
                கொள்ளப்பட்டதை நான் / நாங்கள் இதன்மூலம் உறுதிப்படுத்துகிறோம். 
                இந்த இலத்திரனியல் கணக்கு திறப்பு பயன்பாட்டில் வழங்கப்பட்ட அனைத்து 
                தகவல்களும் உண்மை மற்றும் சரியானவை என்பதை நான் / நாங்கள் 
                இதன்மூலம் உறுதிப்படுத்துகிறோம், மேலும் வழங்கப்பட்ட தகவலில் 
                ஏதேனும் மாற்றம் / மாற்றங்கள் ஏற்பட்டால் உடனடியாக வங்கிக்கு 
                அறிவிப்பதற்கு நடவடிக்கை மேற்கொள்வேன் /மேற்றக்கொள்வோம்.
              </p>
              <br/>
              <p>
                I/we hereby confirm that the terms and conditions applicable to opening 
                and operations of this account and electronic account opening application 
                form were explained to me/us in the language conversant by me /us and 
                understood by me/us. I / we hereby affirm that all the information provided 
                in this electronic account opening application form are true and correct and 
                further undertake to intimate the bank promptly in case of any change /s in 
                any of such information provided.
              </p>
              <div v-if="viewData.joint_acc">
                <br/>
                <p>
                  I/We agreed that, in the event of a death of any one of us,
                  existing credit balance of the account shall be paid to the surviving Party/Parties
                  without referring to the executor or administrator of the deceased person.
                </p>
              </div>
              <br />
              <p>
                I/We hereby agree to comply with and to be bound by all applicable laws of
                the country and rules and regulations of the Central Bank of Sri Lanka issued
                to the General Public for operations of Bank Accounts. 
              </p>
              <br/>
            </div>
            <div v-if="(viewData.id != this.jointOwnerId) && viewData.joint_acc != null">
              <span id="originalText">
                {{ this.jointOwnerId }}
                I hearby give my consent to issue a debit card & active the SMS alert service for this Joint Account to the above applicant. 
              </span>
            </div>
            <div
              style="width: 40%; height: 50%"
              v-if="viewData.image_signature != ''"
            >
              <span class="is-size-5 has-text-primary mt-5"> Signature </span>
              <ImageContainerCard
                image-box-style="column"
                :imageSource="viewData.image_signature"
                :imageTitleStyle="
                  viewData.previous_applications &&
                  isValueInArray('signature')
                    ? `has-text-danger`
                    : ``
                "
                :checkExistingImage="viewData.is_existing_data"
              />
            </div>
            <!-- <div v-if="viewData.application_status == 'pending'">
              <p><a :href="`${baseUrl}/terms_pdf/terms_and_conditions.pdf`">Terms and Conditions pertaining to opening & maintaining savings deposit
                accounts and other banking facilities</a></p>
            </div> -->
            <!-- </div> -->
          </div>
          <!-- </div> -->

          <!-- </span> -->
        </div>
      </div>
    </div>
    <DebitCardNotificationModal
      :isDebitCardIssueModalActive="isFdDebitCardIssueModalActive"
      @closeModal="
        () => {
          closeSecondModal();
        }
      "
      @downloadPdf="
        () => {
          downloadPdf();
        }
      "
      @downloadDebitCardPdf="
        (debitCardNumber) => {
          downloadDebitCardPdf(debitCardNumber);
        }
      "
      :debitCardNumber="debitCardNumber"
    />
    <DebitCardRejectNotificationModal
      :isDebitCardRejectModalActive="isFdDebitCardRejectModalActive"
      @closeRejectModal="
        () => {
          closeSecondRejectModal();
        }
      "
    />

    <DebitCardRejectNotificationModal
      :isDebitCardRejectModalActive="isDebitCardRejectModalActive"
      @closeRejectModal="
        () => {
          closeRejectModal();
        }
      "
    />
    <ApplicationSupportModal
      :isModalActive="isModalActive"
      :otpCode="otpCode"
      :clientNo="viewData.primary_mobile_number"
      :countDown="countDown"
      :isTimeout="isTimeout"
      imageName="CreditCard.webp"
      @verifyOtp="
        (otpCode) => {
          verifyOtp(otpCode);
        }
      "
      @reSendOtp="
        (clientNo) => {
          reSendOtp(clientNo);
        }
      "
      @update:isModalActive="isModalActive = $event"
    />

    <ApplicationSupportModal
      :isModalActive="isFdModalActive"
      :otpCode="otpCode"
      :clientNo="viewData.primary_mobile_number"
      :countDown="fdCountDown"
      :isTimeout="isFDTimeOut"
      imageName="CreditCard.webp"
      @verifyOtp="
        (otpCode) => {
          verifyOtpFD(otpCode);
        }
      "
      @reSendOtp="
        (clientNo) => {
          resendOtpFD(clientNo);
        }
      "
      @update:isModalActive="isFdModalActive = $event"
    />

    <ApplicationSupportModal
      :isModalActive="isCiModalActive"
      :otpCode="otpCode"
      :clientNo="viewData.primary_mobile_number"
      :countDown="ciCountDown"
      :isTimeout="isCITimeOut"
      imageName="CreditCard.webp"
      @verifyOtp="
        (otpCode) => {
          verifyOtpCardIssue(otpCode);
        }
      "
      @reSendOtp="
        (clientNo) => {
          reSendOtpCardIssue(clientNo);
        }
      "
      @update:isModalActive="isCiModalActive = $event"
    />

    <FdCardIssueModal
      :isFdModalActive="isFdCertificateModalOpen"
      :clientData="viewData"
      @close-modal="(e) => (isFdCertificateModalOpen = e)"
      @next-step="(e) => updateFdCertificateInfo(e)"
    />

    <FindYourCustomerModal
      :isCustomerFindModalActive="isFYCMOpen"
      @send-otp="
        (e) => {
          sendOtpCardIssue();
          isFYCMOpen = e.modalvalue;
        }
      "
      @close-modal="
        (e) => {
          isFYCMOpen = e;
        }
      "
    />

    <CardIssueModal
      :isCardModalActive="isCardModalActive"
      :clientData="viewData"
      @debitCardIssueClick="
        (debitCardNum, applicantId, verifiedInterview) => {
          debitCardIssueClick(debitCardNum, applicantId, verifiedInterview);
        }
      "
      @debitCardRejectClick="
        () => {
          debitCardRejectClick();
        }
      "
      @update:isCardModalActive="isCardModalActive = $event"
    />

    <CardIssueModal
      :isCardModalActive="isFdCardModalActive"
      :clientData="viewData"
      @debitCardIssueClick="
        (debitCardNum, applicantId, verifiedInterview) => {
          fDdebitCardIssueClick(debitCardNum, applicantId, verifiedInterview);
        }
      "
      @debitCardRejectClick="
        () => {
          fDdebitCardRejectClick();
        }
      "
      @update:isCardModalActive="isFdCardModalActive = $event"
    />

    <DebitCardNotificationModal
      :isDebitCardIssueModalActive="isDebitCardIssueModalActive"
      @closeModal="
        () => {
          closeModal();
        }
      "
      @downloadPdf="
        () => {
          downloadPdf();
        }
      "
      @downloadDebitCardPdf="
        (debitCardNumber) => {
          downloadDebitCardPdf(debitCardNumber);
        }
      "
      :debitCardNumber="debitCardNumber"
    />

    <DebitCardRejectCard
      :debitCardRejectModalActive="fDdebitCardRejectModalActive"
      :clientNic="viewData.nic"
      @debitCardReject="
        (reason) => {
          fDdebitCardReject(reason);
        }
      "
      @update:debitCardRejectModalActive="debitCardRejectModalActive = $event"
    />
    <CommonNotificationModal
      :isCommonModalEnabled="isCommonNotifyModalOpen"
      imageName="DebitCardIssue.png"
      main-text="Certificate issuance is successful !"
      btn-text="Done"
      @common-action-handler="
        () => {
          commonActionHandler();
        }
      "
    />
    <NotificationModal
      :isNotificationModalActive="isNotificationModalActive"
      :applicationType="applicationStatData.applicationStat"
      @applicationStatActionHandler="
        $emit(applicationEmitHandler(), applicationStatData)
      "
      @closeAction="isNotificationModalActive = false"
    />
    <RequestToImproveNotificationModal
      :isReqNotificationModalActive="isReqNotificationModalActive"
      :applicationType="applicationStatData.applicationStat"
      :applicantId="viewData.id"
      :review_status="viewData.review_status"
      :joint_main_owner="jointOwnerId"
      :account_number="viewData.account_number"
      :req_improve_reasons="viewData.req_improve_reasons"
      :applicant_status="viewData.applicant_status"
      :account_type="viewData.account_type"
      :applicant_individual_account_type="
        viewData.applicant_individual_account_type
      "
      :dropdownOptions="dropdownOptions"
      :branch="viewData.branch"
      @closeAction="isReqNotificationModalActive = false"
    />

    <DebitCardRejectCard
      :debitCardRejectModalActive="debitCardRejectModalActive"
      :clientNic="viewData.nic"
      @debitCardReject="
        (reason) => {
          debitCardReject(reason);
        }
      "
      @update:debitCardRejectModalActive="debitCardRejectModalActive = $event"
    />
    <b-loading
      :is-full-page="isFullPage"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import ActionButton from "@/components/support/buttons/ActionButton.vue";
import SpanFields from "@/components/support/fields/SpanFields.vue";
import ApplicationViewCard from "@/components/support/Cards/ApplicationViewCard.vue";
import KycApplicationViewCard from "@/components/support/Cards/KycApplicationViewCard.vue";
import ImageContainerCard from "@/components/support/Cards/ImageContainerCard.vue";
import CommentField from "@/components/support/fields/CommentField.vue";
import DebitCommentField from "@/components/support/fields/DebitCommentField.vue";
import IssuedItem from "@/components/support/Notifications/IssuedItem.vue";
import NetworkManager from "@/network";
import ApplicationStatCard from "./support/Cards/ApplicationStatCard.vue";
import ApplicationSupportModal from "./support/Modals/ApplicationSupportModal.vue";
import CardIssueModal from "./support/Modals/CardIssueModal.vue";
import CommonNotificationModal from "./support/Modals/CommonNotificationModal.vue";
import FdCardIssueModal from "./support/Modals/FdCardIssueModal.vue";
import DebitCardNotificationModal from "./support/Modals/DebitCardNotificationModal.vue";
import FindYourCustomerModal from "./support/Modals/FindYouCustomerModal.vue";
import DebitCardRejectNotificationModal from "./support/Modals/DebitCardRejectNotificationModal.vue";
import NotificationModal from "./support/Modals/NotificationModal.vue";
import RequestToImproveNotificationModal from "./support/Modals/RequestToImproveNotificationModal.vue";
import { appSettingsStorage } from "@/appSettingsStorage";
import BuefyHelper from "@/helpers/bufeyHelper";
import DebitCardRejectCard from "./support/Cards/DebitCardRejectCard.vue";
import { appSettings } from "@/appSettings"

export default {
  name: "PendingApplicationView",
  props: [
    "viewData",
    "checkboxChangeHandler",
    "comments",
    "debitComments",
    "isFullPage",
    "isLoading",
    "agreedRadioButton",
    "centralizedOpsChangeHandler",
    "listLength",
    "approveBtnLoading",
    "jointOwnerId",
    "fdSignatureRequirement",
  ],
  emits: [
    "userCommentSubmitHandler",
    "debitUserCommentSubmitHandler",
    "aprroveRequestHandler",
    "improveRequestHandler",
    "rejetRequestHandler",
    "pepApprovelCheckHandler",
    "reSendOtp",
    "verifyOtp",
  ],

  components: {
    ActionButton,
    SpanFields,
    ApplicationViewCard,
    KycApplicationViewCard,
    ImageContainerCard,
    CommentField,
    DebitCommentField,
    IssuedItem,
    ApplicationStatCard,
    ApplicationSupportModal,
    CardIssueModal,
    NotificationModal,
    DebitCardNotificationModal,
    DebitCardRejectCard,
    DebitCardRejectNotificationModal,
    FdCardIssueModal,
    FindYourCustomerModal,
    CommonNotificationModal,
    RequestToImproveNotificationModal,
  },

  data() {
    return {
      cityName: "",
      mapCenter: { lat: 10, lng: 10 }, // Default center
      markers: [], // Array to hold markers
      dynamicFields: [
        "kyc_purpose_other_reason",
        "kyc_source_funds_other_reason",
        "kyc_wealth_other_reason",
        "kyc_anticipated_volume",
      ],
      workplaceFields: [
        "workplace_employer_name",
        "workplace_adress",
        "workplace_telephone",
        "workplace_position",
        "workplace_monthly_income",
        "workplace_other_income",
        "workplace_source_other_income",
      ],
      cardData: [],
      cardData2: [],

      employeeName: "",
      cardDataTwo: [
        // {
        //   title: "Employer",
        //   value: ""
        // },
        // {
        //   title: "Address",
        //   value: "",
        // },
        // {
        //   title: "Telephone",
        //   value: "",
        // },
        // {
        //   title: "Position",
        //   value: "",
        // },
        // {
        //   title: "Monthly Income",
        //   value: "",
        // },
        // {
        //   title: "Other Income",
        //   value: "",
        // },
        // {
        //   title: "Source of Other Income",
        //   value: "",
        // },
      ],
      userComment: "",
      userCommentArray: [],
      isPEPChecked: 0,
      // applicationStat: "",
      applicationStatData: {
        isApplicationReviewed: 0,
        applicationStat: "",
        applicationRequestType: "",
        reviewAndNext: 0,
      },
      isModalActive: false,
      isCardModalActive: false,
      isReqNotificationModalActive: false,
      isNotificationModalActive: false,
      debitCardRejectModalActive: false,
      relatedPartyCustomer: false,
      baseUrl: appSettingsStorage.baseUrl,
      existingImageBaseUrl: appSettingsStorage.existingImageBaseUrl,
      version: appSettings.version,
      purposeList: [],
      sourceOfFundsList: [],
      sourceOfWealthList: [],
      anticipatedVolume: [],
      low: {
        workplace_employer_name: "Employer",
        workplace_adress: "Address",
        workplace_telephone: "Telephone",
        workplace_position: "Designation",
        workplace_monthly_income: "Monthly Income",
        workplace_other_income: "Other Income",
        workplace_source_other_income: "Source of Other Income",
        business_total_income: "Total Income",
      },
      houseWifeLow: {
        monthly_income: "Monthly Income",
        source_of_income: "Source of Income",
      },
      entreprenuerLow: {
        business_reg_number: "Business Registration Number",
        business_name: "Business Name",
        business_address: "Business Address",
        business_telephone_number: "Business Telephone Number",
        business_nature: "Business Nature",
        business_income: "Business Income",
        business_other_income: "Other Income",
        business_source_other_income: "Source of Other Income",
        business_total_income: "Total Income",
      },
      retiredLow: {
        workplace_last_employer_name: "Name of the last employer",
        workplace_adress: "Address",
        workplace_telephone: "Telephone",
        workplace_position: "Last Designation",
        workplace_pension_amount: "Pension Amount",
        workplace_other_income: "Other Income",
        workplace_source_other_income: "Source of Other Income",
        business_total_income: "Total Income",
      },
      // agreed: "Yes",
      isCITimeOut: false,
      ciCountDown: 60,
      countDown: 60,
      isTimeout: false,
      isDebitCardIssueModalActive: false,
      isDebitCardRejectModalActive: false,
      debitCardNumber: "",
      otpCode: Array(6).fill(""),
      isCentralizedOpsChecked: 0,
      isSanctionListChecked: 0,
      isSanctionListCheckedTrue: 1,

      // fd_deposit: "1000000"
      isFYCMOpen: false,
      isFdCertificateModalOpen: false,
      isCommonNotifyModalOpen: false,

      isFDTimeOut: false,
      fdCountDown: 60,
      isFdModalActive: false,
      isCiModalActive: false,

      isFdCardModalActive: false,
      fDdebitCardRejectModalActive: false,
      isFdDebitCardRejectModalActive: false,
      isFdDebitCardIssueModalActive: false,
      certificateIssued: false,

      isExpanded: true,
      boxTitle: "Request to Improve",
      reqReasons: [],
      dropdownOptions: [],
      data: [
        { header: "NIC", value: "sss" },
        { header: "NIC rear image", value: "sssee" },
        // Add more data as needed
      ],
      terms_and_conditions: 0,
      existingSavingsAccList: null,
      existingFixedAccList: null,
      existingLoanAccList: null,
      usingTheSDBServer: false,
      customerCif: null,
      minorCif: null,
      changedImages: [],
      existingAccounts: null,
    };
  },

  watch: {
    // Watch for changes in viewData and update the cardData accordingly
    viewData: {
      handler() {
        // this.changeThousendSeperator();
        // this.updateCardData();
        // this.updateCardDataForWorkPlace();
        // this.changeThousendSeperator();
        this.getChangedImages();
        this.isValueInArray()
        // this.getExistingSavingsAccInfo();
        // this.getExistingFixedAccInfo();
        // this.getExistingLoanAccInfo();
        this.getCustomerCif();
        this.getMinorCif();
        this.updateAgreedStat();
        this.updatePepStat();
        this.applicationReviewStatus();
        this.getExistingAccInfo();
        this.cardValueSetter();
        this.lowCaedValueSetter();
        this.checkRelatedPartyCustomer();
      },
      deep: true, // Watch for nested changes in viewData
    },
  },
  mounted() {
    // this.locateCity();
    //  this.updateCardData();
    //  this.updateCardDataForWorkPlace();
    this.getChangedImages();
    // this.getExistingSavingsAccInfo();
    // this.getExistingFixedAccInfo();
    // this.getExistingLoanAccInfo();
    this.getCustomerCif();
    this.updateAgreedStat();
    this.updatePepStat();
    setTimeout(() => {
      this.getExistingAccInfo();
    },3000);
    this.applicationReviewStatus();
    setTimeout(() => {
      this.displayReqImproveReasons();
    }, 5000);
    // setTimeout(() => {
    //  this.getDropdownOptions();
    // }, 5000);

    // this.displayReqImproveReasons();
    this.cardValueSetter();
    this.lowCaedValueSetter();
    this.checkRelatedPartyCustomer();
    // this.changeThousendSeperator();
  },

  // computed:{
  //     btnHideHandler(){
  //       let rejectBtn = document.getElementById('rejectBtn');
  //       let approveBtn = document.getElementById('approveBtn');
  //       let reqToImproveBtn = document.getElementById('reqToImproveBtn');

  //       if()
  //     }
  // },

  methods: {

    formatAddress(address) {
      if(address != null ){
        if (!address.trim().endsWith(',')) {
          return address.trim() + ',';
        }
        return address.trim();
      }
    },
    concatenateAddresses(viewData) {
      const address1 = this.formatAddress(viewData.address_1);
      const address2 = this.formatAddress(viewData.address_2);
      const address3 = this.formatAddress(viewData.address_3);
      const address4 = this.formatAddress(viewData.address_4);
      if(address4 != null){
        return `${address1} ${address2} ${address3} ${address4}`.replace(/,\s*$/, '');
      }else{
        return `${address1} ${address2} ${address3}`.replace(/,\s*$/, '');
      }
    },
    isValueInArray(key) {
      return this.changedImages.includes(key);
    },

    updateFdCertificateInfo(e) {
      // console.log("certificateId", e.certificateId);
      this.isCommonNotifyModalOpen = true;
      this.isFdCertificateModalOpen = false;

      NetworkManager.apiRequest(
        "backend/applicant/updateFdCertificateInfo",
        {
          applicationId: this.viewData.id,
          certificateId: e.certificateId,
          certificateType: this.viewData.fd_how_cus_need_certificate,
        },
        (e) => {
          // console.log(e.data.list)
          if (e.success === true) {
            BuefyHelper.showToastMessage(e.data.message, "is-success");
            // console.log("success");
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(e.data.message, "is-danger");
            // console.log("failed");
          } else {
            BuefyHelper.showToastMessage("Error Occurred", "is-danger");
            // console.log("failed");
          }
        },
        false
      );
    },

    //add thousand seperator to the fd_deposit value
    // changeThousendSeperator(){
    //   if(this.viewData.applicant_individual_account_type == 'Fixed Deposit'){
    //     this.fdDepositValue = this.viewData.fd_deposit.toString(); //  This should comment after updating the fd_deposit to the text
    //     // this.fdDepositValue = this.viewData.fd_deposit; //  This should uncomment after updating the fd_deposit to the text
    //     const fdDeposit = ThousandsSeparator.separateByThousands(this.fdDepositValue, this.convertedFdDepositValue);
    //     this.convertedFdDepositValue = fdDeposit;

    //     // Check if the value is a decimal number
    //     if (this.convertedFdDepositValue.includes('.')) {
    //         // Split the string at the decimal point and count the characters after the dot
    //         let decimalPart = this.convertedFdDepositValue.split('.')[1];
    //         let numberOfDecimalPlaces = decimalPart.length;
    //         if(numberOfDecimalPlaces == 1){
    //           this.viewData.fd_deposit = "Rs." + fdDeposit + "0";
    //         }else{
    //           this.viewData.fd_deposit = "Rs." + fdDeposit;
    //         }
    //     } else {
    //         this.viewData.fd_deposit = "Rs." + fdDeposit + ".00";
    //     }
    //   }
    // },
    getChangedImages(){
      if(this.viewData.changed_image_names != null){
        this.changedImages = JSON.parse(this.viewData.changed_image_names);
      }
    },

    commonActionHandler() {
      this.isCommonNotifyModalOpen = false;
      this.certificateIssued = true;
      location.reload();
    },

    checkRelatedPartyCustomer(){
      if(appSettings.usingSDBServer == true && this.viewData.is_customer_related_party == null){
        NetworkManager.apiRequest(
          "backend/sdb/getRelatedPartyResponseData",
          { nic: this.viewData.nic, id: this.viewData.id},
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              this.relatedPartyCustomer = true;
              // console.log("success checkRelatedPartyCustomer");
            } else if (e.success === false) {
              // console.log("failed checkRelatedPartyCustomer");
            }
          },
          false
        );
      }
    },


    lowCaedValueSetter() {
      if (this.cardDataTwo.length != 0) {
        this.cardDataTwo = [];
      }
      if (
        this.viewData.applicant_status == "Housewife" ||
        this.viewData.applicant_status == "Unemployed" ||
        this.viewData.applicant_status == "Retired (Non-Government)"
      ) {
        for (let propertyHw in this.houseWifeLow) {
          let tempObjHw = {};
          let tempValueHw =
            this.viewData.hasOwnProperty(propertyHw) &&
            this.viewData[propertyHw] !== ""
              ? this.viewData[propertyHw]
              : "-";
          tempObjHw[this.houseWifeLow[propertyHw]] = tempValueHw;
          this.cardDataTwo.push(tempObjHw);
        }
      } else if (
        this.viewData.applicant_status == "Entrepreneur / Self Employee"
      ) {
        for (let propertyEnt in this.entreprenuerLow) {
          let tempObjEnt = {};
          let tempValueEnt =
            this.viewData.hasOwnProperty(propertyEnt) &&
            this.viewData[propertyEnt] !== ""
              ? this.viewData[propertyEnt]
              : "-";
          tempObjEnt[this.entreprenuerLow[propertyEnt]] = tempValueEnt;
          this.cardDataTwo.push(tempObjEnt);
        }
      } else if (this.viewData.applicant_status == "Government Pensioner") {
        for (let propertyRet in this.retiredLow) {
          if (this.viewData[propertyRet] != null) {
            let tempObjRet = {};
            let tempValueRet =
              this.viewData.hasOwnProperty(propertyRet) &&
              this.viewData[propertyRet] !== null
                ? this.viewData[propertyRet]
                : "-";
            tempObjRet[this.retiredLow[propertyRet]] = tempValueRet;
            this.cardDataTwo.push(tempObjRet);
          }
        }
      } else {
        for (let property in this.low) {
          let tempObj = {};
          let tempValue =
            this.viewData.hasOwnProperty(property) &&
            (this.viewData[property] !== "" || this.viewData[property] !== null)
              ? this.viewData[property]
              : "-";
          tempObj[this.low[property]] = tempValue;
          this.cardDataTwo.push(tempObj);
        }
      }
    },

    //only for testion purposes
    deleteApplication() {
      this.$emit("deleteApplication", this.viewData.id);
      NetworkManager.apiRequest(
        "backend/applicant/deleteApplication",
        { id: this.viewData.id },
        (e) => {
          // console.log(e.data.list)
          if (e.success === true) {
            BuefyHelper.showToastMessage("Application deleted", "is-danger");
            // console.log("success");
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "Application not deleted",
              "is-danger"
            );
            // console.log("failed");
          }
        },
        false
      );
    },
    //only for testion purposes

    handleMouseEnter() {
      if (!this.isCheckboxDisabled) {
        // Change the mouse icon for enabled state on hover
        document.querySelector(".application-checkbox").style.cursor =
          "pointer";
      }
    },
    handleMouseLeave() {
      // Reset the mouse icon when leaving the checkbox
      document.querySelector(".application-checkbox").style.cursor = "auto";
    },

    toggleReadMore() {
      var extraText = document.getElementById("extraText");
      var originalText = document.getElementById("originalText");
      var buttonText = document.querySelector("#debitCardDeclaration .custom");
      buttonText.style.cursor = "pointer";

      if (extraText.style.display === "none") {
        extraText.style.display = "inline"; // Change to "block" if using block-level elements
        buttonText.display = "none";
        originalText.style.display = "none";
        // buttonText.textContent = "Read less";
      } else if (originalText.style.display === "none") {
        extraText.style.display = "none"; // Change to "block" if using block-level elements
        buttonText.display = "none";
        originalText.style.display = "inline";
      }
    },

    toggleReadMoreDeclaration() {
      var extraText1 = document.getElementById("extraText1");
      var originalText1 = document.getElementById("originalText1");
      var buttonText1 = document.querySelector("#debitCardDeclaration .customText");
      buttonText1.style.cursor = "pointer";

      if (extraText1.style.display === "none") {
        extraText1.style.display = "inline"; // Change to "block" if using block-level elements
        buttonText1.display = "none";
        originalText1.style.display = "none";
        // buttonText.textContent = "Read less";
      } else if (originalText1.style.display === "none") {
        extraText1.style.display = "none"; // Change to "block" if using block-level elements
        buttonText1.display = "none";
        originalText1.style.display = "inline";
      }
    },

    convertDateTime(dateTime) {
      // Convert the string to a Date object
      const dateObject = new Date(dateTime);

      // Format the date
      const formattedDate = `${dateObject
        .toISOString()
        .slice(0, 19)
        .replace("T", " ")}`;

      // console.log("formattedDate", formattedDate);
      return formattedDate;
    },

    updateAgreedStat() {
      const comp = this;
      comp.agreed = comp.viewData.debit_card_declaration;
    },

    getExistingSavingsAccInfo(){
      if(appSettings.usingSDBServer == true){
        this.usingTheSDBServer = true;
        NetworkManager.apiRequest(
          "backend/sdb/getAllAccountDataUsingNic",
          { nic: this.viewData.nic, applicationNumber: '20' },
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              // console.log("success getAllAccountDataUsingNic", e.data.AccountList);
              this.existingSavingsAccList = e.data.AccountList;
            } else if (e.success === false) {
              // console.log("failed getAllAccountDataUsingNic");
              this.existingSavingsAccList = null;
            }
          },
          false
        );
      }
    },

    getExistingFixedAccInfo(){
      if(appSettings.usingSDBServer == true){
        this.usingTheSDBServer = true;
        NetworkManager.apiRequest(
          "backend/sdb/getAllAccountDataUsingNic",
          { nic: this.viewData.nic, applicationNumber: '30' },
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              // console.log("success getAllAccountDataUsingNic", e.data.AccountList);
              this.existingFixedAccList = e.data.AccountList;
            } else if (e.success === false) {
              // console.log("failed getAllAccountDataUsingNic");
              this.existingFixedAccList = null;
            }
          },
          false
        );
      }
    },
    getExistingLoanAccInfo(){
      if(appSettings.usingSDBServer == true){
        this.usingTheSDBServer = true;
        NetworkManager.apiRequest(
          "backend/sdb/getAllAccountDataUsingNic",
          { nic: this.viewData.nic, applicationNumber: '50' },
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              // console.log("success getAllAccountDataUsingNic", e.data.AccountList);
              this.existingLoanAccList = e.data.AccountList;
            } else if (e.success === false) {
              // console.log("failed getAllAccountDataUsingNic");
              this.existingLoanAccList = null;
            }
          },
          false
        );
      }
    },


    getCustomerCif(){
      if(appSettings.usingSDBServer == true){
        this.usingTheSDBServer = true;
        NetworkManager.apiRequest(
          "backend/sdb/getCustomerInfoUsingNic",
          { nic: this.viewData.nic },
          (e) => {
            if (e.success === true) {
              // console.log("success getCustomerInfoUsingNic", e.data.CustomerDetailList[0].Customer_Number);
              this.customerCif = e.data.CustomerDetailList[0].Customer_Number;
              this.saveCustomerCif(this.customerCif);
            } else if (e.success === false) {
              // console.log("failed getCustomerInfoUsingNic");
              this.customerCif = null
            }
          },
          false
        );
      }
    },

    getExistingAccInfo(){
      // console.log("CIF",this.customerCif)
      if(this.customerCif != null){
        NetworkManager.apiRequest(
          "backend/applicant/getExistingAccountInfo",
          { cif: this.customerCif},
          (e) => {
            if(e.success == true){
              this.existingAccounts = e.data.existingAccInfo;
              // console.log("Existing Accounts",this.existingAccounts)
            }else{
              // console.log("Existing account getting failed")
            }
          },
          false
        );
      }
    },

    saveCustomerCif(cif){
      NetworkManager.apiRequest(
        "backend/applicant/saveCustomerCif",
        { cif: cif, id: this.viewData.id},
        (e) => {
          if (e.success === true) {
            // console.log("CIF saved successfully")
          } else if (e.success === false) {
            // console.log("CIF saved failed");
          }
        },
        false
      );
    },

    saveMinorCif(cif){
      NetworkManager.apiRequest(
        "backend/applicant/saveMinorCif",
        { cif: cif, id: this.viewData.id},
        (e) => {
          if (e.success === true) {
            // console.log("CIF saved successfully")
          } else if (e.success === false) {
            // console.log("CIF saved failed");
          }
        },
        false
      );
    },

    getMinorCif(){
      if(appSettings.usingSDBServer == true){
        this.usingTheSDBServer = true;
        if(this.viewData.lakdaru_minor_nic != null){
          NetworkManager.apiRequest(
            "backend/sdb/getCustomerInfoUsingNic",
            { nic: this.viewData.lakdaru_minor_nic },
            (e) => {
              if (e.success === true) {
                // console.log("success getMinorInfoUsingNic", e.data.CustomerDetailList[0].Customer_Number);
                this.minorCif = e.data.CustomerDetailList[0].Customer_Number;
                this.saveMinorCif(this.minorCif);
              } else if (e.success === false) {
                // console.log("failed getMinorInfoUsingNic");
                this.minorCif = null
              }
            },
            false
          );
        }
        
      }
    },

    cardValueSetter() {
      if (this.cardData2.length != 0) {
        this.cardData2 = [];
      }
      let purpose = this.viewData.kyc_purpose_other_reason != null ? JSON.parse(this.viewData.kyc_purpose_other_reason) : null;
      let sourceOfFunds = this.viewData.kyc_source_funds_other_reason != null ? JSON.parse(this.viewData.kyc_source_funds_other_reason) : null;
      let sourceOfWealth = this.viewData.kyc_wealth_other_reason != null ? JSON.parse(this.viewData.kyc_wealth_other_reason) :null;

      // console.log("purpose", purpose);
      // console.log("sourceOfFunds", sourceOfFunds);
      // console.log("sourceOfWealth", sourceOfWealth);

      this.purposeList = purpose != null ? purpose.list : null;
      if(this.purposeList != null){
        this.purposeList.title = "Purpose of account opening & usage ";
        if (purpose.specifyOther !== "") {
          this.purposeList.push(purpose.specifyOther);
        }
        // for (let i = 0; i < this.purposeList.length; i++) {
        //   let cardObj = {};
        //   cardObj["Purpose " + (i + 1)] = this.purposeList[i];
        //   this.cardData.push(cardObj);
        // }
        this.cardData2.push(this.purposeList);
      }
      

      this.sourceOfFundsList = sourceOfFunds != null ? sourceOfFunds.list : null;
      if(this.sourceOfFundsList != null){
        this.sourceOfFundsList.title = "Source of fund";
        if (sourceOfFunds.specifyOther !== "") {
          this.sourceOfFundsList.push(sourceOfFunds.specifyOther);
        }

        // for (let s = 0; s < this.sourceOfFundsList.length; s++) {
        //   let fundsObj = {};
        //   fundsObj["Source of fund " + (s + 1)] = this.sourceOfFundsList[s];
        //   this.cardData.push(fundsObj);
        // }
        this.cardData2.push(this.sourceOfFundsList);
      }
      

      this.sourceOfWealthList = sourceOfWealth != null ? sourceOfWealth.list : null;
      if(this.sourceOfWealthList != null){
        this.sourceOfWealthList.title = "Source of wealth";
        if (sourceOfWealth.specifyOther !== "") {
          this.sourceOfWealthList.push(sourceOfWealth.specifyOther);
        }

        // for (let j = 0; j < this.sourceOfWealthList.length; j++) {
        //   let wealthObj = {};
        //   wealthObj["Source of wealth " + (j + 1)] = this.sourceOfWealthList[j];
        //   this.cardData.push(wealthObj);
        // }
        this.cardData2.push(this.sourceOfWealthList);
      }
      

      this.anticipatedVolume[0] = this.viewData.kyc_anticipated_volume;
      this.anticipatedVolume.title = "Monthly Anticipated Volume(Interms of FD's initial deposit amount)";
      this.cardData2.push(this.anticipatedVolume);

      // console.log("cardData2", this.cardData2);
    },

    lOWCardValueSetter() {},

    applicationEmitHandler() {
      if (this.applicationStatData.applicationStat === "Reject") {
        return this.$emit("rejetRequestHandler", this.applicationStatData);
      }
      // } else if (this.applicationStatData.applicationStat === "Request To Improve") {
      //   return this.$emit("improveRequestHandler", this.applicationStatData);
      // }
    },

    reqToImproveapplicationEmitHandler() {
      if (this.applicationStatData.applicationStat === "Request To Improve") {
        return this.$emit("improveRequestHandler", this.applicationStatData);
      }
    },

    verifyOtp(otpCode) {
      if (otpCode[0] === "") {
        BuefyHelper.showToastMessage("Please enter the OTP code", "is-danger");
      } else {
        const resultString = otpCode.slice(1).join("");
        // console.log("verify otp", resultString);
        NetworkManager.apiRequest(
          "backend/applicant/verifyWithCustomerOTP",
          { otpCode: resultString },
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              this.isModalActive = false;
              this.isCardModalActive = true;
              // this.$router.push({ name: 'CardIssueModal', query: { otpData: this.otpData } });
            } else if (e.success === false) {
              BuefyHelper.showToastMessage(e.data.message, "is-danger");
              this.otpCode = [];
            }
            // console.log("comment send");
          },
          false
        );
      }
    },

    updateCardData() {
      this.dynamicFields.forEach((field) => {
        const fieldTitle = this.getFieldTitle(field); // Function to get the appropriate title
        const value = this.viewData[field] ? this.viewData[field] : "-";
        const cardItem = this.cardData.find(
          (item) => item.title === fieldTitle
        );
        if (cardItem) {
          cardItem.value = value;
        } else {
          this.cardData.push({ title: fieldTitle, value: value });
        }
      });
    },

    getnameWithInitials(name) {
      // const firstLetters = name.split(" ").map((word) => word[0]);
      // const lastString = name.split(" ").pop();
      // const result = firstLetters.slice(0, -1).join(" ") + " " + lastString;

      // Split the name by spaces and dots
      const parts = name.split(/[\s.]+/);

      // Get the last part of the name
      const lastString = parts.pop();

      // Get the first letters of the remaining parts
      const firstLetters = parts.map((word) => word[0].toUpperCase());

      // Join the first letters and the last part of the name
      const result = firstLetters.join(" ") + " " + lastString;
      return result;
    },

    updatePepStat() {
      this.isPEPChecked = this.viewData.pep_status;
    },

    applicationReviewStatus() {
      this.applicationStatData.applicationRequestType =
        this.viewData.review_status;
    },

    updateCardDataForWorkPlace() {
      this.workplaceFields.forEach((field) => {
        const fieldTitle = this.getWorkplaceTitle(field); // Function to get the appropriate title
        const value = this.viewData[field] ? this.viewData[field] : "-";
        const cardItem = this.cardDataTwo.find(
          (item) => item.title === fieldTitle
        );
        if (cardItem) {
          cardItem.value = value;
        } else {
          this.cardDataTwo.push({ title: fieldTitle, value: value });
        }
      });
    },

    debitCardIssueClick(debitCardNum, applicantId, verifiedInterview) {
      this.debitCardNumber = debitCardNum;
      NetworkManager.apiRequest(
        "backend/applicant/updateDebitCardNumber",
        {
          debit_card_number: debitCardNum,
          applicant_id: applicantId,
          verify_interview: verifiedInterview,
          id: this.viewData.id,
        },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            this.isDebitCardIssueModalActive = true;
            this.isCardModalActive = false;
          } else if (e.success === false) {
            BuefyHelper.showToastMessage("Error Occurred", "is-danger");
          }
        },
        false
      );
    },

    debitCardRejectClick() {
      this.debitCardRejectModalActive = true;
      this.isCardModalActive = false;
    },

    debitCardReject(reason) {
      if (reason == "") {
        BuefyHelper.showToastMessage(
          "Please enter a valid reason for rejection",
          "is-danger"
        );
      } else {
        NetworkManager.apiRequest(
          "backend/applicant/rejectDebitCard",
          {
            reason: reason,
            applicant_id: this.viewData.id,
            id: this.viewData.id,
          },
          (e) => {
            if (e.success === true) {
              this.debitCardRejectModalActive = false;
              this.isDebitCardRejectModalActive = true;
              BuefyHelper.showToastMessage(
                "Rejected Successfully",
                "is-danger"
              );
            } else if (e.success === false) {
              BuefyHelper.showToastMessage("Error Occurred", "is-danger");
            }
          },
          false
        );
      }
    },

    getWorkplaceTitle(field) {
      const fieldTitleMap = {
        workplace_employer_name: "Employer",
        workplace_adress: "Address",
        workplace_telephone: "Telephone",
        workplace_position: "Position",
        workplace_monthly_income: "Monthly Income",
        workplace_other_income: "Other Income",
        workplace_source_other_income: "Source of Other Income",
      };

      const title = fieldTitleMap[field];
      if (title) {
        return title;
      }

      return field
        .split("_") // Split field name by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "); // Join words with space
    },

    getFieldTitle(field) {
      const fieldTitleMap = {
        kyc_purpose_other_reason: "Purpose",
        kyc_source_funds_other_reason: "Source of Funds",
        kyc_wealth_other_reason: "Source of wealth",
        kyc_anticipated_volume: "Monthly Anticipated Volumes(Interms of FD's initial deposit amount)",
      };

      const title = fieldTitleMap[field];
      if (title) {
        return title;
      }

      return field
        .split("_") // Split field name by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "); // Join words with space
    },

    // async locateCity() {
    //   // Use the Google Maps Geocoding API to get the coordinates for the provided city name
    //   const apiKey = 'AIzaSyAS-9Of1ayvTDpuWh1t30C2BNUfyGSjepE';
    //   const city = this.$route.params.branch_name;
    //   const apiUrl = https://maps.googleapis.com/maps/api/geocode/json?address=${city}&key=${apiKey};

    //   try {
    //     const response = await fetch(apiUrl);
    //     const data = await response.json();
    //     console.log(data);
    //     if (data.results && data.results.length > 0) {
    //       const location = data.results[0].geometry.location;
    //       this.mapCenter = { lat: location.lat, lng: location.lng }; // Set center to the found location
    //       this.markers = [{ position: location }]; // Add a marker for the found location
    //     } else {
    //       console.error('No results found');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching data', error);
    //   }
    // },

    //  centerMap(position) {
    // Clicking on a marker updates the center of the map
    //   this.mapCenter = position;
    // },
    // commentSubmitHandler(){

    //     NetworkManager.apiRequest('backend/applicant/filterApplicantList', {"account_type":this.filterFields[0].value,"branch" : this.filterFields[1].value,"nic" : this.filterFields[2].value,},
    //         (e) => {
    //         // console.log(e.data.list)
    //         this.pendingList = e.data.list
    //         console.log("this.dataList ", e.data)
    //     }, false, )

    // }

    // PDF download for applications
    downloadPdf() {
      const comp = this;
      NetworkManager.apiRequest(
        "backend/applicant/pdfDownload",
        {
          id: this.viewData.id,
          pdf_type: "application",
        },
        (e) => {
          // console.log("data", e);
        },
        false
      );
      comp.$router.push({
        name: "PendingApplicationPdf",
        // query: { id: this.viewData.id },
        params: { lowCardData: this.cardDataTwo, kycCardData: this.cardData2, id: this.viewData.id },
      });
    },


    //PDF download for Debit cards
    downloadDebitCardPdf(debitCardNumber) {
      const comp = this;
      NetworkManager.apiRequest(
        "backend/applicant/pdfDownload",
        {
          id: this.viewData.id,
          pdf_type: "debit",
          debit_card_number: debitCardNumber,
        },
        (e) => {},
        false
      );
      comp.$router.push({
        name: "DebitCardPdf",
        query: { id: this.viewData.id },
        params: { lowCardData: this.cardDataTwo, kycCardData: this.cardData },
      });
    },

    closeModal() {
      this.isDebitCardIssueModalActive = false;
      location.reload();
    },
    closeRejectModal() {
      this.isDebitCardRejectModalActive = false;
      location.reload();
    },

    closeSecondModal() {
      this.isFdDebitCardIssueModalActive = false;
      location.reload();
    },
    closeSecondRejectModal() {
      this.isFdDebitCardRejectModalActive = false;
      location.reload();
    },

    sendOtp() {
      // const convertedNumber = '+94' + this.viewData.primary_mobile_number.slice(1);
      const convertedNumber = this.viewData.primary_mobile_number;
      this.otpCode = [];
      NetworkManager.apiRequest(
        "backend/applicant/sendOTPToDebitCardRequest",
        { mobile_number: convertedNumber },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "The OTP code is sent to your Mobile Number",
              "is-success"
            );
            this.isTimeout = false;
            this.countDown = 60;
            this.resendCountDown();
            //  this.otpData = e.data.user;
            //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "OTP code sending failed",
              "is-danger"
            );
          }
        },
        false
      );
      this.isModalActive = true;
    },

    // change this function later (sendOtpFD, resendOtpFD, resendCountDownFD, verifyOtpFD)
    sendOtpFD() {
      // const convertedNumber = '+94' + this.viewData.primary_mobile_number.slice(1);
      const convertedNumber = this.viewData.primary_mobile_number;
      this.otpCode = [];
      NetworkManager.apiRequest(
        "backend/applicant/sendOTPToCustomer",
        { mobile_number: convertedNumber },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "The OTP code is sent to your Mobile Number",
              "is-success"
            );
            this.isFDTimeOut = false;
            this.fdCountDown = 60;
            this.resendCountDownFD();
            //  this.otpData = e.data.user;
            //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "OTP code sending failed",
              "is-danger"
            );
          }
        },
        false
      );
      this.isFdModalActive = true;
    },

    reSendOtpFD(clientNo) {
      // const convertedNumber = '+94' + clientNo.slice(1);
      const convertedNumber = clientNo;
      NetworkManager.apiRequest(
        "backend/applicant/sendOTPToCustomer",
        { mobile_number: convertedNumber },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "The OTP code is sent to your Mobile Number",
              "is-success"
            );
            this.isFDTimeOut = false;
            this.fdCountDown = 60;
            this.resendCountDownFD();
            //  this.otpData = e.data.user;
            //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "OTP code sending failed",
              "is-danger"
            );
          }
        },
        false
      );
      this.isModalActive = true;
    },

    resendCountDownFD: function () {
      let timer = setInterval(() => {
        this.fdCountDown = this.fdCountDown - 1;
        if (this.fdCountDown === 0) {
          this.isFDTimeOut = true;
          clearInterval(timer);
        }
      }, 1000);
    },

    verifyOtpFD(otpCode) {
      if (otpCode[0] === "") {
        BuefyHelper.showToastMessage("Please enter the OTP code", "is-danger");
      } else {
        const resultString = otpCode.slice(1).join("");
        // console.log("verify otp", resultString);
        NetworkManager.apiRequest(
          "backend/applicant/verifyWithCustomerOTP",
          { otpCode: resultString },
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              this.isFdModalActive = false;
              this.isFdCertificateModalOpen = true;
              // this.$router.push({ name: 'CardIssueModal', query: { otpData: this.otpData } });
            } else if (e.success === false) {
              BuefyHelper.showToastMessage(e.data.message, "is-danger");
              this.otpCode = [];
            }
            // console.log("comment send");
          },
          false
        );
      }
    },

    // change this function also

    sendOtpCardIssue() {
      // const convertedNumber = '+94' + this.viewData.primary_mobile_number.slice(1);
      const convertedNumber = this.viewData.primary_mobile_number;
      this.otpCode = [];
      NetworkManager.apiRequest(
        "backend/applicant/sendOTPToCustomer",
        { mobile_number: convertedNumber },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "The OTP code is sent to your Mobile Number",
              "is-success"
            );
            this.isCITimeOut = false;
            this.ciCountDown = 60;
            this.resendCountDownCardIssue();
            //  this.otpData = e.data.user;
            //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "OTP code sending failed",
              "is-danger"
            );
          }
        },
        false
      );
      this.isCiModalActive = true;
    },

    reSendOtpCardIssue(clientNo) {
      // const convertedNumber = '+94' + clientNo.slice(1);
      const convertedNumber = clientNo;
      NetworkManager.apiRequest(
        "backend/applicant/sendOTPToCustomer",
        { mobile_number: convertedNumber },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "The OTP code is sent to your Mobile Number",
              "is-success"
            );
            this.isCITimeOut = false;
            this.ciCountDown = 60;
            this.resendCountDownCardIssue();
            //  this.otpData = e.data.user;
            //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "OTP code sending failed",
              "is-danger"
            );
          }
        },
        false
      );
      this.isModalActive = true;
    },

    resendCountDownCardIssue: function () {
      let timer = setInterval(() => {
        this.ciCountDown = this.ciCountDown - 1;
        if (this.ciCountDown === 0) {
          this.isCITimeOut = true;
          clearInterval(timer);
        }
      }, 1000);
    },

    verifyOtpCardIssue(otpCode) {
      if (otpCode[0] === "") {
        BuefyHelper.showToastMessage("Please enter the OTP code", "is-danger");
      } else {
        const resultString = otpCode.slice(1).join("");
        // console.log("verify otp", resultString);
        NetworkManager.apiRequest(
          "backend/applicant/verifyWithCustomerOTP",
          { otpCode: resultString },
          (e) => {
            // console.log(e.data.list)
            if (e.success === true) {
              this.isCiModalActive = false;
              this.isFdCardModalActive = true;
              // this.$router.push({ name: 'CardIssueModal', query: { otpData: this.otpData } });
            } else if (e.success === false) {
              BuefyHelper.showToastMessage(e.data.message, "is-danger");
              this.otpCode = [];
            }
            // console.log("comment send");
          },
          false
        );
      }
    },

    fDdebitCardIssueClick(debitCardNum, applicantId, verifiedInterview) {
      this.debitCardNumber = debitCardNum;
      NetworkManager.apiRequest(
        "backend/applicant/updateDebitCardNumber",
        {
          debit_card_number: debitCardNum,
          applicant_id: applicantId,
          verify_interview: verifiedInterview,
          id: this.viewData.id,
        },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            this.isFdDebitCardIssueModalActive = true;
            this.isFdCardModalActive = false;
          } else if (e.success === false) {
            BuefyHelper.showToastMessage("Error Occurred", "is-danger");
          }
        },
        false
      );
    },

    fDdebitCardRejectClick() {
      this.fDdebitCardRejectModalActive = true;
      this.isFdCardModalActive = false;
    },

    fDdebitCardReject(reason) {
      if (reason == "") {
        BuefyHelper.showToastMessage(
          "Please enter a valid reason for rejection",
          "is-danger"
        );
      } else {
        NetworkManager.apiRequest(
          "backend/applicant/rejectDebitCard",
          {
            reason: reason,
            applicant_id: this.viewData.id,
            id: this.viewData.id,
          },
          (e) => {
            if (e.success === true) {
              this.fDdebitCardRejectModalActive = false;
              this.isFdDebitCardRejectModalActive = true;
              BuefyHelper.showToastMessage(
                "Rejected Successfully",
                "is-danger"
              );
            } else if (e.success === false) {
              BuefyHelper.showToastMessage("Error Occurred", "is-danger");
            }
          },
          false
        );
      }
    },

    // <><><><><><>

    reSendOtp(clientNo) {
      // const convertedNumber = '+94' + clientNo.slice(1);
      const convertedNumber = clientNo;
      NetworkManager.apiRequest(
        "backend/applicant/sendOTPToCustomer",
        { mobile_number: convertedNumber },
        (e) => {
          // console.log("sfhsufhsdjfhdsjfh", e);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "The OTP code is sent to your Mobile Number",
              "is-success"
            );
            this.isTimeout = false;
            this.countDown = 60;
            this.resendCountDown();
            //  this.otpData = e.data.user;
            //   this.$router.push({ name: 'OTPVerification', query: { otpData: this.otpData } });
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "OTP code sending failed",
              "is-danger"
            );
          }
        },
        false
      );
      this.isModalActive = true;
    },

    resendCountDown: function () {
      let timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown = this.countDown - 1;
          if (this.countDown === 0) {
            this.isTimeout = true;
            clearInterval(timer);
          }
        }
      }, 1000);
    },

    // userCommentsSubmitHandler() {
    //   NetworkManager.apiRequest(
    //     "addCommentForReviewedApplication",
    //     {
    //       comment: this.userComment,
    //       application_id: this.viewData.id,
    //       id: this.viewData.id,
    //     },
    //     (e) => {
    //       // console.log(e.data.list)
    //       // console.log("comment send");
    //     },
    //     false
    //   );
    // },
    
    debitUserCommentsSubmitHandler() {
      NetworkManager.apiRequest(
        "addCommentForDebitCardApplication",
        {
          debit_comment: this.userComment,
          application_id: this.viewData.id,
          id: this.viewData.id,
        },
        (e) => {
          // console.log(e.data.list)
          // console.log("comment send");
        },
        false
      );
    },

    revokeRequestToImprove() {
      const requestData = {
        id: this.viewData.id,
        joint_main_owner: this.jointOwnerId,
      };

      NetworkManager.apiRequest(
        "backend/applicant/revokeRequestToImprove",
        requestData,
        (e) => {
          // console.log("successs", e.success);
          if (e.success === true) {
            BuefyHelper.showToastMessage(
              "Revoke request submission successful",
              "is-success"
            );
            // console.log("success");
            location.reload();
          } else if (e.success === false) {
            BuefyHelper.showToastMessage(
              "Revoke request submission failed",
              "is-danger"
            );
            // console.log("failed");
            location.reload();
          }
        },
        false
      );
    },

    maskCardNumber(cardNumber) {
      if (cardNumber.length === 16) {
        return `${cardNumber.slice(0, 4)}-XXXX-XXXX-${cardNumber.slice(12, 16)}`;
      }
      return cardNumber; // Return as is if length is not 16
    },

    displayReqImproveReasons() {
      const requestData = {
        id: this.viewData.id,
        joint_main_owner: this.jointOwnerId,
      };

      NetworkManager.apiRequest(
        "backend/applicant/displayReqImproveReasons",
        requestData,
        (e) => {
          // console.log("success", e.success);
          if (e.success === true) {
            // console.log("reqqq dataaaa", e.data.reqReasons.req_improve_reasons);
            // console.log(
              // "Raw JSON data:",
              // e.data.reqReasons.req_improve_reasons
            // );

            if (e.data.reqReasons.req_improve_reasons !== undefined) {
              try {
                const parsedData = JSON.parse(
                  e.data.reqReasons.req_improve_reasons
                );

                if (Array.isArray(parsedData)) {
                  this.reqReasons = parsedData.map((item) => ({
                    header: item.header,
                    value: item.value,
                  }));

                  // console.log("success", this.reqReasons);
                } else {
                  // console.error("Invalid JSON data structure:", parsedData);
                }
              } catch (error) {
                // console.error("Error parsing JSON:", error);
              }
            } else {
              // console.error("req_improve_reasons is undefined");
            }
          } else if (e.success === false) {
            // BuefyHelper.showToastMessage("Request to improve reasons retrival failed", "is-danger");
            // console.log("failed");
          }
        },
        false
      );
    },

    getDropdownOptions() {
      const requestData = {
        applicant_status: this.viewData.applicant_status,
        account_type: this.viewData.account_type,
        applicant_individual_account_type:
          this.viewData.applicant_individual_account_type,
      };

      NetworkManager.apiRequest(
        "backend/applicant/getDropdownOptions",
        requestData,
        (e) => {
          // console.log("successs", e.success);
          if (e.success === true) {
            this.dropdownOptions = JSON.parse(
              e.data.reqReasons.applicant_field
            );
            // const backendTitles = this.dropdownOptions.map(option => option.backend_title);
            // this.dropdownOptions = backendTitles;

            const backendTitles = this.dropdownOptions.map((option) => ({
              key: option.backend_title,
              value: option.column_name,
            }));
            this.dropdownOptions = backendTitles;
            // console.log("success");
          } else if (e.success === false) {
            // console.log("failed");
          }
        },
        false
      );
    },

    handleCheckboxClick(){
      const requestData = {
        applicant_id: this.viewData.id,
        terms_and_conditions: 1
      };
      NetworkManager.apiRequest(
        "backend/applicant/updateDebitCardDeclaration",
        requestData,
        (e) => {
          // console.log('Response:', e);
          if (e.success === true) {
            // console.log('Terms and conditions updated successfully');
            // Handle success
          } else if (e.success === false) {
            // console.log('Terms and conditions update failed');
            // Handle failure
          }
          // Reload the page after API request
          location.reload();
        },
        false
      );
    },

    getDataValues(data) {
      let result = "";
      for (let s = 0; s < data.length; s++) {
          // result = result + data[s] + ', ';
          if (s === data.length - 1) {
              result = result + data[s];
          } else {
              result = result + data[s] + ', ';
          }
      }
      return result;
    }
  },
};
</script>

<style scoped>
.round-image-box {
  border-radius: 80%;
  border: 2px solid #009ec2;
  width: 155px;
  height: 150px;
}

/* .notification-div{
  background-color: #c3e7b7;
  display: flex;
  padding: 15px 30px;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 10px;
 

} */

.checkbox-button {
  padding: 10px 35px;
  width: 100%;
  /* background-color: #0083ca; */
  border-radius: 5px;
}

.notification-div img {
  width: 85px;
}

.application-checkbox[disabled] {
  cursor: not-allowed !important;
}

.application-checkbox:not([disabled]):hover {
  cursor: pointer !important;
}

/* .sideOne.scroll {
        overflow-x: hidden;
        overflow-y: auto;
    } */

.main-box {
  display: flex;
  max-height: 100%;
  /* overflow-y: scroll; */
  overflow: hidden;
  padding: 5px 20px;
}

.button-container {
  width: 100%;
  /* margin-bottom: 25%/ */
  margin: 0;
}

.address-proof {
  padding-left: 0px;
  margin-left: 11px;
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.sub-box-one {
  width: 35%;
  overflow-y: scroll;
  max-height: 100%;
  padding: 25px 10px 80px 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sub-box-one::-webkit-scrollbar {
  display: none;
}

.sub-box-two::-webkit-scrollbar {
  display: none;
}

.custom , .customText {
  color: #0083ca;
  font-weight: bold;
  font-size: 14px;
}

.full-name {
  font-size: 2rem;
  font-weight: bolder;
  color: #0083ca;
}

.notification-msg {
  font-size: 0.8rem;
}

.sub-box-two {
  flex-grow: 7;
  margin-left: 15px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 100%;
  padding-bottom: 100px;
  width: 65%;
}

.personal-info {
  font-size: 1rem;
  font-weight: bold;
  color: #757575;
  width: 105%;
}
.personal-info-status {
  font-size: 1rem;
  font-weight: bold;
  color: #ec2b2b;
  width: 105%;
}

video {
  width: 100%;
  height: 94%;
  overflow: hidden;
}
</style>

<style scoped>
.vl {
  border-left: 1px solid green;
  height: 100%;
}

.info-class {
  color: #000000;
}

.custom-box {
  background-color: #feda04;
  border: 1px solid #ccc;
  margin: 10px;
}

.notificationReq {
  background-color: #fbe9b3;
  border-radius: 4px;
  position: relative;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
  margin-bottom: 10px;
  color: #000000;
}

.columnReq {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1rem;
  margin-bottom: 10px;
  border-radius: 7px;
}

.header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-icon {
  font-size: 20px;
  transition: transform 0.3s;
}

.arrow-icon.expanded {
  transform: rotate(180deg);
}

.content {
  margin-top: 10px;
}

.textbox {
  margin-bottom: 10px;
}

.textbox-header {
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 10px;
  height: 30px;
  font-size: 14px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.header.highlighted {
  background-color: #feda04;
}

.textbox-header.highlighted {
  background-color: #feda04;
  justify-content: center;
  align-items: center;
  padding-left: 17px;
  padding-top: 7px;
  color: #000000;
}

.input-box-text input {
  /* justify-content: center;
  align-items: center; */
  padding-left: 17px;

  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 30px;
  width: 755.5px;
  font-size: 14px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #000000 !important;
  border: none;
}

.titleFontStyles {
    font-size: 15px;
    font-weight: 400;
    color: #757575;
    width: 45%;
}

.containerStyles{
    display: flex;
    margin: 10px 0;
    /* justify-content: space-between */
    
}

.seperatorContainer{
    width: 7%;
}

.valueFontStyles {
    font-size: 1rem;
    color: #000000;
    word-wrap: break-word;
    width: 48%;
    font-weight: 500;
}

/* .column button-container{
  margin-bottom:70%;
} */
</style>
