<template>
     <b-modal v-model="isDebitCardRejectModalActive">
       <div class="main-container">
         <img src="@/assets/images/DebitCardReject.png" alt="Debit Card Reject" class="mt-7" />
         <div class="content-container">
           <span class="title is-size-4 has-text-weight-semibold has-text-danger">
              Debit Card Rejected!
           </span>
           <div class="mt-5 mb-5">Debit card rejected data has been successfully sent to the backend.</div>
         </div>
         <div class="button-container mt-70">
           <b-button type="is-danger" size="is-medium" @click="$emit('closeRejectModal')" class="done-button">
             Done
           </b-button>
         </div>
       </div>
     </b-modal>
   </template>
   
   <script>
   import DebitCardIssueCard from '../Cards/DebitCardIssueCard.vue';
   
   export default {
     name: 'DebitCardRejectNotificationModal',
     props: ['isDebitCardRejectModalActive'],
     emits: ['closeRejectModal'],
     components: {
       DebitCardIssueCard,
     },
   };
   </script>
   
   <style scoped>
   .main-container {
     background: #ffffff;
     width: 385px;
     height: 450px; /* Increased height */
     border-radius: 16px; /* Slightly increased border radius */
     margin: 30px auto; /* Increased margin */
     padding: 20px;
     text-align: center;
   }
   
   .main-container img {
     width: 120px;
     margin-bottom: 20px;
   }
   
   .content-container {
     margin-bottom: 30px; /* Increased margin */
   }
   
   .has-text-primary {
     color: #3273dc; /* Blue text color */
   }
   
   .button-container {
     display: flex;
     justify-content: space-around;
   }
   
   .download-pdf-button {
     background-color: #ffffff;
     color: #3273dc; /* Blue text color */
     border-radius: 8px; /* Slightly rounded corners */
   }
   
   .done-button {
     border-radius: 8px; /* Slightly rounded corners */
   }
   </style>
   