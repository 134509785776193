import axios from "axios"
import { appSettings } from "@/appSettings"
import JwtUtils from "@/helpers/jwtUtils";
import BuefyHelper from "@/helpers/bufeyHelper";


class NetworkResponse {
    statusCode
    data

    constructor() {
        this.statusCode = 0
        this.data = null
    }
}
const NetworkManager = {
    apiRequest: function (endpoint, data, taskFunction, withToken, headers) {
       // eslint-disable-next-line no-unused-vars
       
        const withToken2 = localStorage.getItem('token') ? true : false

        if (withToken2) {
            withToken = true
        } else {
            withToken = false
        }
        const URL = appSettings.baseUrl + "/" + endpoint

        let config = {
            headers: {},
            // timeout: appSettings.timeoutDuration
        }
        if (withToken) {
            if(headers){
                config.headers = {
                Authorization: `Bearer ${JwtUtils.loadToken().token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }else{

                config.headers = {
                Authorization: `Bearer ${JwtUtils.loadToken().token}`,
                'Content-Type': 'application/json'
                }
            }
        }
        

        axios.post(
            URL,
            data,
            config
        )
            .then(function (e) {
                taskFunction(e.data)
              
            })
            .catch(function (e) {
                let response = new NetworkResponse()
                // console.log("e.response", e)

                if (e.response) {
                    response.statusCode = e.response.status
                    // console.log("response.statusCode", response.statusCode)
                    if(response.statusCode === 500){
                        BuefyHelper.showToastMessage("Something Went Wrong", "is-danger");
                    }else if (response.statusCode = "Authorization Token not found") {
                        JwtUtils.logoutTheUser()
                        BuefyHelper.showToastMessage("Unauthorized", "is-danger");
                    }
                    if (e.response.data) {
                        response = e.response.data
                    }
                }
            }

            )

    }


}
export default NetworkManager