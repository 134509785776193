import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'



import "@mdi/font/css/materialdesignicons.min.css";
import "../src/myBuefyStyles/myBuefyStyles.scss";
// import "../src/myBuefyStyles/customBuefycss.css";
// import 'buefy/dist/buefy.css';
import * as VueGoogleMaps from 'vue2-google-maps'
import { PiniaStoreHelper } from "./helpers/piniaHelper";
import Global from "@/mixins/global";
import VueExcelXlsx from "vue-excel-xlsx";
import VueApexCharts from 'vue-apexcharts';

import { initializeApp } from "firebase/app";
import firebaseConfig from './firebase';
import {addDoc, collection, getFirestore, query, onSnapshot, doc} from "firebase/firestore";
import { getMessaging, onMessage } from "firebase/messaging";


const app = initializeApp({
    apiKey : firebaseConfig.apiKey,
    authDomain : firebaseConfig.authDomain,
    projectId : firebaseConfig.projectId,
    storageBucket : firebaseConfig.storageBucket,
    messagingSenderId : firebaseConfig.messagingSenderId,
    appId : firebaseConfig.appId,
    measurementId : firebaseConfig.measurementId
});

  const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      // if(payload.data.branch == userBranch){
        // console.log('Message received in mainjs. ', payload);
        const notificationData = {
        title: payload.notification.title,
        body: payload.notification.body,
      };
  });

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(Buefy)
Vue.mixin(Global);

Vue.use(VueExcelXlsx);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'YOUR_API_TOKEN',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

// import and use pinia with vue 2 -------------------------------------------------------------------------------------
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// persisting pinia states ---------------------------------------------------------------------------------------------

pinia.use((context) => {
  const storeID = context.store.$id;
  // console.log("Pinia store id : " + storeID);

  switch (storeID) {
    case "authStore": {
      let fromLocalAuthStorage = PiniaStoreHelper.getDataFromLocalStore(storeID);
      if (fromLocalAuthStorage) {
        context.store.$patch(fromLocalAuthStorage);
      }
      // Listen to changes and update local storage
      PiniaStoreHelper.syncDataToLocalStore(context, storeID);
      break;
    }

    case "videoKycStore": {
      // NOTE: Do not sync video kyc store with local store due to storage limitations.
      // This means data stored in the store will reset after a refresh.

      // Uncomment and add code specific to "videoKycStore" here, if needed.

      // let fromVideoKycStore = PiniaStoreHelper.getDataFromLocalStore(storeID);
      // if (fromVideoKycStore) {
      //   context.store.$patch(fromVideoKycStore);
      // }
      // // Listen to changes and update local storage
      // PiniaStoreHelper.syncDataToLocalStore(context, storeID);
      break;
    }
  }
});

// persisting pinia states - end ---------------------------------------------------------------------------------------

Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  render: h => h(App),
}).$mount('#app')
