<template>
    
        <div class="containerStyles">
            <div class="titleFontStyles" :class="titleStyles">
                {{ fieldTitle }}
            </div>
            <div class="seperatorContainer">
                :
            </div>

            <div class="valueFontStyles" :class="valueStyles" >
                {{ fieldValue }}
            </div>

        </div>



   
</template>

<script>

export default {
    name: "SpanFields",

    props: ["fieldTitle", "fieldValue", "titleStyles", "valueStyles"],
}

</script>

<style>
.titleFontStyles {
    font-size: 15px;
    font-weight: 400;
    color: #757575;
    width: 45%;
}

.containerStyles{
    display: flex;
    margin: 10px 0;
    /* justify-content: space-between */
    
}

.seperatorContainer{
    width: 7%;
}

.valueFontStyles {
    font-size: 15rem;
    color: #000000;
    word-wrap: break-word;
    width: 48%;
    font-weight: 500;
}
</style>