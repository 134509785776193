    // http://127.0.0.1:8000/storage/ - local baseUrl for getting images and videos
    // https://sdb-f2f-api.paymediasolutions.com/public/storage/ - paymedia server baseUrl for getting images and videos
    // http://10.100.32.140/SDBF2FAPIBackend/public/storage/ - sdb bank server baseUrl for getting images and videos
    
    let appSettingsStorage = {
        // baseUrl : "http://127.0.0.1:8000/storage/"//
        baseUrl : "https://sdb-f2f-api.paymediasolutions.com/public/storage/",
        // baseUrl : "http://10.100.32.140/SDBF2FAPIBackend/public/storage/"
        // baseUrl : "https://conboard.sdb.lk:8000/storage/sdbvg-sdblv/",,
        existingImageBaseUrl : "http://10.100.32.140/",
    }
    

    export {appSettingsStorage}

