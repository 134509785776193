<template>
     <div class="notification-div" v-bind:style="{backgroundColor : applicationType === 'Approved'? '#c3e7b7' : applicationType ==='Rejected'? '#f7bdc0' : '#FFCB2B'}">
        <!-- <img :src="applicationType === 'Approved' ? '../../../assets/images/Success.png' : applicationType === 'Rejected' ? '../../../assets/images/Reject.png' : '' "  /> -->
        <img src="../../../assets/images/Success.png" v-if="applicationType === 'Approved'">
        <img src="../../../assets/images/Reject.png" v-else-if="applicationType === 'Rejected'">

 
        <div style="display: flex; flex-direction: column; align-items: center;" v-if="applicationType === 'Approved'">
              <span class="applicationMessage" style="color : #62AF47">{{ applicationMessage }}</span>
              <span  class="is-size-6 has-text-weight-medium" style="color: gray;">{{ message }}</span>
              <span style="background-color: aliceblue; width: 100%; text-align: center; border-radius: 5px; margin-top: 5px; font-size: 15px; font-weight: 600">{{number}}</span>
            </div>

            
        <div v-else-if="applicationType==='Rejected'">
            <span style="color: #FF505A" class="applicationMessage">{{ applicationMessage }}</span>
        </div>
      </div>
</template>

<script>
export default {
    name : 'ApplicationStatCard',
    props : ['applicationType', "applicationMessage", "number", "message"],

    data(){
        return{
          
        }
    },


    // computed: {

    //     getImageSourcePath(){
    //         if(this.applicationType === 'Approved'){
    //           return '../../../assets/images/Success.png'
    //         }
    //         else if(this.applicationType === 'Rejected'){
    //             return '../../../assets/images/Reject.png'
    //         }
    //     }

    // }
    
}
</script>

<style>
    .notification-div{
        background-color: #f7bdc0;
        display: flex;
        /* padding: 5px 25px; */
        border-radius: 8px;
        justify-content: space-around;
        display: flex;
        margin-bottom: 10px;
        align-items: center
    }

    .applicationMessage{
        font-size: 16px;
        font-weight: 600;
    }
    img{
        height: 30px;
    }


    .notification-div img{
        width: 25%;
    }

</style>