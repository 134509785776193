<template>
    <b-modal v-model="customerModalActive">
        <div class="main-container">
            <div class="sub-container">
                <div class="sub-container-one" >
                    <div class="heading-txt has-text-primary has-text-weight-bold">Find  <span class="has-text-weight-semibold">your</span> <span style="font-size:40px">customer</span></div>
                    <div style="width:280px; margin: 60px auto">
                        <img src="../../../assets/images/CreditCard.webp" >
                    </div>
                </div>
                <div class="sub-container-two">
                <div style="margin-left:500px; margin-top:5px">
                    <b-button style="border:none;" @click="closeModal">
                        <b-icon icon="close" size="is-medium" style="color: gray; cursor: pointer;" class="b-icon" onMouseOver="this.style.color='red'" onMouseOut="this.style.color='gray'"></b-icon>
                    </b-button>
                </div>
                    <div style="font-size:24px; font-weight:700" class="has-text-primary  has-text-centered">Customer’s NIC /DL Number</div>
                    <!-- <div style="color:#7f7a7a" class="has-text-centered has-text-weight-normal">Please enter your customer’s NIC /DL Number <span class="has-text-danger">*</span></div> -->
                        <div style="width:70%; margin:15px auto;">
                            <label style="color:#757575; font-weight: 500" class="has-text-centered">Please enter your customer’s NIC /DL Number  <span class="has-text-danger">*</span></label>
                                <b-field style="width:95%; margin: 0">
                                    <b-input v-model="nicOrDlcNumber">
                                    </b-input>
                                </b-field>
                        </div>

                        <div class="profile-container">
                            <img src="" class="round-image-box"/>
                                <div> 
                                    <div class="title-container-one">
                                        <span class="is-size-5 has-text-weight-semibold has-text-grey"> Mr Manula </span>
                                    </div>
                                    <div class="title-container-two">
                                        <span class="has-text-weight-medium gray-txt mr-4 is-size-6">987754215v</span>
                                        <span style="border-left:2px solid gray; width:0%" ></span>
                                   
                                     <span class="has-text-weight-medium gray-txt" style="padding-left:20px">Male</span>
                                    
                                    
                                    </div>
                                    <span class="has-text-weight-medium gray-txt">
                                        Approved
                                    </span>

                                    <div style="margin-top:5px">
                                        <span
                                            class="has-text-success notification-msg"
                                        
                                        >
                                            <b-icon icon="credit-card-multiple-outline" size="is-small"></b-icon>
                                                Card Issued <b-icon icon="check-circle" size="is-small"></b-icon
                                            ></span>
                                    </div>
                                </div>
                        </div>

                        <div class="download-btn">
                            <b-button type="is-primary" expanded style="padding: 20px 0" :disabled="nicOrDlcNumber.trim() == ''" @click="sendOtpCode">Send OTP Code</b-button>
                        </div>  
                </div>
            </div>
        </div>
    </b-modal>
    
</template>

<script>
export default {
    name : "FindYourCustomerModal",
    props : ["isCustomerFindModalActive"],

    data(){
        return{
            nicOrDlcNumber : '',
            // isCustomerFindModalActive :false

        }
    },

    methods:{

        sendOtpCode(){
            // this.isCustomerFindModalActive = false
            this.$emit('send-otp', {modalvalue : false, userInput : this.nicOrDlcNumber})

            
        },

        closeModal(){
            this.$emit('close-modal', false)
        }

    },

    computed:{
        customerModalActive(){
            return this.isCustomerFindModalActive
        }
    }
    
}
</script>

<style scoped>
.main-container{
    background: #ffff;
    width: 900px;
    height: 450px;
    border-radius:12px;
    margin-top: 55px;
    /* padding: 25px; */
}

.sub-container{
    display: flex;
     height: 100%;
    
}

.gray-txt{
    color: gray;
}

.sub-container-one{
    width: 40%;
    height: 100%;
    background-color: #fafbfc;
    box-shadow: 8px 0 8px -4px rgba(0, 0, 0, 0.2);
    border-radius: 12px 0px 0px 12px;
    padding-top: 20px;

}

.sub-container-two{
    width: 60%;
    /* padding-top: 35px; */

}

.profile-container{
    display: flex;
    margin: 30px 80px;
}

.round-image-box {
  border-radius: 70%;
  border: 2px solid #009ec2;
  width: 140px;
  height: 120px;
  margin-right: 10px;
}

.title-container-one{
    padding-bottom: 8px;
    border-bottom: 1px solid gray;

}
.title-container-two{
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

}

.title-container-two span{
    width: 100%;
}

.download-btn{
    width: 70%;
    margin: 50px auto;
}

.heading-txt{
    width: 40%;
    text-align: center;
    margin: 10px auto;
    font-size: 32px;
}


</style>