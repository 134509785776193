const DateTimeConverter = {


    dateFormater : function(dateObject){

        const dateConstructor = new Date(dateObject);
        let year = dateConstructor.getFullYear();
        let month = dateConstructor.getMonth() + 1
        let date = dateConstructor.getDate();
        let hours = dateConstructor.getHours().toString().padStart(2, '0');
        let minutes = dateConstructor.getMinutes().toString().padStart(2, '0');
        let seconds = dateConstructor.getSeconds().toString().padStart(2, '0');

        let captureDate = year + "-"+ month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
        // console.log(captureDate)
        return captureDate
        


    }
}

export default DateTimeConverter;