<template>
    <div  :class="cardStyles">

        <section>

            <b-collapse :open="false" class="notification" animation="slide" aria-id="contentIdForA11y3" style="padding: 0;">
                <template #trigger="props">
                    <div class="is-flex is-justify-content-space-between" role="button" aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title">
                            {{ cardTitle }}
                        </p>
                        <a class="card-header-icon">
                            <b-icon :icon="props.open ? 'minus-box' : 'plus-box'" type="is-primary"
                                size="is-medium">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content">
                    <div class="content">
                        <SpanFields v-for="(data, index) in cardData" :field-title="getFieldTitle(data, index)" :key="index"
                            :fieldValue="getFieldValue(data) != null ? getFieldValue(data) : '-'" />
                    </div>
                </div>

            </b-collapse>

        </section>

    </div>
</template>

<script>
import SpanFields from '../fields/SpanFields.vue';

export default {
    components: {
        SpanFields,
    },

    name: "ApplicationViewCard",
    props: ["cardStyles", "cardTitle", "cardData"],

    methods:{
        getFieldTitle(data, index){
            const keys = Object.keys(data)[0];
            return keys
        },

           getFieldValue(data) {
      // Get the value corresponding to the first key
      const key = Object.keys(data)[0];
    //   console.log("keyyyy", data[key]);

      return data[key];
    },
    }
}

</script>