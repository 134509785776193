<template>
    <div
      style="max-height: 100%; min-height: 100%; overflow-x: hidden; overflow-y: hidden"
      class="pending-table-container"
    >
      <div
        style="
          width: 100%;
          color: gray;
          font-weight: 400;
          font-size: 15px;
          margin: 20px auto;
          font-family:Poppins
        "
        class="is-size-5"
      >
        <!-- Data table with full feature -->
      </div>
  
      <TableComponent
        height="58vh"
        :columns="columns"
        :tableData="pendingList"
        :objectList="filterFields"
        :isLoading="isLoading"
        @tableFilter="
          () => {
            tableFilterRequestHandler();
          }
        "
        @resetFilter="
          () => {
            resetFilter();
          }
        "
      />
    </div>
</template>
  
<script>
  import TableComponent from "@/components/TableComponent.vue";
  import NetworkManager from "@/network";
  import { FilterInputFiled } from "@/helpers/FilterInputFiled";
  import { useAuthStore } from "@/store/authStore";
  import DateConverter from "@/helpers/DateConverter";
  
  export default {
    name: "ActivityLog",
    components: {
      TableComponent,
    },
    setup() {
      const authStore = useAuthStore();
      return { authStore };
    },
  
    data() {
      return {
        pendingList: [],
  
        columns: [
          // {
          //   field: "level",
          //   label: "Level",
          // },
          {
            field: "timestamp",
            label: "Timestamp",
          },
          {
            field: "description",
            label: "Description",
          },
          {
            field: "username",
            label: "User",
          },
          {
            field: "branch_name",
            label: "Branch Name",
          },

        ],
        filterFields: [
            // new FilterInputFiled("Select Branch", "", [], "SELECT"),
            // new FilterInputFiled("Select User", "", [], "USERSELECT"),
            new FilterInputFiled("From", "", "", "DATEPICKER", new Date()),
            new FilterInputFiled("To", "", "", "DATEPICKER", new Date()),
            new FilterInputFiled("Search", "", "", "BUTTON"),
            new FilterInputFiled("Reset", "", "", "RESETBUTTON"),
        ],
        isLoading: false,
        isFullPage: false,
        
        // isTableComponentLoading: false
      };
    },

    watch : {
    filterFields: {
      handler(){
        this.filterButtonStateHandler()
      },
      deep: true
    }
    },
  
    methods: {
      tableFilterRequestHandler: function () {
        this.isLoading=true;
        NetworkManager.apiRequest(
          "backend/activityLog/list",
          {
            // branch: this.filterFields[0].value === 'All' ? '' : this.filterFields[0].value,
            // user: this.filterFields[1].value === 'All' ? '' : this.filterFields[1].value,
            from_date: DateConverter.dateFormater(this.filterFields[0].value),
            to_date: DateConverter.dateFormater(this.filterFields[1].value),
            loggedInUser: "yes",
          },
          (e) => {
            // console.log("dataaaaaaaaaaaaaaaaaa",e.data.list)
            // e.data.list.map((data) => {
            //   data.updated_at = DateConverter.dateFormater(data.updated_at);
            //   data.created_at = DateConverter.dateFormater(data.created_at);
            //   if (data.application_approved_date != null) {
            //     data.application_approved_date = DateConverter.dateFormater(
            //       data.application_approved_date
            //     );
            //   }
            // });
            this.pendingList = e.data.list;
            this.isLoading=false;
            // console.log("this.dataList ", e.data);
            // this.filterFields[4].isButtonDisabled = false
           
          },
          false
        );
      },

      filterButtonStateHandler : function(){
      if(this.filterFields[0].value !== "" || this.filterFields[1].value !== ""){
        this.filterFields[2].isButtonDisabled = false
        this.filterFields[3].isButtonDisabled = false
      }
      else{
        this.filterFields[2].isButtonDisabled = true
        this.filterFields[3].isButtonDisabled = true
      }
    },
  
      resetFilter: function () {
         // Reset filter fields
         this.filterFields.forEach((field) => {
            field.value = "";
        });

        // Clear the table
        this.pendingList = [];

        //get today activities
        this.getTodayActivities();
      },

      getTodayActivities(){
        const currentDate = new Date();

        const formattedDate = currentDate.toISOString().split('T')[0];

        // console.log(formattedDate);

        NetworkManager.apiRequest(
          
          "backend/activityLog/list",
          {
            // branch: this.filterFields[0].value === 'All' ? '' : this.filterFields[0].value,
            // user: this.filterFields[1].value === 'All' ? '' : this.filterFields[1].value,
            from_date: formattedDate,
            to_date: formattedDate,
            loggedInUser: "yes",
          },
          (e) => {
            // console.log("dataaaaaaaaaaaaaaaaaa",e.data.list)
            // e.data.list.map((data) => {
            //   data.updated_at = DateConverter.dateFormater(data.updated_at);
            //   data.created_at = DateConverter.dateFormater(data.created_at);
            //   if (data.application_approved_date != null) {
            //     data.application_approved_date = DateConverter.dateFormater(
            //       data.application_approved_date
            //     );
            //   }
            // });
            this.pendingList = e.data.list;
            this.isLoading = false;
            // console.log("this.dataList ", e.data);
            // this.filterFields[4].isButtonDisabled = false
           
          },
          false
        );
      },
  
  
      filterButtonStateHandler : function(){
        if(this.filterFields[0].value !== "" || this.filterFields[1].value !== ""){
          this.filterFields[2].isButtonDisabled = false
          this.filterFields[3].isButtonDisabled = false
        }
        else{
          this.filterFields[2].isButtonDisabled = true
          this.filterFields[3].isButtonDisabled = true
        }
      }
    },
  
    watch : {
      filterFields: {
        handler(){
          this.filterButtonStateHandler()
        },
        deep: true
  
      }
    },
  
    mounted() {
      // this.branchListLoader(this.filterFields[0].options);
      // this.userListLoader(this.filterFields[1].options);
      this.filterButtonStateHandler();
      this.getTodayActivities();
    },
  };
  </script>
  