import MainLayout from "@/layout";
import Auth from "@/middlewares/auth";
import Permission from "../middlewares/permission";
import User from "@/mixins/modules/authMixins";
import UserList from '../views/User/UserList.vue'
import CreateUser from '../views/User/CreateUser.vue'
import EditUser from '../views/User/EditUser.vue'
import RoleList from '../views/Role/RoleList.vue'
import EditRole from '../views/Role/EditRole.vue'
import CreateRole from '../views/Role/CreateRole.vue'
import AllAplications from '../views/Application/AllApplications.vue'
import ApprovedApplications from '../views/Application/Approved.vue'
import PendingApplications from '../views/Application/Pending.vue'
import RejectedApplications from '../views/Application/Rejected.vue'
// import ReqToImprove from '../views/Application/ReqToImprove.vue'
import DebitAllAplications from '../views/DebitCardIssue/AllApplications.vue'
import DebitIssued from '../views/DebitCardIssue/Issued.vue'
import DebitPending from '../views/DebitCardIssue/Pending.vue'
import DebitRejected from '../views/DebitCardIssue/Rejected.vue'
import AuditReport from '../views/Reports/AuditReport.vue'
import ActivityReport from '../views/Reports/ActivityReport.vue'
import UserReport from '../views/Reports/UserReport.vue'
import ActivityLog from '../views/ActivityLog/ActivityLog.vue'
import ChangeRequests from '../views/ChangeRequests/ChangeRequests.vue'
import NewUser from '../views/User/NewUser.vue'
import PendingApplicationView from '../views/Application/PendingApplicationView.vue'
import RequestToImproveApplications from '../views/Application/ReqToImprove.vue'
import UserLogin from '../views/Login/UserLogin.vue'
import PasswordReset from '../views/Login/PasswordReset.vue'
import OTPVerification from '../views/Login/OTPVerification.vue'
import ForgotPassword from '../views/Login/ForgotPassword.vue'
import ApprovedApplicationView from '../views/Application/ApprovedApplicationView.vue'
import RejectedApplicationView from '../views/Application/RejectedApplicationView.vue'
import ReqToImproveApplicationView from '../views/Application/ReqToImproveApplicationView.vue'
import DebitPendingView from '../views/DebitCardIssue/DebitPendingView.vue'
import DebitIssuedView from '../views/DebitCardIssue/DebitIssuedView.vue'
import DebitRejectedView from '../views/DebitCardIssue/DebitRejectedView.vue'
import ProfileManagement from '../views/ProfileManagement/ProfileManagement.vue'
import Dashboard from '../views/DashBoard/DashBoard.vue'
import CentralizedOpsReviewedApplication from '../views/Application/CentralizedOpsReviewedApplication.vue'
import ReviewedApplicationView from '../views/Application/ReviewedApplicationView.vue'
//SMS Notification
import AllAplicationsSMS from '../views/SMSActivation/AllApplicationsSMS.vue'
import ActivatedSmsApplications from '../views/SMSActivation/Activated.vue'
import FailedSmsApplications from '../views/SMSActivation/Failed.vue'
import SMSActivationView from '../components/SMSActivationView.vue'
import SmsActivationPdf from '../views/SMSActivation/SmsActivationPdf.vue'
import DebitCardReport from "@/views/Reports/DebitCardReport.vue";
import SmsActivationReport from "@/views/Reports/SmsActivationReport.vue";
import PendingSmsApplications from '../views/SMSActivation/Pending.vue';
import SMSPendingView from "@/components/SMSPendingView.vue";

// import FdCertificatePdf from '../views/Application/FdCertificatePdf.vue'


const routes = [
  {
    path: "/",
    hidden: true,
    meta: { middleware: [Auth] },
    redirect: () => { return '/login' }

  },
  {
    hidden: true,
    path: "/login",
    name: "login",
    component: UserLogin,
  },
  // {
  //   hidden: true,
  //   path: "/rough",
  //   name: "rough",
  //   component: FdCertificatePdf,
  // },
  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      return (
        true
      );
    },
    redirect: "dashboard",
    meta: {
      type: "menu",
      icon: "view-dashboard",
      title: "Dashboard",
      isActive: false,
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        isVisible: function () {
          true
        },
        meta: {
          // type: "menu-item",
          title: "Dashboard",
          navTitle: "Dashboard",
          icon: "post",
          isActive: false,
          middleware: [Auth],
        },
      },
    ]
  },
  {
    path: '/#',
    component: MainLayout,
    isVisible: function () {
      return (
        User.hasPermission("application-list") ||
        User.hasPermission("application-approve") ||
        User.hasPermission("application-reject") ||
        User.hasPermission("application-schedule") ||
        User.hasPermission("application-incomplete") ||
        User.hasPermission("approved-application-list") ||
        User.hasPermission("submit-for-approval") ||
        User.hasPermission("view-incomplete-applications") ||
        User.hasPermission("refer-to-branch") ||
        User.hasPermission("reject-application") ||
        User.hasPermission("approve-application")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "file-document",
      title: "Acc. Application",
      isActive: false,
    },

    children: [
      {
        path: '/applications',
        name: 'applications',
        component: AllAplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        redirect: "",
        meta: {
          type: "menu-item",
          // icon: "receipt-text",
          mainTitle: 'Acc. Applications',
          title: "All Applications",
          navTitle: "All Applications",
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/download/:id?',
        name: 'PendingApplicationPdf',
        component: () =>
          import("@/views/Application/PendingApplicationPdf"),
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        redirect: "",
        meta: {
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },

      {
        path: '/fd-pdf-download/:id?',
        name: 'FdCertificatePdf',
        component: () =>
          import("@/views/Application/FdCertificatePdf"),
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        redirect: "",
        meta: {
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },

      {
        path: '/debit-pdf-download',
        name: 'DebitCardPdf',
        component: () =>
          import("@/views/Application/DebitCardPdf"),
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        redirect: "",
        meta: {
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
       {
        path: '/pending-applications',
         name: 'pending-applications',
         component: PendingApplications,
         isVisible: function () {
           return User.hasPermission("application-list");
         },
         meta: {
           type: "menu-item",
          // icon: "receipt-text-clock",
          mainTitle: 'Acc. Applications',
          title: "Pending",
          navTitle: "Pending Applications",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/pending-applications-view/:id?/:jointAcc?',
        name: 'pending-applications-view',
        component: PendingApplicationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          mainTitle: 'Acc. Applications',
          title: "Pending",
          navTitle: "Pending Application",
          subtitle: 'View',
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/approved-applications-view/:id?/:jointAcc?',
        name: 'approved-applications-view',
        component: ApprovedApplicationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          // mainTitle: 'Application',
          mainTitle: 'Acc. Applications',
          title: "Approved",
          navTitle: "Approved Application",
          subtitle: 'View',
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/reviewed-applications-view/:id?/:jointAcc?',
        name: 'reviewed-applications-view',
        component: ReviewedApplicationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          // mainTitle: 'Application',
          mainTitle: 'Acc. Applications',
          title: "Reviewed",
          navTitle: "Reviewed Application",
          subtitle: 'View',
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/rejected-applications-view/:id?/:jointAcc?',
        name: 'rejected-applications-view',
        component: RejectedApplicationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          // mainTitle: 'Application',
          mainTitle: 'Acc. Applications',
          title: "Rejected",
          navTitle: "Rejected Application",
          subtitle: 'View',
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/req-to-improve-applications-view/:id?/:jointAcc?',
        name: 'req-to-improve-applications-view',
        component: ReqToImproveApplicationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          // mainTitle: 'Application',
          mainTitle: 'Acc. Applications',
          title: "Request to Improve",
          navTitle: "Request to Improve Application",
          subtitle: 'View',
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/req-to-improve',
        name: 'req-to-improve',
        component: RequestToImproveApplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        redirect: "",
        meta: {
          type: "menu-item",
          mainTitle: 'Acc. Applications',
          // icon: "receipt-text-edit",
          title: "Request to Improve",
          navTitle: "Request to Improve Applications",
          isActive: false,
        },
      },
      {
        path: '/approve',
        name: 'approve',
        component: ApprovedApplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          // icon: "receipt-text-check",
          mainTitle: 'Acc. Applications',
          title: "Approved",
          navTitle: "Approved Applications",
          isActive: false,
        },
      },
      {
        path: '/reviewed-by-cop',
        name: 'reviewed-by-cop',
        component: CentralizedOpsReviewedApplication,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'Acc. Applications',
          title: "Reviewed by C. Ops",
          navTitle: "Reviewed by centralized Ops",
          isActive: false,
        },
      },
      {
        path: '/rejected',
        name: 'rejected',
        component: RejectedApplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          // icon: "receipt-text-remove",
          mainTitle: 'Account Application',
          title: "Rejected",
          navTitle: "Rejected Applications",
          isActive: false,
        },
      }
    ]
  },

   //SMS Activation
  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      // return User.hasPermission("user-list");
      return (
        User.hasPermission("application-list") ||
        User.hasPermission("application-approve") ||
        User.hasPermission("application-reject") ||
        User.hasPermission("application-schedule") ||
        User.hasPermission("application-incomplete") ||
        User.hasPermission("approved-application-list") ||
        User.hasPermission("submit-for-approval") ||
        User.hasPermission("view-incomplete-applications") ||
        User.hasPermission("refer-to-branch") ||
        User.hasPermission("reject-application") ||
        User.hasPermission("approve-application")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "message-check",
      title: "SMS Activation",
      isActive: false,
    },
    children: [
      {
        path: '/applications-sms',
        name: 'applications-sms',
        component: AllAplicationsSMS,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          title: "All Applications",
          navTitle: "All Applications",
          mainTitle: 'SMS Activation',
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      //pending Sms view
      {
        path: '/pending-sms',
        name: 'pending-sms',
        component: PendingSmsApplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'SMS Activation',
          title: "Pending",
          navTitle: "Pending Applications",
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/activate-sms',
        name: 'activate-sms',
        component: ActivatedSmsApplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          title: "Activated",
          navTitle: "Activated Applications",
          mainTitle: 'SMS Activation',
          isActive: false,
          // icon: "account",
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/failed-sms',
        name: 'failed-sms',
        component: FailedSmsApplications,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          type: "menu-item",
          title: "Reject",
          navTitle: "Reject Applications",
          mainTitle: 'SMS Activation',
          isActive: false,
          // icon: "account",
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/sms-activation-view/:id?',
        name: 'sms-activation-view',
        component: SMSActivationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          // type: "menu-item",
          // title: "Failed",
         
          mainTitle: 'SMS Activation',
          navTitle: "Activated Applications",
          subtitle:'View',
          isActive: false,
          // icon: "account",
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/sms-failed-view/:id?',
        name: 'sms-failed-view',
        component: SMSActivationView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          // type: "menu-item",
          // title: "Failed",
          mainTitle: 'SMS Activation',
          navTitle: "Failed Application",
          subtitle: 'View',
          isActive: false,
          // icon: "account",
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
      {
        path: '/sms-pending-view/:id?',
        name: 'sms-pending-view',
        component: SMSPendingView,
        isVisible: function () {
          return User.hasPermission("application-list");
        },
        meta: {
          mainTitle: 'SMS Activation',
          navTitle: "Pending Applications",
          subtitle:'View',
          isActive: false,
          middleware: [Permission],
          permissions: ["application-list"],
        },
      },
     
    ]
  },
  {
    path: '/#',
    component: MainLayout,
    isVisible: function () {
      return (
        User.hasPermission("application-view") ||
        User.hasPermission("card-issue") ||
        User.hasPermission("card-reject")
        );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "credit-card-multiple",
      title: "Debit Card Applications",
      isActive: false,
    },

    children: [
      {
        path: '/debit-applications',
        name: 'debit-applications',
        component: DebitAllAplications,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        redirect: "",
        meta: {
          mainTitle: 'Debit Card Applications',
          title: "All Applications",
          navTitle: "All Applications",
          type: "menu-item",
          // icon: "credit-card",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
      {
        path: '/debit-pending-applications-view/:id?/:jointAcc?',
        name: 'debit-pending-applications-view',
        component: DebitPendingView,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        meta: {
          mainTitle: 'Debit Card Applications',
          title: "Pending",
          navTitle: "Pending Applications",
          subtitle: 'View',
          // icon: "credit-card-clock",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
      {
        path: '/debit-pending-applications',
        name: 'debit-pending-applications',
        component: DebitPending,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        meta: {
          mainTitle: 'Debit Card Applications',
          title: "Pending",
          navTitle: "Pending Applications",
          type: "menu-item",
          // icon: "credit-card-clock",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
      {
        path: '/debit-issued',
        name: 'debit-issued',
        component: DebitIssued,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        meta: {
          mainTitle: 'Debit Card Applications',
          title: "Approved",
          navTitle: "Approved Applications",
          type: "menu-item",
          isActive: false,
          // icon: "credit-card-fast",
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
      {
        path: '/debit-issued-applications-view/:id?/:jointAcc?',
        name: 'debit-issued-applications-view',
        component: DebitIssuedView,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        meta: {
          mainTitle: 'Debit Card Approve',
          title: "Approved",
          navTitle: "Approved Applications",
          subtitle: 'View',
          icon: "credit-card-clock",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
      {
        path: '/debit-reject',
        name: 'debit-reject',
        component: DebitRejected,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        meta: {
          mainTitle: 'Debit Card Applications',
          title: "Rejected",
          navTitle: "Rejected Applications",
          type: "menu-item",
          isActive: false,
          // icon: "credit-card-check",
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
      {
        path: '/debit-rejected-applications-view/:id?/:jointAcc?',
        name: 'debit-rejected-applications-view',
        component: DebitRejectedView,
        isVisible: function () {
          return User.hasPermission("application-view");
        },
        meta: {
          mainTitle: 'Debit Card Applications',
          title: "Rejected",
          navTitle: "Rejected Applications",
          subtitle: 'View',
          icon: "credit-card-clock",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["application-view"],
        },
      },
    ]
  },
  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      // return User.hasPermission("user-list");
      return (
        User.hasPermission("user-list") ||
        User.hasPermission("user-create") ||
        User.hasPermission("user-edit") ||
        User.hasPermission("user-delete") ||
        User.hasPermission("user-approve") ||
        User.hasPermission("password-reset") ||
        User.hasPermission("attempts-reset") ||
        User.hasPermission("role-list") ||
        User.hasPermission("role-create") ||
        User.hasPermission("role-edit") ||
        User.hasPermission("role-delete") ||
        User.hasPermission("role-approve")
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "account-multiple",
      title: "My Teams",
      isActive: false,
    },
    children: [
      {
        path: '/user',
        name: 'user',
        component: UserList,
        isVisible: function () {
          return User.hasPermission("user-list");
        },
        meta: {
          type: "menu-item",
          title: "Users",
          navTitle: "Users",
          mainTitle: 'My Teams',
          isActive: false,
          // icon: "account",
          middleware: [Auth, Permission],
          permissions: ["user-list"],
        },
      },
      {
        path: '/role',
        name: 'role',
        component: RoleList,
        isVisible: function () {
          return User.hasPermission("role-list");
        },
        meta: {
          type: "menu-item",
          title: "Role",
          navTitle: "Role",
          mainTitle: 'My Teams',
          isActive: false,
          // icon: "shield-account",
          middleware: [Auth, Permission],
          permissions: ["role-list"],
        },
      },
      {
        path: "/user/create-user",
        name: "create-user",
        component: CreateUser,
        meta: {
          title: "User",
          navTitle: "User",
          mainTitle: 'My Teams',
          subtitle: 'Create User',
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-create"],
        },
      },
      {
        path: "/role/create-role",
        name: "create-role",
        component: CreateRole,
        meta: {
          title: "Role",
          navTitle: "Role",
          mainTitle: 'My Teams',
          subtitle: 'Create Role',
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["role-create"],
        },
      },
      {
        path: "/user/new-user",
        name: "new-user",
        component: NewUser,
        meta: {
          title: "User",
          navTitle: "User",
          mainTitle: 'My Teams',
          subtitle: 'New Role',
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-list"],
        },
      },
      {
        path: "/user/edit-user/:id",
        name: "edit-user",
        component: EditUser,
        meta: {
          title: "User",
          navTitle: "User",
          mainTitle: 'My Teams',
          subtitle: 'Edit User',
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["user-edit"],
        },
      },
      {
        path: "/role/edit-role/:id",
        name: "edit-role",
        component: EditRole,
        meta: {
          title: "Role",
          navTitle: "Role",
          mainTitle: 'My Teams',
          subtitle: 'Edit Role',
          type: "",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["role-edit"],
        },
      },
    ]
  },

 
  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      return User.hasPermission("report-view");
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "file-chart",
      title: "Reports",
      isActive: false,
    },
    children: [
      {
        path: '/auidt-report',
        name: 'auidt-report',
        component: AuditReport,
        isVisible: function () {
          return User.hasPermission("report-view");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'Reports',
          title: "Audit Report",
          navTitle: "Audit Report",
          // icon: "chart-bar",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["report-view"],
        },
      },
      {
        path: '/activity-report',
        name: 'activity-report',
        component: ActivityReport,
        isVisible: function () {
          return User.hasPermission("report-view");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'Reports',
          title: "Activity Report",
          navTitle: "Activity Report",
          // icon: "chart-histogram",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["report-view"],
        },
      },
      {
        path: '/user-report',
        name: 'user-report',
        component: UserReport,
        isVisible: function () {
          return User.hasPermission("report-view");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'Reports',
          title: "User Report",
          navTitle: "User Report",
          // icon: "chart-tree",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["report-view"],
        },
      },
      {
        path: '/debit-card-report',
        name: 'debit-card-report',
        component: DebitCardReport,
        isVisible: function () {
          return User.hasPermission("report-view");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'Reports',
          title: "Debit Card Report",
          navTitle: "Debit Card Report",
          // icon: "chart-tree",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["report-view"],
        },
      },
      {
        path: '/sms-activation-report',
        name: 'sms-activation-report',
        component: SmsActivationReport,
        isVisible: function () {
          return User.hasPermission("report-view");
        },
        meta: {
          type: "menu-item",
          mainTitle: 'Reports',
          title: "SMS Activation Report",
          navTitle: "SMS Activation Report",
          // icon: "chart-tree",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["report-view"],
        },
      }
    ]
  },

  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      return (
        true
      );
    },
    redirect: "activity-log",
    meta: {
      type: "menu",
      icon: "arrow-u-up-left-bold",
      title: "Activity Log",
      isActive: false,
    },
    children: [
      {
        path: '/activity-log',
        name: 'activity-log',
        component: ActivityLog,
        isVisible: function () {
          true;
        },
        meta: {
          // type: "menu-item",
          title: "Activity Log",
          navTitle: "Activity Log",
          icon: "post",
          isActive: false,
          middleware: [Auth],
        },
      },
    ]
  },
  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      return (
        User.hasPermission("change-request-list") ||
        User.hasPermission("change-request-approve-reject")
      );
    },
    redirect: "changeReq",
    meta: {
      type: "menu",
      icon: "radar",
      title: "Change Requests",
      isActive: false,
    },
    children: [
      {
        path: '/changeReq',
        name: 'changeReq',
        component: ChangeRequests,
        isVisible: function () {
          return (
            User.hasPermission("change-request-list") ||
            User.hasPermission("hange-request-approve-reject")
          );
        },
        meta: {
          // type: "menu-item",
          title: "Change Requests",
          navTitle: "Change Requests",
          icon: "file-arrow-up-down",
          isActive: false,
          middleware: [Auth, Permission],
          permissions: ["change-request-list", "change-request-approve-reject"],
        },
      },
    ]
  },
  {
    path: "/#",
    component: MainLayout,
    isVisible: function () {
      return (
        true
      );
    },
    redirect: "",
    meta: {
      type: "menu",
      icon: "cog",
      title: "Settings",
      isActive: false,
    },
    children: [
      {
        path: '/profileManagement',
        name: 'profile-management',
        component: ProfileManagement,
        isVisible: function () {
          return (
            true
          );
        },
        meta: {
          type: "menu-item",
          title: "Profile Management",
          navTitle: "Profile Management",
          mainTitle: 'Settings',
          // icon: "face-man-profile",
          isActive: false,
          middleware: [Auth],
          // permissions: ["user-list"],
        },
      },
    ]
  },

  {
    path: "/user/resetPassword",
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      type: "",
      isActive: false,
    },
    props: (route) => ({ userData: route.params.userData }),
  },
  {
    path: "/user/OTPVerification",
    name: "OTPVerification",
    component: OTPVerification,
    meta: {
      type: "",
      isActive: false,
    },
    props: (route) => ({ otpData: route.params.otpData }),
  },
  {
    path: "/user/forgotPassword",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      type: "",
      isActive: false,
    },
  },

  //Sms Acivation PDF Download
  {
    path: '/sms-activation-pdf-download',
    name: 'SmsActivationPdf',
    component: SmsActivationPdf,
    isVisible: function () {
      return User.hasPermission("application-list");
    },
    redirect: "",
    meta: {
      isActive: false,
      middleware: [Permission],
      permissions: ["application-list"],
    },
  },
]

export default routes;